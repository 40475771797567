import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { SCREEN_TAG } from "@/shared/tagFile";
import Tabs from "@/shared/components/Tabs";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ScreenForm from "./ScreenForm";
import { getAllScreen } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";
import SEOForm from "./SEOForm";
import ApplicationScreenForm from "./ApplicationScreenForm";
import CloneApplicationScreenForm from "./CloneApplicationScreenForm";
import ScreenThemeTab from "./ScreenThemeTab";
import CloneScreenThemeForm from "./CloneScreenThemeForm";

const Screen = () => {
  const [screenDetails, setScreenDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddCloneModalOpen, setIsAddCloneModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [currentScreenId, setCurrentScreenId] = useState(0);
  const [screenValues, setScreenValues] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [showScreenThemeTab, setShowScreenThemeTab] = useState(false);
  const [cloneActiveTab, setCloneActiveTab] = useState("1");
  const [deleteScreen, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getScreenRecords,
    {
      data: screenList,
      isError: isScreenError,
      isLoading: isScreenLoading,
      isFetching: isScreenFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Path",
      accessor: "Path",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Screen Type",
      accessor: "ScreenTypeDTO.Name",
      filter: true,
    },
    {
      Header: "Content",
      accessor: "",
      filter: true,
      Cell: (value) => {
        return value?.row?.original?.Name ?
          <ul>
            <Link
              to= {{
                pathname: "/cms/screen-content",
                state: {
                  name: value?.row?.original?.Name,
                  title: "ScreenDTO"
                }
              }}
              className="disabledCursor"
            >
              { `${value?.row?.original?.Name}'s Content` }
            </Link>
          </ul> 
        : "-";
      }
    },
    {
      Header: "Meta Title",
      accessor: "MetaTitle",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Meta Description",
      accessor: "MetaDescription",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Meta Viewport",
      accessor: "MetaViewport",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Meta Charset",
      accessor: "MetaCharset",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "IsIndex",
      accessor: "IsIndex",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? "Yes" : "No";
      },
    },
    {
      Header: "IsFollow",
      accessor: "IsFollow",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? "Yes" : "No";
      },
    },
  ];

  const handleEditData = (screenData) => {
    setCurrentScreenId(screenData);
    setSelectedRecord(screenData);
    setIsAddModalOpen(true);
    if (screenData?.ScreenTypeId === 2) setShowScreenThemeTab(true)
  };

  const handleDelete = () => {
    deleteScreen({
      entity: "CMS/Screen",
      id: selectedRecord.Id,
      tag: SCREEN_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Screen deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getScreenRecords({
      entity: "CMS/Screens",
      entities: { ScreenType: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SCREEN_TAG,
    });
    setScreenDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!isScreenLoading && !isScreenError && screenList && !isScreenFetching) {
      dispatch(getAllScreen(screenList.value));
      let screenData = [...screenList.value];
      setScreenDetails({
        ...screenDetails,
        tableRowsData: screenList.value ? screenData : [],
        totalData:
          screenList && screenList["@odata.count"]
            ? screenList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onCloneClick: (data) => {
          setSelectedRecord({ Id: data.Id });
          setIsAddCloneModalOpen(true);
          setShowScreenThemeTab(false)
        }
      });
    }
    // eslint-disable-next-line
  }, [isScreenError, screenList, isScreenLoading, isScreenFetching]);

  const toggle = () => {
    setIsAddModalOpen(!isAddModalOpen);
    setActiveTab("1");
    setCurrentScreenId(0);
    setSelectedRecord();
  };

  const cloneToggle = () => {
    setIsAddCloneModalOpen(false);
    setCloneActiveTab("1");
    setCurrentScreenId(0);
    setSelectedRecord();
  };

  const tabs = {
    Screen: (
      <ScreenForm
        editData={selectedRecord ? selectedRecord : {}}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setScreenValues={setScreenValues}
      />
    ),
    SEO: (
      <SEOForm
        value={screenValues && screenValues}
        editData={selectedRecord ? selectedRecord : {}}
        setActiveTab={setActiveTab}
        setIsAddModalOpen={setIsAddModalOpen}
        isAddModalOpen={isAddModalOpen}
        setCurrentScreenId={setCurrentScreenId}
        setShowScreenThemeTab={setShowScreenThemeTab}
      />),
    "Application Screen": (
      <ApplicationScreenForm
        parentData={currentScreenId}
        setIsAddModalOpen={setIsAddModalOpen}
        isAddModalOpen={isAddModalOpen}
        toggle={toggle}
        parentId={currentScreenId?.id || currentScreenId || 0}
      />
    ),
    ...(showScreenThemeTab && (currentScreenId?.screenTypeId === 2 || currentScreenId?.ScreenTypeId === 2) && {
      "Screen Theme": (
        <ScreenThemeTab
          parentData={currentScreenId}
          setIsAddModalOpen={setIsAddModalOpen}
          isAddModalOpen={isAddModalOpen}
          toggle={toggle}
          parentId={currentScreenId?.id || currentScreenId || 0}
        />
      )
    })
  };

  const screenCloneTabs = {
    Screen: (
      <ScreenForm
        editData={selectedRecord ? selectedRecord : {}}
        activeTab={cloneActiveTab}
        setActiveTab={setCloneActiveTab}
        setScreenValues={setScreenValues}
      />
    ),
    SEO: (
      <SEOForm
        value={screenValues && screenValues}
        editData={selectedRecord ? selectedRecord : {}}
        setActiveTab={setCloneActiveTab}
        setIsAddModalOpen={cloneToggle}
        setCurrentScreenId={setCurrentScreenId}
        cloneScreen={true}
        setShowScreenThemeTab={setShowScreenThemeTab}
      />),
    "Application Screen": (
      <CloneApplicationScreenForm
        parentData={currentScreenId}
        setIsAddModalOpen={cloneToggle}
        setActiveTab={setCloneActiveTab}
        setCurrentScreenId={setCurrentScreenId}
        parentId={currentScreenId?.id || currentScreenId || 0}
      />
    ),
    ...(showScreenThemeTab && (currentScreenId?.screenTypeId === 2 || currentScreenId?.ScreenTypeId === 2 || currentScreenId?.ScreenModel?.ScreenTypeId === 2) && {
      "Screen Theme": (
        <CloneScreenThemeForm
          parentData={currentScreenId}
          setIsAddModalOpen={cloneToggle}
          parentId={currentScreenId?.ApplicationId || currentScreenId?.ScreenModel?.ApplicationModel || 0}
          setShowScreenThemeTab={setShowScreenThemeTab}
        />
      )
    })
  }

  const deleteMessage = () => {
    return (
      <div>
        Are you sure you want to delete {selectedRecord?.Name}?
        <div className="warning-div">
          <i>
            This will delete all the associated records <br />
            ( Like - Application screen, Screen Content, Screen Menu, Screen Theme)
          </i>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        {screenDetails && (
          <DataReactTable
            reactTableData={screenDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
              setShowScreenThemeTab(false)
            }}
            title="Screen"
            isFetching={isScreenFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={toggle}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Screen`}
          header
          btn="Success"
          message={
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
        />
      )}
      {isAddCloneModalOpen && (
        <AddModal
          toggle={cloneToggle}
          color="success"
          title={`Clone Screen`}
          header
          btn="Success"
          message={
            <Tabs
              tabs={screenCloneTabs}
              activeTab={cloneActiveTab}
              setActiveTab={setCloneActiveTab}
            />}
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Screen;
