import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { CONTACT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ContactForm = ({
  editData,
  setIsAddModalOpen = () => {},
  setActiveTab = () => {},
}) => {
  const [addContact, { isLoading: isContactAddLoading }] = useAddMutation();
  const [updateContact, { isLoading: isContactUpdateLoading }] =
    useUpdateMutation();

  const contactValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    Entity: Yup.mixed().required("Please select entity."),
    ContactType: Yup.mixed().required("Please select contact type."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const contactDetail = {
      Name: values.Name,
      Description: values.Description,
      EntityId: values.Entity.Id,
      ContactTypeId: values.ContactType.Id,
      WebAddress: values.WebAddress,
      EmailAddress: values.EmailAddress,
      PhoneNumber: values.PhoneNumber,
      HashTag: values.HashTag,
    };
    if (values.SortOrder) contactDetail.SortOrder = parseInt(values.SortOrder);
    if (editData && Object.entries(editData).length > 0) {
      contactDetail.Id = editData.Id;
      updateContact({
        entity: "Contact",
        isWogdApi: true,
        data: contactDetail,
        tag: CONTACT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContactUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Contact updated successfully.");
        }
      });
    } else {
      addContact({
        entity: "Contact",
        isWogdApi: true,
        data: contactDetail,
        tag: CONTACT_TAG,
      }).then((response) => {
        if (!response.error) {
          setActiveTab("1");
          !isContactAddLoading && setIsAddModalOpen(false);
          successToast("Contact added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        Entity: editData && editData.Entity ? editData.Entity : "",
        ContactType:
          editData && editData.ContactType ? editData.ContactType : "",
        WebAddress: editData && editData.WebAddress ? editData.WebAddress : "",
        EmailAddress:
          editData && editData.EmailAddress ? editData.EmailAddress : "",
        PhoneNumber:
          editData && editData.PhoneNumber ? editData.PhoneNumber : "",
        HashTag: editData && editData.HashTag ? editData.HashTag : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={contactValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Web Address"
              name="WebAddress"
              fieldErrors={errors.WebAddress}
              fieldTouched={touched.WebAddress}
              fieldValue={values.WebAddress}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Email Address"
              name="EmailAddress"
              fieldErrors={errors.EmailAddress}
              fieldTouched={touched.EmailAddress}
              fieldValue={values.EmailAddress}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Phone Number"
              name="PhoneNumber"
              fieldErrors={errors.PhoneNumber}
              fieldTouched={touched.PhoneNumber}
              fieldValue={values.PhoneNumber}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.Entity}
              label="Entity"
              name="Entity"
              entity="Entity"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Entity}
              fieldTouched={touched.Entity}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.ContactType}
              label="Contact Type"
              name="ContactType"
              entity="ContactType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.ContactType}
              fieldTouched={touched.ContactType}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isContactAddLoading || isContactUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isContactAddLoading || isContactUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ContactForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ContactForm;
