import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SortIcon from "mdi-react/SortIcon";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";

const Header = ({ column, isSortable, setSort }) => {
  useEffect(() => {
    if (column.isSorted && column.isSortedDesc !== undefined) {
      let order = column.isSortedDesc ? "DESC" : "ASC";
      if (column.id.includes(".")) {
        column.id = column.id.replace(".", "/");
      }
      setSort([
        {
          columnName: column.id,
          direction: order,
        },
      ]);
    } else {
      setSort([]);
    }
    // eslint-disable-next-line
  }, [column.isSorted, column.isSortedDesc]);

  return (
    <span
      className={`react-table__column-header ${
        !column.filter ? "react-table_column-header_no-filter" : ""
      }`}
    >
      <span
        className={isSortable ? `react-table__column-header sortable ` : ""}
      >
        {column.render("Header")}
      </span>
      {isSortable && column.canSort && column.Header !== "Action" && (
        <Sorting column={column} />
      )}
    </span>
  );
};

Header.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    render: PropTypes.func,
    canSort: PropTypes.bool,
  }).isRequired,
  isSortable: PropTypes.bool.isRequired,
  setSort: PropTypes.func.isRequired,
};

const Sorting = ({ column }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? (
      <SortIcon />
    ) : (
      <span>
        {column.isSortedDesc ? <SortDescendingIcon /> : <SortAscendingIcon />}
      </span>
    )}
  </span>
);

Sorting.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }).isRequired,
};

const getStylesResizable = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

const ReactTableHeader = ({
  headerGroups,
  isSortable,
  setSort,
  gotoPage,
  setColumnFilter,
  isPerformAction
}) => {
  const headerPropsSortable = (props, { column }) => {
    if (column.getSortByToggleProps && isSortable) {
      return column.getSortByToggleProps;
    }
    return { column };
  };
  const headerPropsResize = (props, { column }) => {
    if (column.getResizerProps) {
      return getStylesResizable(props, column.align);
    }
    return { column };
  };

  return (
    <thead className="thead th">
      {headerGroups.map((headerGroup) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          className="react-table thead tr"
        >
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(headerPropsSortable)}
              {...column.getHeaderProps(headerPropsResize)}
              {...column.getHeaderProps({ style: { width: column.width } })}
            >
              <Header
                column={column}
                isSortable={isSortable}
                setSort={setSort}
                setColumnFilter={setColumnFilter}
              />
              {column.filter && (
                <div className="filter-div mr-2">
                  <input
                    className="column-filter-input"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    placeholder={`Search ${column.Header}`}
                    onChange={(e) => {
                      gotoPage(0);
                      setColumnFilter((prev) => ({
                        ...prev,
                        [column.id]:
                          column.type && column.type === "Number"
                            ? parseInt(e.target.value)
                            : e.target.value,
                      }));
                    }}
                  />
                </div>
              )}
            </th>
          ))}
          <th
            colSpan="1"
            role="columnheader"
            style={{ position: "relative", width: "150px" }}
          >
            <span className="react-table__column-header react-table_column-header_no-filter">
              <span className="react-table__column-header sortable ">
                {isPerformAction && "Action"}
              </span>
            </span>
          </th>
        </tr>
      ))}
    </thead>
  );
};

ReactTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    })
  ).isRequired,
  isSortable: PropTypes.bool.isRequired,
  setSort: PropTypes.func.isRequired,
  setColumnFilter: PropTypes.func,
};

export default ReactTableHeader;
