import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { useHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { USER_TAG } from "@/shared/tagFile";
import { useAddMutation } from "@/services/gamecodeXApi";
import { SUPER_ADMIN, ADMIN, USER } from "@/shared/userRoles";
import { auth } from "@/redux/actions/authActions";
import Loading from "@/shared/components/Loading";

const background = `${process.env.PUBLIC_URL}/img/landing/header_bg.png`;
const img = `${process.env.PUBLIC_URL}/img/landing/macbook.png`;

const Header = ({ setIsAuthorized }) => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [addUser, { isLoading: isUserAddLoading }] = useAddMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      user.role = USER;
      Object.entries(user).forEach(([key, value]) => {
        if (key.includes("roles") && value.length > 0) {
          value.includes(SUPER_ADMIN) ? (user.role = SUPER_ADMIN) : value.includes(ADMIN) ? (user.role = ADMIN) : (user.role = value[0]);
        }
      });
      const userDetail = {
        Name: user.name,
        Description: user.Description ? user.Description : "",
        Email: user.email,
        SsoIdentifier: user.sub,
      };
      var accessToken = "";
      const getToken = async () => {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        localStorage.setItem("accessToken", accessToken);
        if (accessToken?.length > 0) {
          addUser({
            entity: "User/CreateUser",
            isWogdApi: true,
            data: userDetail,
            tag: USER_TAG,
          }).then((response) => {
            if (user.role === ADMIN || user.role === SUPER_ADMIN) {
              setCurrentUserId({UserId : response.data.id, EntityId: response.data.entityId});
              setIsLoading(false);
            } else {
              history.push("/");
              localStorage.removeItem("accessToken");
              setIsAuthorized(false);
              setIsLoading(false);
            }
          });
        }
      };
      getToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isUserAddLoading && currentUserId && isAuthenticated) {
      dispatch(
        auth({
          ...user,
          isAuthenticate: true,
          UserId: currentUserId.UserId,
          EntityId: currentUserId.EntityId
        })
      );
      history.push("/cms/application");
    }
  }, [isUserAddLoading, currentUserId.UserId, isAuthenticated]);

  return isLoading || isUserAddLoading ? (
    <Loading loading={isLoading} />
  ) : (
    <div
      className="landing__header"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Container>
        <Row>
          <Col className="landing_col_container" md={12}>
            <h2 className="landing__header-title">
              GameCodex Admin Tool
            </h2>
            <p className="landing__header-subhead">
              Manage your campaigns, books, websites, portfolios and other items in the GameCodex.
            </p>
            <NavLink
              className="landing__btn landing__btn--header"
              to="#"
              onClick={loginWithRedirect}
            >
              Enter the Admin Site
            </NavLink>
            <img className="landing__header-img" src={img} alt="macbook" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Header;
