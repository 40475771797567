import React, { useEffect, useState } from 'react';
import ImageMapper from "react-img-mapper";
import { Button, Col, Input, Label, Row, Table } from 'reactstrap';
import DeleteIcon from "mdi-react/DeleteIcon";
import AddModal from "@/shared/components/AddModal";

const initialState = {
  map: {
    name: "my-map",
    areas: [],
  },
};
const GetImageCoordinates = ({ label, formik, imageUrl, setIsCoordinatesModalOpen, isCoordinatesModalOpen }) => {
  const { values, setFieldValue } = formik;
  const [state, setState] = useState(initialState)
  const [hoveredArea, setHoveredArea] = useState(null);
  const [msg, setMsg] = useState(null);
  const [moveMsg, setMoveMsg] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [activePoints, setActivePoints] = useState([]);
  const [newContentData, setNewContentData] = useState({
    name: "",
    shape: "poly",
    coords: [],
    lineWidth: 3,
    strokeColor: "rgb(255, 99, 71)",
    preFillColor: "#5da0d02e",
  })

  const load = () => {
    setMsg("Interact with image !");
  }
  const clicked = (area) => {
    setMsg(`You clicked on ${area.shape} at coords ${JSON.stringify(area.coords)} !`);
  }
  const clickedOutside = (evt) => {
    if (newContentData?.shape === "circle" && coordinates?.length >= 1) {
      setNewContentData(prev => ({ ...prev, coords: [coordinates[0]?.x, coordinates[0]?.y, Math.sqrt((Math.abs(coordinates[0]?.x - evt.nativeEvent.layerX) ** 2) + (Math.abs(coordinates[0]?.y - evt.nativeEvent.layerY) ** 2))] }))
    } else {
      setCoordinates(prev => [...prev, { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY }])
      const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
      setMsg(`You clicked on the image at coords ${JSON.stringify(coords)} !`);
      setNewContentData(prev => ({ ...prev, coords: [...prev.coords, evt.nativeEvent.layerX, evt.nativeEvent.layerY] }))
    }
    setActivePoints(prev => [...prev, {
      name: coordinates.length + 1,
      shape: "circle",
      coords: [evt.nativeEvent.layerX, evt.nativeEvent.layerY, 5],
      lineWidth: 3,
      strokeColor: "rgb(255, 99, 71)",
      preFillColor: "#5da0d0",
    }])
  }
  const moveOnImage = (evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMoveMsg(`You moved on the image at coords ${JSON.stringify(coords)} !`);
  }
  const enterArea = (area) => {
    setHoveredArea(area)
    setMsg(`You entered ${area.shape} ${area.name} at coords ${JSON.stringify(area.coords)} !`);
  }
  const leaveArea = (area) => {
    setHoveredArea(null)
    setMsg(`You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(area.coords)} !`);
  }
  const moveOnArea = (area, evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMoveMsg(`You moved on ${area.shape} ${area.name} at coords ${JSON.stringify(coords)} !`);
  }
  const getTipPosition = (area) => {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }
  const clearState = () => {
    setHoveredArea(null)
    setState(initialState)
    setCoordinates([])
    setActivePoints([])
    setNewContentData(prev => ({ ...prev, coords: [], name: "" }))
    setIsCoordinatesModalOpen(!isCoordinatesModalOpen)
  }

  useEffect(()=> {
    if (values?.ContentObject[label]?.coordinates) {
      const temp = values?.ContentObject[label]?.coordinates
      setState(prev => ({ ...prev, map: { ...prev.map, areas: [...values?.ContentObject[label]?.coordinates.map(area => {
        return {
          name: area.name,
          shape: area.shape,
          coords: area.coords,
          lineWidth: 3,
          strokeColor: "rgb(255, 99, 71)",
          preFillColor: "#5da0d02e",
        }
      } )] } }))
    }
  }, [])

  return (
    <AddModal
      xxxl
      toggle={() => clearState()}
      color="success"
      title={`Add Image Coordinates`}
      header
      btn="Success"
      message={
        <div className="grid" style={{ height: "725px" }}>
          <div className="d-flex">
            <Col style={{ position: "relative" }}>
              <ImageMapper
                src={imageUrl}
                map={{ ...state.map, areas: [...state.map.areas, newContentData, ...activePoints] }}
                width={1000}
                height={610}
                onLoad={() => load()}
                onClick={(area) => clicked(area)}
                onMouseEnter={(area) => enterArea(area)}
                onMouseLeave={(area) => leaveArea(area)}
                onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
                onImageClick={(evt) => clickedOutside(evt)}
                onImageMouseMove={(evt) => moveOnImage(evt)}
              />
              {hoveredArea && (
                <span
                  className="tooltip"
                  style={{ ...getTipPosition(hoveredArea) }}
                >
                  {hoveredArea && hoveredArea.name}
                </span>
              )}

              <Row>
                <Col lg={4} sm={4} md={4}>
                  <Label for='name' className="mt-2">Coordinate Name</Label><span className="text-danger">*</span>
                  <Input
                    type="test"
                    name="name"
                    value={newContentData.name}
                    onChange={(e) => setNewContentData(prev => ({ ...prev, name: e.target.value }))}
                    placeholder='Enter coordinate name'
                  />
                  <div>
                    {!newContentData?.name && coordinates.length >= 2 ? <span className="text-danger"> Please Enter shape name and save the coordinates </span> : ""}
                  </div>
                </Col>
                <Col lg={2} sm={2} md={2} className="">
                  {coordinates.length >= 2 ? <Button className={`w-100 mb-0`} style={{ marginTop: "33px" }} color={"primary"} disabled={!newContentData?.name} onClick={(e) => {
                    e.preventDefault();
                    setState(prev => ({ ...prev, map: { ...prev.map, areas: [...prev.map.areas, newContentData] } }))
                    setCoordinates([]);
                    setActivePoints([]);
                    setNewContentData(prev => ({ ...prev, coords: [], name: "" }))
                  }}>Add Coords</Button> : ""}
                </Col>
              </Row>

            </Col>
            <Col>
              <h3 className="w-100 text-center font-italic font-weight-bold " >Image coordinates</h3>
              <div style={{ height: "300px", overflow: "auto", marginBottom: "5px" }}>
                <Table striped>
                  <thead>
                    <tr>
                      <th style={{ width: "50%", textAlign: "center" }}>X</th>
                      <th style={{ width: "50%", textAlign: "center" }}>Y</th>
                    </tr>
                  </thead>
                  <tbody >
                    {coordinates.map((area, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{area.x}</td>
                        <td style={{ textAlign: "center" }}>{area.y}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex">
                {<Button disabled={!coordinates?.length} className='w-50 mb-1' onClick={(e) => {
                  e.preventDefault();
                  setCoordinates([]);
                  setActivePoints([]);
                  setNewContentData(prev => ({ ...prev, coords: [] }))
                }}>Clear</Button>}
                {<Button disabled={!coordinates?.length} className='w-50 mb-1' onClick={(e) => {
                  e.preventDefault();
                  const temp = newContentData.coords
                  newContentData.shape === "circle" ? setNewContentData(prev => ({ ...prev, coords: [...newContentData.coords.slice(0, temp.length - 1)] })) :
                    setNewContentData(prev => ({ ...prev, coords: [...newContentData.coords.slice(0, temp.length - 2)] }))
                  const tempCoods = coordinates
                  tempCoods.pop();
                  setCoordinates([...tempCoods]);
                  const tempActivePoints = activePoints
                  tempActivePoints.pop();
                  setActivePoints([...tempActivePoints]);
                }}>Undo</Button>}
              </div>

              <Row>
                <>
                  <h3 className="w-100 text-center font-italic font-weight-bold " >Coordinates</h3>
                  <div style={{ height: "260px", minWidth : "95%", maxWidth :"95%", overflow: "auto", marginBottom: "5px" }}>
                    <Table striped>
                      <thead>
                        <tr>
                          <th style={{ width: "15%", textAlign: "left" }}>SR</th>
                          <th style={{ width: "70%", textAlign: "center" }}>COORDS</th>
                          <th style={{ width: "10%", textAlign: "center" }}>ACT</th>
                        </tr>
                      </thead>
                      <tbody >
                        {state.map.areas.length ? state.map.areas.map((area, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "left" }}>{index + 1}</td>
                            <td style={{ textAlign: "center", maxWidth: "200px", width:"200px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{area.coords.join(",")}</td>
                            <td> 
                              <div
                                className="cursor-pointer"
                                style={{ textAlign: "center"}}
                                onClick={() => {
                                  const temp = state.map.areas
                                  temp.splice(index, 1)
                                  setState(prev => ({ ...prev, map: { ...prev.map, areas: [...temp] } }))
                                }}
                              >
                                <DeleteIcon />
                              </div>
                            </td>
                          </tr>
                        ))
                        : 
                        <tr>
                          <td colspan="3">
                            No Coordinates exiest...
                          </td>
                        </tr>}
                      </tbody>
                    </Table>
                  </div>
                </>
              </Row>

              {state.map.areas.length || values?.ContentObject[label]?.coordinates?.length ?<div className="d-flex">
                <Button className="mb-0 w-50" onClick={(e) => {
                  e.preventDefault();
                  clearState()
                }}>Cancel</Button>
                <Button color="success" className="mb-0 w-50" onClick={(e) => {
                  e.preventDefault();
                  const contentObjectData = values?.ContentObject;
                  const tempCoordinates = state.map.areas.map((area) => {
                    return {
                      name: area.name,
                      shape: area.shape,
                      coords: area.coords
                    }
                  })
                  contentObjectData[label]["coordinates"] = tempCoordinates
                  setFieldValue("ContentObject", contentObjectData);
                  clearState()
                }}>{values?.ContentObject[label]?.coordinates?.length ? "Update" : "Save"}</Button>
              </div>:""}
            </Col>
          </div>
        </div>
      }
    />
  )
}

export default GetImageCoordinates
