import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { QUESTION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";

const QuestionForm = ({ editData, setIsAddModalOpen, isAddModalOpen }) => {
  const [addQuestion, { isLoading: isQuestionAddLoading }] = useAddMutation();
  const [updateQuestion, { isLoading: isQuestionUpdateLoading }] =
    useUpdateMutation();

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    QuestionType: Yup.mixed().required("Please select question type."),
  });

  const onSubmit = (values) => {
    let questionDetail = {
      Name: values.Name,
      Description: values.Description,
      PlaceholderText: values.PlaceholderText,
      QuestionTypeId: values.QuestionType.Id,
      IsRequired: values.IsRequired,
    };
    if (editData && Object.entries(editData).length > 0) {
      questionDetail.Id = editData.Id;
      updateQuestion({
        entity: "Question",
        isWogdApi: true,
        data: questionDetail,
        tag: QUESTION_TAG,
      }).then((response) => {
        if (!response.error) {
          !isQuestionUpdateLoading && setIsAddModalOpen(false);
          successToast("Question updated successfully.");
        }
      });
    } else {
      addQuestion({
        entity: "Question",
        isWogdApi: true,
        data: questionDetail,
        tag: QUESTION_TAG,
      }).then((response) => {
        if (!response.error) {
          !isQuestionAddLoading && setIsAddModalOpen(false);
          successToast("Question added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        PlaceholderText:
          editData && editData.PlaceholderText ? editData.PlaceholderText : "",
        QuestionType:
          editData && editData.QuestionType ? editData.QuestionType : "",
        IsRequired:
          editData && editData.IsRequired ? editData.IsRequired : false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Placeholder Text"
              name="PlaceholderText"
              fieldErrors={errors.PlaceholderText}
              fieldTouched={touched.PlaceholderText}
              fieldValue={values.PlaceholderText}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.QuestionType}
              label="Question Type"
              name="QuestionType"
              entity="QuestionType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.QuestionType}
              fieldTouched={touched.QuestionType}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Is this question required?"
              name="IsRequired"
              type="checkbox"
              fieldErrors={errors.IsRequired}
              fieldTouched={touched.IsRequired}
              fieldValue={values.IsRequired}
              handleBlur={handleBlur}
              isChecked={values.IsRequired}
              handleChange={(e) => {
                setFieldValue("IsRequired", e.target.checked);
              }}
            />
            <Button
              disabled={isQuestionAddLoading || isQuestionUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isQuestionAddLoading || isQuestionUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

QuestionForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default QuestionForm;
