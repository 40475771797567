import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import {
  ENTITY_TAG,
  RESPONSE_TAG,
  ENTITY_RESPONSE_TAG,
  ENTITY_RESPONSE_TYPE_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";

const EntityResponseForm = ({
  editData,
  setIsAddModalOpen,
  isAddModalOpen,
  parentData,
}) => {
  const [addRecord, { isLoading: isAddEntityResponseLoading }] =
    useAddMutation();
  const [updateRecord, { isLoading: isUpdateEntityResponseLoading }] =
    useUpdateMutation();

  const validationSchema = Yup.object().shape({
    Entity: Yup.mixed().required("Please select entity."),
    EntityResponseType: Yup.mixed().required(
      "Please select entity response type."
    ),
    Response: Yup.mixed().required("Please select response."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const EntityResponseDetail = {
      EntityId: values.Entity.Id,
      EntityResponseTypeId: values.EntityResponseType.Id,
      ResponseId: values.Response.Id,
    };
    if (values.SortOrder)
      EntityResponseDetail.SortOrder = parseInt(values.SortOrder);
    if (editData && Object.entries(editData).length > 0) {
      EntityResponseDetail.Id = editData.Id;
      updateRecord({
        entity: "EntityResponse",
        isWogdApi: true,
        data: EntityResponseDetail,
        tag: [
          ENTITY_RESPONSE_TAG,
          ENTITY_RESPONSE_TYPE_TAG,
          ENTITY_TAG,
          RESPONSE_TAG,
        ],
      }).then((response) => {
        if (!response.error) {
          !isAddEntityResponseLoading && setIsAddModalOpen(false);
          successToast("Entity-response updated successfully.");
        }
      });
    } else {
      addRecord({
        entity: "EntityResponse",
        isWogdApi: true,
        data: EntityResponseDetail,
        tag: [
          ENTITY_RESPONSE_TAG,
          ENTITY_RESPONSE_TYPE_TAG,
          ENTITY_TAG,
          RESPONSE_TAG,
        ],
      }).then((response) => {
        if (!response.error) {
          !isAddEntityResponseLoading && setIsAddModalOpen(false);
          successToast("Entity-response added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Entity: editData && editData.Entity ? editData.Entity : "",
        EntityResponseType:
          editData && editData.EntityResponseType
            ? editData.EntityResponseType
            : parentData && parentData.EntityResponseType
            ? parentData.EntityResponseType
            : "",
        Response: editData && editData.Response ? editData.Response : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Entity}
              label="Entity"
              name="Entity"
              entity="Entity"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Entity}
              fieldTouched={touched.Entity}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.EntityResponseType}
              label="EntityResponseType"
              name="EntityResponseType"
              entity="EntityResponseType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.EntityResponseType}
              fieldTouched={touched.EntityResponseType}
              readonly={parentData && parentData.EntityResponseType}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Response}
              label="Response"
              name="Response"
              entity="Response"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Response}
              fieldTouched={touched.Response}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={
                isAddEntityResponseLoading || isUpdateEntityResponseLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAddEntityResponseLoading ||
                isUpdateEntityResponseLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

EntityResponseForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.bool,
  parentData: PropTypes.object,
};

export default EntityResponseForm;
