import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { APPLICATION_CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ApplicationContentForm from "./ApplicationContentForm";
import { getAllApplicationScreen } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ApplicationContent = () => {
  const [applicationContentDetails, setApplicationContentDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteApplicationContent, { isLoading: isDeleting }] =
    useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [
    getApplicationContentRecords,
    {
      data: applicationContentList,
      isError: isApplicationContentError,
      isLoading: isApplicationContentLoading,
      isFetching: isApplicationContentFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Application Name",
      accessor: "ApplicationDTO.Name",
      filter: true,
    },
    {
      Header: "Content Name",
      accessor: "ContentDTO.Name",
      filter: true,
    },
  ];

  const handleEditData = (applicationContentData) => {
    setSelectedRecord(applicationContentData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteApplicationContent({
      entity: "CMS/ApplicationContent",
      id: selectedRecord.Id,
      tag: APPLICATION_CONTENT_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Application-content deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state?.name) {
      setColumnFilter({
        [`${location.state.title.split(" ").join("")}.Name`]:
          location.state.name,
      });
    }
    history.push({ pathname: location.pathname, state: {} });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getApplicationContentRecords({
      entity: "CMS/ApplicationContents",
      entities: { Content: {}, Application: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: APPLICATION_CONTENT_TAG,
    });
    setApplicationContentDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isApplicationContentLoading &&
      !isApplicationContentError &&
      applicationContentList &&
      !isApplicationContentFetching
    ) {
      dispatch(getAllApplicationScreen(applicationContentList.value));
      let applicationContentData = [...applicationContentList.value];
      setApplicationContentDetails({
        ...applicationContentDetails,
        tableRowsData: applicationContentList.value ? applicationContentData : [],
        totalData:
          applicationContentList && applicationContentList["@odata.count"]
            ? applicationContentList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isApplicationContentError,
    applicationContentList,
    isApplicationContentLoading,
    isApplicationContentFetching,
  ]);

  return (
    <Container>
      <Row>
        {applicationContentDetails && (
          <DataReactTable
            reactTableData={applicationContentDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Application Content"
            isFetching={isApplicationContentFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Application Content`}
          header
          btn="Success"
          message={
            <ApplicationContentForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default ApplicationContent;
