import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { successToast } from "@/shared/components/toast";
import { ROLE_TAG } from "@/shared/tagFile";

const RoleForm = ({ editData, setIsAddModalOpen }) => {
  const [addRole, { isLoading: isRoleAddLoading }] = useAddMutation();
  const [updateRole, { isLoading: isRoleUpdateLoading }] = useUpdateMutation();

  const roleValidationSchema = Yup.object().shape({
    Role: Yup.mixed().required("Please select role."),
    User: Yup.mixed().required("Please select user."),
  });

  const onSubmit = (values) => {
    const roleDetail = {
        RoleTypeId: values.Role.Id,
        UserId: values.User.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      roleDetail.Id = editData.Id;
      updateRole({
        entity: "SiteRole",
        isWogdApi: true,
        data: roleDetail,
        tag: ROLE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRoleUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Role updated successfully.");
        }
      });
    } else {
      addRole({
        entity: "SiteRole",
        isWogdApi: true,
        data: roleDetail,
        tag: ROLE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRoleAddLoading && setIsAddModalOpen(false);
          successToast("Role added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Role: editData && editData.RoleType ? editData.RoleType : "",
        User: editData && editData.User ? editData.User : "",
      }}
      validationSchema={roleValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Role}
              label="Role"
              name="Role"
              entity="RoleType"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Role}
              fieldTouched={touched.Role}
              handleBlur={handleBlur}
              isParentBaseUrl={true}
              required
            />
            <AsyncPaginates
              value={values.User}
              label="User"
              name="User"
              entity="User"
              setFieldValue={setFieldValue}
              fieldErrors={errors.User}
              fieldTouched={touched.User}
              handleBlur={handleBlur}
              isParentBaseUrl={true}
              required
            />
            <Button
              disabled={isRoleAddLoading || isRoleUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isRoleAddLoading || isRoleUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

RoleForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default RoleForm;
