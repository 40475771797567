import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { APPLICATION_MENU_TAG, MENU_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const AppMenuForm = ({ editData, setIsAddModalOpen }) => {
  const [addApplicationMenu, { isLoading: isApplicationMenuAddLoading }] =
    useAddMutation();
  const [
    updateApplicationMenu,
    { isLoading: isApplicationMenuUpdateLoading },
  ] = useUpdateMutation();

  const MenuValidationSchema = Yup.object().shape({
    Application: Yup.mixed().required("Please select application."),
    Menu: Yup.mixed().required("Please select Menu."),
  });

  const onSubmit = (values) => {
    const appMenuDetail = {
      ApplicationId: values.Application.Id,
      MenuId: values.Menu.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      appMenuDetail.Id = editData.Id;
      updateApplicationMenu({
        entity: "CMS/ApplicationMenu",
        data: appMenuDetail,
        tag: [APPLICATION_MENU_TAG, MENU_TAG],
      }).then((response) => {
        if (!response.error) {
          !isApplicationMenuUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Application-Menu updated successfully.");
        }
      });
    } else {
      addApplicationMenu({
        entity: "CMS/ApplicationMenu",
        data: appMenuDetail,
        tag: [APPLICATION_MENU_TAG, MENU_TAG],
      }).then((response) => {
        if (!response.error) {
          !isApplicationMenuAddLoading && setIsAddModalOpen(false);
          successToast("Application-Menu added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Application:
          editData && editData.ApplicationDTO ? editData.ApplicationDTO : "",
        Menu: editData && editData.MenuDTO ? editData.MenuDTO : "",
      }}
      validationSchema={MenuValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Application}
              label="Application"
              name="Application"
              entity="CMS/Applications"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Application}
              fieldTouched={touched.Application}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Menu}
              label="Menu"
              name="Menu"
              entity="CMS/Menus"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Menu}
              fieldTouched={touched.Menu}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={
                isApplicationMenuAddLoading ||
                isApplicationMenuUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isApplicationMenuAddLoading ||
                isApplicationMenuUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

AppMenuForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default AppMenuForm;
