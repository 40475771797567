import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation, useLazyGetRecordsQuery } from "@/services/gamecodeXApi";
import { successToast } from "@/shared/components/toast";
import { CONTRACT_MEDIA_TAG } from "@/shared/tagFile";

const ContractmediaForm = ({ parentId, toggle, editData, setIsAddModalOpen }) => {
    const [addContractMedia, { isLoading: isContractMediaAddLoading }] = useAddMutation();
    const [updateContractMedia, { isLoading: isContractMediaUpdateLoading }] = useUpdateMutation();
  
    const [
      getContractMediaRecords,
      {
        data: contractMediaList,
        isError: isContractMediaError,
        isLoading: isContractMediaLoading,
        isFetching: isContractMediaFetching,
      },
    ] = useLazyGetRecordsQuery();

  useEffect(() => {
    if (parentId && Object.keys(parentId)?.length < 3) {
      getContractMediaRecords({
        entity: "Doc/ContractMedias",
        entities: { Contract: {}, Media: {} },
        tag: CONTRACT_MEDIA_TAG,
        filter: parentId ? `ContractDTO/Id eq ${parentId.Id} and  contains(MediaDTO/Name,'layout')` : ""
      });
    }
  }, [parentId]);

    const contractMediaValidationSchema = Yup.object().shape({
      Contract: Yup.mixed().required("Please select ContractMedia."),
      Media: Yup.mixed().required("Please select media."),
    });
  
    const onSubmit = (values) => {
      const contractMediaDetail = {
          ContractId: values.Contract.Id,
          MediaId: values.Media.Id,
          ...(parentId && {LogoMediaId: values.LogoMedia.Id})
      };
  
      if ((editData && Object.entries(editData).length > 0) || contractMediaList?.value?.length) {
        contractMediaDetail.Id = contractMediaList?.value?.length ? contractMediaList?.value?.[0]?.Id : editData?.Id;
        updateContractMedia({
          entity: "Doc/ContractMedia",
          data: contractMediaDetail,
          tag: CONTRACT_MEDIA_TAG,
        }).then((response) => {
          if (!response.error) {
            !isContractMediaUpdateLoading && setIsAddModalOpen(false)
            successToast("Contract Media updated successfully.");
            parentId && toggle()
          }
        });
      } else {
        addContractMedia({
          entity: `Doc/${parentId ? "AddContractMedia" : "ContractMedia"}`,
          data: contractMediaDetail,
          tag: CONTRACT_MEDIA_TAG,
        }).then((response) => {
          if (!response.error) {
            !isContractMediaAddLoading && setIsAddModalOpen(false);
            successToast("Contract Media added successfully.");
            parentId && toggle()
          }
        });
      }
    };
  
    return (
      <Formik
        initialValues={{
          Contract: editData?.ContractDTO || parentId || "",
          Media: editData?.MediaDTO || contractMediaList?.value?.[0]?.MediaDTO || "",
          LogoMedia: ""
        }}
        enableReinitialize
        validationSchema={contractMediaValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          } = formik;
  
          return (
            <Form>
              {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
              <AsyncPaginates
                value={values.Contract}
                label="Contract"
                name="Contract"
                entity="Doc/Contracts"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Contract}
                fieldTouched={touched.Contract}
                handleBlur={handleBlur}
                required
                readonly={parentId}
              />
              <AsyncPaginates
                value={values.Media}
                label={parentId ? "Contract Layout" : "Media"}
                name="Media"
                customFieldName={"CustomName"}
                entity="Doc/DocMedias"
                filter={parentId ? "contains(tolower(name),'layout')" : ""}
                setFieldValue={setFieldValue}
                fieldErrors={errors.Media}
                fieldTouched={touched.Media}
                handleBlur={handleBlur}
                required
              />
              {parentId && <AsyncPaginates
                value={values.LogoMedia}
                label={"Contract Logo"}
                name="LogoMedia"
                customFieldName={"CustomName"}
                entity="Doc/DocMedias"
                filter={parentId ? "contains(tolower(name),'logo')" : ""}
                setFieldValue={setFieldValue}
                fieldErrors={errors.LogoMedia}
                fieldTouched={touched.LogoMedia}
                handleBlur={handleBlur}
              />}
              <Button
                disabled={isContractMediaAddLoading || isContractMediaUpdateLoading || isContractMediaFetching || isContractMediaLoading}
                onClick={handleSubmit}
                className="modal_ok float-right"
                color="success"
              >
                {(isContractMediaAddLoading || isContractMediaUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  };
  
  ContractmediaForm.propTypes = {
    editData: PropTypes.object.isRequired,
    setIsAddModalOpen: PropTypes.func.isRequired,
  };
  
export default ContractmediaForm
