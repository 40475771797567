import React from "react";
import PropTypes from "prop-types";
import { Button, Label, Spinner } from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { CONTRACT_CLAUSE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const isOptional = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ]

const ContractClauseForm = ({ editData, setIsAddModalOpen, parentData }) => {
  const [addContractClause, { isLoading: isContractClauseAddLoading }] =
    useAddMutation();
  const [updateContractClause, { isLoading: isContractClauseUpdateLoading }] =
    useUpdateMutation();

  const contractClauseValidationSchema = Yup.object().shape({
    Contract: Yup.mixed().required("Please select contract."),
    Clause: Yup.mixed().required("Please select clause."),
    Sequence: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const contractClauseDetail = {
      ContractId: values.Contract.Id,
      ClauseId: values.Clause.Id,
      Comments: values.Comments,
      Sequence: values.Sequence ? parseInt(values.Sequence) : null,
      IsOptional: values.IsOptional.value,
    };
    if (editData && Object.entries(editData).length > 0) {
      contractClauseDetail.Id = editData.Id;
      updateContractClause({
        entity: "Doc/ContractClause",
        data: contractClauseDetail,
        tag: CONTRACT_CLAUSE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContractClauseAddLoading && setIsAddModalOpen(false);
          successToast("Contract-clause updated successfully.");
        }
      });
    } else {
      addContractClause({
        entity: "Doc/ContractClause",
        data: contractClauseDetail,
        tag: CONTRACT_CLAUSE_TAG,
      }).then((response) => {
          if (!response.error) {
              !isContractClauseAddLoading && setIsAddModalOpen(false);
              successToast("Contract-clause added successfully.");
            }
        });
    }
};

  return (
    <Formik
      initialValues={{
        Contract:
          editData && editData.ContractDTO
            ? editData.ContractDTO
            : "",
        Clause:
          editData && editData.ClauseDTO
            ? editData.ClauseDTO
            : "",
        Comments: editData ? editData.Comments : "",
        Sequence:
            editData?.Sequence === 0
              ? 0
              : editData?.Sequence
              ? editData?.Sequence
              : 999,
        IsOptional: editData?.IsOptional ?  isOptional[0] : isOptional[1],
      }}
      validationSchema={contractClauseValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Contract}
              label="Contract"
              name="Contract"
              entity="Doc/Contracts"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Contract}
              fieldTouched={touched.Contract}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Contract}
              required
            />
            <AsyncPaginates
              value={values.Clause}
              label="Clause"
              name="Clause"
              entity="Doc/Clauses"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Clause}
              fieldTouched={touched.Clause}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Clause}
              clauseId = {values.Clause?.ClauseTypeId}
              required
            />
            <CustomInput
              label="Comments"
              name="Comments"
              type="textarea"
              fieldErrors={errors.Comments}
              fieldTouched={touched.Comments}
              fieldValue={values.Comments}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="mb-3">
              <Label for="IsOptional">
                IsOptional
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="IsOptional"
                value={values.IsOptional || isOptional[0]}
                options={isOptional}
                placeholder={`Select IsOptional`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("IsOptional", e)}
              />
            </div>
            <CustomInput
              label="Sequence"
              name="Sequence"
              type="number"
              fieldErrors={errors.Sequence}
              fieldTouched={touched.Sequence}
              fieldValue={values.Sequence}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={
                isContractClauseAddLoading || isContractClauseUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isContractClauseAddLoading || isContractClauseUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ContractClauseForm.propTypes = {
  parentData: PropTypes.object,
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
};

export default ContractClauseForm;
