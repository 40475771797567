import React, { useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    useLazyGetRecordsQuery,
    useAddMutation,
    useDeleteMutation,
    useUpdateMutation,
} from "@/services/gamecodeXApi";
import Modal from "@/shared/components/Modal";
import AsyncPaginates from "@/shared/components/LazySelect";
import { SCREEN_THEME_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenThemeTab = ({
    value,
    parentData,
    setIsAddModalOpen,
    isAddModalOpen,
}) => {
    const [screenThemeDetails, setScreenThemeDetails] = useState();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [addScreenTheme, { isLoading: isScreenThemeAddLoading }] =
        useAddMutation();

    const [
        getScreenThemeRecords,
        {
            data: screenThemeList,
            isError: isScreenThemeError,
            isLoading: isScreenThemeLoading,
            isFetching: isScreenThemeFetching,
        },
    ] = useLazyGetRecordsQuery();

    const [deleteScreenTheme, { isLoading: isDeleting }] = useDeleteMutation();
    const [updateScreenTheme, { isLoading: isScreenThemeUpdateLoading }] = useUpdateMutation();

    const handleDelete = () => {
        deleteScreenTheme({
            entity: "CMS/ScreenTheme",
            id: selectedRecord,
            tag: SCREEN_THEME_TAG,
        }).then((response) => {
            if (!response.error) {
                !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
                successToast("Screen-theme deleted successfully.");
            }
        });
    };

    useEffect(() => {
        if (parentData?.Id || parentData?.id) {
            getScreenThemeRecords({
                entity: "CMS/ScreenThemes",
                entities: { Screen: {}, Theme: {} },
                top: pageSize,
                skip: (page - 1) * pageSize,
                filter: `(ScreenId eq ${parentData?.id || parentData?.Id})`,
                tag: SCREEN_THEME_TAG,
            });
            setScreenThemeDetails({ tableHeaderData: column, tableRowsData: [] });
        }
        // eslint-disable-next-line
    }, [parentData, pageSize, page, sort]);

    useEffect(() => {
        if (!isScreenThemeLoading && !isScreenThemeError && screenThemeList && !isScreenThemeFetching) {
            if (Object.keys(parentData)?.length > 0) {
                setScreenThemeDetails({
                    ...screenThemeDetails,
                    tableRowsData: screenThemeList.value
                        ? screenThemeList.value
                        : [],
                    totalData:
                        screenThemeList && screenThemeList["@odata.count"]
                            ? screenThemeList["@odata.count"]
                            : 0,
                    onEditClick: (data) => handleEditData(data),
                    onDeleteClick: (data) => {
                        setIsDeleteModalOpen(true);
                        setSelectedRecord(data.Id);
                    },
                });
            }
        }
        // eslint-disable-next-line
    }, [
        screenThemeList,
        isScreenThemeError,
        isScreenThemeLoading,
        parentData,
        isScreenThemeFetching,
    ]);

    const column = [
        {
            Header: "#",
            accessor: "Id",
        },
        {
            Header: "Screen",
            accessor: "ScreenDTO.Name",
        },
        {
            Header: "Theme",
            accessor: "ThemeDTO.Name",
        }
    ];

    const handleEditData = (applicationScreenData) => {
        setSelectedRecord({ ...applicationScreenData });
        setIsAddModalOpen(true);
    };

    const formValidationSchema = Yup.object().shape({
        Theme: Yup.mixed().required("Please select screen."),
    });

    const onSubmit = (values) => {
        const screenThemeDetail = {
            ThemeId: values.Theme.Id,
            ScreenId: parentData.id || parentData.Id,
        };

        if (selectedRecord?.Id) {
            screenThemeDetail.Id = selectedRecord.Id;
            updateScreenTheme({
                entity: "CMS/ScreenTheme",
                data: screenThemeDetail,
                tag: SCREEN_THEME_TAG,
            }).then((response) => {
                if (!response.error) {
                    setSelectedRecord();
                    successToast("Screen-theme updated successfully.");
                }
            });
        } else {
            addScreenTheme({
                entity: "CMS/ScreenTheme",
                data: screenThemeDetail,
                responseType: 'text',
                tag: SCREEN_THEME_TAG,
            }).then((response) => {
                if (!response?.error) {
                    successToast(response?.data);
                }
            });
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    Theme: "",
                    Screen: parentData ? parentData : "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    const {
                        errors,
                        touched,
                        values,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    } = formik;
                    return (
                        <Form className="mt-2">
                            <AsyncPaginates
                                value={values.Theme || selectedRecord?.ThemeDTO || ""}
                                label="Theme"
                                name="Theme"
                                entity="CMS/Themes"
                                setFieldValue={setFieldValue}
                                fieldErrors={errors.Theme}
                                fieldTouched={touched.Theme}
                                handleBlur={handleBlur}
                                required
                            />
                            <AsyncPaginates
                                value={parentData}
                                label="Screen"
                                name="Screen"
                                setFieldValue={setFieldValue}
                                fieldErrors={errors.Screen}
                                fieldTouched={touched.Screen}
                                handleBlur={handleBlur}
                                readonly={parentData && parentData}
                            />
                            <div className="modal_ok d-flex justify-content-end">
                                <Button
                                    type="reset"
                                    onClick={() => {
                                        handleSubmit() && formik.resetForm();
                                    }}
                                    color="success"
                                >
                                    {isScreenThemeAddLoading && (
                                        <Spinner size="sm" variant="light" />
                                    )}{" "}
                                    {selectedRecord?.Id ? "Update" : "Save"}
                                </Button>
                                {selectedRecord?.Id && (
                                    <Button
                                        type="reset"
                                        onClick={() => {
                                            setSelectedRecord();
                                            formik.resetForm();
                                        }}
                                        color="danger"
                                    >
                                        Clear
                                    </Button>)}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <div>
                {screenThemeDetails && (
                    <DataReactTable
                        reactTableData={screenThemeDetails}
                        onAddClick={() => { }}
                        title="Screen Theme"
                        headerContent={false}
                        isFetching={isScreenThemeFetching}
                        setPageNumber={setPage}
                        recordsPerPage={pageSize}
                        setRecordsPerPage={setPageSize}
                        setSort={setSort}
                    />
                )}
                {isDeleteModalOpen && (
                    <Modal
                        color="danger"
                        title="Delete"
                        message="Are you sure you want to delete?"
                        handleOkClick={() => handleDelete()}
                        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                        isDeleting={isDeleting}
                    />
                )}
            </div>
        </>
    );
};

export default ScreenThemeTab;