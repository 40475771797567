import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TopbarProfile from "../components/topbar/TopbarProfile";
import TopbarSidebarButton from "../components/topbar/TopbarSidebarButton";
import TopbarNav from "./topbar_nav/TopbarNav";

const TopbarWithNavigation = ({ changeMobileSidebarVisibility }) => (
  <div className="topbar topbar--navigation">
    <div className="topbar__left">
      <TopbarSidebarButton
        onClickMobile={changeMobileSidebarVisibility}
        onClickDesktop={changeMobileSidebarVisibility}
      />
      <Link className="topbar__logo" to="/cms/application" />
    </div>
    <TopbarNav />
    <div className="topbar__right">
      <div className="topbar__right-over">
        <TopbarProfile />
      </div>
    </div>
  </div>
);

TopbarWithNavigation.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default TopbarWithNavigation;
