import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import {
  ENTITY_RESPONSE_TYPE_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import DetailPage from "@/shared/components/SharedDetailPage";
import { getAllEntityResponseTypes } from "../../CommSlice";
import EntityResponseTypeForm from "./EntityResponseTypeForm";
import EntityResponseForm from "../EntityResponse/EntityResponseForm";
import "react-toastify/dist/ReactToastify.css";

const EntityResponseType = () => {
  const [isEyeClicked, setIsEyeClicked] = useState(false);
  const [entityResponseTypeDetails, setEntityResponseTypeDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteEntityResponseType, { isLoading: isDeleting }] =
    useDeleteMutation();
  const [deleteRecord, setDeleteRecord] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getEntityResponseTypeRecords,
    {
      data: entityResponseTypeList,
      isError: isEntityResponseTypeError,
      isLoading: isEntityResponseTypeLoading,
      isFetching: isEntityResponseTypeFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (entityResponseTypeData) => {
    setSelectedRecord(entityResponseTypeData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteEntityResponseType({
      entity: deleteRecord
        ? deleteRecord.entity && deleteRecord.entity === "EntityResponse"
          ? "EntityResponse"
          : "EntityResponse"
        : "EntityResponseType",
      isWogdApi: true,
      id: deleteRecord && deleteRecord.Id ? deleteRecord.Id : selectedRecord.Id,
      tag: ENTITY_RESPONSE_TYPE_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(
          `${
            deleteRecord && deleteRecord.entity
              ? deleteRecord.entity
              : "Entity-response-type"
          } deleted successfully.`
        );
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getEntityResponseTypeRecords({
      entity: "EntityResponseType",
      isWogdApi: true,
      expand: { EntityResponses: { expand: { Entity: {}, response: {} } } },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: ENTITY_RESPONSE_TYPE_TAG,
    });
    setEntityResponseTypeDetails({
      tableHeaderData: column,
      tableRowsData: [],
    });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isEntityResponseTypeLoading &&
      !isEntityResponseTypeError &&
      entityResponseTypeList &&
      !isEntityResponseTypeFetching
    ) {
      dispatch(getAllEntityResponseTypes(entityResponseTypeList.value));
      let entityResponseTypeData = [...entityResponseTypeList.value];
      if (selectedRecord) {
        setSelectedRecord((prev) =>
          entityResponseTypeList.value.find((value) => value.Id === prev.Id)
        );
      }
      entityResponseTypeData = entityResponseTypeList.value.map(
        (entityResponseType) => {
          let newEntityResponseTypeData = { ...entityResponseType };
          return newEntityResponseTypeData;
        }
      );
      setEntityResponseTypeDetails({
        ...entityResponseTypeDetails,
        tableRowsData: entityResponseTypeList.value
          ? entityResponseTypeData
          : [],
        totalData:
          entityResponseTypeList && entityResponseTypeList["@odata.count"]
            ? entityResponseTypeList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => {
          setIsEyeClicked(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isEntityResponseTypeError,
    entityResponseTypeList,
    isEntityResponseTypeLoading,
    isEntityResponseTypeFetching,
  ]);

  const crossLinkColumns = {
    "Entity Response": [
      { header: "Entity Name", accessor: "Entity.Name" },
      { header: "Response Name", accessor: "Response.Name" },
    ],
  };

  return (
    <Container>
      <Row>
        {isEyeClicked && (
          <DetailPage
            setIsEyeClicked={() => {
              setIsEyeClicked(false);
              setSelectedRecord();
              setDeleteRecord();
            }}
            title="Entity Response Type"
            displayData={selectedRecord}
            restContent={
              selectedRecord
                ? {
                    Name:
                      selectedRecord && selectedRecord.Name
                        ? selectedRecord.Name
                        : "-",
                    Description: selectedRecord.Description
                      ? selectedRecord.Description
                      : "-",
                  }
                : null
            }
            crossLinkData={{
              "Entity Response": {
                totalRecords: selectedRecord.EntityResponses.length,
                records: [...selectedRecord.EntityResponses.slice(0, 3)],
                colorStyle: "success",
                redirectUrl: "/comm/entity-response",
                addForm: (
                  addFormActiveTab,
                  addFormSetActiveTab,
                  isCrossLinkAddModalOpen,
                  setIsCrossLinkAddModalOpen
                ) => (
                  <EntityResponseForm
                    parentData={{ EntityResponseType: selectedRecord }}
                    setIsAddModalOpen={setIsCrossLinkAddModalOpen}
                    isAddModalOpen={isCrossLinkAddModalOpen}
                  />
                ),
              },
            }}
            crossLinkColumns={crossLinkColumns}
            setIsAddModalOpen={setIsAddModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setDeleteRecord={setDeleteRecord}
          />
        )}
        {entityResponseTypeDetails && !isEyeClicked && (
          <DataReactTable
            reactTableData={entityResponseTypeDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Entity Response Type"
            isFetching={isEntityResponseTypeFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => {
            if (!isEyeClicked) setSelectedRecord();
            setIsAddModalOpen(!isAddModalOpen);
          }}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Entity Response Type`}
          header
          btn="Success"
          message={
            <div>
              <EntityResponseTypeForm
                editData={
                  selectedRecord
                    ? selectedRecord
                    : isEyeClicked
                    ? isEyeClicked
                    : {}
                }
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
              />
            </div>
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default EntityResponseType;
