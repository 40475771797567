import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { ROLE_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import RoleTypeForm from "./RoleTypeForm";
import { getAllRoleTypes } from "../../../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const RoleType = () => {
  const [roleTypeDetails, setRoleTypeDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteRoleType, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getRoleTypeRecords,
    {
      data: roleTypeList,
      isError: isRoleTypeError,
      isLoading: isRoleTypeLoading,
      isFetching: isRoleTypeFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Auth Role Id",
      accessor: "AuthRoleId",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Parent",
      accessor: "RoleTypeParentDTO.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (roleTypeData) => {
    setSelectedRecord(roleTypeData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteRoleType({
      entity: "DocReference/DocRoleType",
      id: selectedRecord.Id,
      tag: ROLE_TYPE_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Role-type deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getRoleTypeRecords({
      entity: "DocReference/DocRoleTypes",
      entities: { RoleTypeParent: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: ROLE_TYPE_TAG,
    });
    setRoleTypeDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isRoleTypeLoading &&
      !isRoleTypeError &&
      roleTypeList &&
      !isRoleTypeFetching
    ) {
      dispatch(getAllRoleTypes(roleTypeList.value));
      let roleTypeData = [...roleTypeList.value];
      setRoleTypeDetails({
        ...roleTypeDetails,
        tableRowsData: roleTypeList.value ? roleTypeData : [],
        totalData:
          roleTypeList && roleTypeList["@odata.count"]
            ? roleTypeList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isRoleTypeError, roleTypeList, isRoleTypeLoading, isRoleTypeFetching]);

  return (
    <Container>
      <Row>
        {roleTypeDetails && (
          <DataReactTable
            reactTableData={roleTypeDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Role Type"
            isFetching={isRoleTypeFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Role Type`}
          header
          btn="Success"
          message={
            <RoleTypeForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default RoleType;
