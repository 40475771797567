import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { TAG_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import TagForm from "./TagForm";
import { getAllTag } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const Tag = () => {
    const [appDetails, setAppDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteApp, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const [
      getTagRecords,
      {
        data: tagList,
        isError: isTagError,
        isLoading: isTagLoading,
        isFetching: isTagFetching,
      },
    ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Name",
        accessor: "Name",
        filter: true,
      },
      {
        Header: "Description",
        accessor: "Description",
        filter: true,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Tag Type",
        accessor: "TagTypeDTO.Name",
        filter: true,
      },
    ];

    const handleEditData = (tagData) => {
      setSelectedRecord(tagData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteApp({
        entity: "CMS/Tag",
        id: selectedRecord.Id,
        tag: TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Tag deleted successfully.");
        }
      });
    };
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
  
      getTagRecords({
        entity: "CMS/Tags",
        entities: {TagType : {}},
        top: pageSize,
        skip: (page - 1) * pageSize,
        sort: sort,
        filter: filterQuery,
        globalFilter: {
          columns: filterColumn,
          globalSearchKeyword: globalSearchKeyword,
        },
        tag: TAG_TAG,
      });
      setAppDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);
  
    useEffect(() => {
      if (
        !isTagLoading &&
        !isTagError &&
        tagList &&
        !isTagFetching
      ) {
        dispatch(getAllTag(tagList.value));
        let appData = [...tagList.value];
        setAppDetails({
          ...appDetails,
          tableRowsData: tagList.value ? appData : [],
          totalData:
            tagList && tagList["@odata.count"]
              ? tagList["@odata.count"]
              : 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
          },
        });
      }
      // eslint-disable-next-line
    }, [
      isTagError,
      tagList,
      isTagLoading,
      isTagFetching,
    ]);
  
    const deleteMessage = () => {
      return (
        <div>
          Are you sure you want to delete {selectedRecord.Name}?
          <div className="warning-div">
            <i>
              This will delete all the associated records <br />
              ( Like - Screen Tag, Content Tag)
            </i>
          </div>
        </div>
      );
    }
  
    return (
      <Container data-testid="test-app">
        <Row>
          {appDetails && (
            <DataReactTable
              reactTableData={appDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              title="Tag"
              isFetching={isTagFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            testid="addModal"
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Tag`}
            header
            btn="Success"
            message={
                <TagForm
                    editData={selectedRecord ? selectedRecord : {}}
                    setIsAddModalOpen={()=>setIsAddModalOpen(!isAddModalOpen)}
                />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={deleteMessage()}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    );
}

export default Tag
