import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useLazyGetRecordsQuery,
  useAddMutation,
  useDeleteMutation,
  useUpdateMutation,
} from "@/services/gamecodeXApi";
import Modal from "@/shared/components/Modal";
import AsyncPaginates from "@/shared/components/LazySelect";
import { APPLICATION_SCREEN_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { color, use } from "echarts";

const ApplicationScreenForm = ({
  parentData,
  setIsAddModalOpen,
  isAddModalOpen,
}) => {

  const [applicationScreenDetails, setApplicationScreenDetails] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [addApplicationScreen, { isLoading: isApplicationScreenAddLoading }] =
  useAddMutation();
  
  const [
    getApplicationScreenRecords,
    {
      data: applicationScreenList,
      isError: isApplicationScreenError,
      isLoading: isApplicationScreenLoading,
      isFetching: isApplicationscreenFetching,
    },
  ] = useLazyGetRecordsQuery();

  const [deleteApplicationScreen, { isLoading: isDeleting }] =
  useDeleteMutation();
  const [
    updateApplicationScreen,
    { isLoading: isApplicationScreenUpdateLoading },
  ] = useUpdateMutation();

  const handleDelete = () => {
    deleteApplicationScreen({
      entity: "CMS/ApplicationScreen",
      id: selectedRecord,
      tag: APPLICATION_SCREEN_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Application-screen deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if(parentData?.Id || parentData?.id){
      getApplicationScreenRecords({
        entity: "CMS/ApplicationScreens",
        entities: { Screen: {}, Application: {} },
        top: pageSize,
        skip: (page - 1) * pageSize,
        filter: `(ScreenId eq ${parentData?.id || parentData?.Id})`,
        sort: sort,
        tag: APPLICATION_SCREEN_TAG,
      });
      setApplicationScreenDetails({ tableHeaderData: column, tableRowsData: [] });
    }
    // eslint-disable-next-line
  }, [parentData, pageSize, page, sort]);

  useEffect(() => {
    if (
      !isApplicationScreenLoading &&
      !isApplicationScreenError &&
      applicationScreenList &&
      !isApplicationscreenFetching
    ) {
      if (Object.keys(parentData)?.length > 0) {
        setApplicationScreenDetails({
          ...applicationScreenDetails,
          tableRowsData: applicationScreenList.value
            ? applicationScreenList.value
            : [],
          totalData:
            applicationScreenList && applicationScreenList["@odata.count"]
              ? applicationScreenList["@odata.count"]
              : 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data.Id);
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [
    applicationScreenList,
    isApplicationScreenError,
    isApplicationScreenLoading,
    parentData,
    isApplicationscreenFetching,
  ]);

  const column = [
    {
      Header: "#",
      accessor: "Id",
    },
    {
      Header: "Screen",
      accessor: "ScreenDTO.Name",
    },
    {
      Header: "Application",
      accessor: "ApplicationDTO.Name",
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (applicationScreenData) => {
    setSelectedRecord({...applicationScreenData});
    setIsAddModalOpen(true);
  };

  const applicationValidationSchema = Yup.object().shape({
    Application: Yup.mixed().required("Please select screen."),
  });

  const onSubmit = (values) => {
    const applicationScreenDetail = {
      ApplicationId: values.Application.Id,
      ScreenId: parentData.id || parentData.Id,
    };

    if (selectedRecord?.Id) {
      applicationScreenDetail.Id = selectedRecord.Id;
      updateApplicationScreen({
        entity: "CMS/ApplicationScreen",
        data: applicationScreenDetail,
        tag: APPLICATION_SCREEN_TAG,
      }).then((response) => {
        if (!response.error) {
          setSelectedRecord();
          successToast("Application-screen updated successfully.");
        }
      });
    }else {
      addApplicationScreen({
        entity: "CMS/ApplicationScreen",
        data: applicationScreenDetail,
        tag: APPLICATION_SCREEN_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Application-screen added successfully.");
        }
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          Application: "",
          Screen: parentData ? parentData : "",
        }}
        validationSchema={applicationValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          } = formik;
          return (
            <Form className="mt-2">
              <AsyncPaginates
                value={values.Application || selectedRecord?.ApplicationDTO || "" }
                label="Application"
                name="Application"
                entity="CMS/Applications"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Application}
                fieldTouched={touched.Application}
                handleBlur={handleBlur}
                required
                />
              <AsyncPaginates
                value={parentData}
                label="Screen"
                name="Screen"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Screen}
                fieldTouched={touched.Screen}
                handleBlur={handleBlur}
                readonly={parentData && parentData}
              />
              <div className="modal_ok d-flex justify-content-end">
                <Button
                  type="reset"
                  onClick={() => {
                    handleSubmit() && formik.resetForm();
                  }}
                  color="success"
                >
                  {isApplicationScreenAddLoading && (
                    <Spinner size="sm" variant="light" />
                  )}{" "}
                  {selectedRecord?.Id ? "Update" : "Save"}
                </Button>
                {selectedRecord?.Id && (
                <Button
                  type="reset"
                  onClick={() => {
                    setSelectedRecord();
                    formik.resetForm();
                  }}
                  color="danger"
                >
                  Clear
                </Button>)}
              </div>
            </Form>
          );
        }}
      </Formik>
      <div>
        {applicationScreenDetails && (
          <DataReactTable
            reactTableData={applicationScreenDetails}
            onAddClick={() => { }}
            title="Application Screen"
            headerContent={false}
            isFetching={isApplicationscreenFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message="Are you sure you want to delete?"
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </div>
    </>
  );
};

export default ApplicationScreenForm;
