import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { SCREEN_CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenContentForm = ({ editData, setIsAddModalOpen, parentData }) => {
  const [addScreenContent, { isLoading: isScreenContentAddLoading }] =
    useAddMutation();
  const [updateScreenContent, { isLoading: isScreenContentUpdateLoading }] =
    useUpdateMutation();

  const screenContentValidationSchema = Yup.object().shape({
    Screen: Yup.mixed().required("Please select screen."),
    Content: Yup.mixed().required("Please select content."),
    Component: Yup.mixed().required("Please select component."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const screenContentDetail = {
      ScreenId: values.Screen.Id,
      ContentId: values.Content.Id,
      ComponentId: values.Component.Id,
      SortOrder: values.SortOrder ? parseInt(values.SortOrder) : null,
    };
    if (editData && Object.entries(editData).length > 0) {
      screenContentDetail.Id = editData.Id;
      updateScreenContent({
        entity: "CMS/ScreenContent",
        data: screenContentDetail,
        tag: SCREEN_CONTENT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenContentAddLoading && setIsAddModalOpen(false);
          successToast("Screen-content updated successfully.");
        }
      });
    } else {
      addScreenContent({
        entity: "CMS/ScreenContent",
        data: screenContentDetail,
        tag: SCREEN_CONTENT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenContentAddLoading && setIsAddModalOpen(false);
          successToast("Screen-content added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Screen:
          editData && editData.ScreenDTO
            ? editData.ScreenDTO
            : "",
        Content:
          editData && editData.ContentDTO
            ? editData.ContentDTO
            : "",
        Component:
          editData && editData.ComponentDTO
            ? editData.ComponentDTO
            : "",
            SortOrder:
            editData?.SortOrder === 0
              ? 0
              : editData?.SortOrder
              ? editData?.SortOrder
              : 999,
      }}
      validationSchema={screenContentValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Screen}
              label="Screen"
              name="Screen"
              entity="CMS/Screens"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Screen}
              fieldTouched={touched.Screen}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Screen}
              required
            />
            <AsyncPaginates
              value={values.Content}
              label="Content"
              name="Content"
              entity="CMS/Contents"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Content}
              fieldTouched={touched.Content}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Content}
              contentId = {values.Content?.ContentTypeId}
              required
            />
            <AsyncPaginates
              value={values.Component}
              label="Component"
              name="Component"
              entity="CMS/ComponentContentTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Component}
              fieldTouched={touched.Component}
              handleBlur={handleBlur}
              filter={`ContentTypeId eq ${values.Content?.ContentTypeId}&entities=Component&$select=Id,ComponentDTO`}
              readonly={parentData && parentData.Component}
              isDisable={!values.Content?.ContentTypeId}
              contentId = {values.Content?.ContentTypeId}
              cacheUniqs={[values.Content?.ContentTypeId]}
              required
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={
                isScreenContentAddLoading || isScreenContentUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isScreenContentAddLoading || isScreenContentUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ScreenContentForm.propTypes = {
  parentData: PropTypes.object,
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
};

export default ScreenContentForm;
