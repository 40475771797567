import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import {
  useAddMutation,
  useUpdateMutation,
} from "@/services/gamecodeXApi";
import { CLAUSE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ClauseForm = ({ editData, setIsAddModalOpen }) => {
  const [addClause, { isLoading: isClauseAddLoading }] =
    useAddMutation();
  const [updateClause, { isLoading: isClauseUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const clauseValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    ClauseType: Yup.mixed().required("Please select clause-type."),
  });

  const onSubmit = (values) => {
    const clauseDetail = {
      Name: values.Name,
      FullText: values.FullText,
      ClauseTypeId: values.ClauseType.Id,
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      clauseDetail.Id = editRecordDetail.Id;
      clauseDetail.PipelineId = editRecordDetail.PipelineId;
      updateClause({
        entity: "Doc/Clause",
        data: clauseDetail,
        tag: CLAUSE_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Clause updated successfully.");
        }
      });
    } else {
      addClause({
        entity: "Doc/Clause",
        data: clauseDetail,
        tag: CLAUSE_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Clause added successfully.");
          setIsAddModalOpen(false );
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editRecordDetail?.Name || "",
        FullText: editRecordDetail?.FullText || "",
        ClauseType: editRecordDetail?.ClauseTypeDTO || "",
      }}
      onSubmit={onSubmit}
      validationSchema={clauseValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <AsyncPaginates
              value={values.ClauseType}
              label="Clause Type"
              name="ClauseType"
              entity="DocReference/ClauseTypes"
              handleChange={(e) => {
                const allData = values
                allData.ClauseType = e
                allData.FullText = e?.Description
                setFieldValue("values", allData)
              }}
              fieldErrors={errors.ClauseType}
              fieldTouched={touched.ClauseType}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Full Text"
              name="FullText"
              type="textarea"
              fieldErrors={errors.FullText}
              fieldTouched={touched.FullText}
              fieldValue={values.FullText}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isClauseAddLoading || isClauseUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isClauseAddLoading ||
                isClauseUpdateLoading ) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ClauseForm.propTypes = {
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
};

export default ClauseForm;
