import React from "react";
import { Route } from "react-router-dom";
//Entity
import ClauseType from "@/components/Doc/Clause/ClauseTypes/ClauseType";
import ContractType from "@/components/Doc/Contract/ContractTypes/ContractType";
import DocMediaType from "@/components/Doc/DocMedia/DocMediaType/DocMediaType";
import Action from "@/components/Doc/Action/Actions/Action";
import Clause from "@/components/Doc/Clause/Clauses/Clause";
import Contract from "@/components/Doc/Contract/Contracts/Contract";
import DocMedia from "@/components/Doc/DocMedia/DocMedia/DocMedia";
import ContractClause from "@/components/Doc/Contract/ContractClause/ContractClause";
import CounterParty from "@/components/Doc/CounterParty/CounterParty/CounterParty";
import CounterPartyAction from "@/components/Doc/CounterParty/CounterPartyAction/CounterPartyAction";
import RoleType from "@/components/Doc/Role/RoleType/RoleType";
import ContractMedia from "@/components/Doc/DocMedia/ContractMedia/ContractMedia";
import ClauseModifyRequest from "@/components/Doc/Clause/ClauseModifyRequests/ClauseModifyRequest";
import Address from "@/components/Doc/Address/Address";

const DocRoutes = () => (
  <div>
    <Route path="/doc/action" component={Action} />
    <Route path="/doc/address" component={Address} />
    <Route path="/doc/clause" component={Clause} />
    <Route path="/doc/clause-type" component={ClauseType} />
    <Route path="/doc/clause-modify-request" component={ClauseModifyRequest} />
    <Route path="/doc/contract" component={Contract} />
    <Route path="/doc/contract-type" component={ContractType} />
    <Route path="/doc/doc-media" component={DocMedia} />
    <Route path="/doc/doc-media-type" component={DocMediaType} />
    <Route path="/doc/contract-clause" component={ContractClause} />
    <Route path="/doc/counter-party" component={CounterParty} />
    <Route path="/doc/counter-party-action" component={CounterPartyAction} />
    <Route path="/doc/contract-media" component={ContractMedia} />
    <Route path="/doc/role-type" component={RoleType} />
  </div>
);

export default DocRoutes;
