import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
    useLazyGetRecordsQuery,
    useDeleteMutation,
} from "@/services/gamecodeXApi";
import { THEME_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ThemeTypeForm from "./ThemeTypeForm";
import { getAllThemeType } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ThemeType = () => {
    const [themeTypeDetails, setThemeTypeDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteThemeType, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const [
        getThemeTypeRecords,
        {
            data: themeTypeList,
            isError: isThemeTypeError,
            isLoading: isThemeTypeLoading,
            isFetching: isThemeTypeFetching,
        },
    ] = useLazyGetRecordsQuery();

    const column = [
        {
            Header: "#",
            accessor: "Id",
            type: "Number",
        },
        {
            Header: "Name",
            accessor: "Name",
            filter: true,
        },
        {
            Header: "Description",
            accessor: "Description",
            filter: true,
            Cell: ({ value }) => {
                return value ? value : "-";
            },
        },
        {
            Header: "ContentObject",
            accessor: "ContentObject",
            filter: true,
            Cell: ({ value }) => {
                let contentKeys = "";
                if (value) {
                    try {
                        var contentObject = JSON.parse(value);
                        for (const key in contentObject) {
                            contentKeys += key + " | ";
                        }
                    } catch (error) { }
                }
                return value ? contentKeys.slice(0, -2) : "-";
            },
        },
        {
            Header: "Sort Order",
            accessor: "SortOrder",
            type: "Number",
            Cell: ({ value }) => {
                return value ? value : "-";
            },
        },
    ];

    const handleEditData = (themeTypeData) => {
        setSelectedRecord(themeTypeData);
        setIsAddModalOpen(true);
    };

    const handleDelete = () => {
        deleteThemeType({
            entity: "CMSReference/ThemeType",
            id: selectedRecord.Id,
            tag: THEME_TYPE_TAG,
        }).then((response) => {
            if (!response.error) {
                !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
                successToast("Theme-type deleted successfully.");
            }
        });
    };

    useEffect(() => {
        let { filterColumn, filterQuery } = formateColumnFilter(
            column,
            columnFilter
        );

        getThemeTypeRecords({
            entity: "CMSReference/ThemeTypes",
            top: pageSize,
            skip: (page - 1) * pageSize,
            // sort: sort,
            filter: filterQuery,
            globalFilter: {
                columns: filterColumn,
                globalSearchKeyword: globalSearchKeyword,
            },
            tag: THEME_TYPE_TAG,
        });
        setThemeTypeDetails({ tableHeaderData: column, tableRowsData: [] });
        // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

    useEffect(() => {
        if (!isThemeTypeLoading && !isThemeTypeError && themeTypeList && !isThemeTypeFetching) {
            dispatch(getAllThemeType(themeTypeList.value));
            let themeTypeData = [...themeTypeList.value];
            setThemeTypeDetails({
                ...themeTypeDetails,
                tableRowsData: themeTypeList.value ? themeTypeData : [],
                totalData:
                    themeTypeList && themeTypeList["@odata.count"]
                        ? themeTypeList["@odata.count"] : 0,
                onEditClick: (data) => handleEditData(data),
                onDeleteClick: (data) => {
                    setIsDeleteModalOpen(true);
                    setSelectedRecord(data);
                },
            });
        }
        // eslint-disable-next-line
    }, [isThemeTypeError, themeTypeList, isThemeTypeLoading, isThemeTypeFetching]);

    const deleteMessage = () => {
        return (
            <div>
                Are you sure you want to delete {selectedRecord.Name}?
                <div className="warning-div">
                    <i>
                        This will delete all the associated records <br />
                        ( Like - Theme, Screen Theme )
                    </i>
                </div>
            </div>
        );
    }

    return (
        <Container>
            <Row>
                {themeTypeDetails && (
                    <DataReactTable
                        reactTableData={themeTypeDetails}
                        onAddClick={() => {
                            setIsAddModalOpen(true);
                            setSelectedRecord();
                        }}
                        title="Theme Type"
                        isFetching={isThemeTypeFetching}
                        setPageNumber={setPage}
                        recordsPerPage={pageSize}
                        setRecordsPerPage={setPageSize}
                        setSort={setSort}
                        setGlobalSearchKeyword={setGlobalSearchKeyword}
                        setColumnFilter={setColumnFilter}
                    />
                )}
            </Row>
            {isAddModalOpen && (
                <AddModal
                    toggle={() => setIsAddModalOpen(!isAddModalOpen)}
                    color="success"
                    xxl
                    title={`${selectedRecord ? "Edit" : "Add"} Theme Type`}
                    header
                    btn="Success"
                    message={
                        <ThemeTypeForm
                            editData={selectedRecord ? selectedRecord : {}}
                            setIsAddModalOpen={setIsAddModalOpen}
                        />
                    }
                />
            )}
            {isDeleteModalOpen && (
                <Modal
                    color="danger"
                    title="Delete"
                    message={deleteMessage()}
                    handleOkClick={() => handleDelete()}
                    toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                    isDeleting={isDeleting}
                />
            )}
        </Container>
    );
};

export default ThemeType;