import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TopbarProfile from "../components/topbar/TopbarProfile";
import TopbarSidebarButton from "../components/topbar/TopbarSidebarButton";

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => (
  <div className="topbar">
    {/* <div className="topbar__left"> */}
    <div className="topbar__left h3 d-dlex align-items-center ">
      <TopbarSidebarButton
        onClickMobile={changeMobileSidebarVisibility}
        onClickDesktop={changeSidebarVisibility}
      /> 
      <Link className="topbar__logo" to="/cms/application" />
    </div>
    <div className="topbar__right">
      <div className="topbar__right-over">
        <TopbarProfile />
      </div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
