import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { MENU_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import MenuForm from "./MenuForm";
import { getAllMenu } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const Menu = () => {
  const [menuDetails, setMenuDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteMenu, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [
    getMenuRecords,
    {
      data: menuList,
      isError: isMenuError,
      isLoading: isMenuLoading,
      isFetching: isMenuFetching,
    },
  ] = useLazyGetRecordsQuery();
  
  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Menu Type",
      accessor: "MenuTypeDTO.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Help Text",
      accessor: "HelpText",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "ContentObject",
      accessor: "ContentObject",
      filter: true,
      Cell: ({ value }) => {
        return value ? 
        Object.entries(JSON.parse(value))?.map(([keys, values], index) => {
          return keys === "lists" ?
          <ul key={index}> 
            {values?.map((menuNames, subIndex) => {
              return <li key={subIndex + menuNames?.name}> - {menuNames?.name}</li>
            })} 
          </ul>
          : ""
        }) 
        : "-";
      },
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "0";
      },
    },
  ];

  const handleShowDetails = (menuData) => {
    history.push({
      pathname: "/site/menu-detail",
      state: {
        menuId: menuData.Id,
      },
    });
  };

  const handleEditData = (menuData) => {
    setSelectedRecord(menuData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteMenu({
      entity: "CMS/Menu",
      id: selectedRecord.Id,
      tag: MENU_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Menu deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state?.name) {
      setColumnFilter({
        [`${location.state.title.split(" ").join("")}.Name`]:
          location.state.name,
      });
    }
    history.push({ pathname: location.pathname, state: {} });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getMenuRecords({
      entity: "CMS/Menus",
      entities: { MenuType: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: MENU_TAG,
    });
    setMenuDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!isMenuLoading && !isMenuError && menuList && !isMenuFetching) {
      dispatch(getAllMenu(menuList.value));
      let menuData = [...menuList.value];
      setMenuDetails({
        ...menuDetails,
        tableRowsData: menuList.value ? menuData : [],
        totalData:
          menuList && menuList["@odata.count"] ? menuList["@odata.count"] : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isMenuError, menuList, isMenuLoading, isMenuFetching]);

  const deleteMessage = () => {
    return (
      <div>
        Are you sure you want to delete {selectedRecord.Name}?
        <div className="warning-div">
          <i>
            This will delete all the associated records <br />
            ( Like - Screen Menu, Application Menu)
          </i>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        {menuDetails && (
          <DataReactTable
            reactTableData={menuDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Menu"
            isFetching={isMenuFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          xxxl
          title={`${selectedRecord ? "Edit" : "Add"} Menu`}
          header
          btn="Success"
          message={
            <MenuForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Menu;
