import React from "react";
import { Route } from "react-router-dom";
import ShortCode from "@/components/Item/ShortCode/ShortCode";
import ShortCodeType from "@/components/Item/ShortCodeType/ShortCodeType";

const ItemRoutes = () => (
  <div>
    <Route path="/item/short-code" component={ShortCode} />
    <Route path="/item/short-code-type" component={ShortCodeType} />
  </div>
);

export default ItemRoutes;
