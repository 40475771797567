import { createSlice } from "@reduxjs/toolkit";

const siteSlice = createSlice({
    name: "site",
    initialState: {
        applications: [],
        applicationTypes: [],
        applicationScreens: [],
        components: [],
        contents: [],
        contentLocations: [],
        contentMedia: [],
        contentMenu: [],
        contentTypes: [],
        contentTemplates: [],
        contentTemplateTypes: [],
        media: [],
        mediaTypes: [],
        menuTypes: [],
        menus: [],
        menuItems: [],
        menuItemTypes: [],
        menuMedia: [],
        roles: [],
        roleTypes: [],
        screens: [],
        screenTypes: [],
        screenMappings: [],
        screenZones: [],
        screenContents: [],
        skin: [],
        skinScreen: [],
        zones: [],
        zoneContents: [],
        zoneTypes: [],
        zoneMedia: [],
        zoneMenus: [],
        zoneContentMedia: [],
        zoneMyCollections: [],
        zoneMyDice: [],
        zoneMySets: [],
        dashboard: [],
        clauseTypes: [],
        contractTypes: [],
        docMediaTypes: [],
        actions: [],
        clauses: [],
        contracts: [],
        contractClauses: [],
        counterParties: [],
        counterPartyActions: [],
        contractMedia: [],
        appVersion: [],
        applicationReferer: [],
        address: [],
        countries: [],
        states: [],
        cities: [],
        themes: [],
        themeTypes: [],
        screenThemes: []
    },
    reducers: {
        getAllApplication: (state, action) => {
            state.applications = action.payload
        },
        getAllApplicationTypes: (state, action) => {
            state.applicationTypes = action.payload
        },
        getAllApplicationScreen: (state, action) => {
            state.applicationScreens = action.payload
        },
        getAllComponents: (state, action) => {
            state.components = action.payload
        },
        getAllContents: (state, action) => {
            state.contents = action.payload
        },
        getAllContentLocations: (state, action) => {
            state.contentLocations = action.payload
        },
        getAllContentMedia: (state, action) => {
            state.contentMedia = action.payload
        },
        getAllContentMenu: (state, action) => {
            state.contentMenu = action.payload
        },
        getAllContentTypes: (state, action) => {
            state.contentTypes = action.payload
        },
        getAllContentTemplate: (state, action) => {
            state.contentTemplates = action.payload
        },
        getAllContentTemplateType: (state, action) => {
            state.contentTemplateTypes = action.payload
        },
        getAllMedia: (state, action) => {
            state.media = action.payload
        },
        getAllMediaTypes: (state, action) => {
            state.mediaTypes = action.payload
        },
        getAllMenuType: (state, action) => {
            state.menuTypes = action.payload
        },
        getAllMenu: (state, action) => {
            state.menus = action.payload
        },
        getAllMenuItemTypes: (state, action) => {
            state.menuItemTypes = action.payload
        },
        getAllMenuItems: (state, action) => {
            state.menuItems = action.payload
        },
        getAllMenuMedia: (state, action) => {
            state.menuMedia = action.payload
        },
        getAllRoles: (state, action) => {
            state.roles = action.payload;
        },
        getAllRoleTypes: (state, action) => {
            state.roleTypes = action.payload;
        },
        getAllScreen: (state, action) => {
            state.screens = action.payload
        },
        getAllScreenTypes: (state, action) => {
            state.screenTypes = action.payload
        },
        getAllTag: (state, action) => {
            state.tags = action.payload
        },
        getAllTagTypes: (state, action) => {
            state.tagTypes = action.payload
        },
        getAllScreenTags: (state, action) => {
            state.screenTags = action.payload
        },
        getAllScreenMappings: (state, action) => {
            state.screenMappings = action.payload
        },
        getAllContentTags: (state, action) => {
            state.contentTags = action.payload
        },
        getAllScreenMenus: (state, action) => {
            state.screenMenus = action.payload
        },
        getAllScreenZones: (state, action) => {
            state.screenZones = action.payload
        },
        getAllScreenContents: (state, action) => {
            state.screenContents = action.payload
        },
        getAllSkin: (state, action) => {
            state.skin = action.payload
        },
        getAllScreenSkin: (state, action) => {
            state.skinScreen = action.payload
        },
        getAllZone: (state, action) => {
            state.zones = action.payload
        },
        getAllZoneContents: (state, action) => {
            state.zoneContents = action.payload
        },
        getAllZoneTypes: (state, action) => {
            state.zoneTypes = action.payload
        },
        getAllZoneMedia: (state, action) => {
            state.zoneMedia = action.payload
        },
        getAllZoneMenus: (state, action) => {
            state.zoneMenus = action.payload
        },
        getAllZoneContentMedia: (state, action) => {
            state.zoneContentMedia = action.payload
        },
        getAllZoneMyCollections: (state, action) => {
            state.zoneMyCollections = action.payload
        },
        getAllZoneMyDice: (state, action) => {
            state.zoneMyCollections = action.payload
        },
        getAllZoneMySets: (state, action) => {
            state.zoneMySets = action.payload
        },
        getAllDashboardDetails: (state, action) => {
            state.dashboard = action.payload
        },
        getAllClauseTypes: (state, action) => {
            state.clauseTypes = action.payload
        },
        getAllContractTypes: (state, action) => {
            state.contractTypes = action.payload
        },
        getAllDocMediaTypes: (state, action) => {
            state.docMediaTypes = action.payload
        },
        getAllActions: (state, action) => {
            state.actions = action.payload
        },
        getAllClauses: (state, action) => {
            state.clauses = action.payload
        },
        getAllContracts: (state, action) => {
            state.contracts = action.payload
        },
        getAllContractClauses: (state, action) => {
            state.contractClauses = action.payload
        },
        getAllCounterParties: (state, action) => {
            state.counterParties = action.payload
        },
        getAllCounterPartyActions: (state, action) => {
            state.counterPartyActions = action.payload
        },
        getAllContractMedias: (state, action) => {
            state.contractMedia = action.payload
        },
        getAllAppVersion: (state, action) => {
            state.appVersion = action.payload
        },
        getAllApplicationReferers: (state, action) => {
            state.applicationReferer = action.payload
        },
        getAllAddresses: (state, action) => {
            state.address = action.payload
        },
        getAllCountries: (state, action) => {
            state.countries = action.payload
        },
        getAllStates: (state, action) => {
            state.states = action.payload
        },
        getAllCities: (state, action) => {
            state.cities = action.payload
        },
        getAllTheme: (state, action) => {
            state.themes = action.payload
        },
        getAllThemeType: (state, action) => {
            state.themeTypes = action.payload
        },
        getAllScreenThemes: (state, action) => {
            state.screenThemes = action.payload
        },
    },
});

export default siteSlice.reducer;
export const {
    getAllApplication,
    getAllApplicationTypes,
    getAllApplicationScreen,
    getAllComponents,
    getAllContents,
    getAllContentLocations,
    getAllContentMedia,
    getAllContentMenu,
    getAllContentTypes,
    getAllContentTemplate,
    getAllContentTemplateType,
    getAllMedia,
    getAllMediaTypes,
    getAllMenuType,
    getAllMenu,
    getAllMenuItems,
    getAllMenuItemTypes,
    getAllMenuMedia,
    getAllRoles,
    getAllRoleTypes,
    getAllScreen,
    getAllScreenTypes,
    getAllTag,
    getAllTagTypes,
    getAllScreenTags,
    getAllScreenMappings,
    getAllContentTags,
    getAllScreenMenus,
    getAllScreenZones,
    getAllScreenContents,
    getAllSkin,
    getAllScreenSkin,
    getAllZone,
    getAllZoneContents,
    getAllZoneTypes,
    getAllZoneMedia,
    getAllZoneMenus,
    getAllZoneContentMedia,
    getAllZoneMyCollections,
    getAllZoneMyDice,
    getAllZoneMySets,
    getAllDashboardDetails,
    getAllClauseTypes,
    getAllContractTypes,
    getAllDocMediaTypes,
    getAllActions,
    getAllClauses,
    getAllContracts,
    getAllContractClauses,
    getAllCounterParties,
    getAllCounterPartyActions,
    getAllContractMedias,
    getAllAppVersion,
    getAllApplicationReferers,
    getAllAddresses,
    getAllCountries,
    getAllStates,
    getAllCities,
    getAllTheme,
    getAllThemeType,
    getAllScreenThemes
} = siteSlice.actions