import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { SUPER_ADMIN, ADMIN, USER } from "@/shared/userRoles";
import Loading from "@/shared/components/Loading";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

const SidebarContent = ({ onClick, sidebarCollapse }) => {
  const role = useSelector((state) => state.user.role);
  const [displayComponent, setDisplayComponent] = useState({
    component: <Loading loading={true} />,
  });

  if (role === USER) {
    return <Redirect to="/" />;
  }

  return role &&
    role.length > 0 &&
    (role === ADMIN || role === SUPER_ADMIN) ? (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarCategory
          title="Dashboard"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink title="Dashboard" route="/dashboard" onClick={onClick} />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Comm"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarCategory title="Answer" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Answer"
              route="/comm/answer"
              onClick={onClick}
            />
            <SidebarLink
              title="Answer Type"
              route="/comm/answer-type"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Choice" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Choice"
              route="/comm/choice"
              onClick={onClick}
            />
            <SidebarLink
              title="Choice Type"
              route="/comm/choice-type"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory
            title="Entity Response"
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink
              title="Entity Response"
              route="/comm/entity-response"
              onClick={onClick}
            />
            <SidebarLink
              title="Entity Response Type"
              route="/comm/entity-response-type"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarLink
            title="Form Response"
            route="/comm/form-response"
            onClick={onClick}
          />
          <SidebarCategory title="Option" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Option"
              route="/comm/option"
              onClick={onClick}
            />
            <SidebarLink
              title="Option Type"
              route="/comm/option-type"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Question" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Question"
              route="/comm/question"
              onClick={onClick}
            />
            <SidebarLink
              title="Question option"
              route="/comm/question-option"
              onClick={onClick}
            />
            <SidebarLink
              title="Question Type"
              route="/comm/question-type"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Response" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Response"
              route="/comm/response"
              onClick={onClick}
            />
            <SidebarLink
              title="Response Type"
              route="/comm/response-type"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Survey" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Survey"
              route="/comm/survey"
              onClick={onClick}
            />
            <SidebarLink
              title="Survey Contact"
              route="/comm/survey-contact"
              onClick={onClick}
            />
            <SidebarLink
              title="Survey Question"
              route="/comm/survey-question"
              onClick={onClick}
            />
            <SidebarLink
              title="Survey Type"
              route="/comm/survey-type"
              onClick={onClick}
            />
          </SidebarCategory>
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Org"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarCategory title="Contact" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Contact"
              route="/org/contact"
              onClick={onClick}
            />
            <SidebarLink
              title="Contact Type"
              route="/org/contact-type"
              onClick={onClick}
            />
          </SidebarCategory>
          {role === SUPER_ADMIN && (
            <>
              <SidebarCategory
                title="Entity"
                sidebarCollapse={sidebarCollapse}
              >
                <SidebarLink
                  title="Entity Type"
                  route="/org/entity-type"
                  onClick={onClick}
                />
                <SidebarLink
                  title="Entity"
                  route="/org/entity"
                  onClick={onClick}
                />
              </SidebarCategory>
              <SidebarCategory
                title="Role"
                sidebarCollapse={sidebarCollapse}
              >
                <SidebarLink
                  title="Role Type"
                  route="/org/role-type"
                  onClick={onClick}
                />
                <SidebarLink
                  title="Role"
                  route="/org/role"
                  onClick={onClick}
                />
              </SidebarCategory>
              <SidebarLink
                title="User"
                route="/org/user"
                onClick={onClick}
              />
            </>
          )}
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Item"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink
            title="Short Code Type"
            route="/item/short-code-type"
            onClick={onClick}
          />
          <SidebarLink
            title="Short Code"
            route="/item/short-code"
            onClick={onClick}
          />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="CMS"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarCategory
            title="Application"
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink
              title="Application Type"
              route="/cms/application-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Application"
              route="/cms/application"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Screen" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Screen Type"
              route="/cms/screen-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Screen"
              route="/cms/screen"
              onClick={onClick}
            />
            <SidebarLink
              title="Application Screen"
              route="/cms/application-screen"
              onClick={onClick}
            />
            <SidebarLink
              title="Screen Mapping"
              route="/cms/screen-mapping"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarLink
            title="Component"
            route="/cms/component"
            onClick={onClick}
          />
          <SidebarCategory title="Media" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Media Type"
              route="/cms/media-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Media"
              route="/cms/media"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Content" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Content Type"
              route="/cms/content-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Content"
              route="/cms/content"
              onClick={onClick}
            />
            <SidebarLink
              title="Screen Content"
              route="/cms/screen-content"
              onClick={onClick}
            />
            <SidebarLink
              title="Application Content"
              route="/cms/application-content"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Menu" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Menu Type"
              route="/cms/menu-type"
              onClick={onClick}
            />
            <SidebarLink title="Menu" route="/cms/menu" onClick={onClick} />
            <SidebarLink
              title="Application Menu"
              route="/cms/application-menu" onClick={onClick}
            />
            <SidebarLink
              title="Screen Menu"
              route="/cms/screen-menu" onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Tag" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Tag Type"
              route="/cms/tag-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Tag"
              route="/cms/tag"
              onClick={onClick}
            />
            <SidebarLink
              title="Screen Tag"
              route="/cms/screen-tag" onClick={onClick}
            />
            <SidebarLink
              title="Content Tag"
              route="/cms/content-tag" onClick={onClick}
            />
          </SidebarCategory>
          <SidebarLink
            title="App Version"
            route="/cms/app-version"
            onClick={onClick}
          />
          <SidebarLink
            title="Application Referers"
            route="/cms/application-referers"
            onClick={onClick}
          />
          <SidebarCategory title="Theme" sidebarCollapse={sidebarCollapse}>
            <SidebarLink title="Theme Type" route="/cms/theme-type" onClick={onClick} />
            <SidebarLink title="Theme" route="/cms/theme" onClick={onClick} />
            <SidebarLink title="Screen Theme" route="/cms/screen-theme" onClick={onClick} />
          </SidebarCategory>
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Doc"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink
            title="Action"
            route="/doc/action"
            onClick={onClick}
          />
          <SidebarLink
            title="Address"
            route="/doc/address"
            onClick={onClick}
          />
          <SidebarCategory
            title="Clause"
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink
              title="Clause Type"
              route="/doc/clause-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Clause"
              route="/doc/clause"
              onClick={onClick}
            />
            <SidebarLink
              title="Clause Modify Request"
              route="/doc/clause-modify-request"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory
            title="Contract"
            sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Contract Type"
              route="/doc/contract-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Contract"
              route="/doc/contract"
              onClick={onClick}
            />
            <SidebarLink
              title="ContractClause"
              route="/doc/contract-clause"
              onClick={onClick}
            />
            <SidebarLink
              title="Contract Media"
              route="/doc/contract-media"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory
            title="Doc Media"
            sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Doc Media Type"
              route="/doc/doc-media-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Doc Media"
              route="/doc/doc-media"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory
            title="Counter Party"
            sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Counter Party"
              route="/doc/counter-party"
              onClick={onClick}
            />
            <SidebarLink
              title="CounterParty Action"
              route="/doc/counter-party-action"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory
            title="Role"
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink
              title="Role Type"
              route="/doc/role-type"
              onClick={onClick}
            />
          </SidebarCategory>
        </SidebarCategory>
      </ul>
    </div>
  ) : (
    displayComponent.component
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => { },
};

export default SidebarContent;
