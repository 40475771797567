import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import {
  ZONE_CONTENT_TAG,
  ZONE_TAG,
  CONTENT_TAG,
  COMPONENT_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ZoneContentForm = ({
  editData,
  setActiveTab,
  setCurrentZoneContent,
  setIsAddModalOpen,
  parentData,
}) => {
  const [addZoneContent, { isLoading: isZoneContentAddLoading }] =
    useAddMutation();
  const [updateZoneContent, { isLoading: isZoneContentUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const zoneContentValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    Zone: Yup.mixed().required("Please select zone."),
    Content: Yup.mixed().required("Please select content."),
    Component: Yup.mixed().required("Please select component."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const zoneContentDetail = {
      Name: values.Name,
      ComponentId: values.Component.Id,
      ZoneId: values.Zone.Id,
      ContentId: values.Content.Id,
    };
    if (values.SortOrder)
      zoneContentDetail.SortOrder = parseInt(values.SortOrder);
    if (values.CallToAction)
      zoneContentDetail.CallToAction = values.CallToAction;
    if (values.Description) zoneContentDetail.Description = values.Description;
    if (values.FeaturedText)
      zoneContentDetail.FeaturedText = values.FeaturedText;
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      zoneContentDetail.Id = editRecordDetail.Id;
      updateZoneContent({
        entity: "ZoneContent",
        data: zoneContentDetail,
        tag: [ZONE_CONTENT_TAG, ZONE_TAG, CONTENT_TAG, COMPONENT_TAG],
      }).then((response) => {
        if (!response.error) {
          setCurrentZoneContent(zoneContentDetail);
          !isZoneContentUpdateLoading && setActiveTab("2");
          successToast("Zone-content updated successfully.");
        }
      });
    } else {
      addZoneContent({
        entity: "ZoneContent",
        data: zoneContentDetail,
        tag: [ZONE_CONTENT_TAG, ZONE_TAG, CONTENT_TAG, COMPONENT_TAG],
      }).then((response) => {
        if (!response.error) {
          setEditRecordDetail(response.data);
          if (setActiveTab) {
            setCurrentZoneContent(response.data);
            !isZoneContentAddLoading && setActiveTab("2");
          } else {
            !isZoneContentAddLoading && setIsAddModalOpen(false);
          }
          successToast("Zone-content added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name:
          editRecordDetail && editRecordDetail.Name
            ? editRecordDetail.Name
            : "",
        Description:
          editRecordDetail && editRecordDetail.Description
            ? editRecordDetail.Description
            : "",
        Component:
          editRecordDetail && editRecordDetail.Component
            ? editRecordDetail.Component
            : parentData && parentData.Component
            ? parentData.Component
            : "",
        Zone:
          editRecordDetail && editRecordDetail.Zone
            ? editRecordDetail.Zone
            : parentData && parentData.Zone
            ? parentData.Zone
            : "",
        Content:
          editRecordDetail && editRecordDetail.Content
            ? editRecordDetail.Content
            : parentData && parentData.Content
            ? parentData.Content
            : "",
        FeaturedText:
          editRecordDetail && editRecordDetail.FeaturedText
            ? editRecordDetail.FeaturedText
            : "",
        CallToAction:
          editRecordDetail && editRecordDetail.CallToAction
            ? editRecordDetail.CallToAction
            : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={zoneContentValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.Zone}
              label="Zone"
              name="Zone"
              entity="Zone"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Zone}
              fieldTouched={touched.Zone}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Zone}
              required
            />
            <AsyncPaginates
              value={values.Content}
              label="Content"
              name="Content"
              entity="Content"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Content}
              fieldTouched={touched.Content}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Content}
              required
            />
            <AsyncPaginates
              value={values.Component}
              label="Component"
              name="Component"
              entity="Component"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Component}
              fieldTouched={touched.Component}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Component}
              required
            />
            <CustomInput
              label="Featured Text"
              name="FeaturedText"
              fieldErrors={errors.FeaturedText}
              fieldTouched={touched.FeaturedText}
              fieldValue={values.FeaturedText}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Call To Action"
              name="CallToAction"
              fieldErrors={errors.CallToAction}
              fieldTouched={touched.CallToAction}
              fieldValue={values.CallToAction}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isZoneContentAddLoading || isZoneContentUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isZoneContentAddLoading || isZoneContentUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ZoneContentForm.propTypes = {
  parentData: PropTypes.object,
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  setCurrentZoneContent: PropTypes.func,
};

export default ZoneContentForm;
