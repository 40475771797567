import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddComponentModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { COMPONENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import DetailPage from "@/shared/components/SharedDetailPage";
import ComponentForm from "./ComponentForm";
import { getAllComponents } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";
import ZoneContentForm from "../../Content/ZoneContent/ZoneContentForm";
import MenuForm from "../../Menu/Menu/MenuForm";

const Component = () => {
  const [isEyeClicked, setIsEyeClicked] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();
  const [componentDetails, setComponentDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteComponent, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getComponentRecords,
    {
      data: componentList,
      isError: isComponentError,
      isLoading: isComponentLoading,
      isFetching: isComponentFetching,
    },
  ] = useLazyGetRecordsQuery();
  
  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "File Path",
      accessor: "FilePath",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Component Demo",
      accessor: "ImageURL",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (componentData) => {
    setSelectedRecord(componentData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteComponent({
      entity:
        deleteRecord && deleteRecord.entity ? deleteRecord.entity : "CMSReference/Component",
      id: deleteRecord && deleteRecord.Id ? deleteRecord.Id : selectedRecord.Id,
      tag: COMPONENT_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(
          `${
            deleteRecord && deleteRecord.entity
              ? deleteRecord.entity
              : "Component"
          } deleted successfully.`
        );
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getComponentRecords({
      entity: "CMSReference/Components",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: COMPONENT_TAG,
    });
    setComponentDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isComponentLoading &&
      !isComponentError &&
      componentList &&
      !isComponentFetching
    ) {
      dispatch(getAllComponents(componentList.value));
      let componentData = [...componentList.value];
      if (selectedRecord) {
        setSelectedRecord((prev) =>
          componentList.value.find((value) => value.Id === prev.Id)
        );
      }
      setComponentDetails({
        ...componentDetails,
        tableRowsData: componentList.value ? componentData : [],
        totalData:
          componentList && componentList["@odata.count"]
            ? componentList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isComponentError,
    componentList,
    isComponentLoading,
    isComponentFetching,
  ]);

  const crossLinkColumns = {
    Menu: [{ header: "Menu Name", accessor: "Name" }],
    "Zone Content": [
      { header: "Name", accessor: "Name" },
      { header: "Description", accessor: "Description" },
      { header: "Featured Text", accessor: "FeaturedText" },
      { header: "Call To Action", accessor: "CallToAction" },
      { header: "Zone Name", accessor: "Zone.Name" },
      { header: "Content Name", accessor: "Content.Name" },
    ],
  };

  const deleteMessage = () => {
    return (
      <div>
        Are you sure you want to delete {selectedRecord.Name}?
        <div className="warning-div">
          <i>
            This will delete all the associated records <br />
            ( Like - Screen Content )
          </i>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        {isEyeClicked && (
          <DetailPage
            setIsEyeClicked={() => {
              setIsEyeClicked(false);
              setSelectedRecord();
              setDeleteRecord();
            }}
            title="Component"
            displayData={selectedRecord}
            crossLinkData={{
              Menu: {
                totalRecords: selectedRecord.Menus.length,
                records: [...selectedRecord.Menus.slice(0, 3)],
                colorStyle: "success",
                redirectUrl: "/cms/menu",
                addForm: (
                  addFormActiveTab,
                  addFormSetActiveTab,
                  isCrossLinkAddModalOpen,
                  setIsCrossLinkAddModalOpen
                ) => (
                  <MenuForm
                    parentData={{ Component: selectedRecord }}
                    setIsAddModalOpen={setIsCrossLinkAddModalOpen}
                    isAddModalOpen={isCrossLinkAddModalOpen}
                  />
                ),
              },
              "Zone Content": {
                totalRecords: selectedRecord.ZoneContents.length,
                records: [...selectedRecord.ZoneContents],
                colorStyle: "warning",
                redirectUrl: "/site/zone-content",
                addForm: (
                  addFormActiveTab,
                  addFormSetActiveTab,
                  isCrossLinkAddModalOpen,
                  setIsCrossLinkAddModalOpen
                ) => (
                  <ZoneContentForm
                    parentData={{ Component: selectedRecord }}
                    parentId={selectedRecord.Id}
                    setIsAddModalOpen={setIsCrossLinkAddModalOpen}
                    isAddModalOpen={isCrossLinkAddModalOpen}
                  />
                ),
              },
            }}
            crossLinkColumns={crossLinkColumns}
            setIsAddModalOpen={setIsAddModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setDeleteRecord={setDeleteRecord}
          />
        )}
        {componentDetails && !isEyeClicked && (
          <DataReactTable
            reactTableData={componentDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Component"
            isFetching={isComponentFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddComponentModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Component`}
          header
          btn="Success"
          message={
            <div>
              <ComponentForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsAddModalOpen={setIsAddModalOpen}
              />
            </div>
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Component;
