import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  authReducer,
  roundBordersReducer,
  blocksShadowsReducer,
} from "../../redux/reducers/index";
import appConfigReducer from "../../redux/reducers/appConfigReducer";

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { gamecodeXApi } from "../../services/gamecodeXApi";
import commReducer from "../../components/Comm/CommSlice";
import siteReducer from "../../components/Site/SiteSlice";

export const store = configureStore({
  reducer: {
    [gamecodeXApi.reducerPath]: gamecodeXApi.reducer,
    theme: themeReducer,
    rtl: rtlReducer,
    roundBorders: roundBordersReducer,
    blocksShadows: blocksShadowsReducer,
    appConfig: appConfigReducer,
    customizer: customizerReducer,
    sidebar: sidebarReducer,
    user: authReducer,
    comm: commReducer,
    siteData: siteReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(gamecodeXApi.middleware),
});

setupListeners(store.dispatch);

export default store;
