import React from "react";
import { Route } from "react-router-dom";
//Entity\
import Contact from "@/components/Org/Contact/Contact/Contact";
import ContactType from "@/components/Org/Contact/ContactType/ContactType";
import User from "@/components/Org/User/User";
import Role from "@/components/Org/Role/Role/Role";
import RoleType from "@/components/Org/Role/RoleType/RoleType";
import EntityType from "@/components/Org/Entity/EntityType/EntityType";
import Entity from "@/components/Org/Entity/Entity/Entity";

const OrgRoutes = () => (
  <div>
    <Route path="/org/contact" component={Contact} />
    <Route path="/org/contact-type" component={ContactType} />
    <Route path="/org/user" component={User} />
    <Route path="/org/role" component={Role} />
    <Route path="/org/role-type" component={RoleType} />
    <Route path="/org/entity" component={Entity} />
    <Route path="/org/entity-type" component={EntityType} />
  </div>
);

export default OrgRoutes;
