import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import {
  SURVEY_QUESTION_TAG,
  QUESTION_TAG,
  SURVEY_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";

const SurveyQuestionForm = ({
  editData,
  setIsAddModalOpen,
  isAddModalOpen,
  parentData,
}) => {
  const [addRecord, { isLoading: isAddRecordLoading }] = useAddMutation();
  const [updateRecord, { isLoading: isUpdateRecordLoading }] =
    useUpdateMutation();

  const validationSchema = Yup.object().shape({
    Survey: Yup.mixed().required("Please select survey."),
    Question: Yup.mixed().required("Please select Question."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const SurveyQuestionDetail = {
      SurveyId: values.Survey.Id,
      QuestionId: values.Question.Id,
    };
    if (values.SortOrder)
      SurveyQuestionDetail.SortOrder = parseInt(values.SortOrder);
    if (editData && Object.entries(editData).length > 0) {
      SurveyQuestionDetail.Id = editData.Id;
      updateRecord({
        entity: "SurveyQuestion",
        isWogdApi: true,
        data: SurveyQuestionDetail,
        tag: [SURVEY_QUESTION_TAG, QUESTION_TAG, SURVEY_TAG],
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Survey-question updated successfully.");
        }
      });
    } else {
      addRecord({
        entity: "SurveyQuestion",
        isWogdApi: true,
        data: SurveyQuestionDetail,
        tag: [SURVEY_QUESTION_TAG, QUESTION_TAG, SURVEY_TAG],
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Survey-question added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Survey:
          editData && editData.Survey
            ? editData.Survey
            : parentData && parentData.Survey
            ? parentData.Survey
            : "",
        Question:
          editData && editData.Question
            ? editData.Question
            : parentData && parentData.Question
            ? parentData.Question
            : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Survey}
              label="Survey"
              name="Survey"
              entity="Survey"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Survey}
              fieldTouched={touched.Survey}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Survey}
              required
            />
            <AsyncPaginates
              value={values.Question}
              label="Question"
              name="Question"
              entity="Question"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Question}
              fieldTouched={touched.Question}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Question}
              required
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isAddRecordLoading || isUpdateRecordLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAddRecordLoading || isUpdateRecordLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

SurveyQuestionForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.bool,
};

export default SurveyQuestionForm;
