import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { COUNTER_PARTY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import CounterPartyForm from "./CounterPartyForm";
import { getAllCounterParties } from "../../../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const CounterParty = () => {
    const [counterPartyDetails, setCounterPartyDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteCounterParty, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const [isArchiveCounterParty, setIsArchiveCounterParty] = useState(false)
    const dispatch = useDispatch();
    const [
      getCounterPartyRecords,
      {
        data: counterPartyList,
        isError: isCounterPartyError,
        isLoading: isCounterPartyLoading,
        isFetching: isCounterPartyFetching,
      },
    ] = useLazyGetRecordsQuery();

    const [
      getArchivedCounterParty, {
        data: archivedCounterParty,
        isError: isDeletedCounterPartyError,
        isLoading: isDeletedCounterPartyLoading,
        isFetching: isDeletedCounterPartyFetching,
      }
      ] = useLazyGetRecordsQuery();
  
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Name",
        accessor: "Name",
        filter: true,
      },
      {
        Header: "Description",
        accessor: "Description",
        filter: true,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Address",
        accessor: "AddressDTO",
        filter: true,
        Cell: ({ value }) => {
          return value ? `${value?.Line || ""} ${value?.City || ""} ${value?.State || ""} ${value?.Country || ""} ${value?.ZipCode}` : "-";
        },
      },
      {
        Header: "Role",
        accessor: "RoleTypeDTO.Name",
        filter: true,
      },
      {
        Header: "Contract",
        accessor: "ContractDTO.Name",
        filter: true,
      },
      {
        Header: "Entity",
        accessor: "EntityDTO.Name",
        filter: true,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Email",
        accessor: "Email",
        filter: true,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
    ];

    const handleEditData = (counterPartyData) => {
      setSelectedRecord(counterPartyData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteCounterParty({
        entity: "Doc/CounterParty",
        id: selectedRecord.Id,
        tag: COUNTER_PARTY_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Counter-Party deleted successfully.");
        }
      });
    };

    const handleRestoreData = (data) => {
      deleteCounterParty({
        entity: "Doc/CounterParty",
        id: `${data?.Id}?isRestore=true`,
        tag: COUNTER_PARTY_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("CounterParty restored successfully.");
        }
      });
    }
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
  
      if (!isArchiveCounterParty) {
        getCounterPartyRecords({
          entity: "Doc/CounterParties",
          entities: {Contract : {}, Entity : {}, RoleType : {}, Address : {}},
          top: pageSize,
          skip: (page - 1) * pageSize,
          sort: sort,
          filter: filterQuery,
          globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
          },
          tag: COUNTER_PARTY_TAG,
        });
      } else {
        getArchivedCounterParty({
          entity: "Doc/CounterParties",
          entities: {Contract : {}, Entity : {}, RoleType : {}},
          isArchive:true,
          top: pageSize,
          skip: (page - 1) * pageSize,
          sort: sort,
          filter: filterQuery,
          globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
          },
          tag: COUNTER_PARTY_TAG,
        })
      }
      setCounterPartyDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter, isArchiveCounterParty]);
  
    useEffect(() => {
      if (
        !isCounterPartyLoading &&
        !isDeletedCounterPartyLoading &&
        !isCounterPartyError &&
        !isDeletedCounterPartyError &&
        (counterPartyList || archivedCounterParty) &&
        !isCounterPartyFetching &&
        !isDeletedCounterPartyFetching
      ) {
        dispatch(getAllCounterParties(isArchiveCounterParty ? archivedCounterParty.value : counterPartyList.value));
        let CounterPartyData = isArchiveCounterParty ? [...archivedCounterParty.value] : [...counterPartyList.value];
        setCounterPartyDetails({
          ...counterPartyDetails,
          tableRowsData: counterPartyList?.value ? CounterPartyData : [],
          totalData:
          isArchiveCounterParty ? 
            archivedCounterParty && archivedCounterParty?.["@odata.count"] 
              : counterPartyList && counterPartyList?.["@odata.count"] || 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
          },
          onRestoreClick: isArchiveCounterParty ? (data) => {
            handleRestoreData(data);
          }: "",
        });
      }
      // eslint-disable-next-line
    }, [
      isCounterPartyError,
      isDeletedCounterPartyError,
      counterPartyList,
      archivedCounterParty,
      isCounterPartyLoading,
      isCounterPartyLoading,
      isCounterPartyFetching,
      isDeletedCounterPartyFetching
    ]);
  
    const deleteMessage = () => {
      return (
        <div>
          Are you sure you want to delete {selectedRecord.Name}?
        </div>
      );
    }
  
    return (
      <Container data-testid="test-CounterParty">
        <Row>
          {counterPartyDetails && (
            <DataReactTable
              reactTableData={counterPartyDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              onArchive={{
                setIsArchiveDetails : () => setIsArchiveCounterParty(!isArchiveCounterParty),
                isArchiveDetails : isArchiveCounterParty
              }}
              title="Counter Party"
              isFetching={isCounterPartyFetching || isDeletedCounterPartyFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            testid="addModal"
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Counter Party`}
            header
            btn="Success"
            message={
                <CounterPartyForm
                    editData={selectedRecord ? selectedRecord : {}}
                    setIsAddModalOpen={()=>setIsAddModalOpen(!isAddModalOpen)}
                />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={deleteMessage()}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    );
}

export default CounterParty
