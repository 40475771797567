import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import {
  QUESTION_OPTION_TAG,
  QUESTION_TAG,
  OPTION_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";

const QuestionOptionForm = ({
  editData,
  setIsAddModalOpen,
  isAddModalOpen,
  parentData,
}) => {
  const [addQuestionOption, { isLoading: isAddQuestionOptionLoading }] =
    useAddMutation();
  const [updateQuestion, { isLoading: isUpdateQuestionOptionLoading }] =
    useUpdateMutation();
  const validationSchema = Yup.object().shape({
    Question: Yup.mixed().required("Please select question."),
    Option: Yup.mixed().required("Please select option."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const questionOptionDetail = {
      QuestionId: values.Question.Id,
      OptionId: values.Option.Id,
    };
    if (values.SortOrder)
      questionOptionDetail.SortOrder = parseInt(values.SortOrder);
    if (editData && Object.entries(editData).length > 0) {
      questionOptionDetail.Id = editData.Id;
      updateQuestion({
        entity: "QuestionOption",
        isWogdApi: true,
        data: questionOptionDetail,
        tag: [QUESTION_OPTION_TAG, QUESTION_TAG, OPTION_TAG],
      }).then((response) => {
        if (!response.error) {
          !isUpdateQuestionOptionLoading && setIsAddModalOpen(false);
          successToast("Question-option updated successfully.");
        }
      });
    } else {
      addQuestionOption({
        entity: "QuestionOption",
        isWogdApi: true,
        data: questionOptionDetail,
        tag: [QUESTION_OPTION_TAG, QUESTION_TAG, OPTION_TAG],
      }).then((response) => {
        if (!response.error) {
          !isAddQuestionOptionLoading && setIsAddModalOpen(false);
          successToast("Question-option added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Question:
          editData && editData.Question
            ? editData.Question
            : parentData && parentData.Question
            ? parentData.Question
            : "",
        Option: editData && editData.Option ? editData.Option : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Question}
              label="Question"
              name="Question"
              entity="Question"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Question}
              fieldTouched={touched.Question}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Question}
              required
            />
            <AsyncPaginates
              value={values.Option}
              label="Option"
              name="Option"
              entity="Option"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Option}
              fieldTouched={touched.Option}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={
                isAddQuestionOptionLoading || isUpdateQuestionOptionLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAddQuestionOptionLoading ||
                isUpdateQuestionOptionLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

QuestionOptionForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.bool,
};

export default QuestionOptionForm;
