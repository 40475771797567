import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner, Col, Row, Label } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { THEME_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import CreatableSelect from "react-select/creatable";

const customInput = [
    { value: "title", label: "Title" },
    { value: "subTitle", label: "SubTitle" },
    { value: "description", label: "Description" }
];

const customInputType = [
    { value: "string", label: "String" },
]

const ThemeTypeForm = ({ editData, setIsAddModalOpen }) => {
    const [addContentType, { isLoading: isContentTypeAddLoading }] = useAddMutation();
    const [updateContentType, { isLoading: isContentTypeUpdateLoading }] = useUpdateMutation();
    const styles = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
    };
    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Please enter name."),
    });

    const onSubmit = (values) => {
        let payload = {
            Name: values.Name,
            Description: values.Description,
            ContentObject: JSON.stringify(values.ContentObject),
        }
        if (editData && Object.entries(editData).length > 0) {
            payload.Id = editData.Id;
            updateContentType({
                entity: "CMSReference/ThemeType",
                data: payload,
                tag: THEME_TYPE_TAG,
            }).then((response) => {
                if (!response.error) {
                    !isContentTypeUpdateLoading && setIsAddModalOpen(false)
                    !isContentTypeUpdateLoading && successToast("Theme-type updated successfully.");
                }
            });
        } else {
            addContentType({
                entity: "CMSReference/ThemeType",
                data: payload,
                tag: THEME_TYPE_TAG,
            }).then((response) => {
                if (!response.error) {
                    !isContentTypeAddLoading && setIsAddModalOpen(false);
                    successToast("Theme-type added successfully.");
                }
            });
        }
    };
    const handleDropdownChange = (event, values, setFieldValue) => {
        let resp = {}
        event.forEach((val) => {
            var item = val
            values?.ContentObject[val?.label]?.value === undefined ?
                (resp[item['value'].split(" ").join("")] = { type: "", value: "" }) : (resp[item['value'].split(" ").join("")] = { type: values?.ContentObject[val?.label]?.type, value: values?.ContentObject[val?.label]?.value })
        })
        setFieldValue("ContentObject", resp)
    };

    return (
        <Formik
            initialValues={{
                Name: editData && editData.Name ? editData.Name : "",
                Description:
                    editData && editData.Description ? editData.Description : "",
                ContentObject: editData && editData.ContentObject ? JSON.parse(editData.ContentObject) : {},
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
            }) => (
                <Form noValidate>
                    <Row>
                        <Col lg={8}>
                            <Row>
                                <Col lg={6} md={6}>
                                    {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
                                    <CustomInput
                                        label="Name"
                                        name="Name"
                                        fieldErrors={errors.Name}
                                        fieldTouched={touched.Name}
                                        fieldValue={values.Name}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        required
                                    />

                                    <CustomInput
                                        label="Description"
                                        name="Description"
                                        type="textarea"
                                        fieldErrors={errors.Description}
                                        fieldTouched={touched.Description}
                                        fieldValue={values.Description}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                    />
                                </Col>
                                <Col lg={6} md={6}>
                                    <>
                                        <Label for="ContentObject">
                                            Inputs
                                        </Label>
                                        <CreatableSelect
                                            name='ContentObject'
                                            id="ContentObject"
                                            isMulti
                                            placeholder="Select Inputs"
                                            isClearable={false}
                                            backspaceRemovesValue={false}
                                            value={Object.keys(values.ContentObject).map((val) => {
                                                if (val !== 'images' && val !== 'lists') {
                                                    return {
                                                        'label': val,
                                                        'value': val,
                                                        isFixed: editData && editData.ContentObject ? Object.keys(JSON.parse(editData?.ContentObject)).includes(val) : false
                                                    }
                                                }
                                            })}
                                            onChange={(event) => handleDropdownChange(event, values, setFieldValue)}
                                            options={customInput}
                                            styles={styles}
                                            className="mb-2"
                                            fieldErrors={errors.ContentObject}
                                            fieldTouched={touched.ContentObject}
                                            autoFocus={true}
                                        />
                                    </>
                                    {Object.entries(values.ContentObject).map((val, index) => {
                                        return val[0] === "images" || val[0] === "lists" ? <div key={index}></div> : (
                                            <div key={index}>
                                                <Label for="InputType">
                                                    {val[0]}
                                                </Label>
                                                <CreatableSelect
                                                    name='InputType'
                                                    id="InputType"
                                                    placeholder={`Select ${val[0]}'s Type`}
                                                    isClearable
                                                    value={val[1].type ? { 'label': val[1].type, 'value': val[1].type } : null}
                                                    onChange={(event) => {
                                                        setFieldValue(`ContentObject.${val[0]}.type`, event?.value)
                                                    }}
                                                    options={customInputType}
                                                    styles={styles}
                                                    className="mb-2"
                                                    fieldErrors={errors.ContentObject}
                                                    fieldTouched={touched.ContentObject}
                                                    autoFocus={true}
                                                />
                                            </div>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4}>
                            <Row>
                                <Col>
                                    <CustomInput
                                        label="Content Object"
                                        type="textarea"
                                        name="ContentType"
                                        fieldValue={JSON.stringify(values, null, 2)}
                                        readonly
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <Button disabled={isContentTypeAddLoading || isContentTypeUpdateLoading || Object.keys(values.ContentObject).some(item => (item !== "images" && item !== "list") && values.ContentObject[item]?.type === "")}
                            onClick={handleSubmit}
                            className="modal_ok float-right"
                            color="success"
                        >
                            {(isContentTypeAddLoading || isContentTypeUpdateLoading) && (
                                <Spinner size="sm" variant="light" />
                            )}{" "}
                            Save
                        </Button>
                    </Row>
                </Form>
            )
            }
        </Formik>
    )
};

ThemeTypeForm.propTypes = {
    editData: PropTypes.object.isRequired,
    setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ThemeTypeForm;