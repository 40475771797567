import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { FormGroup, Label } from "reactstrap";

const AsyncPaginates = ({
  value = {},
  label,
  name,
  customFieldName = "",
  fieldType = "",
  fieldName = "",
  type = "",
  entity = "",
  entities = "",
  isParentBaseUrl = false,
  options,
  filter = "",
  query = "",
  orderby = "",
  sortOrder = "",
  allValues,
  index,
  setFieldValue,
  fieldErrors,
  fieldTouched,
  handleBlur,
  readonly = false,
  required = false,
  expandRelation = [],
  isAllowToAddContent = false,
  handleEditRecords = false,
  isMulti = false,
  cacheUniqs = [],
  handleChange,
  contentId,
  isDisable = false,
  isSearchable = true,
  isMobileImage1 = false,
}) => {
  const loadOptions = async (e, _, { page }) => {
    if (options) {
      let finalOptions = options;
      if (e) {
        finalOptions = options.filter((value) => value.Name.includes(e));
      }
      return {
        options:
          finalOptions && finalOptions.length
            ? finalOptions.slice((page - 1) * 5, page * 5)
            : finalOptions,
        hasMore: page * 5 < finalOptions.length,
        additional: {
          page: page + 1,
        },
      };
    } else {
      // const baseUrl = process.env.REACT_APP_API_URL;
      const baseUrl = isParentBaseUrl ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_ZWEIHANDER;
      const urlString = `${baseUrl}${entity}?$orderby=${orderby ? orderby : "Id"} ${sortOrder ? sortOrder : "desc"} 
      ${query.length ? `&${query}` : ""} &$filter=isDeleted ne true ${filter ? "and "+filter : ""}`;

      let updatedUrlString = "";
      if (value && e) updatedUrlString = urlString;
      if (e || !value)
        updatedUrlString =
          urlString + ` and (contains(tolower(Name),'${encodeURIComponent(e.replaceAll("'", "''")).toLowerCase()}'))`;
      if (!e) updatedUrlString = urlString;
      if (entities) updatedUrlString += `&${entities}`
      let accessToken = localStorage.getItem("accessToken");
      const response = await fetch(updatedUrlString, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      let responseJSON = await response.json();

      if (label === "Component" && contentId && responseJSON?.value) {
        responseJSON.value = responseJSON.value.map((val) => {
          return val.ComponentDTO;
        });
      }

      return {
        options: responseJSON.value,
        hasMore: page * 5 < responseJSON["@odata.count"],
        additional: {
          page: page + 1,
        },
      };
    }
  };
  const handleExistingRecords = (e, setFieldValue) => {
    let secondObject = JSON.parse(e.ContentObject)

    let firstObject = JSON.parse(e.ContentObject);
    Object.entries(firstObject).forEach((content) => {
      if(content[0] !== "images" && content[0] !== "lists" && content[0] !== "insertBlog" && content[0] !== "videos" && content[0] !== "mobileImages" && content[0] !== "dynamicFieldList") {
        if(content[1]?.type !== "image" && content[1]?.type !== "video" && content[1]?.type !== "audio"){
          firstObject[content[0]] = "";
        }else{
          firstObject[content[0]] = firstObject[content[0]].value;
        }
      }
    });
    
    for (let k in firstObject) {
      if (k === 'lists' || k === 'insertBlog' || k === 'images' || k === 'videos' || k === 'mobileImages' || k === 'dynamicFieldList') {
        let existingLists = []
        firstObject[k] && firstObject[k].forEach((val, pIndex) => {
          if (firstObject[k].some((_, cIndex) => pIndex === cIndex)) {
            existingLists.push(firstObject[k][pIndex])
          } else {
            existingLists.push(val)
          }
          secondObject[k] = existingLists;
        })
      }
      else {
        secondObject[k] = firstObject[k];
      }
    }
    setFieldValue(
      "ContentObject",
      JSON.parse(secondObject ? JSON.stringify(secondObject) : "{}")
    );
  }

  const setAllFieldValues = (e) => {
    if (name === "File") {
      const contentObjectData = allValues.ContentObject && allValues.ContentObject;
      if (type === "video") {
        contentObjectData.videos[index].url = e.URL;
        contentObjectData.videos[index].name = e.Name;
      }else if (type === "image") {
        contentObjectData.images[index].url = e.URL;
        contentObjectData.images[index].name = e.Name;
      }else if (type === "singleFile") {
        if(fieldType === "image"){
          contentObjectData[fieldName].url = e.URL;
          contentObjectData[fieldName].name = e.Name;
        }else if(fieldType === "video"){
          contentObjectData[fieldName].url = e.URL;
          contentObjectData[fieldName].name = e.Name;
        }else{
          contentObjectData[fieldName].url = e.URL;
          contentObjectData[fieldName].name = e.Name;
        }
      }else if (type === "mobileImage") {
        if(isMobileImage1){
          contentObjectData.mobileImages[index].image1.url = e.URL;
          contentObjectData.mobileImages[index].image1.name = e.Name;
        }else{
          contentObjectData.mobileImages[index].image2.url = e.URL;
          contentObjectData.mobileImages[index].image2.name = e.Name;
        }
      }else if (type === "dynamicFieldList") {
        if(fieldType === "image"){
          contentObjectData.dynamicFieldList[index][fieldName].url = e.URL;
          contentObjectData.dynamicFieldList[index][fieldName].name = e.Name;
        }else if(fieldType === "video"){
          contentObjectData.dynamicFieldList[index][fieldName].url = e.URL;
          contentObjectData.dynamicFieldList[index][fieldName].name = e.Name;
        }else{
          contentObjectData.dynamicFieldList[index][fieldName].url = e.URL;
          contentObjectData.dynamicFieldList[index][fieldName].name = e.Name;
        }
      }
      setFieldValue("ContentObject", contentObjectData);
    } else {
      if (label === "Component" && e.length) {
        e = e.map((val) => {
          return { Id: val.Id, Name: val.Name };
        });
      }
      if (label === "Content" && contentId) {
        setFieldValue("Component", "");
      }
      setFieldValue(name, e);
      if (isAllowToAddContent) {
        if (Object.entries(handleEditRecords).length && value.ContentObject !== e.ContentObject) {
          handleExistingRecords(e, setFieldValue)
        } else {
          let contentObjectData = JSON.parse(e.ContentObject);
          Object.entries(contentObjectData).forEach((content) => {
            if(content[0] !== "images" && content[0] !== "lists" && content[0] !== "insertBlog" && content[0] !== "videos" && content[0] !== "mobileImages" && content[0] !== "dynamicFieldList") {
              if(content[1]?.type !== "image" && content[1]?.type !== "video" && content[1]?.type !== "audio"){
                contentObjectData[content[0]] = "";
              }else{
                contentObjectData[content[0]] = contentObjectData[content[0]].value;
              }
            }else if(content[0] === "dynamicFieldList"){
              contentObjectData[content[0]] = contentObjectData[content[0]].map((val, index) => {                
                return Object.entries(val).reduce((nestedContent, current) => {
                  nestedContent[current[0]] = current[1].value;
                  return nestedContent;
                },{})
              })
            }
          });
          contentObjectData = JSON.stringify(contentObjectData);
          setFieldValue(
            "ContentObject",
            JSON.parse(e.ContentObject ? contentObjectData : "{}")
          );
        }
      }
    }
  }

  return (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <font color="red"> *</font>}
      </Label>
      <AsyncPaginate
        className={`lazySelect ${(readonly || isDisable) && "disabled"}`}
        value={value}
        cacheUniqs={cacheUniqs}
        loadOptions={loadOptions}
        getOptionValue={(option) => option.Id}
        getOptionLabel={(option) => {
          if (option.Id) {
            if (expandRelation.length && option && option[expandRelation[0]]) {
              let optionString = `${option.Id}`;
              optionString +=
                "-" +
                expandRelation
                  .map((value) => {
                    return `${option[value].Name}`;
                  })
                  .join("-");
              return optionString;
            } else if (entity === "User") {
              return `${option.Id} - ${option.Name} - ${option.Email}`;
            } else if (name === "City") {
              return `${option?.Id} - ${option?.Name} - ${option?.State?.Name} - ${option?.State?.Country?.Name} `;
            } else if (name === "State") {
              return `${option?.Id} - ${option?.Name} - ${option?.Country?.Name}`;
            } else if (entity === "Org/OrgEntities") {
              return `${option.Id} - ${option.Name} ${option?.UserDTO?.[0]?.Email ? " - " + option?.UserDTO?.[0]?.Email : ""}`;
            } else if (entity === "Tile") {
              return `${option.Id} - ${option.UrlFileName}`;
            } else if (entity === "GetAllCell") {
              return `${option.Id} - (X : ${option.X}) (Y : ${option.Y}) (Z : ${option.Z})`;
            } else if (name === "File") {
              return <><img src={option.URL} style={{ width: "50px" }} alt="" /> {option?.Name ?? option?.name}</>;
            } else if (customFieldName) {
              return `${option.Id} - ${option?.[customFieldName]}`;
            } else {
              return `${option.Id} - ${option?.Title || option?.Name || option?.name}`;
            }
          } else {
            if (option.Name || option.name) return `${option.Name || option.name}`
            else return `New ${name}`
          }
        }}
        placeholder={`Select ${label}`}
        isSearchable={isSearchable}
        onBlur={handleBlur}
        onChange={handleChange ? handleChange :setAllFieldValues}
        isDisabled={readonly || isDisable}
        isMulti={isMulti}
        additional={{
          page: 1,
        }}
      />
      {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
    </FormGroup>
  );
};

export default AsyncPaginates;
