import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import moment from "moment/moment";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { useLazyGetRecordsQuery } from "@/services/gamecodeXApi";
import { COUNTER_PARTY_ACTIONS_TAG } from "@/shared/tagFile";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllCounterPartyActions } from "../../../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const CounterPartyAction = () => {
  const [counterPartyActionDetails, setCounterPartyActionDetails] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getCounterPartyActionRecords,
    {
      data: counterPartyActionList,
      isError: isCounterPartyActionError,
      isLoading: isCounterPartyActionLoading,
      isFetching: isCounterPartyActionFetching,
    },
] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "CounterParty Name",
      accessor: "CounterPartyDTO.Name",
      filter: true,
    },
    {
      Header: "Contract Name",
      accessor: "CounterPartyDTO.ContractDTO.Name",
      filter: true,
    },
    {
      Header: "Action Name",
      accessor: "ActionDTO.Name",
      filter: true,
    },
    {
      Header: "Action Date",
      accessor: "ActionDate",
      filter: true,
      Cell: ({ value }) => {
        return value ? moment(value).format("YYYY-MM-DD") : "-";
      },
    },
    {
      Header: "Rejection Message",
      accessor: "RejectedReason",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getCounterPartyActionRecords({
      entity: "Doc/CounterPartyActions",
      entities: { "CounterParty.Contract":{}, Action: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: COUNTER_PARTY_ACTIONS_TAG,
    });
    setCounterPartyActionDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!isCounterPartyActionLoading && !isCounterPartyActionError && counterPartyActionList && !isCounterPartyActionFetching) {
      dispatch(getAllCounterPartyActions(counterPartyActionList.value));
      let counterPartyActionData = [...counterPartyActionList.value];
      setCounterPartyActionDetails({
        ...counterPartyActionDetails,
        tableRowsData: counterPartyActionList.value ? counterPartyActionData : [],
        totalData:
          counterPartyActionList && counterPartyActionList["@odata.count"] ? counterPartyActionList["@odata.count"] : 0,
      });
    }
    // eslint-disable-next-line
  }, [isCounterPartyActionError, counterPartyActionList, isCounterPartyActionLoading, isCounterPartyActionFetching]);

  return (
    <Container>
      <Row>
        {counterPartyActionDetails && (
          <DataReactTable
            reactTableData={counterPartyActionDetails}
            title="CounterParty Action"
            isFetching={isCounterPartyActionFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
    </Container>
  );
};

export default CounterPartyAction;
