import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Label, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { AsyncPaginate } from "react-select-async-paginate";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { APPVERSION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const AppVersionForm = ({
  editData,
  setIsAddModalOpen,
}) => {

  const [addAppVersion, { isLoading: isAppVersionAddLoading }] =
    useAddMutation();
  const [updateAppVersion, { isLoading: isAppVersionUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const isOption = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    }
  ]

  const appName = [
    {
      value: "WOGD Contract",
      label: "WOGD Contract",
    },
    {
      value: "Any System",
      label: "Any System",
    }
  ]

  const isPlatformOption = [
    {
      value: "ios",
      label: "IOS",
    },
    {
      value: "android",
      label: "Android",
    }
  ]
  const appVersionValidationSchema = Yup.object().shape({
    AppName: Yup.mixed().required("Please select any AppName"),
    UpdateDescription: Yup.string().required("Please enter UpdateDescription"),
    Platform: Yup.mixed().required(("Please select any platform.")),
    IsMandatoryUpdate: Yup.mixed().required("Please select any IsMandatoryUpdate"),
    VersionNumber: Yup.string().required("Please enter VersionNumber"),
  });

  const onSubmit = (values) => {
    const appDetail = {
      AppName: values.AppName.value,
      Platform: values.Platform.value,
      UpdateDescription: values.UpdateDescription,
      IsMandatoryUpdate: values.IsMandatoryUpdate.value,
      VersionNumber: values.VersionNumber,
    };

    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      appDetail.Id = editRecordDetail.Id;
      updateAppVersion({
        entity: "AppVersion",
        isWogdApi: true,
        data: appDetail,
        tag: APPVERSION_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("AppVersion updated successfully.");
          setIsAddModalOpen(false)
        }
      });
    } else {
      addAppVersion({
        entity: "AppVersion",
        isWogdApi: true,
        data: appDetail,
        tag: APPVERSION_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("AppVersion added successfully.");
          setIsAddModalOpen(false)
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        AppName: editRecordDetail && editRecordDetail.AppName ? {
          label: editRecordDetail?.AppName === "WOGD Contract" ? "WOGD Contract" : "Any System",
          value: editRecordDetail?.AppName,
        }: "",
        Platform: editRecordDetail && editRecordDetail.Platform ? {
          label: editRecordDetail?.Platform === "ios" ? "IOS" : "Android",
          value: editRecordDetail?.Platform,
        } : "",
        UpdateDescription: editRecordDetail && editRecordDetail.UpdateDescription ? editRecordDetail.UpdateDescription : "Click on Update to get the latest version",
        IsMandatoryUpdate: editRecordDetail ? editRecordDetail.IsMandatoryUpdate ? isOption[0] : isOption[1] : "",
        VersionNumber: editRecordDetail && editRecordDetail.VersionNumber ? editRecordDetail.VersionNumber : "",
      }}
      onSubmit={onSubmit}
      validationSchema={appVersionValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <div className="mb-3">
              <Label for="AppName">
                App Name
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="AppName"
                value={values.AppName}
                options={appName}
                placeholder={`Select AppName`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("AppName", e)}
                required
              />
              {touched.AppName && errors.AppName && (
                <font color="red">{errors.AppName}</font>
              )}
            </div>
            <CustomInput
              label="UpdateDescription"
              name="UpdateDescription"
              type="textarea"
              fieldErrors={errors.UpdateDescription}
              fieldTouched={touched.UpdateDescription}
              fieldValue={values.UpdateDescription}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Version"
              name="VersionNumber"
              fieldErrors={errors.VersionNumber}
              fieldTouched={touched.VersionNumber}
              fieldValue={values.VersionNumber}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <div className="mb-3">
              <Label for="IsMandatoryUpdate">
                IsMandatoryUpdate
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="IsMandatoryUpdate"
                value={values.IsMandatoryUpdate}
                options={isOption}
                placeholder={`Select IsMandatoryUpdate`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("IsMandatoryUpdate", e)}
              />
              {touched.IsMandatoryUpdate && errors.IsMandatoryUpdate && (
                <font color="red">{errors.IsMandatoryUpdate}</font>
              )}
            </div>
            <div className="mb-3">
              <Label for="Platform">
                Platform
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="Platform"
                value={values.Platform}
                options={isPlatformOption}
                placeholder={`Select Platform`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("Platform", e)}
                required
              />
              {touched.Platform && errors.Platform && (
                <font color="red">{errors.Platform}</font>
              )}
            </div>
            <Button
              disabled={isAppVersionAddLoading || isAppVersionUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAppVersionAddLoading ||
                isAppVersionUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

AppVersionForm.propTypes = {
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
  setCurrentAppVersionId: PropTypes.func,
};

export default AppVersionForm;
