import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { SCREEN_THEME_TAG, THEME_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenThemeForm = ({ editData, setIsAddModalOpen }) => {
    const [addScreenTheme, { isLoading: isScreenThemeAddLoading }] =
        useAddMutation();
    const [updateScreenTheme, { isLoading: isScreenThemeUpdateLoading }] =
        useUpdateMutation();

    const formValidationSchema = Yup.object().shape({
        Screen: Yup.mixed().required("Please select screen."),
        Theme: Yup.mixed().required("Please select Theme."),
    });

    const onSubmit = (values) => {
        const screenThemeDetail = {
            ScreenId: values.Screen.Id,
            ThemeId: values.Theme.Id,
        };

        if (editData && Object.entries(editData).length > 0) {
            screenThemeDetail.Id = editData.Id;
            updateScreenTheme({
                entity: "CMS/ScreenTheme",
                data: screenThemeDetail,
                tag: [SCREEN_THEME_TAG, THEME_TAG],
            }).then((response) => {
                if (!response.error) {
                    !isScreenThemeUpdateLoading &&
                        setIsAddModalOpen(false) &&
                        successToast("Screen-Theme updated successfully.");
                }
            });
        } else {
            addScreenTheme({
                entity: "CMS/ScreenTheme",
                data: screenThemeDetail,
                tag: [SCREEN_THEME_TAG, THEME_TAG],
                responseType: 'text'
            }).then((response) => {
                if (!response.error) {
                    !isScreenThemeAddLoading && setIsAddModalOpen(false);
                    successToast(response?.data);
                }
            });
        }
    };

    return (
        <Formik
            initialValues={{
                Screen:
                    editData && editData.ScreenDTO ? editData.ScreenDTO : "",
                Theme: editData && editData.ThemeDTO ? editData.ThemeDTO : "",
            }}
            validationSchema={formValidationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => {
                const {
                    errors,
                    touched,
                    values,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                } = formik;

                return (
                    <Form>
                        {Object.keys(editData ?? {}).length > 0 && (
                            <CustomInput label="Id" fieldValue={editData.Id} readonly />
                        )}
                        <AsyncPaginates
                            value={values.Screen}
                            label="Screen"
                            name="Screen"
                            entity="CMS/Screens"
                            setFieldValue={setFieldValue}
                            fieldErrors={errors.Screen}
                            fieldTouched={touched.Screen}
                            handleBlur={handleBlur}
                            required
                            readonly={editData && Object.entries(editData).length > 0}
                        />
                        <AsyncPaginates
                            value={values.Theme}
                            label="Theme"
                            name="Theme"
                            entity="CMS/Themes"
                            setFieldValue={setFieldValue}
                            fieldErrors={errors.Theme}
                            fieldTouched={touched.Theme}
                            handleBlur={handleBlur}
                            required
                        />
                        <Button
                            disabled={
                                isScreenThemeAddLoading || isScreenThemeUpdateLoading
                            }
                            onClick={handleSubmit}
                            className="modal_ok float-right"
                            color="success"
                        >
                            {(isScreenThemeAddLoading ||
                                isScreenThemeUpdateLoading) && (
                                    <Spinner size="sm" variant="light" />
                                )}{" "}
                            Save
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};

ScreenThemeForm.propTypes = {
    editData: PropTypes.object.isRequired,
    setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ScreenThemeForm;