import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { SURVEY_CONTACT_TAG, CONTACT_TAG, SURVEY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";

const SurveyContactForm = ({
  editData,
  setIsAddModalOpen,
  isAddModalOpen,
  parentData,
}) => {
  const [addRecord, { isLoading: isAddRecordLoading }] = useAddMutation();
  const [updateRecord, { isLoading: isUpdateRecordLoading }] =
    useUpdateMutation();

  const validationSchema = Yup.object().shape({
    Survey: Yup.mixed().required("Please select survey."),
    Contact: Yup.mixed().required("Please select contact."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const surveyContactDetail = {
      SurveyId: values.Survey.Id,
      ContactId: values.Contact.Id,
      IsPrivate: values.IsPrivate,
    };
    if (values.SortOrder)
      surveyContactDetail.SortOrder = parseInt(values.SortOrder);
    if (editData && Object.entries(editData).length > 0) {
      surveyContactDetail.Id = editData.Id;
      updateRecord({
        entity: "SurveyContact",
        isWogdApi: true,
        data: surveyContactDetail,
        tag: [SURVEY_CONTACT_TAG, CONTACT_TAG],
      }).then((response) => {
        if (!response.error) {
          !isAddRecordLoading && setIsAddModalOpen(false);
          successToast("Survey-contact updated successfully.");
        }
      });
    } else {
      addRecord({
        entity: "SurveyContact",
        isWogdApi: true,
        data: surveyContactDetail,
        tag: [SURVEY_CONTACT_TAG, SURVEY_TAG, CONTACT_TAG],
      }).then((response) => {
        if (!response.error) {
          !isAddRecordLoading && setIsAddModalOpen(false);
          successToast("Survey-contact added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Survey:
          editData && editData.Survey
            ? editData.Survey
            : parentData && parentData.Survey
            ? parentData.Survey
            : "",
        Contact: editData && editData.Contact ? editData.Contact : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
        IsPrivate: editData && editData.IsPrivate ? editData.IsPrivate : false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Survey}
              label="Survey"
              name="Survey"
              entity="Survey"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Survey}
              fieldTouched={touched.Survey}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Survey}
              required
            />
            <AsyncPaginates
              value={values.Contact}
              label="Contact"
              name="Contact"
              entity="Contact"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Contact}
              fieldTouched={touched.Contact}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Private"
              name="IsPrivate"
              type="checkbox"
              fieldErrors={errors.IsPrivate}
              fieldTouched={touched.IsPrivate}
              fieldValue={values.IsPrivate}
              handleBlur={handleBlur}
              isChecked={values.IsPrivate}
              handleChange={(e) => {
                setFieldValue("IsPrivate", e.target.checked);
              }}
            />
            <Button
              disabled={isAddRecordLoading || isUpdateRecordLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAddRecordLoading || isUpdateRecordLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

SurveyContactForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.bool,
};

export default SurveyContactForm;
