import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import _ from "lodash";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
  useLazyGetAllRecordsQuery,
} from "@/services/gamecodeXApi";
import { SURVEY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import DetailPage from "@/shared/components/SharedDetailPage";
import ContactForm from "@/components/Org/Contact/Contact/ContactForm";
import Tabs from "@/shared/components/Tabs";
import { formateColumnFilter } from "@/utils/columnFilter";
import SurveyForm from "./SurveyForm";
import { getAllSurveys } from "../../CommSlice";
import SurveyContactForm from "../SurveyContact/SurveyContactForm";
import SurveyQuestionForm from "../SurveyQuestion/SurveyQuestionForm";
import "react-toastify/dist/ReactToastify.css";

const Survey = () => {
  const [isEyeClicked, setIsEyeClicked] = useState(false);
  const [surveyDetails, setSurveyDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteSurvey, { isLoading: isDeleting }] = useDeleteMutation();
  const [deleteRecord, setDeleteRecord] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [selectedAllRecord, setSelectedAllRecord] = useState({});
  
  const dispatch = useDispatch();
  const [
    getSurveyRecords,
    {
      data: surveyList,
      isError: isSurveyError,
      isLoading: isSurveyLoading,
      isFetching: isSurveyFetching,
    },
  ] = useLazyGetRecordsQuery();

  const [
    getAllSurveyRecords,
    {
      data: allSurveyList,
      isError: isAllSurveyRecordError,
      isLoading: isAllSurveyRecordLoading,
      isFetching: isAllSurveyRecordFetching,
    },
  ] = useLazyGetAllRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Survey Type",
      accessor: "SurveyType.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (surveyData) => {
    setSelectedRecord(surveyData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteSurvey({
      entity: deleteRecord
        ? deleteRecord.entity && deleteRecord.entity === "SurveyContact"
          ? "SurveyContact"
          : "SurveyQuestion"
        : "Survey",
      isWogdApi: true,
      id: deleteRecord && deleteRecord.Id ? deleteRecord.Id : selectedRecord.Id,
      tag: SURVEY_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(
          `${
            deleteRecord && deleteRecord.entity ? deleteRecord.entity : "Survey"
          } deleted successfully.`
        );
      }
    });
  };

  useEffect(() => {
    getAllSurveyRecords({
      entity: "Survey",
      isWogdApi: true,
      expand: {
        SurveyType: {},
        SurveyContacts: {
          expand: { Contact: { expand: { ContactType: {}, Entity: {} } } },
        },
        SurveyQuestions: {
          expand: {
            Question: {
              expand: {
                QuestionType: {},
                QuestionOptions: {
                  expand: { Option: { expand: { OptionType: {} } } },
                },
              },
            },
          },
        },
      },
      tag: SURVEY_TAG,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(allSurveyList && selectedRecord) && !isAllSurveyRecordError && !isAllSurveyRecordFetching && !isAllSurveyRecordLoading) {
      setSelectedAllRecord(
        allSurveyList.value.filter(
          (survey) => survey.Id === selectedRecord.Id
        )[0]
      );
    }
    // eslint-disable-next-line
  }, [allSurveyList, selectedRecord]);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getSurveyRecords({
      entity: "Survey",
      isWogdApi: true,
      expand: {
        SurveyType: {},
        SurveyContacts: {
          expand: { Contact: { expand: { ContactType: {}, Entity: {} } } },
        },
        SurveyQuestions: {
          expand: {
            Question: {
              expand: {
                QuestionType: {},
                QuestionOptions: {
                  expand: { Option: { expand: { OptionType: {} } } },
                },
              },
            },
          },
        },
      },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SURVEY_TAG,
    });
    setSurveyDetails({
      tableHeaderData: column,
      tableRowsData: [],
    });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!isSurveyLoading && !isSurveyError && surveyList && !isSurveyFetching) {
      dispatch(getAllSurveys(surveyList.value));
      let surveyData = [...surveyList.value];
      if (selectedRecord) {
        setSelectedRecord((prev) =>
          surveyList.value.find((value) => value.Id === prev.Id)
        );
      }
      surveyData = surveyList.value.map((survey) => {
        let surveyData = { ...survey };
        return surveyData;
      });
      setSurveyDetails({
        ...surveyDetails,
        tableRowsData: surveyList.value ? surveyData : [],
        totalData:
          surveyList && surveyList["@odata.count"]
            ? surveyList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => {
          setIsEyeClicked(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isSurveyError, surveyList, isSurveyLoading, isSurveyFetching]);

  const crossLinkColumns = {
    "Survey Contact": [{ header: "Contact", accessor: "Contact.Name" }],
    "Survey Question": [{ header: "Question", accessor: "Question.Name" }],
  };

  const tabs = {
    Survey: (
      <SurveyForm
        editData={
          selectedRecord ? selectedAllRecord : isEyeClicked ? isEyeClicked : {}
        }
        setIsAddModalOpen={setIsAddModalOpen}
      />
    ),
    Contact: (
      <ContactForm
        editData={{}}
        setActiveTab={setActiveTab}
      />
    ),
  };

  return (
    <Container>
      <Row>
        {isEyeClicked && (
          <DetailPage
            setIsEyeClicked={() => {
              setIsEyeClicked(false);
              setSelectedRecord();
              setDeleteRecord();
            }}
            title="Survey"
            displayData={selectedRecord}
            restContent={
              selectedRecord
                ? {
                    Name:
                      selectedRecord && selectedRecord.Name
                        ? selectedRecord.Name
                        : "-",
                    Description: selectedRecord.Description
                      ? selectedRecord.Description
                      : "-",
                    "Survey Type": selectedRecord.SurveyType.Name
                      ? selectedRecord.SurveyType.Name
                      : "-",
                  }
                : null
            }
            crossLinkData={{
              "Survey Contact": {
                totalRecords: !_.isEmpty(selectedAllRecord) && selectedAllRecord.SurveyContacts.length,
                records: !_.isEmpty(selectedAllRecord) && [...selectedAllRecord.SurveyContacts.slice(0, 3)],
                colorStyle: "success",
                redirectUrl: "/comm/survey-contact",
                addForm: (
                  addFormActiveTab,
                  addFormSetActiveTab,
                  isCrossLinkAddModalOpen,
                  setIsCrossLinkAddModalOpen
                ) => (
                  <SurveyContactForm
                    parentData={{ Survey: !_.isEmpty(selectedAllRecord) && selectedAllRecord }}
                    setIsAddModalOpen={setIsCrossLinkAddModalOpen}
                    isAddModalOpen={isCrossLinkAddModalOpen}
                  />
                ),
              },
              "Survey Question": {
                totalRecords: !_.isEmpty(selectedAllRecord) && selectedAllRecord.SurveyQuestions.length,
                records: !_.isEmpty(selectedAllRecord) && [...selectedAllRecord.SurveyQuestions.slice(0, 3)],
                colorStyle: "warning",
                redirectUrl: "/comm/survey-question",
                addForm: (
                  addFormActiveTab,
                  addFormSetActiveTab,
                  isCrossLinkAddModalOpen,
                  setIsCrossLinkAddModalOpen
                ) => (
                  <SurveyQuestionForm
                    parentData={{ Survey: !_.isEmpty(selectedAllRecord) && selectedAllRecord }}
                    setIsAddModalOpen={setIsCrossLinkAddModalOpen}
                    isAddModalOpen={isCrossLinkAddModalOpen}
                  />
                ),
              },
            }}
            crossLinkColumns={crossLinkColumns}
            setIsAddModalOpen={setIsAddModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setDeleteRecord={setDeleteRecord}
          />
        )}
        {surveyDetails && !isEyeClicked && (
          <DataReactTable
            reactTableData={surveyDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Survey"
            isFetching={isSurveyFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => {
            if (!isEyeClicked) {
              setSelectedRecord();
              setActiveTab("1");
              setIsAddModalOpen(!isAddModalOpen);
            }
          }}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Survey`}
          header
          xxl
          btn="Success"
          message={
            <div>
              <Tabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Survey;
