import React, { useEffect, useState } from "react";
import { Button, Container, Row, Spinner } from "reactstrap";
import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { useUpdateMutation } from "@/services/gamecodeXApi";
import { successToast } from "@/shared/components/toast";
import { CLAUSE_TAG } from "@/shared/tagFile";

const ClauseModifyRequestForm = ({ editData, setIsAddModalOpen }) => {
  const [clauseModifyRequestDetails, setClauseModifyRequestDetails] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [columnFilter, setColumnFilter] = useState({});
  const [editRecords, setEditRecords] = useState({});
  const [finalApprovel, setFinalApprovel] = useState([]);

  const [updateClause, { isLoading: isClauseUpdateLoading }] =
    useUpdateMutation();

  const column = [
    {
      Header: "#",
      accessor: "id",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Name",
      accessor: "name",
      filter: true,
    },
    {
      Header: "fullText",
      accessor: "fullText",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "clauseTypeId",
      accessor: "clauseTypeId",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filter: true,
      Cell: (data) => {
        return <div className="d-flex justify-content-around">
          {!data?.value ? (
            <div>
              <CheckCircleOutlineIcon
                title="Approved"
                className="cursor-pointer mx-2"
                color={data?.cell?.row?.original?.status === "approved" ? "green" : "#646777"}
                onClick={() => {
                  handleApproveData(data?.cell?.row?.original)
                }}
              />
              <CloseCircleOutlineIcon
                className="cursor-pointer mx-2"
                color={data?.cell?.row?.original?.status === "rejected" ? "red" : "#646777"}
                onClick={() => {
                  handleRejectClick(data?.cell?.row?.original)
                }}
              />
            </div>
          ) : data.value === "approved" ? (
            <span className="badge badge-success">{data?.value}</span>
          ) : (
            <span className="badge badge-danger">{data?.value}</span>
          )}
        </div>
      },
    },
  ];

  useEffect(() => {
    setClauseModifyRequestDetails({ tableHeaderData: column, tableRowsData: [] });
  }, [pageSize, page, sort, columnFilter]);

  const handleApproveData = (data) => {
    data["status"] = "approved"
    setFinalApprovel(prev => {
      let isExist = false;
      let temp2 = []
      prev?.forEach((clause, index) => {
        if (clause?.name === data?.name) {
          isExist = true
          if (clause?.status === data?.status) {
            prev.splice(index,1)
            temp2 = [...prev]
            delete data["status"]
          } else {
            temp2.push({ ...data })
          }
        } else {
          temp2.push({ ...clause })
        }
      })
      return isExist ? [...temp2] : [...prev, {...data}]
    })
  }

  const handleRejectClick = (data) => {
    data["status"] = "rejected"
    setFinalApprovel(prev => {
      let isExist = false;
      let temp2 = []
      prev.forEach((clause, index) => {
        if (clause?.name === data?.name) {
          isExist = true
          if (clause?.status === data?.status) {
            prev?.splice(index,1)
            temp2 = [...prev]
            delete data["status"]
          } else {
            temp2?.push({ ...data })
          }
        } else {
          temp2?.push({ ...clause })
        }
      })
      return isExist ? [...temp2] : [...prev, {...data}]
    })
  }

  const updateClauseHandler = () => {
    const updatedClauseObject = JSON.parse(editRecords?.ClauseObject)
    const tempPayload = updatedClauseObject?.map(clause => {
      const exist = finalApprovel?.filter(newClause => newClause?.name === clause?.name)
      return exist?.length ? exist[0] : clause
    })
    const finalPayload = {
      id: editRecords?.Id,
      counterPartyId: editRecords?.CounterPartyId,
      clauseObject: JSON.stringify(tempPayload)
    }
    updateClause({
      entity: "Doc/HandleContractModificationRequest",
      Id: "",
      data: finalPayload,
      tag: CLAUSE_TAG
    }).then(resp => {
      if (!resp.error) {
        successToast("Successfully responded on clause request.");
        setIsAddModalOpen()
      }
    })
  }

  useEffect(() => {
    if (Object.keys(editRecords).length) {
      let tempClause = JSON.parse(editRecords?.ClauseObject)
      let clauseData = [...tempClause];
      setClauseModifyRequestDetails({
        ...clauseModifyRequestDetails,
        tableRowsData: clauseData,
        totalData: 10,
        onApproveClick: (data) => handleApproveData(data),
        onRejectClick: (data) => handleRejectClick(data),
      });
    }
  }, [editRecords, pageSize, page, sort, columnFilter, ]);

  useEffect(() => {
    if (Object.keys(editData).length) {
      setEditRecords(editData)
    }
  }, [editData])

  return (
    <Container data-testid="test-Contract">
      {clauseModifyRequestDetails && <Row style={{ position: "relative" }}>
        {clauseModifyRequestDetails && <DataReactTable
          reactTableData={clauseModifyRequestDetails}
          setPageNumber={setPage}
          recordsPerPage={pageSize}
          setRecordsPerPage={setPageSize}
          setSort={setSort}
          isFetching={false}
        />}
        <Button disabled={!finalApprovel.length} color="success" onClick={updateClauseHandler} style={{ position: "absolute", bottom: 24, right: 35 }}>
          {isClauseUpdateLoading && <Spinner size="sm" variant="light" />}   Save
        </Button>
      </Row>}
    </Container>
  );
}

export default ClauseModifyRequestForm
