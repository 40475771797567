import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAsyncDebounce } from "react-table";
import { Input, Button } from "reactstrap";

const ReactTableFilter = ({
  setGlobalFilter,
  setFilterValue,
  placeholder,
  dataLength,
  gotoPage,
  onAddClick,
  onWizardClick,
  onArchive
}) => {
  const [isGlobalFilterApplyed, setIsGlobalFilterApplyed] = useState(false);
  const onChange = useAsyncDebounce((item) => {
    const value = item.trim();
    if (!value.length > 0) {
      setIsGlobalFilterApplyed(false);
    } else {
      setIsGlobalFilterApplyed(true);
    }
    setFilterValue(value);
    setGlobalFilter(value);
    gotoPage(0);
  }, 200);

  return (
    <div className="table__search d-flex justify-content-between">
      <div className="d-flex w-25">
        <Input
          className="table__search table__search-input"
          onChange={(e) => onChange(e.target.value)}
          placeholder={`${placeholder}`}
        />
        {isGlobalFilterApplyed && <span>Found {dataLength} matches</span>}
      </div>
      <div className="d-flex">
        {onArchive && (
          <Button
            color={onArchive.isArchiveDetails ? "primary" : "secondary"}
            className="rounded float-right"
            onClick={onArchive.setIsArchiveDetails}
          >
            {onArchive.isArchiveDetails ? "Archived" : "Archive"}
          </Button>
        )}
        {onAddClick && (
          <Button
            color="primary"
            className="rounded float-right"
            onClick={onAddClick}
          >
            Add
          </Button>
        )}
        {onWizardClick && (
          <Button
            color="primary"
            className="rounded float-right"
            onClick={onWizardClick}
          >
            Wizard
          </Button>
        )}
      </div>
    </div>
  );
};

const filterGreaterThan = (rows, id, filterValue) =>
  rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });

filterGreaterThan.autoRemove = (val) => typeof val !== "number";

ReactTableFilter.propTypes = {
  setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  placeholder: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
  onAddClick: PropTypes.func,
};

ReactTableFilter.defaultProps = {
  setFilterValue: () => {},
  placeholder: "Search...",
};

export default ReactTableFilter;
