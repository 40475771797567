import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { SCREEN_MAPPING_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenMappingForm = ({ editData, setIsAddModalOpen }) => {
  const [addScreenMapping, { isLoading: isScreenMappingAddLoading }] =
  useAddMutation();
  const [
    updateScreenMapping,
    { isLoading: isScreenMappingUpdateLoading },
  ] = useUpdateMutation();

  const screenValidationSchema = Yup.object().shape({
      Screen: Yup.mixed().required("Please select screen."),
      LongWebAddress: Yup.string().required("Please enter Web Address."),
  });

  const onSubmit = (values) => {
    const screenMappingDetail = {
        ScreenId: values.Screen.Id,
        LongWebAddress: values.LongWebAddress,
    };

    if (editData && Object.entries(editData).length > 0) {
      screenMappingDetail.Id = editData.Id;
      updateScreenMapping({
        entity: "CMS/ScreenMapping",
        data: screenMappingDetail,
        tag: SCREEN_MAPPING_TAG,
        responseType: 'text',
      }).then((response) => {
        if (!response.error) {
          !isScreenMappingUpdateLoading && setIsAddModalOpen(false);
          successToast(response.data);
        }
      });
    } else {
      addScreenMapping({
        entity: "CMS/ScreenMapping",
        data: screenMappingDetail,
        tag: SCREEN_MAPPING_TAG,
        responseType: 'text',
      }).then((response) => {
        if (!response.error) {
          !isScreenMappingAddLoading && setIsAddModalOpen(false);
          successToast(response.data);
        } 
      });
    }
  };

  return (
    <div>
        <Formik
            initialValues={{
                LongWebAddress: editData && editData?.LongWebAddress || "",
                Screen: editData && editData?.ScreenDTO || "",
            }}
            validationSchema={screenValidationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => {
            const {
                errors,
                touched,
                values,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
            } = formik;

            return (
                <Form>
                {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
                <AsyncPaginates
                    value={values.Screen}
                    label="Screen"
                    name="Screen"
                    entity="CMS/Screens"
                    setFieldValue={setFieldValue}
                    fieldErrors={errors.Screen}
                    fieldTouched={touched.Screen}
                    handleBlur={handleBlur}
                    required
                />
                <CustomInput
                    label="Long Web Address"
                    name="LongWebAddress"
                    fieldErrors={errors.LongWebAddress}
                    fieldTouched={touched.LongWebAddress}
                    fieldValue={values.LongWebAddress}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    required
                />
                <Button
                    disabled={
                    isScreenMappingAddLoading ||
                    isScreenMappingUpdateLoading
                    }
                    onClick={handleSubmit}
                    className="modal_ok float-right"
                    color="success"
                >
                    {(isScreenMappingAddLoading ||
                    isScreenMappingUpdateLoading) && (
                    <Spinner size="sm" variant="light" />
                    )}{" "}
                    Save
                </Button>
                </Form>
            );
            }}
        </Formik>
    </div>
  )
}

ScreenMappingForm.propTypes = {
    editData: PropTypes.object.isRequired,
    setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ScreenMappingForm
