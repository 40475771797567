import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner, Col, Row, Label } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { Link } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { CONTENT_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import AsyncPaginates from "@/shared/components/LazySelect";

const customInput = [
  { value: "title", label: "Title" },
  { value: "subTitle", label: "SubTitle" },
  { value: "description", label: "Description" },
  { value: "email", label: "Email" },
  { value: "phoneNumber", label: "PhoneNumber" },
  { value: "buttonText", label: "ButtonText" }
];

const customInputType = [
  { value: "string", label: "String" },
  { value: "textarea", label: "Full Text" },
  { value: "textEditor", label: "Text Editor" },
  { value: "number", label: "Number" },
  { value: "email", label: "Email" },
  { value: "date", label: "Date" },
  { value: "image", label: "Image" },
  { value: "audio", label: "Audio" },
  { value: "video", label: "Video" },
]

const ContentTypeForm = ({ editData, setIsAddModalOpen }) => {
  const [addContentType, { isLoading: isContentTypeAddLoading }] = useAddMutation();
  const [updateContentType, { isLoading: isContentTypeUpdateLoading }] = useUpdateMutation();
  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };
  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    if (!(values.ContentObject.images && values.ContentObject.images.length)) {
      delete values.ContentObject.images
    }
    if (!(values.ContentObject.lists && values.ContentObject.lists.length)) {
      delete values.ContentObject.lists
    }
    if (!(values.ContentObject.insertBlog && values.ContentObject.insertBlog.length)) {
      delete values.ContentObject.insertBlog
    }
    if (!(values.ContentObject.videos && values.ContentObject.videos.length)) {
      delete values.ContentObject.videos
    }
    if (!(values.ContentObject.mobileImages && values.ContentObject.mobileImages.length)) {
      delete values.ContentObject.mobileImages
    }
    if (!(values.ContentObject.dynamicFieldList && values.ContentObject.dynamicFieldList.length)) {
      delete values.ContentObject.dynamicFieldList
    }
    const components = values?.Component ? values?.Component?.map((val) => {
      return val.Id || val.id
    }) : []
    let payload = {
      Name: values.Name,
      ComponentIds: components,
      Description: values.Description,
      ContentObject: JSON.stringify(values.ContentObject),
    }
    if (editData && Object.entries(editData).length > 0) {
      payload.Id = editData.Id;
      updateContentType({
        entity: "CMSReference/ContentType",
        data: payload,
        tag: CONTENT_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContentTypeUpdateLoading && setIsAddModalOpen(false)
          !isContentTypeUpdateLoading && successToast("Content-type updated successfully.");
        }
      });
    } else {
      addContentType({
        entity: "CMSReference/ContentType",
        data: payload,
        tag: CONTENT_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContentTypeAddLoading && setIsAddModalOpen(false);
          successToast("Content-type added successfully.");
        }
      });
    }
  };
  const handleDropdownChange = (event, values, setFieldValue) => {
    let resp = {}
    event.forEach((val) => {
      var item = val
      values?.ContentObject[val?.label]?.value === undefined ?
        (resp[item['value'].split(" ").join("")] = { type: "", value: "" }) : (resp[item['value'].split(" ").join("")] = { type: values?.ContentObject[val?.label]?.type, value: values?.ContentObject[val?.label]?.value })
      return resp
    })
    resp['images'] = values.ContentObject.images
    resp['lists'] = values.ContentObject.lists
    resp['insertBlog'] = values.ContentObject.insertBlog
    resp['videos'] = values.ContentObject.videos
    resp['mobileImages'] = values.ContentObject.mobileImages
    resp['dynamicFieldList'] = values.ContentObject.dynamicFieldList
    setFieldValue("ContentObject", resp)
  };
  const handleComponent = (option, setFieldValue, values) => {
    const allOptions = option.map(({ Id, Name }) => ({ Id, Name }));
    const shopifyDetail = allOptions.find(({ Name }) => Name === "ShopifyProductList");
    values.Component = shopifyDetail
      ? [{ Id: shopifyDetail.Id, Name: shopifyDetail.Name }]
      : [...allOptions];
    if (shopifyDetail) {
      values.ContentObject.apiURL = { type: "textarea", value: "" };
      ["images", "lists", "mobileImages", "videos", "dynamicFieldList", "insertBlog"].forEach(field => {
        delete values?.ContentObject[field];
      });
    } else {
      delete values?.ContentObject?.apiURL;
    }
    setFieldValue("Component", values?.Component);
    setFieldValue("ContentObject", values?.ContentObject);
  };




  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Component: editData && editData?.ComponentContentTypeDTO ? editData.ComponentContentTypeDTO?.map(val => {
          return { Id: val.ComponentDTO.Id, Name: val.ComponentDTO.Name }
        }) : [],
        Description:
          editData && editData.Description ? editData.Description : "",
        ContentObject: editData && editData.ContentObject ? JSON.parse(editData.ContentObject) : {},
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }) => (
        <Form>
          <Row>
            <Col lg={8}>
              <Row>
                <Col lg={6} md={6}>
                  {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
                  <CustomInput
                    label="Name"
                    name="Name"
                    fieldErrors={errors.Name}
                    fieldTouched={touched.Name}
                    fieldValue={values.Name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    required
                  />

                  <CustomInput
                    label="Description"
                    name="Description"
                    type="textarea"
                    fieldErrors={errors.Description}
                    fieldTouched={touched.Description}
                    fieldValue={values.Description}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <AsyncPaginates
                    value={values.Component}
                    label="Component"
                    name="Component"
                    entity="CMSReference/Components"
                    fieldErrors={errors.Component}
                    fieldTouched={touched.Component}
                    handleBlur={handleBlur}
                    isMulti={true}
                    handleChange={(option) => handleComponent(option, setFieldValue, values)}
                  />
                  {values?.Component && !values.Component.some((value) => value?.Name === "ShopifyProductList") &&
                    <>
                      <div className="d-flex justify-content-between mt-4">
                        <CustomInput
                          label="Insert Images"
                          name="ContentObject.images"
                          type="checkbox"
                          fieldErrors={errors.ContentObject}
                          fieldTouched={touched.ContentObject}
                          fieldValue={values.ContentObject?.images}
                          handleBlur={handleBlur}
                          isChecked={values.ContentObject?.images}
                          handleChange={(e) => {
                            setFieldValue("ContentObject.images", e.target.checked && [{ url: "", name: "" }]);
                          }}
                        />
                        <CustomInput
                          label="Insert List"
                          name="ContentObject.lists"
                          type="checkbox"
                          fieldErrors={errors.ContentObject}
                          fieldTouched={touched.ContentObject}
                          fieldValue={values.ContentObject?.lists}
                          handleBlur={handleBlur}
                          isChecked={values.ContentObject?.lists}
                          handleChange={(e) => {
                            setFieldValue("ContentObject.lists", e.target.checked && [{ name: "" }]);
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-between ">
                        <CustomInput
                          label="Insert Mobile Images"
                          name="ContentObject.mobileImages"
                          type="checkbox"
                          fieldErrors={errors.ContentObject}
                          fieldTouched={touched.ContentObject}
                          fieldValue={values.ContentObject?.mobileImages}
                          handleBlur={handleBlur}
                          isChecked={values.ContentObject?.mobileImages}
                          handleChange={(e) => {
                            setFieldValue("ContentObject.mobileImages", e.target.checked && [{ image1: { name: "", url: "" }, image2: { name: "", url: "" }, description: "" }]);
                          }}
                        />

                        <CustomInput
                          label="Insert Video"
                          name="ContentObject.videos"
                          type="checkbox"
                          fieldErrors={errors.ContentObject}
                          fieldTouched={touched.ContentObject}
                          fieldValue={values.ContentObject?.videos}
                          handleBlur={handleBlur}
                          isChecked={values.ContentObject?.videos}
                          handleChange={(e) => {
                            setFieldValue("ContentObject.videos", e.target.checked && [{ url: "", name: "" }]);
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-between ">
                        <CustomInput
                          label="Dynamic fields list"
                          name="ContentObject.dynamicFieldList"
                          type="checkbox"
                          fieldErrors={errors.ContentObject}
                          fieldTouched={touched.ContentObject}
                          fieldValue={values.ContentObject?.dynamicFieldList}
                          handleBlur={handleBlur}
                          isChecked={values.ContentObject?.dynamicFieldList}
                          handleChange={(e) => {
                            setFieldValue("ContentObject.dynamicFieldList", e.target.checked && [{}]);
                          }}
                        />
                        <CustomInput
                          label="Insert Blog"
                          name="ContentObject.insertBlog"
                          type="checkbox"
                          fieldErrors={errors.ContentObject}
                          fieldTouched={touched.ContentObject}
                          fieldValue={values.ContentObject?.insertBlog}
                          handleBlur={handleBlur}
                          isChecked={values.ContentObject?.insertBlog}
                          handleChange={(e) => {
                            setFieldValue("ContentObject.insertBlog", e.target.checked && [{}]);
                          }}
                        />
                      </div>
                    </>}
                </Col>
                <Col lg={6} md={6}>
                  <div style={{ border: "1px solid #d3d3d3", borderRadius: "3px", padding: "5px", marginBottom: "5px" }}>
                    <Label for="ContentObject">
                      Inputs
                    </Label>
                    <CreatableSelect
                      name='ContentObject'
                      id="ContentObject"
                      isMulti
                      placeholder="Select Inputs"
                      isClearable={false}
                      backspaceRemovesValue={false}
                      value={Object.keys(values.ContentObject).map((val) => {
                        if (val !== 'images' && val !== 'lists' && val !== 'insertBlog' && val !== 'videos' && val !== 'mobileImages' && val !== 'dynamicFieldList') {
                          return {
                            'label': val,
                            'value': val,
                            isFixed: val === "apiURL" || (editData && editData.ContentObject ? Object.keys(JSON.parse(editData?.ContentObject)).includes(val) : false)
                          }
                        }
                      })}
                      onChange={(event) => handleDropdownChange(event, values, setFieldValue)}
                      options={customInput}
                      styles={styles}
                      className="mb-2"
                      fieldErrors={errors.ContentObject}
                      fieldTouched={touched.ContentObject}
                      autoFocus={true}
                      required={true}
                    />
                    {Object.entries(values.ContentObject).map((val, index) => {
                      return val[0] === "images" || val[0] === "lists" || val[0] === 'insertBlog' || val[0] === "videos" || val[0] === "mobileImages" || val[0] === "dynamicFieldList" ? <div key={index}></div> : (
                        <div key={index}>
                          <Label for="InputType">
                            {val[0]}
                          </Label>
                          <CreatableSelect
                            name='InputType'
                            id="InputType"
                            placeholder={`Select ${val[0]}'s Type`}
                            value={val[0] === "apiURL" ? { 'label': "textarea", 'value': "" } : val[1].type ? { 'label': val[1].type, 'value': val[1].type } : null}
                            onChange={(event) => {
                              if (event.value === "image" || event.value === "audio" || event.value === "video") {
                                setFieldValue(`ContentObject.${val[0]}.type`, event.value)
                                setFieldValue(`ContentObject.${val[0]}.value`, { url: "", name: "", href: "" })
                              } else if (val[0] === "apiURL") {
                                setFieldValue(`ContentObject.${val[0]}.type`, "textarea")
                              } else {
                                setFieldValue(`ContentObject.${val[0]}.type`, event.value)
                                setFieldValue(`ContentObject.${val[0]}.value`, "")
                              }
                            }}
                            options={customInputType}
                            isDisabled={val[0] === "apiURL"}
                            styles={{
                              control: base => ({
                                ...base,
                                borderColor: val[1].type === "" ? "red" : base.borderColor,
                                outline: val[1].type === "" ? "red" : base.outline,
                                boxShadow: val[1].type === "" ? " red" : base.boxShadow,
                                "&:hover": {
                                  borderColor: val[1].type === "" ? 'red' : base.borderColor,
                                }}
                              )}}
                           
                            className="mb-2"
                            fieldErrors={errors.ContentObject}
                            fieldTouched={touched.ContentObject}
                            autoFocus={true}
                            required={true}
                            isSearchable={false}
                          />
                        </div>
                      )
                    })}
                  </div>

                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <Row>
                <Col>
                  <CustomInput
                    label="Content Object"
                    type="textarea"
                    name="ContentType"
                    fieldValue={JSON.stringify(values, null, 2)}
                    readonly
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-end">
            {Object.keys(values.ContentObject).some(item => (item !== "images" && item !== "list" && item !== "videos" && item !== "mobileImages" && item !== "dynamicFieldList") && values.ContentObject[item]?.type === "") &&
              <span style={{color: "red", marginRight: "5rem" }}>
                Please select the type of input<br />
                for each input field.
              </span>
            }
            <Button
              disabled={isContentTypeAddLoading || isContentTypeUpdateLoading || Object.keys(values.ContentObject).some(item => (item !== "images" && item !== "list" && item !== "videos" && item !== "mobileImages" && item !== "dynamicFieldList") && values.ContentObject[item]?.type === "")}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isContentTypeAddLoading || isContentTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Row>
        </Form>
      )
      }
    </Formik >
  )
}

ContentTypeForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ContentTypeForm;
