import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { CLAUSE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ClauseForm from "./ClauseForm";
import { getAllClauses } from "../../../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const Clause = () => {
    const [clauseDetails, setClauseDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteClause, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const [isArchiveClause, setIsArchiveClause] = useState(false)
    const dispatch = useDispatch();
    const [
      getClauseRecords,
      {
        data: clauseList,
        isError: isClauseError,
        isLoading: isClauseLoading,
        isFetching: isClauseFetching,
      },
    ] = useLazyGetRecordsQuery();
  
    const [
      getArchivedClause, {
        data: archivedClause,
        isError: isDeletedClauseError,
        isLoading: isDeletedClauseLoading,
        isFetching: isDeletedClauseFetching,
      }
      ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Name",
        accessor: "Name",
        filter: true,
      },
      {
        Header: "Full Text",
        accessor: "FullText",
        filter: true,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Clause Type",
        accessor: "ClauseTypeDTO.Name",
        filter: true,
      },
    ];

    const handleEditData = (clauseData) => {
      setSelectedRecord(clauseData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteClause({
        entity: "Doc/Clause",
        id: selectedRecord.Id,
        tag: CLAUSE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Clause deleted successfully.");
        }
      });
    };

    const handleRestoreData = (data) => {
      deleteClause({
        entity: "Doc/Clause",
        id: `${data?.Id}?isRestore=true`,
        tag: CLAUSE_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Clause restored successfully.");
        }
      });
    }
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
  
      if (!isArchiveClause) {
        getClauseRecords({
          entity: "Doc/Clauses",
          entities: {ClauseType : {}},
          top: pageSize,
          skip: (page - 1) * pageSize,
          sort: sort,
          filter: filterQuery,
          globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
          },
          tag: CLAUSE_TAG,
        });
      } else {
        getArchivedClause({
          entity: "Doc/Clauses",
          entities: {ClauseType : {}},
          isArchive:true,
          top: pageSize,
          skip: (page - 1) * pageSize,
          sort: sort,
          filter: filterQuery,
          globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
          },
          tag: CLAUSE_TAG,
        })
      }
      setClauseDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter, isArchiveClause]);
  
    useEffect(() => {
      if (
        !isClauseLoading &&
        !isDeletedClauseLoading &&
        !isClauseError &&
        !isDeletedClauseError &&
        (clauseList || archivedClause)&&
        !isClauseFetching &&
        !isDeletedClauseFetching
      ) {
        dispatch(getAllClauses(isArchiveClause ? archivedClause.value : clauseList.value));
        let ClauseData = isArchiveClause ? [...archivedClause.value] : [...clauseList.value];
        setClauseDetails({
          ...clauseDetails,
          tableRowsData: clauseList.value ? ClauseData : [],
          totalData:
          isArchiveClause ? 
            archivedClause && archivedClause?.["@odata.count"] 
              : clauseList && clauseList?.["@odata.count"] || 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
          },
          onRestoreClick: isArchiveClause ? (data) => {
            handleRestoreData(data);
          }: "",
        });
      }
      // eslint-disable-next-line
    }, [
      isClauseError,
      isDeletedClauseError,
      clauseList,
      archivedClause,
      isClauseLoading,
      isDeletedClauseLoading,
      isClauseLoading,
      isClauseFetching,
      isDeletedClauseFetching
    ]);
  
    const deleteMessage = () => {
      return (
        <div>
          Are you sure you want to delete {selectedRecord.Name}?
          <div className="warning-div">
            <i>
              This will delete all the associated records <br />
              ( Like - ContractClause)
            </i>
          </div>
        </div>
      );
    }
  
    return (
      <Container data-testid="test-Clause">
        <Row>
          {clauseDetails && (
            <DataReactTable
              reactTableData={clauseDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              onArchive={{
                setIsArchiveDetails : () => setIsArchiveClause(!isArchiveClause),
                isArchiveDetails : isArchiveClause
              }}
              title="Clause"
              isFetching={isClauseFetching || isDeletedClauseFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            testid="addModal"
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Clause`}
            header
            btn="Success"
            message={
                <ClauseForm
                    editData={selectedRecord ? selectedRecord : {}}
                    setIsAddModalOpen={()=>setIsAddModalOpen(!isAddModalOpen)}
                />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={deleteMessage()}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    );
}

export default Clause
