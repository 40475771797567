import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddComponentModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { QUESTION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import DetailPage from "@/shared/components/SharedDetailPage";
import { getAllQuestions } from "../../CommSlice";
import QuestionForm from "./QuestionForm";
import QuestionOptionForm from "../QuestionOption/QuestionOptionForm";
import SurveyQuestionForm from "../../Survey/SurveyQuestion/SurveyQuestionForm";
import "react-toastify/dist/ReactToastify.css";

const Question = () => {
  const [isEyeClicked, setIsEyeClicked] = useState(false);
  const [questionDetails, setQuestionDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteQuestion, { isLoading: isDeleting }] = useDeleteMutation();
  const [deleteRecord, setDeleteRecord] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getQuestionRecords,
    {
      data: questionList,
      isError: isQuestionError,
      isLoading: isQuestionLoading,
      isFetching: isQuestionFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Placeholder Text",
      accessor: "PlaceholderText",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "QuestionType",
      accessor: "QuestionType.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Required",
      accessor: "IsRequired",
      filter: true,
      Cell: ({ value }) => {
        return `${value}`;
      },
    },
  ];

  const handleEditData = (questionData) => {
    setSelectedRecord(questionData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteQuestion({
      entity: deleteRecord
        ? deleteRecord.entity && deleteRecord.entity === "QuestionOption"
          ? "QuestionOption"
          : "SurveyQuestion"
        : "Question",
      isWogdApi: true,
      id: deleteRecord && deleteRecord.Id ? deleteRecord.Id : selectedRecord.Id,
      tag: QUESTION_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(
          `${
            deleteRecord && deleteRecord.entity
              ? deleteRecord.entity
              : "Question"
          } deleted successfully.`
        );
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getQuestionRecords({
      entity: "Question",
      isWogdApi: true,
      top: pageSize,
      expand: {
        QuestionType: {},
        QuestionOptions: { expand: { Option: {} } },
        SurveyQuestions: { expand: { Survey: {} } },
      },
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: QUESTION_TAG,
    });
    setQuestionDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isQuestionLoading &&
      !isQuestionError &&
      questionList &&
      !isQuestionFetching
    ) {
      dispatch(getAllQuestions(questionList.value));
      let questionData = [...questionList.value];
      if (selectedRecord) {
        setSelectedRecord((prev) =>
          questionList.value.find((value) => value.Id === prev.Id)
        );
      }
      setQuestionDetails({
        ...questionDetails,
        tableRowsData: questionList.value ? questionData : [],
        totalData:
          questionList && questionList["@odata.count"]
            ? questionList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => {
          setIsEyeClicked(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isQuestionError, questionList, isQuestionLoading, isQuestionFetching]);

  const crossLinkColumns = {
    "Question Option": [{ header: "Option", accessor: "Option.Name" }],
    "Survey Question": [{ header: "Survey", accessor: "Survey.Name" }],
  };
  return (
    <Container>
      <Row>
        {isEyeClicked && (
          <DetailPage
            setIsEyeClicked={() => {
              setIsEyeClicked(false);
              setSelectedRecord();
              setDeleteRecord();
            }}
            title="Question"
            displayData={selectedRecord}
            restContent={
              selectedRecord
                ? {
                    Name:
                      selectedRecord && selectedRecord.Name
                        ? selectedRecord.Name
                        : "-",
                    Description: selectedRecord.Description
                      ? selectedRecord.Description
                      : "-",
                    QuestionType: selectedRecord.QuestionType.Name
                      ? selectedRecord.QuestionType.Name
                      : "-",
                  }
                : null
            }
            crossLinkData={{
              "Question Option": {
                totalRecords: selectedRecord.QuestionOptions.length,
                records: [...selectedRecord.QuestionOptions.slice(0, 3)],
                colorStyle: "success",
                redirectUrl: "/comm/question-option",
                addForm: (
                  addFormActiveTab,
                  addFormSetActiveTab,
                  isCrossLinkAddModalOpen,
                  setIsCrossLinkAddModalOpen
                ) => (
                  <QuestionOptionForm
                    parentData={{ Question: selectedRecord }}
                    setIsAddModalOpen={setIsCrossLinkAddModalOpen}
                    isAddModalOpen={isCrossLinkAddModalOpen}
                  />
                ),
              },
              "Survey Question": {
                totalRecords: selectedRecord.SurveyQuestions.length,
                records: [...selectedRecord.SurveyQuestions],
                colorStyle: "warning",
                redirectUrl: "/comm/survey-question",
                addForm: (
                  addFormActiveTab,
                  addFormSetActiveTab,
                  isCrossLinkAddModalOpen,
                  setIsCrossLinkAddModalOpen
                ) => (
                  <SurveyQuestionForm
                    parentData={{ Question: selectedRecord }}
                    parentId={selectedRecord.Id}
                    setIsAddModalOpen={setIsCrossLinkAddModalOpen}
                    isAddModalOpen={isCrossLinkAddModalOpen}
                  />
                ),
              },
            }}
            crossLinkColumns={crossLinkColumns}
            setIsAddModalOpen={setIsAddModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setDeleteRecord={setDeleteRecord}
          />
        )}
        {questionDetails && !isEyeClicked && (
          <DataReactTable
            reactTableData={questionDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Question"
            isFetching={isQuestionFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddComponentModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Question`}
          header
          btn="Success"
          message={
            <div>
              <QuestionForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsAddModalOpen={setIsAddModalOpen}
              />
            </div>
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Question;
