import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
} from "react-table";
import ReactTableHeader from "./ReactTableHeader";
import BodyReactTable from "./ReactTableBody";
import ReactTableFooter from "./ReactTableFooter";
import ReactTableFilter from "./ReactTableFilter";
import ReactTablePagination from "./ReactTablePagination";

const ReactTableConstructor = ({
  tableConfig,
  tableOptions,
  tableOptionalHook,
  onAddClick,
  onWizardClick,
  onArchive,
  isFetching,
  headerContent = true,
  setRecordsPerPage,
  setPageNumber,
  setSort,
  setColumnFilter,
  onEditClick,
  onDeleteClick,
  onDisplayDetailClick,
  onCloneClick,
  onRestoreClick,
}) => {
  const { isSortable, withPagination, manualPageSize, placeholder } =
    tableConfig;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    updateDraggableData,
    updateEditableData,
    dataLength,
    state: { pageIndex, pageSize },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook
  );
  useEffect(() => {
    setRecordsPerPage(pageSize);
    // eslint-disable-next-line
  }, [pageSize]);

  useEffect(() => {
    setPageNumber(pageIndex + 1);
    // eslint-disable-next-line
  }, [pageIndex]);
  return (
    <div>
      {headerContent && (
        <>
          <ReactTableFilter
            setGlobalFilter={setGlobalFilter}
            setFilterValue={tableOptions.setFilterValue}
            globalFilter={state.globalFilter}
            placeholder={placeholder}
            dataLength={dataLength}
            gotoPage={gotoPage}
            onAddClick={onAddClick}
            onWizardClick={onWizardClick}
            onArchive={onArchive}
          />
        </>
      )}
      <div
        className={
          withPagination
            ? "table react-table"
            : "table react-table table--not-pagination"
        }
      >
        <table {...getTableProps()} className="react-table resizable-table">
          <ReactTableHeader
            headerGroups={headerGroups}
            isSortable={isSortable}
            setSort={setSort}
            gotoPage={gotoPage}
            setColumnFilter={setColumnFilter}
            isPerformAction={
              onDeleteClick || onEditClick || onDisplayDetailClick || onCloneClick || onRestoreClick
            }
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            updateEditableData={updateEditableData}
            isFetching={isFetching}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            onDisplayDetailClick={onDisplayDetailClick}
            onCloneClick={onCloneClick}
            onRestoreClick={onRestoreClick}
          />
          {(pageCount === pageIndex + 1 ||
            (!withPagination && rows.length !== 0)) && (
              <ReactTableFooter footerGroups={footerGroups} />
            )}
        </table>
      </div>
      {withPagination && rows.length > 0 && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          dataLength={dataLength}
        />
      )}
    </div>
  );
};

ReactTableConstructor.propTypes = {
  tableConfig: PropTypes.shape({
    isSortable: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    dataLength: PropTypes.number,
    headerContent: PropTypes.bool,
  }),
  tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,
  isFetching: PropTypes.bool.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setRecordsPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setColumnFilter: PropTypes.func,
  onAddClick: PropTypes.func,
};

ReactTableConstructor.defaultProps = {
  tableConfig: {
    isSortable: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
  },
  tableOptions: [
    {
      columns: [],
      data: [],
      setFilterValue: () => {},
      updateDraggableData: () => {},
      updateEditableData: () => {},
      defaultColumn: [],
      withDragAndDrop: false,
      dataLength: null,
      disableSortBy: false,
      manualSortBy: false,
      manualGlobalFilter: false,
      manualPagination: false,
    },
  ],
};

export default ReactTableConstructor;
