import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import { FieldArray } from "formik";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import { Divider, Link } from "@material-ui/core";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useLazyGetSimpleRecordsQuery } from "@/services/gamecodeXApi";

const InsertBlog = ({ formik }) => {
    const { errors, touched, values, handleBlur, setFieldValue } = formik;
    const [blogs, setBlogs] = useState([]);
    const [getAllBlogContents] = useLazyGetSimpleRecordsQuery();
    const [firstTimeEditBlogs, setFirstTimeEditBlogs] = useState(false);

    useEffect(() => {
      if (values?.ContentObject?.insertBlog?.length) {
        getAllBlogContents({
            entity: "/CMS/Contents?entities=ContentTags.Tag&$orderby=Id%20desc&$expand=ContentTagDTO($filter=TagDTO/Id eq 1)&$select=Id,Name,ContentObject,ContentTagDTO&$filter=IsDeleted eq false and ContentTagDTO/any(c:c/TagDTO/Id eq 1)",
            filter: JSON.stringify({
                ContentType: "Blog",
            }),
        }).then((res) => {
            if (!res?.isError) {
                setBlogs(res?.data?.value || []);
                setFirstTimeEditBlogs(true);
            }
        });
      }
    }, [])

    useEffect(() => {
        if (firstTimeEditBlogs && blogs?.length && values?.ContentObject?.insertBlog?.length) {
            const tempBlog = {
                ...values.ContentObject,
                insertBlog: values?.ContentObject?.insertBlog.map((data) => {
                    const result = blogs.find(({ Id }) => Id === data?.Id);
                    return result ? {Id : result?.Id , Name : result?.Name, Blog : JSON.parse(result?.ContentObject)} : data
                })
            }
            setFieldValue("ContentObject", tempBlog);
        }
    }, [firstTimeEditBlogs,blogs]);

    useEffect(() => {
        if (blogs?.length && firstTimeEditBlogs && values?.ContentObject?.insertBlog?.length) {
            const result = blogs?.filter((data) => {
                return !values.ContentObject?.insertBlog?.some((data2) => data2.Id === data.Id);
            })
            const finalBlogs =result.map((data) => ({Id : data?.Id , Name : data?.Name, Blog : data?.ContentObject}))
            setBlogs(finalBlogs);
            setFirstTimeEditBlogs(false);
        }
    }, [blogs, firstTimeEditBlogs, values?.ContentObject?.insertBlog]);


    return (
      <Col sm="12" md="12" lg="12">
        {values?.ContentObject?.images && values?.ContentObject?.insertBlog && (
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        )}
        <Row>
          {values.ContentObject?.insertBlog && values.ContentObject?.insertBlog?.length && (
            <Col>
              <FieldArray name="ContentObject.insertBlog">
                {({ push, remove }) => (
                  <>
                    {values.ContentObject?.insertBlog?.length &&
                      values.ContentObject.insertBlog.map((blog, index) => {
                        return (
                          <div key={`blog_container${index}`}>
                            <Row
                              className="align-items-center"
                              key={`blog${index}`}
                            >
                                <Col className="mb-2">
                                  <h5
                                    style={{
                                      color: "#4ce1b6",
                                    }}
                                  >
                                    Blog {index + 1}
                                  </h5>
                                </Col>
                              <Col
                                xs={!index ? 12 : 11}
                                sm={!index ? 12 : 11}
                                md={!index ? 12 : 11}
                              >
                                <AsyncPaginates
                                    value={blog}
                                    label="Blog Content"
                                    name="Blog Content"
                                    options={blogs}
                                    handleChange={(e) => {
                                        setBlogs(blogs?.filter((data) => data.Id !== e.Id ));
                                        Object.keys(blog).length && setBlogs([...blogs?.filter((data) => data.Id !== e.Id ), {...blog, "Blog" : JSON.stringify(blog.Blog)}].sort((a, b) => b.Id - a.Id));
                                        const contentObjectData = values.ContentObject && values.ContentObject;
                                        contentObjectData.insertBlog[index]["Id"] = e.Id;
                                        contentObjectData.insertBlog[index]["Name"] = e.Name;
                                        contentObjectData.insertBlog[index]["Blog"] = JSON.parse(e.ContentObject || e.Blog);
                                        setFieldValue("ContentObject", contentObjectData);
                                    }}
                                    cacheUniqs={[...blogs?.map((data) => data.Id) ,blog?.Id]}
                                    fieldErrors={errors.Content}
                                    fieldTouched={touched.Content}
                                    handleBlur={handleBlur}
                                /> 
                              </Col>
                              <Col
                                xs={1}
                                sm={1}
                                md={1}
                                className="p-0"
                                style={{
                                  display: !index ? "none" : "block",
                                }}
                              >
                                <MinusCircleIcon
                                  onClick={() => {
                                    Object.keys(blog).length && setBlogs([...blogs, {...blog, "Blog" : JSON.stringify(blog.Blog)}].sort((a, b) => b.Id - a.Id));
                                    if (
                                      values.ContentObject?.insertBlog &&
                                      values.ContentObject?.insertBlog?.length === 1
                                    ) {
                                      remove(index);
                                      push({
                                        name: "",
                                      });
                                    } else {
                                      remove(index);
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
  
                    {values.ContentObject.insertBlog.length && (
                      <div className="d-flex justify-content-end">
                        <Link
                          onClick={() => push({})}
                          className="mt-3 cursor-pointer"
                          style={{ color: "#4ce1b6" }}
                          size="sm"
                        >
                          + Add Blog
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </FieldArray>
            </Col>
          )}
        </Row>
      </Col>
    );
  };

export default InsertBlog
