import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { SURVEY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import QuestionForm from "./QuestionForm";

const SurveyForm = ({ editData, setIsAddModalOpen }) => {
  const [addSurvey, { isLoading: isSurveyAddLoading }] = useAddMutation();
  const [updateSurvey, { isLoading: isSurveyUpdateLoading }] =
    useUpdateMutation();

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    SurveyType: Yup.mixed().required("Please select survey type."),
    questions: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please enter name."),
        QuestionType: Yup.mixed().required("Please select question type."),
      })
    ),
  });

  const onSubmit = (values) => {
    const Question = values.questions.map((question, index) => {
      const QuestionOptions = question.Options.filter((data) => data.Name).map(
        (data, i) => {
          const Option = {
            Option: {
              Name: data.Name,
              Description: data.Description,
              PlaceholderText: data.PlaceholderText,
              OptionTypeId: data.OptionType.Id,
            },
            SortOrder: i + 1,
          };
          if (editData && editData.SurveyQuestions) {
            editData.SurveyQuestions.forEach(({ Question }) =>
              Question.QuestionOptions.filter(
                ({ Option }) => Option.Id === data.Id
              ).forEach((data) => {
                Option.Option.Id = data.OptionId;
                Option.Id = data.Id;
                Option.OptionId = data.OptionId;
                Option.Question = question;
                Option.QuestionId = data.QuestionId;
              })
            );
          }

          return Option;
        }
      );

      const Question = {
        Name: question.Name,
        Description: question.Description,
        PlaceholderText: question.PlaceholderText,
        QuestionTypeId: question.QuestionType.Id,
        SortOrder: index + 1,
        IsRequired: question.IsRequired,
        QuestionOptions: question.Options[0]?.Name ? QuestionOptions : [],
      };
      if (editData && editData.SurveyQuestions) {
        editData.SurveyQuestions.filter(
          ({ Question }) => Question.Id === question.Id
        ).forEach((data) => {
          Question.Id = data.QuestionId;
        });
      }
      return Question;
    });

    let surveyDetail = {
      Survey: {
        Name: values.Name,
        Description: values.Description,
        SurveyTypeId: values.SurveyType.Id,
      },
      Question,
      Contact: values.contacts,
    };
    if (editData && Object.entries(editData).length > 0) {
      surveyDetail.Survey.Id = editData.Id;
      updateSurvey({
        entity: "Survey",
        isWogdApi: true,
        data: surveyDetail,
        tag: SURVEY_TAG,
        Id: surveyDetail.Survey.Id,
      }).then((response) => {
        if (!response.error) {
          !isSurveyUpdateLoading && setIsAddModalOpen(false);
          successToast("Survey updated successfully.");
        }
      });
    } else {
      addSurvey({
        entity: "Survey",
        isWogdApi: true,
        data: surveyDetail,
        tag: SURVEY_TAG,
      }).then((response) => {
        if (!response.error) {
          !isSurveyAddLoading && setIsAddModalOpen(false);
          successToast("Survey added successfully.");
        }
      });
    }
  };

  const getSurveyQuestions = () => {
    const questions = editData.SurveyQuestions.map(({ Question }) => {
      const Options = Question.QuestionOptions.map(({ Option, SortOrder }) => ({
        Id: Option.Id,
        Name: Option.Name,
        Description: Option.Description,
        PlaceholderText: Option.PlaceholderText,
        OptionType: Option.OptionType,
        SortOrder,
      })).sort((a, b) => a.SortOrder - b.SortOrder);

      return {
        Id: Question.Id,
        Name: Question.Name,
        Description: Question.Description,
        PlaceholderText: Question.PlaceholderText,
        QuestionType: Question.QuestionType,
        SortOrder: Question.SortOrder,
        IsRequired: Question.IsRequired,
        Options,
      };
    }).sort((a, b) => a.SortOrder - b.SortOrder);

    return questions;
  };

  const getSurveyContacts = () => {
    const contacts = editData.SurveyContacts.map(({ Contact }) => ({
      Id: Contact.Id,
      Name: Contact.Name,
      Description: Contact.Description,
      WebAddress: Contact.WebAddress,
      EmailAddress: Contact.EmailAddress,
      PhoneNumber: Contact.PhoneNumber,
      Entity: Contact.Entity,
      ContactType: Contact.ContactType,
      SortOrder: Contact.SortOrder,
    }));

    return contacts;
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        SurveyType: editData && editData.SurveyType ? editData.SurveyType : "",
        questions:
          editData && editData.SurveyQuestions ? getSurveyQuestions() : [],
        AddQuestion:
          editData &&
          editData.SurveyQuestions &&
          editData.SurveyQuestions?.length
            ? true
            : false,
        contacts:
          editData && editData.SurveyContacts ? getSurveyContacts() : [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form className="mt-3">
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.SurveyType}
              label="Survey Type"
              name="SurveyType"
              entity="SurveyType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.SurveyType}
              fieldTouched={touched.SurveyType}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.contacts}
              label="Contact"
              name="contacts"
              entity="Contact"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.contacts}
              fieldTouched={touched.contacts}
              handleBlur={handleBlur}
              isMulti={true}
            />
            <QuestionForm formik={formik} editData={editData} />
            <Button
              disabled={isSurveyAddLoading || isSurveyUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isSurveyAddLoading || isSurveyUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

SurveyForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default SurveyForm;
