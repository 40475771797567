import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { ADDRESS_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import AddressForm from "./AddressForm";
import { getAllAddresses } from "../../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const Address = () => {
    const [addressDetails, setAddressDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteAddress, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const [
      getAddressRecords,
      {
        data: addressList,
        isError: isAddressError,
        isLoading: isAddressLoading,
        isFetching: isAddressFetching,
      },
    ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Name",
        accessor: "Name",
        filter: true,
      },
      {
        Header: "Address",
        accessor: "Line",
        filter: true,
      },
      {
        Header: "City",
        accessor: "City",
        filter: true,
        Cell: ({ value }) => {
            return value ? value : "-";
        },
      },
      {
        Header: "State",
        accessor: "State",
        filter: true,
        Cell: ({ value }) => {
            return value ? value : "-";
        },
      },
      {
        Header: "Country",
        accessor: "Country",
        filter: true,
        Cell: ({ value }) => {
            return value ? value : "-";
        },
      },
      {
        Header: "ZipCode",
        accessor: "ZipCode",
        filter: true,
      },
    ];

    const handleEditData = (addressData) => {
      setSelectedRecord(addressData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteAddress({
        entity: "Doc/Address",
        id: selectedRecord.Id,
        tag: ADDRESS_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Address deleted successfully.");
        }
      });
    };

    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
  
        getAddressRecords({
            entity: "Doc/Addresses",
            top: pageSize,
            skip: (page - 1) * pageSize,
            sort: sort,
            filter: filterQuery,
            globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
            },
            tag: ADDRESS_TAG,
        });
      setAddressDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);
  
    useEffect(() => {
      if (
        !isAddressLoading &&
        !isAddressError &&
        addressList &&
        !isAddressFetching
      ) {
        dispatch(getAllAddresses(addressList.value));
        let AddressData = [...addressList.value];
        setAddressDetails({
          ...addressDetails,
          tableRowsData: addressList.value ? AddressData : [],
          totalData: addressList && addressList?.["@odata.count"] || 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
          },
        });
      }
      // eslint-disable-next-line
    }, [
      isAddressError,
      addressList,
      isAddressLoading,
      isAddressLoading,
      isAddressFetching,
    ]);
  
    const deleteMessage = () => {
      return (
        <div>
          Are you sure you want to delete {selectedRecord.Name}?
        </div>
      );
    }

  return (
    <div>
            <Container data-testid="test-Address">
        <Row>
          {addressDetails && (
            <DataReactTable
              reactTableData={addressDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              title="Address"
              isFetching={isAddressFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            testid="addModal"
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Address`}
            header
            btn="Success"
            message={
                <AddressForm
                    editData={selectedRecord ? selectedRecord : {}}
                    setIsAddModalOpen={()=>setIsAddModalOpen(!isAddModalOpen)}
                />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={deleteMessage()}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    </div>
  )
}

export default Address
