import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { SITE_MEDIA_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import MediaForm from "./MediaForm";
import { getAllMedia } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const SiteMedia = () => {
  const [siteMediaDetails, setSiteMediaDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteSiteMedia, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getSiteMediaRecords,
    {
      data: siteMediaList,
      isError: isSiteMediaError,
      isLoading: isSiteMediaLoading,
      isFetching: isSiteMediaFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Media Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
    },
    {
      Header: "Media Type",
      accessor: "MediaTypeDTO.Name",
      filter: true,
    },
    {
      Header: "Media URL",
      accessor: "URL",
      filter: true,
      Cell: ( { value } ) => {
        return value ? 
        <div className="d-flex">
          <div style={{height : "50px" , width : "50px"}}>
            {["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"].includes(value.split('.').pop().toLowerCase()) ? <img src={value} alt="media" /> : ""}
          </div>
          <a href={value} className="mb-0 ml-2" target="_blank">{value}</a>
        </div> : "-"
      }
    },
  ];

  const handleDelete = () => {
    deleteSiteMedia({
      entity: "CMS/Media",
      id: selectedRecord.Id,
      tag: SITE_MEDIA_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Media deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getSiteMediaRecords({
      entity: "CMS/Medias",
      entities: {MediaType : {}},
      expand: {},
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SITE_MEDIA_TAG,
    });
    setSiteMediaDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isSiteMediaLoading &&
      !isSiteMediaError &&
      siteMediaList &&
      !isSiteMediaFetching
    ) {
      dispatch(getAllMedia(siteMediaList.value));
      let siteMediaData = [...siteMediaList.value];
      setSiteMediaDetails({
        ...siteMediaDetails,
        tableRowsData: siteMediaList.value ? siteMediaData : [],
        totalData:
          siteMediaList && siteMediaList["@odata.count"]
            ? siteMediaList["@odata.count"]
            : 0,
        // onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isSiteMediaError,
    siteMediaList,
    isSiteMediaLoading,
    isSiteMediaFetching,
  ]);
  return (
    <Container>
      <Row>
        {siteMediaDetails && (
          <DataReactTable
            reactTableData={siteMediaDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Media"
            isFetching={isSiteMediaFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Media`}
          header
          btn="Success"
          message={
            <MediaForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default SiteMedia;
