import React from "react";
import { Route } from "react-router-dom";
//Entity
import ApplicationType from "@/components/Site/Application/ApplicationTypes/ApplicationType";
import Application from "@/components/Site/Application/Applications/Application";
import ScreenType from "@/components/Site/Screen/ScreenTypes/ScreenType";
import Screen from "@/components/Site/Screen/Screen/Screen";
import ApplicationScreen from "@/components/Site/Screen/ApplicationScreen/ApplicationScreen";
import Component from "@/components/Site/Component/Component/Component";
import SiteMediaType from "@/components/Site/Media/MediaType/MediaType";
import SiteMedia from "@/components/Site/Media/Media/Media";
import ContentType from "@/components/Site/Content/ContentTypes/ContentType";
import Content from "@/components/Site/Content/Content/Content";
import ScreenContent from "@/components/Site/Screen/ScreenContent/ScreenContent";
import ApplicationContent from "@/components/Site/Content/ApplicationContent/ApplicationContent";
import MenuType from "@/components/Site/Menu/MenuType/MenuType";
import Menu from "@/components/Site/Menu/Menu/Menu";
import ApplicationMenu from "@/components/Site/Menu/ApplicationMenu/ApplicationMenu";
import ScreenMenu from "@/components/Site/Menu/ScreenMenu/ScreenMenu";
import TagType from "@/components/Site/Tag/TagType/TagType";
import Tag from "@/components/Site/Tag/Tag/Tag";
import ScreenTag from "@/components/Site/Tag/ScreenTag/ScreenTag";
import ContentTag from "@/components/Site/Tag/ContentTag/ContentTag";
import ScreenMapping from "@/components/Site/ScreenMapping/ScreenMapping";
import AppVersion from "@/components/Site/AppVersion/AppVersion";
import ApplicationReferer from "@/components/Site/ApplicationReferer/ApplicationReferer";
import Themes from "@/components/Site/Theme/Themes/Themes";
import ThemeType from "@/components/Site/Theme/ThemeType/ThemeType";
import ScreenTheme from "@/components/Site/Theme/ScreenTheme/ScreenTheme";

const CMSRoutes = () => (
  <div>
    <Route path="/cms/application-type" component={ApplicationType} />
    <Route path="/cms/application" component={Application} />
    <Route path="/cms/screen-type" component={ScreenType} />
    <Route path="/cms/screen" component={Screen} />
    <Route path="/cms/application-screen" component={ApplicationScreen} />
    <Route path="/cms/component" component={Component} />
    <Route path="/cms/media-type" component={SiteMediaType} />
    <Route path="/cms/media" component={SiteMedia} />
    <Route path="/cms/content-type" component={ContentType} />
    <Route path="/cms/content" component={Content} />
    <Route path="/cms/screen-content" component={ScreenContent} />
    <Route path="/cms/application-content" component={ApplicationContent} />
    <Route path="/cms/menu-type" component={MenuType} />
    <Route path="/cms/menu" component={Menu} />
    <Route path="/cms/application-menu" component={ApplicationMenu} />
    <Route path="/cms/screen-menu" component={ScreenMenu} />
    <Route path="/cms/tag-type" component={TagType} />
    <Route path="/cms/tag" component={Tag} />
    <Route path="/cms/screen-tag" component={ScreenTag} />
    <Route path="/cms/content-tag" component={ContentTag} />
    <Route path="/cms/app-version" component={AppVersion} />
    <Route path="/cms/application-referers" component={ApplicationReferer} />
    <Route path="/cms/screen-mapping" component={ScreenMapping} />
    <Route path="/cms/theme" component={Themes} />
    <Route path="/cms/theme-type" component={ThemeType} />
    <Route path="/cms/screen-theme" component={ScreenTheme} />
  </div>
);

export default CMSRoutes;
