import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import CrosslinkPanel from "@/shared/components/entity_detail/CrosslinkPanel";
import AddModal from "@/shared/components/AddModal";

const Crosslinks = ({
  crossLinkData,
  crossLinkColumns,
  setIsDeleteModalOpen,
  setDeleteRecord,
  selectedData,
}) => {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState("1");
  const [addForm, setAddForm] = useState();
  const [selectedRecord, setSelectedRecord] = useState({});
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [xlForm, setXlForm] = useState(false);
  const [xxlForm,setXxlForm] = useState(false)

  useEffect(() => {
    if (isAddModalOpen && selectedRecord) {
      setAddForm(
        selectedRecord.data.addForm(
          activeTab,
          setActiveTab,
          isAddModalOpen,
          setIsAddModalOpen
        )
      );
    }
    // eslint-disable-next-line
  }, [activeTab]);

  const onAddClick = (title, value) => {
    setAddForm(
      value.addForm(activeTab, setActiveTab, isAddModalOpen, setIsAddModalOpen)
    );
    setSelectedRecord({ title: title, data: value });
    setIsAddModalOpen(true);
    setXlForm(value?.xl === true);
    setXxlForm(value?.xxl === true)
  };

  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">
                {t("gamecodex.org.entity.crosslinks.section_title")}
              </h5>
            </div>
            <div className="tabs tabs--bordered-bottom">
              {crossLinkData && Object.entries(crossLinkData).length
                ? Object.entries(crossLinkData).map(([key, value], index) => {
                    return (
                      <CrosslinkPanel
                        key={index}
                        title={`${key} (${
                          value.totalRecords ? value.totalRecords : 0
                        })`}
                        onAddClick={() => onAddClick(key, value)}
                        colorStyle={
                          value.colorStyle ? value.colorStyle : "success"
                        }
                        description={
                          <div key={index}>
                            {value.records && value.records.length
                              ? value.records.map((val) => {
                                  return (
                                    <div key={val.Id}>
                                      <div className="border-bottom-secondary d-flex">
                                        <div
                                          className="align-self-center mr-2 cursor-pointer"
                                          onClick={() => {
                                            setIsDeleteModalOpen(true);
                                            setDeleteRecord({
                                              ...val,
                                              entity: value.entity
                                                ? value.entity
                                                : key.replaceAll(" ", ""),
                                              tag: `${key.replace(
                                                " ",
                                                "_"
                                              )}_TAG`,
                                            });
                                          }}
                                        >
                                          <MinusCircleIcon />
                                        </div>
                                        <div
                                          className="border-bottom-secondary"
                                          key={index}
                                        >
                                          {crossLinkColumns &&
                                          crossLinkColumns[key] &&
                                          crossLinkColumns[key].length
                                            ? crossLinkColumns[key].map(
                                                (column, index) => {
                                                  var keyName =
                                                    column.accessor.split(".");
                                                  if (column.type !== "Image") {
                                                    return (
                                                      <div key={index}>
                                                        <b>{column.header}: </b>
                                                        {val &&
                                                        keyName.length &&
                                                        keyName.length === 1 &&
                                                        val[keyName[0]]
                                                          ? val[keyName[0]]
                                                          : keyName.length ===
                                                              2 &&
                                                            val[keyName[0]] &&
                                                            val[keyName[0]][
                                                              keyName[1]
                                                            ]
                                                          ? val[keyName[0]][
                                                              keyName[1]
                                                            ]
                                                          : keyName.length ===
                                                              3 &&
                                                            val[keyName[0]] &&
                                                            val[keyName[0]][
                                                              keyName[1]
                                                            ] &&
                                                            val[keyName[0]][
                                                              keyName[1]
                                                            ][keyName[2]]
                                                          ? val[keyName[0]][
                                                              keyName[1]
                                                            ][keyName[2]]
                                                          : "-"}
                                                      </div>
                                                    );
                                                  } else {
                                                    let url =
                                                      keyName.length === 1 &&
                                                      val[keyName[0]]
                                                        ? val[keyName[0]]
                                                        : keyName.length ===
                                                            2 &&
                                                          val[keyName[0]] &&
                                                          val[keyName[0]][
                                                            keyName[1]
                                                          ]
                                                        ? val[keyName[0]][
                                                            keyName[1]
                                                          ]
                                                        : keyName.length ===
                                                            3 &&
                                                          val[keyName[0]] &&
                                                          val[keyName[0]][
                                                            keyName[1]
                                                          ] &&
                                                          val[keyName[0]][
                                                            keyName[1]
                                                          ][keyName[2]]
                                                        ? val[keyName[0]][
                                                            keyName[1]
                                                          ][keyName[2]]
                                                        : "";
                                                    return (
                                                      <div key={index}>
                                                        <a
                                                          href={url}
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <img
                                                            src={url}
                                                            alt="avatar"
                                                          />
                                                        </a>
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )
                                            : null}
                                        </div>
                                      </div>
                                      <hr></hr>
                                    </div>
                                  );
                                })
                              : ""}
                            {value &&
                            value.redirectUrl &&
                            value.records &&
                            value.records.length ? (
                              <div className="typography-message">
                                <Link
                                  to={{
                                    pathname: value.redirectUrl,
                                    state: { ...selectedData },
                                  }}
                                  className="disabledCursor"
                                >
                                  View All Records
                                </Link>
                              </div>
                            ) : value.records && value.records.length ? (
                              ""
                            ) : (
                              <div>No Records Found</div>
                            )}
                          </div>
                        }
                      />
                    );
                  })
                : ""}
            </div>
          </CardBody>
        </Card>
      </Col>
      {isAddModalOpen && selectedRecord && addForm && (
        <AddModal
          toggle={() => setIsAddModalOpen(false)}
          color="success"
          title={`Add ${selectedRecord.title}`}
          header
          btn="Success"
          xl={xlForm}
          xxl={xxlForm}
          message={addForm}
        />
      )}
    </>
  );
};

export default Crosslinks;
