import React, { useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import {
  Collapse,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { connect, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { UserProps } from "@/shared/prop-types/ReducerProps";
import { TRIGGER_PIPELINE } from "@/shared/tagFile";
import { SUPER_ADMIN, ADMIN } from "@/shared/userRoles";
import Modal from "@/shared/components/Modal";
import { useAddMutation } from "@/services/gamecodeXApi";
import TopbarMenuLink from "./TopbarMenuLink";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = ({ user }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isInnerCollapsed, setIsInnerCollapsed] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedPipline, setSelectedPipline] = useState([
    {
      web: false,
      app: false,
    },
  ]);
  const [runPipeline] = useAddMutation();
  const role = useSelector((state) => state.user.role);
  const { logout } = useAuth0();

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const togglePipline = () => {
    setIsInnerCollapsed(!isInnerCollapsed);
  };

  const onPiplineSelect = (event) => {
    setSelectedPipline((state) => {
      return {
        ...state,
        [event.target.value]: !selectedPipline[event.target.value],
      };
    });
  };

  const triggerPipeline = () => {
    let appId = [];
    if (selectedPipline.web && selectedPipline.app) {
      appId = [1, 17];
    } else {
      if (selectedPipline.web) appId = [1];
      if (selectedPipline.app) appId = [17];
    }

    setIsCollapsed(!isCollapsed);
    runPipeline({
      entity: "CMS/TriggerPipeline",
      tag: TRIGGER_PIPELINE,
      data: {"applicationIds":appId},
    }).then((response) => {
      if (!response.error) {
        setIsModelOpen(!isModelOpen);
      }
    });
  };

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    logout({ returnTo: process.env.REACT_APP_LOGIN_REDIRECT });
  };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          src={(user && (user.picture || user.avatar)) || Ava}
          alt="avatar"
        />
        <p className="topbar__avatar-name">{user && user.name}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="My Profile"
            icon="user"
            path="/account/profile"
            onClick={toggleProfile}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            onClick={logoutUser}
            path="/"
          />
          {(role === SUPER_ADMIN || role === ADMIN) && (
            <>
              <div className="topbar__menu-divider" />
              <Dropdown
                menuRole="listbox"
                isOpen={isInnerCollapsed}
                toggle={togglePipline}
                direction="left"
              >
                <DropdownToggle tag="div" className="d-flex">
                  <TopbarMenuLink
                    title="Pipeline"
                    icon="enter"
                    onClick={() => {}}
                    path="#"
                  />
                  <DownIcon className="topbar__icon mr-2" />
                </DropdownToggle>
                <DropdownMenu className="custom-menu-wrapper">
                  <h5 className="custom-menu-title">Select Pipline</h5>
                  <DropdownItem divider />
                  <Form>
                    <FormGroup className="m-0 select-wrapper">
                      <Input
                        className="custom-select-wrapper"
                        multiple
                        name="selectMulti"
                        type="select"
                        value={selectedPipline}
                        onChange={(event) => {
                          onPiplineSelect(event);
                        }}
                      >
                        <option
                          value="web"
                          className={`custom-menu-item ${
                            selectedPipline.web && "selected-item"
                          } mb-1`}
                        >
                          Single Page App
                        </option>
                      </Input>
                    </FormGroup>
                    <div className="d-flex justify-content-center h-100">
                      <Button
                        color="primary"
                        className="rounded float-left menu-btn mb-0"
                        onClick={triggerPipeline}
                        disabled={!selectedPipline.web && !selectedPipline.app}
                      >
                        Run
                      </Button>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>
            </>
          )}
        </div>
      </Collapse>

      {isModelOpen && (
        <Modal
          color="success"
          title="Pipeline triggered successfully."
          message={`Please wait for few minutes to see changes.`}
          handleOkClick={() => setIsModelOpen(!isModelOpen)}
          toggle={() => setIsModelOpen(!isModelOpen)}
        />
      )}
    </div>
  );
};

TopbarProfile.propTypes = {
  user: UserProps.isRequired,
};

export default connect((state) => ({
  user: state.user,
}))(TopbarProfile);
