import React, { useEffect, useState } from "react";
import Masonry from 'react-masonry-component';
import { Col, Container, Row, Spinner } from "reactstrap";
import { useLazyGetRecordsByIdQuery } from "@/services/gamecodeXApi";
import { ENTITY_TAG } from "@/shared/tagFile";
import EmailIcon from 'mdi-react/EmailIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import FaxIcon from 'mdi-react/FaxIcon';
import SkypeIcon from 'mdi-react/SkypeIcon';

const Details = ({ tree, displayData }) => {
	const [data, setData] = useState();
	const [contactDetails, setContactDetails] = useState();

	const masonryOptions = {
		transitionDuration: 0,
		fitWidth: false,
		columnWidth: 250,
		gutter: 100,
	};

	const [
		getEntityRecords,
		{
			data: EntityList,
			isError: isEntityError,
			isLoading: isEntityListLoading,
		},
	] = useLazyGetRecordsByIdQuery();

	useEffect(() => {
		getEntityRecords({
			entity: `Entity/GetEntityDetail`,
			id: displayData.Id,
			tag: ENTITY_TAG,
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let contactDetails = {};
		if (EntityList) {
			setData(EntityList);
			if (EntityList.Contacts?.length) {
				EntityList.Contacts?.forEach((contact) => {
					if (contact.ContactType?.Name.toLowerCase() === "email") contactDetails[`${contact.ContactType?.Name}`] = contact.EmailAddress
					else if (contact.ContactType?.Name.toLowerCase() === "telephone" || contact.ContactType?.Name.toLowerCase() === "number") contactDetails[`${contact.ContactType?.Name}`] = contact.PhoneNumber
					else if (contact.ContactType?.Name.toLowerCase() === "hashtag") contactDetails[`${contact.ContactType?.Name}`] = contact.HashTag
					else if (contact.ContactType?.Name.toLowerCase() === "skype") contactDetails[`${contact.ContactType?.Name}`] = contact.WebAddress
					else contactDetails[`${contact.ContactType?.Name}`] = contact.WebAddress
				})
			}
		}
		setContactDetails(contactDetails)
	}, [EntityList]);

	return (
		<>{
			!isEntityListLoading ? !isEntityError && data ?
				<div className="profile">
					<Container>
						<Row className="m-3">
							<Col>
								<>
									<span>
										<p><strong style={{ textTransform: "capitalize" }}>{data.Name}</strong> is a <strong>{data.EntityType?.Name}</strong> in this system.{data.Users[0]?.Email ?
											<> If you have any queries or need assistance, you can reach out via email at
												<a className="text-primary font-weight-bold" target="_blank" rel="noreferrer" href={`mailto:${data.Users[0]?.Email}?subject=WOGD - World of Game Design`}>{" "}{data.Users[0]?.Email}</a>.
											</> : ""}</p>
										{Object.keys(contactDetails).length ?
											<>
												<p>Additionally, <strong style={{ textTransform: "capitalize" }}>{data.Name}</strong> can be contacted through various social media platforms such as {" "}
													{Object.keys(contactDetails).map((key, i) => {
														return <>
															{key.toLowerCase() === "email" ? <a className="text-primary" href={`mailto:${contactDetails[key]}?subject=WOGD - World of Game Design`} rel="noreferrer" target="_blank"><EmailIcon></EmailIcon></a> : ""}
															{key.toLowerCase() === "instagram" ? <a className="text-primary" href={contactDetails[key]} rel="noreferrer" target="_blank"><InstagramIcon></InstagramIcon></a> : ""}
															{key.toLowerCase() === "twitter" ? <a className="text-primary" href={contactDetails[key]} rel="noreferrer" target="_blank"><TwitterIcon></TwitterIcon></a> : ""}
															{key.toLowerCase() === "fax" ? <a className="text-primary" href={contactDetails[key]} rel="noreferrer" target="_blank"><FaxIcon></FaxIcon></a> : ""}
															{key.toLowerCase() === "telephone" || key.toLowerCase() === "number" ? <a className="text-primary" href={`tel:+${contactDetails[key]}`} rel="noreferrer" target="_blank"><PhoneIcon></PhoneIcon></a> : ""}
															{key.toLowerCase() === "facebook" ? <a className="text-primary" href={contactDetails[key]} rel="noreferrer" target="_blank"><FacebookIcon></FacebookIcon></a> : ""}
															{key.toLowerCase() === "skype" ? <a className="text-primary" href={`skype:${contactDetails[key]}?chat`} rel="noreferrer" target="_blank"><SkypeIcon></SkypeIcon></a > : ""}
														</>
													})}</p>
											</> : ""}
										{data?.EntitySkills?.length ?
											<>
												<p>These are all the skills that <strong style={{ textTransform: "capitalize" }}>{data.Name}</strong> possesses:</p>
												<ul>
													{data?.EntitySkills?.map((key, i) => {
														return <div key={i}>
															<li>{key.Skill.Name}</li>
														</div>
													})}
												</ul>
											</> : ""}
										{data?.OrgEntityMedias?.length && data?.OrgEntityMedias?.filter((media) => media.EntityMediaType?.Name === "Post")?.length ?
											<>
												<p>Here are some post</p>
												<div >
													<Masonry
														className={"photo-list"}
														options={masonryOptions}
														disableImagesLoaded={false}
														updateOnEachImageLoad={false}
													>
														{data?.OrgEntityMedias?.filter((media) => media.EntityMediaType?.Name === "Post") ?
															data?.OrgEntityMedias?.filter((media) => media.EntityMediaType?.Name === "Post").map((image, i) => {
																return <div className="photo-item">
																	<img src={image.Media?.Url} alt="post" />
																</div>

															}) : <Spinner size="sm" variant="light" />}
													</Masonry>
												</div>
											</> : ""}
									</span>
								</>
							</Col>
						</Row>
					</Container>
				</div > : <p>Here is a list of notes made on the Entity in chronological order. </p>
				: <Spinner size="sm" variant="light" />
		}
		</>
	)
}



export default Details