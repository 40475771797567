import React, { useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import EditIcon from "mdi-react/PencilIcon";
import LeftIcon from "mdi-react/KeyboardArrowLeftIcon";
import Collapse from "./Collapse";

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;
const Summary = ({
  details,
  setIsEyeClicked,
  title,
  setIsAddModalOpen,
  restContent,
  image = "",
}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <div
                className="bold-text cursor-pointer text-primary"
                onClick={() => {
                  setIsEyeClicked(false);
                }}
              >
                <LeftIcon />
                {title}
              </div>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <div className="d-flex">
                <div className="profile__avatar">
                  <img src={image ? image : Ava} alt="avatar" />
                </div>
                <div className="profile__data">
                  <p className="profile__name">{details && details.Name}</p>
                  <p className="profile__work">
                    {details[`${title}Type`] && details[`${title}Type`].Name}
                  </p>
                  <p className="profile__contact">
                    {details && details.Description
                      ? details.Description
                      : null}
                  </p>
                </div>
                {setIsAddModalOpen && (
                  <div className="cursor-pointer">
                    <EditIcon onClick={() => setIsAddModalOpen(true)} />
                  </div>
                )}
              </div>
            </div>
            {restContent && Object.keys(restContent).length && (
              <>
                <hr />
                <div>
                  <Collapse
                    className="with-shadow"
                    title={`About this ${title.toLowerCase()}`}
                    collapse={isCollapseOpen}
                    onClick={() => {
                      setIsCollapseOpen(!isCollapseOpen);
                    }}
                  >
                    <table className="project-summary__info">
                      <tbody>
                        {Object.entries(restContent).map(([key, value]) => {
                          return (
                            <tr key={value}>
                              <th>{key}:</th>
                              <td>{value}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Collapse>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Summary;
