import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Details from "./Details";

const Tabs = ({ tree, displayData }) => {
  const [activeTab, setActiveTab] = useState("1");

  const { t } = useTranslation("common");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            {t("gamecodex.org.entity.activity.detail.title")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            {t("gamecodex.org.entity.activity.event.title")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {tree ? <Details tree={tree} displayData={displayData} /> : (<p>Here is a list of notes made on the Entity in chronological order. </p>)}
        </TabPane>
        <TabPane tabId="2">
          <p>
            Here is a list of messages involving this Entity in chronological
            order
          </p>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Tabs;
