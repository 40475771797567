import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { SCREEN_MENU_TAG, MENU_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenMenuForm = ({ editData, setIsAddModalOpen }) => {
  const [addScreenMenu, { isLoading: isScreenMenuAddLoading }] =
    useAddMutation();
  const [updateScreenMenu, { isLoading: isScreenMenuUpdateLoading }] =
    useUpdateMutation();

  const MenuValidationSchema = Yup.object().shape({
    Screen: Yup.mixed().required("Please select screen."),
    Menu: Yup.mixed().required("Please select Menu."),
  });

  const onSubmit = (values) => {
    const appMenuDetail = {
      ScreenId: values.Screen.Id,
      MenuId: values.Menu.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      appMenuDetail.Id = editData.Id;
      updateScreenMenu({
        entity: "CMS/ScreenMenu",
        data: appMenuDetail,
        tag: [SCREEN_MENU_TAG, MENU_TAG],
      }).then((response) => {
        if (!response.error) {
          !isScreenMenuUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Screen-Menu updated successfully.");
        }
      });
    } else {
      addScreenMenu({
        entity: "CMS/ScreenMenu",
        data: appMenuDetail,
        tag: [SCREEN_MENU_TAG, MENU_TAG],
      }).then((response) => {
        if (!response.error) {
          !isScreenMenuAddLoading && setIsAddModalOpen(false);
          successToast("Screen-Menu added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Screen:
          editData && editData.ScreenDTO ? editData.ScreenDTO : "",
        Menu: editData && editData.MenuDTO ? editData.MenuDTO : "",
      }}
      validationSchema={MenuValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && (
              <CustomInput label="Id" fieldValue={editData.Id} readonly />
            )}
            <AsyncPaginates
              value={values.Screen}
              label="Screen"
              name="Screen"
              entity="CMS/Screens"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Screen}
              fieldTouched={touched.Screen}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Menu}
              label="Menu"
              name="Menu"
              entity="CMS/Menus"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Menu}
              fieldTouched={touched.Menu}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={
                isScreenMenuAddLoading || isScreenMenuUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isScreenMenuAddLoading ||
                isScreenMenuUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ScreenMenuForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ScreenMenuForm;
