import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Spinner } from "reactstrap";
import Activity from "@/shared/components/entity_detail/Activity";
import Summary from "./SharedSummary";
import Crosslinks from "./SharedCrosslink";
import "react-toastify/dist/ReactToastify.css";

const DetailPage = ({
  setIsEyeClicked,
  title,
  displayData,
  crossLinkColumns,
  crossLinkData,
  setIsAddModalOpen,
  setIsDeleteModalOpen,
  setDeleteRecord,
  restContent,
  image,
  tree,
}) => {
  return (
    <Container>
      {displayData && displayData.Name ? (
        <Row>
          <Summary
            setIsEyeClicked={setIsEyeClicked}
            details={displayData}
            title={title}
            setIsAddModalOpen={setIsAddModalOpen}
            restContent={restContent}
            image={image}
          ></Summary>
          <Activity tree={tree} displayData={displayData}></Activity>
          <Crosslinks
            crossLinkData={crossLinkData}
            crossLinkColumns={crossLinkColumns}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setDeleteRecord={setDeleteRecord}
            selectedData={{ name: displayData.Name, title }}
          ></Crosslinks>
        </Row>
      ) : (
        <Spinner size="sm" variant="light" />
      )}
    </Container>
  );
};

DetailPage.propTypes = {
  setIsEyeClicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  displayData: PropTypes.object.isRequired,
  crossLinkColumns: PropTypes.object,
  crossLinkData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
  setIsDeleteModalOpen: PropTypes.func.isRequired,
  setDeleteRecord: PropTypes.func.isRequired,
  restContent: PropTypes.object,
  image: PropTypes.string,
};

export default DetailPage;
