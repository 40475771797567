import React from "react";
import { Label } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useAddMutation } from "@/services/gamecodeXApi";

const TextEditor = ({ label, index, formik }) => {
  const { values, setFieldValue } = formik;
  const [ addMedia ] = useAddMutation();

  const handleCKEditorChanges = (event, editor) => {
    const contentObjectData = values?.ContentObject
    if (index >= 0) {
      contentObjectData.dynamicFieldList[index][label] = editor.getData()
    } else {
      contentObjectData[label] = editor.getData()
    }
    setFieldValue("ContentObject", contentObjectData);
  };

  const SimpleUploadAdapterPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return {
        upload: async () => {
          try {
            const file = await loader.file;
            const formData = new FormData();
            formData.append("File", file);
            formData.append("MediaTypeId", 1);
            formData.append("PathFolderName", "content");

            const response = await addMedia({
              entity: `CMS/Media`,
              data: formData,
            });

            if (!response.error) {
              const imageUrl = response.data.url;
              return { default: imageUrl };
            }
          } catch (error) {
            throw error;
          }
        },
      };
    };
  };

  return (
  <div style={{padding : "0 15px 10px", width : "100%"}}>
    <Label for="exampleText">{label}</Label>
    <CKEditor 
      disabled = {values?.ContentObject?.dynamicFieldList?.[index]?.video?.url}
      editor={ClassicEditor} 
      data={index>=0 ? values?.ContentObject?.dynamicFieldList?.[index]?.[label] : typeof values?.ContentObject?.[label] === "string" ? values?.ContentObject?.[label] : values?.ContentObject?.[label]?.["value"] } 
      onChange={handleCKEditorChanges} 
      onReady={(editor) => {
        SimpleUploadAdapterPlugin(editor);
      }}
    />
  </div> 
  )
};

export default TextEditor;