import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { ANSWER_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const AnswerTypeForm = ({ editData, setIsAddModalOpen }) => {
  const [addAnswerType, { isLoading: isAnswerTypeAddLoading }] =
    useAddMutation();
  const [updateAnswerType, { isLoading: isAnswerTypeUpdateLoading }] =
    useUpdateMutation();

  const answerValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    let answerTypeDetail = {
      Name: values.Name,
      Description: values.Description,
    };

    if (editData && Object.entries(editData).length > 0) {
      answerTypeDetail.Id = editData.Id;
      updateAnswerType({
        entity: "AnswerType",
        isWogdApi: true,
        data: answerTypeDetail,
        tag: ANSWER_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isAnswerTypeUpdateLoading && setIsAddModalOpen(false);
          successToast("Answer type updated successfully.");
        }
      });
    } else {
      addAnswerType({
        entity: "AnswerType",
        isWogdApi: true,
        data: answerTypeDetail,
        tag: ANSWER_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isAnswerTypeAddLoading && setIsAddModalOpen(false);
          successToast("Answer type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
      }}
      validationSchema={answerValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isAnswerTypeAddLoading || isAnswerTypeUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAnswerTypeAddLoading || isAnswerTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

AnswerTypeForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
  parentData: PropTypes.object,
  parentId: PropTypes.object,
};

export default AnswerTypeForm;
