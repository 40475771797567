import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { TAG_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import TagTypeForm from "./TagTypeForm";
import { getAllTagTypes } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const TagType = () => {
    const [tagTypeDetails, setTagTypeDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteTagType, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const [
        getTagTypeRecords,
        {
        data: tagTypeList,
        isError: isTagTypeError,
        isLoading: isTagTypeLoading,
        isFetching: isTagTypeFetching,
        },
    ] = useLazyGetRecordsQuery();

    const column = [
        {
        Header: "#",
        accessor: "Id",
        type: "Number",
        },
        {
        Header: "Name",
        accessor: "Name",
        filter: true,
        },
        {
        Header: "Description",
        accessor: "Description",
        filter: true,
        Cell: ({ value }) => {
            return value ? value : "-";
        },
        },
    ];

    const handleEditData = (tagTypeData) => {
        setSelectedRecord(tagTypeData);
        setIsAddModalOpen(true);
    };

    const handleDelete = () => {
    deleteTagType({
        entity: "CMSReference/TagType",
        id: selectedRecord.Id,
        tag: TAG_TYPE_TAG,
    }).then((response) => {
        if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Tag-type deleted successfully.");
        }
    });
    };

    useEffect(() => {
        let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
        );

        getTagTypeRecords({
        entity: "CMSReference/TagTypes",
        top: pageSize,
        skip: (page - 1) * pageSize,
        sort: sort,
        filter: filterQuery,
        globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
        },
        tag: TAG_TYPE_TAG,
        });
        setTagTypeDetails({ tableHeaderData: column, tableRowsData: [] });
        // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

    useEffect(() => {
        if (
        !isTagTypeLoading &&
        !isTagTypeError &&
        tagTypeList &&
        !isTagTypeFetching
        ) {
        dispatch(getAllTagTypes(tagTypeList.value));
        let tagTypeData = [...tagTypeList.value];
        setTagTypeDetails({
            ...tagTypeDetails,
            tableRowsData: tagTypeList.value ? tagTypeData : [],
            totalData:
            tagTypeList && tagTypeList["@odata.count"]
                ? tagTypeList["@odata.count"]
                : 0,
            onEditClick: (data) => handleEditData(data),
            onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
            },
        });
        }
        // eslint-disable-next-line
    }, [
        isTagTypeError,
        tagTypeList,
        isTagTypeLoading,
        isTagTypeFetching,
    ]);

    const deleteMessage = () => {
        return (
        <div>
            Are you sure you want to delete {selectedRecord.Name}?
            <div className="warning-div">
            <i>
                This will delete all the associated records <br />
                ( Like - Tag, Screen Tag, Content Tag)
            </i>
            </div>
        </div>
        );
    }

  return (
    <Container>
      <Row>
        {tagTypeDetails && (
          <DataReactTable
            reactTableData={tagTypeDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Tag Type"
            isFetching={isTagTypeFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Tag Type`}
          header
          btn="Success"
          message={
            <TagTypeForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  )
}

export default TagType
