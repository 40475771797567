import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { CLAUSE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ClauseModifyRequestForm from "./ClauseModifyRequestForm";
import { getAllClauses } from "../../../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ClauseModifyRequest = () => {
  const [clauseDetails, setClauseDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteClause, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const [detailViewClauses, setDetailViewClauses] = useState()
  const dispatch = useDispatch();
  const [
    getClauseRecords,
    {
      data: clauseList,
      isError: isClauseError,
      isLoading: isClauseLoading,
      isFetching: isClauseFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Counter Party Names",
      accessor: "CounterPartyDTO.Name",
      filter: true,
    },
    {
      Header: "Contract Names",
      accessor: "CounterPartyDTO.ContractDTO.Name",
      filter: true,
    },
    {
      Header: "Clauses",
      accessor: "ClauseObject",
      filter: true,
      Cell: ({ value }) => {
        return value ? JSON.parse(value)?.map((clause, index) => {
          return <li key={index}>{clause?.fullText}</li> 
        }) : "-";
      },
    }
  ];

  const handleEditData = (clauseData) => {
    setSelectedRecord(clauseData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteClause({
      entity: "Doc/ContractRequest",
      id: selectedRecord.Id,
      tag: CLAUSE_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Deleted modify request successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getClauseRecords({
      entity: "Doc/ContractRequests",
      entities: { CounterParty: {}, "CounterParty.Contract": {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: CLAUSE_TAG,
    }).then((resp) => {
      if (!resp.error) {
        const finalClauseList = []
        resp?.data?.value?.forEach(clauses => {
          finalClauseList.push(...JSON.parse(clauses?.ClauseObject).map(clause => {
            return { ...clauses, ClauseObject: JSON.stringify([clause]) }
          }))
        })
        let response = { ...resp, data: { ...resp?.data, value: finalClauseList } }
        setDetailViewClauses(response.data)
      }
    })
    setClauseDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isClauseLoading &&
      !isClauseError &&
      clauseList &&
      detailViewClauses &&
      !isClauseFetching
    ) {
      dispatch(getAllClauses(clauseList?.value));
      let ClauseData = [...clauseList?.value];
      setClauseDetails({
        ...clauseDetails,
        tableRowsData: clauseList?.value ? ClauseData : [],
        totalData: clauseList && clauseList?.["@odata.count"] || 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isClauseError,
    clauseList,
    detailViewClauses,
    isClauseLoading,
    isClauseLoading,
    isClauseFetching
  ]);

  return (
    <Container data-testid="test-Clause">
      <Row>
        {clauseDetails && (
          <DataReactTable
            reactTableData={clauseDetails}
            title="Clause Modify Requests"
            isFetching={isClauseFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          xxxl
          testid="addModal"
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={"Clause Modify Requests"}
          header
          btn="Success"
          message={
            <ClauseModifyRequestForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={() => setIsAddModalOpen(!isAddModalOpen)}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={"Are you sure you want to delete this Request?"}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  )
}

export default ClauseModifyRequest
