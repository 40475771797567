import { createSlice } from "@reduxjs/toolkit";

const orgSlice = createSlice({
  name: "org",
  initialState: {
    contact: [],
    contactType: [],
    roles: [],
    roleTypes: [],
    users: [],
    entityTypes: [],
    entity: [],
  },
  reducers: {
    getAllContact: (state, action) => {
      state.contact = action.payload;
    },
    getAllContactType: (state, action) => {
      state.contactType = action.payload;
    },
    getAllRoles: (state, action) => {
      state.roles = action.payload;
    },
    getAllRoleTypes: (state, action) => {
      state.roleTypes = action.payload;
    },
    getAllUsers: (state, action) => {
      state.users = action.payload;
    },
    getAllEntityTypes: (state, action) => {
      state.entityTypes = action.payload;
    }, 
    getAllEntity: (state, action) => {
      state.entity = action.payload;
    }, 
  },
});

export default orgSlice.reducer;
export const {
  getAllContact,
  getAllContactType,
  getAllRoles,
  getAllRoleTypes,
  getAllUsers,
  getAllEntityTypes,
  getAllEntity,
} = orgSlice.actions;
