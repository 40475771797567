import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { ANSWER_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const AnswerForm = ({ editData, setIsAddModalOpen, parentId, parentData }) => {
  const [addAnswer, { isLoading: isAnswerAddLoading }] = useAddMutation();
  const [updateAnswer, { isLoading: isAnswerUpdateLoading }] =
    useUpdateMutation();

  const answerValidationSchema = Yup.object().shape({
    Question: Yup.mixed().required("Please select question."),
    AnswerType: Yup.mixed().required("Please select answer type."),
    Response: Yup.mixed().required("Please select response."),
  });

  const onSubmit = (values) => {
    let answerDetail = {
      Name: values.Name,
      Description: values.Description,
      PlaceholderText: values.PlaceholderText,
      QuestionId: values.Question.Id,
      AnswerTypeId: values.AnswerType.Id,
      ResponseId: values.Response.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      answerDetail.Id = editData.Id;
      updateAnswer({
        entity: "Answer",
        isWogdApi: true,
        data: answerDetail,
        tag: ANSWER_TAG,
      }).then((response) => {
        if (!response.error) {
          !isAnswerUpdateLoading && setIsAddModalOpen(false) 
            successToast("Answer updated successfully.");
        }
      });
    } else {
      addAnswer({
        entity: "Answer",
        isWogdApi: true,
        data: answerDetail,
        tag: ANSWER_TAG,
      }).then((response) => {
        if (!response.error) {
          !isAnswerAddLoading && setIsAddModalOpen(false);
          successToast("Answer added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        PlaceholderText:
          editData && editData.PlaceholderText ? editData.PlaceholderText : "",
        Question:
          editData && editData.Question
            ? editData.Question
            : parentData && parentData.Question
            ? parentData.Question
            : "",
        AnswerType:
          editData && editData.AnswerType
            ? editData.AnswerType
            : parentData && parentData.AnswerType
            ? parentData.AnswerType
            : "",
        Response:
          editData && editData.Response
            ? editData.Response
            : parentData && parentData.Response
            ? parentData.Response
            : "",
      }}
      validationSchema={answerValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Placeholder Text"
              name="PlaceholderText"
              fieldErrors={errors.PlaceholderText}
              fieldTouched={touched.PlaceholderText}
              fieldValue={values.PlaceholderText}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.Question}
              label="Question"
              name="Question"
              entity="Question"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Question}
              fieldTouched={touched.Question}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.AnswerType}
              label="Answer Type"
              name="AnswerType"
              entity="AnswerType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.AnswerType}
              fieldTouched={touched.AnswerType}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Response}
              label="Response"
              name="Response"
              entity="Response"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Response}
              fieldTouched={touched.Response}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isAnswerAddLoading || isAnswerUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAnswerAddLoading || isAnswerUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

AnswerForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
  parentData: PropTypes.object,
  parentId: PropTypes.object,
};

export default AnswerForm;
