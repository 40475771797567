import React from "react";
import { Col } from "reactstrap";
import CustomInput from "@/shared/components/form/CustomInput";

const QuestionFields = ({
  data,
  replace,
  index,
  field,
  colSize,
  errorObj = {},
  touchedObj = {},
  handleBlur = () => {},
}) => {
  const onQuestionFieldsChange = (data, index, value, replace, fieldName) => {
    replace(index, {
      ...data,
      [`${fieldName}`]: value,
    });
  };

  return (
    <>
      <Col lg={colSize} md={colSize}>
        <CustomInput
          label={field === "Question" ? "Question" : "Name"}
          name={`${field} Name`}
          fieldValue={data.Name}
          fieldErrors={errorObj && errorObj.Name}
          fieldTouched={touchedObj && touchedObj.Name}
          handleBlur={handleBlur}
          handleChange={(event) => {
            onQuestionFieldsChange(
              data,
              index,
              event.target.value,
              replace,
              "Name"
            );
          }}
          required
        />
      </Col>
      <Col lg={colSize} md={colSize}>
        <CustomInput
          label="Placeholder Text"
          name="Placeholder Text"
          fieldValue={data.PlaceholderText}
          handleChange={(event) => {
            onQuestionFieldsChange(
              data,
              index,
              event.target.value,
              replace,
              "PlaceholderText"
            );
          }}
        />
      </Col>
      <Col lg={colSize} md={colSize}>
        <CustomInput
          label="Description"
          name={`${field} Description`}
          type="textarea"
          fieldValue={data.Description}
          handleChange={(event) => {
            onQuestionFieldsChange(
              data,
              index,
              event.target.value,
              replace,
              "Description"
            );
          }}
        />
      </Col>
    </>
  );
};

export default QuestionFields;
