import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { CHOICE_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ChoiceTypeForm = ({ editData, setIsAddModalOpen, parentId, parentData }) => {
  const [addChoiceType, { isLoading: isChoiceTypeAddLoading }] = useAddMutation();
  const [updateChoice, { isLoading: isChoiceTypeUpdateLoading }] =
    useUpdateMutation();

  const choiceTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    let choiceTypeDetail = {
      Name: values.Name,
      Description: values.Description,
    };

    if (editData && Object.entries(editData).length > 0) {
      choiceTypeDetail.Id = editData.Id;
      updateChoice({
        entity: "ChoiceType",
        isWogdApi: true,
        data: choiceTypeDetail,
        tag: CHOICE_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isChoiceTypeUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Choice type updated successfully.");
        }
      });
    } else {
      addChoiceType({
        entity: "ChoiceType",
        isWogdApi: true,
        data: choiceTypeDetail,
        tag: CHOICE_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isChoiceTypeAddLoading && setIsAddModalOpen(false);
          successToast("Choice type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
      }}
      validationSchema={choiceTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isChoiceTypeAddLoading || isChoiceTypeUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isChoiceTypeAddLoading || isChoiceTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ChoiceTypeForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
  parentData: PropTypes.object,
  parentId: PropTypes.object,
};

export default ChoiceTypeForm;
