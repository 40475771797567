import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { Button, Modal, ModalBody } from "reactstrap";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";
import { useLazyGetRecordsQuery } from "@/services/gamecodeXApi";
import { RESPONSE_TAG } from "@/shared/tagFile";
import { formateColumnFilter } from "@/utils/columnFilter";
import "react-toastify/dist/ReactToastify.css";

const FormResponse = () => {
  const [formResponseDetails, setFormResponseDetails] = useState();
  const [isEyeClicked, setIsEyeClicked] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const [
    getFormResponseRecords,
    {
      data: formResponseList,
      isError: isFormResponseError,
      isLoading: isFormResponseLoading,
      isFetching: isFormResponseFetching,
    },
  ] = useLazyGetRecordsQuery();

  const handleModal = () => {
    setIsEyeClicked(false);
  };

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getFormResponseRecords({
      entity: "Response",
      isWogdApi: true,
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      expand: {},
      query: "$expand=Answers($expand=Question)",
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: RESPONSE_TAG,
    });
    setFormResponseDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isFormResponseLoading &&
      !isFormResponseError &&
      formResponseList &&
      !isFormResponseFetching
    ) {
      let formResponseData = [...formResponseList.value];
      setFormResponseDetails({
        ...formResponseDetails,
        tableRowsData: formResponseList.value ? formResponseData : [],
        totalData:
          formResponseList && formResponseList["@odata.count"]
            ? formResponseList["@odata.count"]
            : 0,
        onDisplayDetailClick: (data) => {
          setIsEyeClicked(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isFormResponseError,
    formResponseList,
    isFormResponseLoading,
    isFormResponseFetching,
  ]);

  return (
    <Container>
      <Row>
        {formResponseDetails && (
          <DataReactTable
            reactTableData={formResponseDetails}
            title="Form Response"
            isFetching={isFormResponseFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isEyeClicked && (
        <div>
          <Modal
            isOpen={isEyeClicked}
            toggle={() => {
              setIsEyeClicked(!isEyeClicked);
            }}
            modalClassName="ltr-support"
            className="modal-dialog--success modal-dialog--header-xl modal-dialog-box theme-light"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                aria-label="modal__close-btn"
                type="button"
                onClick={handleModal}
              />
              <h4 className="text-modal modal__title">Form Response</h4>
            </div>
            <ModalBody>
              {selectedRecord.Answers.map((answer, index) => {
                return (
                  <div
                    style={{
                      border: "1px solid grey",
                      margin: "0px 0px 15px 0px",
                      borderRadius: "6px",
                    }}
                    className="text-left p-3"
                  >
                    <h4 style={{ fontWeight: 500 }}>
                      {index + 1}. {answer.Question.Name}
                    </h4>

                    <h4>
                      <ArrowRightIcon size={18} className="mr-1" />
                      {answer.Name ? answer.Name : "Not Answered"}
                    </h4>
                  </div>
                );
              })}
              <Button
                onClick={handleModal}
                className="modal_ok float-right"
                color="success"
              >
                Close
              </Button>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Container>
  );
};

export default FormResponse;
