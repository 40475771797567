import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { CONTRACT_MEDIA_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ContractMediaForm from "./ContractmediaForm";
import { getAllContractMedias } from "../../../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ContractMedia = () => {
    const [contractMediaDetails, setContractMediaDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteContractMedia, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const [
      getContractMediaRecords,
      {
        data: contractMediaList,
        isError: isContractMediaError,
        isLoading: isContractMediaLoading,
        isFetching: isContractMediaFetching,
      },
  ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Contract Name",
        accessor: "ContractDTO.Name",
        filter: true,
      },
      {
        Header: "Media Name",
        accessor: "MediaDTO.Name",
        filter: true,
      },
    ];
  
    const handleEditData = (userData) => {
      setSelectedRecord(userData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteContractMedia({
        entity: "Doc/ContractMedia",
        id: selectedRecord.Id,
        tag: CONTRACT_MEDIA_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Contract-Media deleted successfully.");
        }
      });
    };
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
      getContractMediaRecords({
        entity: "Doc/ContractMedias",
        entities: { Contract: {}, Media: {} },
        top: pageSize,
        skip: (page - 1) * pageSize,
        sort: sort,
        filter: filterQuery,
        globalFilter: {
          columns: filterColumn,
          globalSearchKeyword: globalSearchKeyword,
        },
        tag: CONTRACT_MEDIA_TAG,
      });
      setContractMediaDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);
  
    useEffect(() => {
      if (!isContractMediaLoading && !isContractMediaError && contractMediaList && !isContractMediaFetching) {
        dispatch(getAllContractMedias(contractMediaList.value));
        let contractMediaData = [...contractMediaList.value];
        setContractMediaDetails({
          ...contractMediaDetails,
          tableRowsData: contractMediaList.value ? contractMediaData : [],
          totalData:
            contractMediaList && contractMediaList["@odata.count"] ? contractMediaList["@odata.count"] : 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
          },
        });
      }
      // eslint-disable-next-line
    }, [isContractMediaError, contractMediaList, isContractMediaLoading, isContractMediaFetching]);
  
    return (
      <Container>
        <Row>
          {contractMediaDetails && (
            <DataReactTable
              reactTableData={contractMediaDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              title="Contract-Media"
              isFetching={isContractMediaFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Contract-Media`}
            header
            btn="Success"
            message={
              <ContractMediaForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsAddModalOpen={setIsAddModalOpen}
              />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={`Are you sure you want to delete ${selectedRecord?.Name || ""}?`}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    );
  };
export default ContractMedia
