import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import _ from "lodash";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation, useLazyGetRecordsByIdQuery } from "@/services/gamecodeXApi";
import { CONTENT_TAG, COMPONENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import InsertImage from "./InsertImage";
import InsertList from "./InsertList";
import InsertVideo from "./InsertVideo";
import InsertSingleFile from "./InsertSingleFile";
import InsertMobileImages from "./InsertMobileImages";
import InsertDynamicFields from "./InsertDynamicFields";
import InsertBlog from "./InsertBlog";
import TextEditor from "./TextEditor";

const ContentForm = ({
  editData,
  setIsAddModalOpen,
}) => {
  const [addContent, { isLoading: isContentAddLoading }] = useAddMutation();
  const [addMedia, { isLoading: isMediaAddLoading }] = useAddMutation();
  const [updateContent, { isLoading: isContentUpdateLoading }] =
    useUpdateMutation();
  const [getComponentDetails, { data: componentDetails }] = useLazyGetRecordsByIdQuery();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );
  const [storeContentId, setStoreContentId] = useState(0);
  const [allCollection, setAllCollection] = useState([])
  const componentId = editData?.ContentTypeDTO?.ComponentContentTypeDTO?.[0]?.ComponentId || editData?.ScreenContentDTO?.[0]?.ComponentId
  const contentValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    ContentType: Yup.mixed().required("Please select content-type."),
  });

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  const onSubmit = (values, { resetForm }) => {
    const filteredImages =
      values.ContentObject?.images &&
      values.ContentObject.images?.filter((image) => image.url);
    if (filteredImages?.length) {
      values.ContentObject.images = filteredImages;
    } else {
      delete values.ContentObject?.images;
    }
    const contentDetail = {
      Name: values.Name,
      Description: values.Description,
      ContentTypeId: values.ContentType.Id,
      ContentObject: JSON.stringify(values.ContentObject),
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      contentDetail.Id = editRecordDetail.Id;
      updateContent({
        entity: "CMS/Content",
        data: contentDetail,
        tag: CONTENT_TAG,
      }).then((response) => {
        if (!response?.error) {
          setIsAddModalOpen(false);
          successToast("Content updated successfully.");
          resetForm();
        }
      });
    } else {
      addContent({
        entity: "CMS/Content",
        data: contentDetail,
        tag: CONTENT_TAG,
      }).then((response) => {
        if (!response?.error) {
          !isContentAddLoading && setIsAddModalOpen(false);
          successToast("Content added successfully.");
          resetForm();
        }
      });
    }
  };

  const onFieldChange = (event, replace, index, value) => {
    let resp = {};
    event.forEach((val) => {
      var item = val;
      resp[item["label"].split(" ").join("")] = item.__isNew__
        ? ""
        : value[item.label];
      return resp;
    });
    replace(index, resp);
  };

  const getFieldValue = (field, data, index = 0, label = "") => {
    switch (field) {
      case "image":
        return Object.keys(data).map((val) => {
          return ({
          label: val,
          value: val,
          isFixed:
            editData && editData.ContentObject
              ? JSON.parse(editData?.ContentObject)?.[label]
                ? Object.keys(
                    JSON.parse(editData?.ContentObject)[label]
                  ).includes(val)
                : val === "name" || val === "url"
              : val === "name" || val === "url",
        })}
      );
      case "images":
        return Object.keys(data).map((val) => ({
          label: val,
          value: val,
          isFixed:
            editData && editData.ContentObject
              ? JSON.parse(editData?.ContentObject)["images"]
                ? JSON.parse(editData?.ContentObject)["images"][index]
                  ? Object.keys(
                      JSON.parse(editData?.ContentObject)["images"][index]
                    ).includes(val)
                  : val === "name" || val === "url"
                : val === "name" || val === "url"
              : val === "name" || val === "url",
        }));
      case "mobileImages":
        return Object.keys(data).map((val) => ({
          label: val,
          value: val,
          isFixed:
            editData && editData.ContentObject
              ? JSON.parse(editData?.ContentObject)["mobileImages"]
                ? JSON.parse(editData?.ContentObject)["mobileImages"][index]
                  ? Object.keys(
                      JSON.parse(editData?.ContentObject)["mobileImages"][index]
                    ).includes(val)
                  : val === "image1" || val === "image2" || val === "description"
                : val === "image1" || val === "image2" || val === "description"
              : val === "image1" || val === "image2" || val === "description",
        }));
      case "videos":
        return Object.keys(data).map((val) => ({
          label: val,
          value: val,
          isFixed:
            editData && editData.ContentObject
              ? JSON.parse(editData?.ContentObject)["videos"]
                ? JSON.parse(editData?.ContentObject)["videos"][index]
                  ? Object.keys(
                      JSON.parse(editData?.ContentObject)["videos"][index]
                    ).includes(val)
                  : val === "name" || val === "url"
                : val === "name" || val === "url"
              : val === "name" || val === "url",
        }));
      case "lists":
        return Object.keys(data).map((val) => {
          return {
            label: val,
            value: val,
            isFixed:
              editData && editData.ContentObject
                ? JSON.parse(editData?.ContentObject)["lists"]
                  ? JSON.parse(editData?.ContentObject)["lists"][index]
                    ? Object.keys(
                        JSON.parse(editData?.ContentObject)["lists"][index]
                      ).includes(val)
                    : val === "name"
                    ? true
                    : false
                  : val === "name"
                  ? true
                  : false
                : val === "name"
                ? true
                : false,
          };
        });
      default:
        break;
    }
  };

  const handleCollections = (option, setFieldValue, values) => {
    if (option) {
      values.ContentObject.apiURL = `https://api.prioritycouriers.com/api/Shopify/GetProductDetailFromShopifyCollection?collectionId=${option.id}`;
      values.ContentObject.collectionName = { id: option.id, title: option.title }
      setFieldValue("ContentObject", {
        ...values.ContentObject,
      });
    } else {
      values.ContentObject.apiURL = "";
      values.ContentObject.collectionName = ""
      setFieldValue("ContentObject", {
        ...values.ContentObject,
      });
    }
  }
  const getAllProductDetails = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `https://api.prioritycouriers.com/api/Shopify/GetSmartCollectionDetailFromShopify/`
      })
      if (response?.data?.items?.length) {
        setAllCollection(response?.data?.items)
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (componentId)
      getComponentDetails({
        entity: `CMSReference/Component`,
        id: componentId,
        tag: COMPONENT_TAG,
      });
  }, [componentId]);


  useEffect(() => {
    getAllProductDetails()
  }, [])

  const handleContentTypeId = (values) => {
    if (values?.ContentType?.Id) {
      setStoreContentId(values?.ContentType?.Id)
    }
  }
  return (
    <Formik
      initialValues={{
        Name:
          editRecordDetail && editRecordDetail.Name
            ? editRecordDetail.Name
            : "",
        Description:
          editRecordDetail && editRecordDetail.Description
            ? editRecordDetail.Description
            : "",
        ContentType:
          editRecordDetail && editRecordDetail.ContentTypeDTO
            ? editRecordDetail.ContentTypeDTO
            : "",
        ContentObject:
          editRecordDetail && editRecordDetail.ContentObject
            ? JSON.parse(editData.ContentObject)
            : "",
      }}
      validationSchema={contentValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;
        const isShopifyProductList =
          componentDetails?.Name === "ShopifyProductList" ||
          values?.ContentType?.ComponentContentTypeDTO?.[0]?.ComponentDTO?.Name === "ShopifyProductList";
        storeContentId !== values?.ContentType?.Id && handleContentTypeId(values)
        return (
          <Form>
            <Row
              className="mt-3"
              style={{
                maxHeight:
                  Object.keys(editData).length || values.ContentType
                    ? "590px"
                    : "",
                height:
                  Object.keys(editData).length || values.ContentType
                    ? "100%"
                    : "",
                overflow:
                  Object.keys(editData).length || values.ContentType
                    ? "auto"
                    : "",
              }}
            >
              <Col>
                <Row>
                  <Col
                    sm={12}
                    md={
                      values.ContentObject?.images?.length ||
                      values.ContentObject?.videos?.length ||
                      values.ContentObject?.lists?.length ||
                      values.ContentObject?.insertBlog?.length ||
                      values.ContentObject?.mobileImages?.length ||
                      values.ContentObject?.dynamicFieldList?.length
                        ? 6
                        : 12
                    }
                    lg={
                      values.ContentObject?.images?.length ||
                      values.ContentObject?.videos?.length ||
                      values.ContentObject?.lists?.length ||
                      values.ContentObject?.insertBlog?.length ||
                      values.ContentObject?.mobileImages?.length ||
                      values.ContentObject?.dynamicFieldList?.length
                        ? 4
                        : 6
                    }
                  >
                    {Object.keys(editData ?? {}).length > 0 && (
                      <CustomInput
                        label="Id"
                        fieldValue={editData.Id}
                        readonly
                      />
                    )}
                    <CustomInput
                      label="Name"
                      name="Name"
                      fieldErrors={errors.Name}
                      fieldTouched={touched.Name}
                      fieldValue={values.Name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      required
                    />
                    <CustomInput
                      label="Description"
                      name="Description"
                      type="textarea"
                      fieldErrors={errors.Description}
                      fieldTouched={touched.Description}
                      fieldValue={values.Description}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                    <AsyncPaginates
                      value={values.ContentType}
                      label="Content Type"
                      name="ContentType"
                      entity="CMSReference/ContentTypes"
                      query={"entities=ComponentContentTypes&entities=ComponentContentTypes.Component&$expand=ComponentContentTypeDTO"}
                      setFieldValue={setFieldValue}
                      fieldErrors={errors.ContentType}
                      fieldTouched={touched.ContentType}
                      isAllowToAddContent={true}
                      handleBlur={handleBlur}
                      required
                      handleEditRecords={editRecordDetail}
                    />
                    {isShopifyProductList && values?.ContentObject?.hasOwnProperty("apiURL") &&
                      <div className="mb-3">
                        <Label for="ContentObject">Collection Name</Label>
                        <Select
                          name="ContentObject.collectionName"
                          options={allCollection}
                          getOptionLabel={(item) => item.title}
                          getOptionValue={(item) => item.id}
                          value={values?.ContentObject?.collectionName}
                          isClearable
                          onChange={(option) => handleCollections(option, setFieldValue, values)}
                        />
                      </div>
                    }
                    <Row>
                      {values?.ContentObject &&
                        Object.keys(values.ContentObject).map(
                          (data, fieldIndex) => {
                            if (
                              data !== "images" &&
                              data !== "lists" &&
                              data !== "insertBlog" &&
                              data !== "videos" &&
                              data !== "mobileImages" &&
                              data !== "dynamicFieldList" &&
                              data !== "collectionName" &&
                              data !== "dynamicFieldType"
                            ) {
                              const fieldType = JSON.parse(
                                values.ContentType.ContentObject
                              )[data]?.type;
                              if (
                                fieldType !== "image" &&
                                fieldType !== "video" &&
                                fieldType !== "audio" &&
                                fieldType !== "textEditor"
                              ) {
                                return (
                                  <Col md={12} key={fieldIndex}>
                                    <CustomInput
                                      label={_.startCase(data)}
                                      name={data}
                                      placeholder={_.startCase(data)}
                                      type={
                                        fieldType === "image" ||
                                        fieldType === "video" ||
                                        fieldType === "audio"
                                          ? "file"
                                          : fieldType
                                      }
                                      fieldValue={
                                        typeof values.ContentObject[data] ===
                                          "string"
                                          ? values.ContentObject[data]
                                          : values.ContentObject[data].value
                                      }
                                      handleBlur={handleBlur}
                                      handleChange={(e) => {
                                        const contentObjectData =
                                          values.ContentObject &&
                                          values.ContentObject;
                                        if (
                                          fieldType === "image" ||
                                          fieldType === "video" ||
                                          fieldType === "audio"
                                        ) {
                                          contentObjectData[data].value.url =
                                            e.target.files[0];
                                        } else {
                                          contentObjectData[data] =
                                            e.target.value;
                                        }
                                        setFieldValue(
                                          "ContentObject",
                                          contentObjectData
                                        );
                                      }}
                                    />
                                  </Col>
                                );
                              } else if (fieldType === "image" || fieldType === "video" || fieldType === "audio") {
                                return (
                                  <InsertSingleFile
                                    key={fieldIndex}
                                    label={data}
                                    fieldType={fieldType}
                                    formik={formik}
                                    styles={styles}
                                    getFieldValue={getFieldValue}
                                    onFieldChange={onFieldChange}
                                    addMedia={addMedia}
                                    isMediaAddLoading={isMediaAddLoading}
                                  />
                                );
                              } else if (fieldType === "textEditor") {
                                return (
                                  <TextEditor 
                                    label={data}
                                    formik={formik}
                                  />
                                )
                              } else {
                                return <div key={fieldIndex}></div>;
                              }
                            }
                          }
                        )}
                    </Row>
                  </Col>
                  {((values.ContentObject?.images &&
                    values.ContentObject?.images?.length) ||
                    (values.ContentObject?.videos &&
                      values.ContentObject?.videos?.length) ||
                    (values.ContentObject?.lists &&
                      values.ContentObject?.lists?.length) ||
                    (values.ContentObject?.insertBlog &&
                      values.ContentObject?.insertBlog?.length) ||
                    (values.ContentObject?.dynamicFieldList &&
                      values.ContentObject?.dynamicFieldList?.length) ||
                    (values.ContentObject?.mobileImages &&
                      values.ContentObject?.mobileImages?.length)) && (
                    <Col
                      sm={
                        values.ContentObject?.images?.length ||
                        values.ContentObject?.videos?.length ||
                        values.ContentObject?.lists?.length ||
                        values.ContentObject?.insertBlog?.length ||
                        values.ContentObject?.mobileImages?.length ||
                        values.ContentObject?.dynamicFieldList?.length
                          ? 12
                          : ""
                      }
                      md={
                        values.ContentObject?.images?.length ||
                        values.ContentObject?.videos?.length ||
                        values.ContentObject?.lists?.length ||
                        values.ContentObject?.insertBlog?.length ||
                        values.ContentObject?.mobileImages?.length ||
                        values.ContentObject?.dynamicFieldList?.length
                          ? 6
                          : ""
                      }
                      lg={
                        values.ContentObject?.images?.length ||
                        values.ContentObject?.videos?.length ||
                        values.ContentObject?.lists?.length ||
                        values.ContentObject?.insertBlog?.length ||
                        values.ContentObject?.mobileImages?.length ||
                        values.ContentObject?.dynamicFieldList?.length
                          ? 4
                          : ""
                      }
                    >
                      <Row>
                        <InsertImage
                          formik={formik}
                          styles={styles}
                          getFieldValue={getFieldValue}
                          onFieldChange={onFieldChange}
                          addMedia={addMedia}
                          isMediaAddLoading={isMediaAddLoading}
                        />
                        <InsertMobileImages 
                          formik={formik}
                          styles={styles}
                          getFieldValue={getFieldValue}
                          onFieldChange={onFieldChange}
                          addMedia={addMedia}
                          isMediaAddLoading={isMediaAddLoading}
                        />
                        <InsertVideo
                          formik={formik}
                          styles={styles}
                          getFieldValue={getFieldValue}
                          onFieldChange={onFieldChange}
                          addMedia={addMedia}
                          isMediaAddLoading={isMediaAddLoading}
                        />
                        <InsertBlog
                          formik={formik}
                        />
                        <InsertList
                          formik={formik}
                          styles={styles}
                          getFieldValue={getFieldValue}
                          onFieldChange={onFieldChange}
                        />
                        <InsertDynamicFields 
                          formik={formik}
                          styles={styles}
                          getFieldValue={getFieldValue}
                          onFieldChange={onFieldChange}
                          addMedia={addMedia}
                          isMediaAddLoading={isMediaAddLoading}
                          storeContentId={storeContentId}
                          editData={editData}
                        />
                      </Row>
                    </Col>
                  )}
                  <Col
                    sm={12}
                    lg={
                      values.ContentObject?.images?.length ||
                      values.ContentObject?.videos?.length ||
                      values.ContentObject?.lists?.length ||
                      values.ContentObject?.insertBlog?.length ||
                      values.ContentObject?.mobileImages?.length ||
                      values.ContentObject?.dynamicFieldList?.length
                        ? 4
                        : 6
                    }
                  >
                    <CustomInput
                      label="ContentObject"
                      name="ContentObject"
                      type="codeblock"
                      fieldErrors={errors.ContentObject}
                      fieldTouched={touched.ContentObject}
                      fieldValue={
                        values.ContentObject
                          ? (function () {
                            const contentObjectCopy = { ...values.ContentObject };
                            if (contentObjectCopy.hasOwnProperty("collectionName")) {
                              delete contentObjectCopy.collectionName;
                            }
                            return JSON.stringify(contentObjectCopy, null, 4);
                          })()
                          : JSON.stringify({})
                      }
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      readonly
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-end mt-3">
              <Button
                disabled={
                  isContentAddLoading ||
                  isContentUpdateLoading ||
                  isMediaAddLoading
                }
                onClick={handleSubmit}
                className="modal_ok float-right"
                color="success"
              >
                {(isContentAddLoading || isContentUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
                Save
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

ContentForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
  setCurrentContent: PropTypes.func,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  isAddModalOpen: PropTypes.object,
};

export default ContentForm;
