import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import moment from "moment/moment";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { CONTRACT_TAG } from "@/shared/tagFile";
import Tabs from "@/shared/components/Tabs";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ContractForm from "./ContractForm";
import { getAllContracts } from "../../../Site/SiteSlice";
import ContractmediaForm from "../../DocMedia/ContractMedia/ContractmediaForm";
import "react-toastify/dist/ReactToastify.css";

const Contract = () => {
    const [contractDetails, setContractDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [activeTab, setActiveTab] = useState("1");
    const [currentContractId, setCurrentContractId] = useState(0);
    const [deleteContract, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
    const [isArchiveContract, setIsArchiveContract] = useState(false)
    const dispatch = useDispatch();
    const [
      getContractRecords,
      {
        data: contractList,
        isError: isContractError,
        isLoading: isContractLoading,
        isFetching: isContractFetching,
      },
    ] = useLazyGetRecordsQuery();

    const [
      getArchivedContract, {
        data: archivedContract,
        isError: isDeletedContractError,
        isLoading: isDeletedContractLoading,
        isFetching: isDeletedContractFetching,
      }
      ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Name",
        accessor: "Name",
        filter: true,
      },
      {
        Header: "Description",
        accessor: "Description",
        filter: true,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Version",
        accessor: "Version",
        filter: true,
      },
      {
        Header: "Contract Type",
        accessor: "ContractTypeDTO.Name",
        filter: true,
      },
      {
        Header: "Entity Type",
        accessor: "EntityDTO.Name",
        filter: true,
      },
      {
        Header: "Created On",
        accessor: "CreatedOn",
        filter: true,
        Cell: ({ value }) => {
          return value ? moment(value)?.utc()?.format("YYYY-MM-DD hh:mm A") : "-";
        },
      },
      {
        Header: "State",
        accessor: "State",
        filter: true,
      },
    ];

    const handleEditData = (contractData) => {
      setCurrentContractId({Id :contractData?.Id, Name: contractData?.Name})
      setSelectedRecord(contractData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteContract({
        entity: "Doc/Contract",
        id: selectedRecord.Id,
        tag: CONTRACT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Contract deleted successfully.");
        }
      });
    };

    const handleRestoreData = (data) => {
      deleteContract({
        entity: "Doc/Contract",
        id: `${data?.Id}?isRestore=true`,
        tag: CONTRACT_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Contract restored successfully.");
        }
      });
    }
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );

      if (!isArchiveContract) {
        getContractRecords({
          entity: "Doc/Contracts",
          entities: {ContractType : {}, Entity : {}},
          top: pageSize,
          skip: (page - 1) * pageSize,
          sort: sort,
          filter: filterQuery,
          globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
          },
          tag: CONTRACT_TAG,
        });
      } else {
        getArchivedContract({
          entity: "Doc/Contracts",
          entities: {ContractType : {}, Entity : {}},
          isArchive:true,
          top: pageSize,
          skip: (page - 1) * pageSize,
          sort: sort,
          filter: filterQuery,
          globalFilter: {
            columns: filterColumn,
            globalSearchKeyword: globalSearchKeyword,
          },
          tag: CONTRACT_TAG,
        })
      }
      setContractDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter, isArchiveContract]);
  
    useEffect(() => {
      if (
        !isContractLoading &&
        !isDeletedContractLoading &&
        !isContractError && 
        !isDeletedContractError &&
        (contractList || archivedContract) &&
        !isContractFetching &&
        !isDeletedContractFetching
      ) {
        dispatch(getAllContracts(isArchiveContract ? archivedContract.value : contractList.value));
        let ContractData = isArchiveContract ? [...archivedContract.value] : [...contractList.value];
        setContractDetails({
          ...contractDetails,
          tableRowsData: contractList.value ? ContractData : [],
          totalData: 
            isArchiveContract ? 
              archivedContract && archivedContract?.["@odata.count"] 
              : contractList && contractList?.["@odata.count"] || 0,
          onEditClick: (data) => {
              handleEditData(data)
          },
          onDeleteClick: (data) => {
              setIsDeleteModalOpen(true);
              setSelectedRecord(data);
          },
          onRestoreClick: isArchiveContract ? (data) => {
            handleRestoreData(data);
          }: "",
          onCloneClick: (data) => {
            setSelectedRecord({Id: data.Id});
            setIsCloneModalOpen(true);
          }
        });
      }
      // eslint-disable-next-line
    }, [
      isContractError,
      isDeletedContractError,
      contractList,
      archivedContract,
      isContractLoading,
      isDeletedContractLoading,
      isContractFetching,
      isDeletedContractFetching,
    ]);

    const toggle = () => {
      setIsAddModalOpen(!isAddModalOpen)
      setActiveTab("1");
      setSelectedRecord();
      setCurrentContractId(0)
    }

    const tabs = {
      Contract :( 
        <ContractForm
            editData={selectedRecord ? selectedRecord : {}}
            setCurrentContractId={setCurrentContractId}
            setActiveTab={setActiveTab}
            toggle={toggle}
        />
      ),
      "Contract Media" :(
        <ContractmediaForm editData={{}} parentId={currentContractId} toggle={toggle} setIsAddModalOpen={setIsAddModalOpen} />
      )
    }
  
    const deleteMessage = () => {
      return (
        <div>
          Are you sure you want to delete {selectedRecord.Name}?
          <div className="warning-div">
            <i>
              This will delete all the associated records <br />
              ( Like - Contract clause, Contract media )
            </i>
          </div>
        </div>
      );
    }
  
    return (
      <Container data-testid="test-Contract">
        <Row>
          {contractDetails && (
            <DataReactTable
              reactTableData={contractDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              onArchive={{
                setIsArchiveDetails : () => setIsArchiveContract(!isArchiveContract),
                isArchiveDetails : isArchiveContract
              }}
              title="Contract"
              isFetching={isContractFetching || isDeletedContractFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            testid="addModal"
            toggle={toggle}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Contract`}
            header
            btn="Success"
            message={
                <Tabs
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
            }
          />
        )}
        {isCloneModalOpen && (
          <AddModal
            testid="addModal"
            toggle={() => setIsCloneModalOpen(!isCloneModalOpen)}
            color="success"
            title={`Clone Contract`}
            header
            btn="Success"
            message={
                <ContractForm
                    editData={selectedRecord ? selectedRecord : {}}
                    setIsAddModalOpen={()=>setIsCloneModalOpen(!isCloneModalOpen)}
                    cloneContract={true}
                />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={deleteMessage()}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    );
}

export default Contract
