import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { QUESTION_OPTION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllQuestionOptions } from "../../CommSlice";
import QuestionOptionForm from "./QuestionOptionForm";
import "react-toastify/dist/ReactToastify.css";

const QuestionOption = () => {
  const [questionOptionDetails, setQuestionOptionDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteItem, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [
    getQuestionOptionRecords,
    {
      data: questionOptionList,
      isError: isQuestionOptionError,
      isLoading: isQuestionOptionLoading,
      isFetching: isQuestionOptionFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Question",
      accessor: "Question.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Option",
      accessor: "Option.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (questionOptionData) => {
    setSelectedRecord(questionOptionData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteItem({
      entity: "QuestionOption",
      isWogdApi: true,
      id: selectedRecord.Id,
      tag: QUESTION_OPTION_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Question-option deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state?.name) {
      setColumnFilter({
        [`${location.state.title.split(" ").join("")}.Name`]:
          location.state.name,
      });
    }
    history.push({ pathname: location.pathname, state: {} });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getQuestionOptionRecords({
      entity: "QuestionOption",
      isWogdApi: true,
      expand: { Question: {}, Option: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: QUESTION_OPTION_TAG,
    });
    setQuestionOptionDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isQuestionOptionLoading &&
      !isQuestionOptionError &&
      questionOptionList &&
      !isQuestionOptionFetching
    ) {
      dispatch(getAllQuestionOptions(questionOptionList.value));
      let questionOptionData = [...questionOptionList.value];
      if (selectedRecord) {
        setSelectedRecord((prev) =>
          questionOptionList.value.find((value) => value.Id === prev.Id)
        );
      }
      questionOptionData = questionOptionList.value.map((questionOption) => {
        let newQuestionOptionData = { ...questionOption };
        return newQuestionOptionData;
      });
      setQuestionOptionDetails({
        ...questionOptionDetails,
        tableRowsData: questionOptionList.value ? questionOptionData : [],
        totalData:
          questionOptionList && questionOptionList["@odata.count"]
            ? questionOptionList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isQuestionOptionError,
    questionOptionList,
    isQuestionOptionLoading,
    isQuestionOptionFetching,
  ]);

  return (
    <Container>
      <Row>
        {questionOptionDetails && (
          <DataReactTable
            reactTableData={questionOptionDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Question Option"
            isFetching={isQuestionOptionFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Question Option`}
          header
          btn="Success"
          message={
            <div>
              <QuestionOptionForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
              />
            </div>
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default QuestionOption;
