import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { APPLICATION_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ApplicationTypeForm from "./ApplicationTypeForm";
import { getAllApplicationTypes } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ApplicationType = () => {
  const [appTypeDetails, setAppTypeDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteAppType, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getApplicationTypeRecords,
    {
      data: applicationTypeList,
      isError: isApplicationTypeError,
      isLoading: isApplicationTypeLoading,
      isFetching: isApplicationTypeFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (applicationTypeData) => {
    setSelectedRecord(applicationTypeData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteAppType({
      entity: "CMSReference/ApplicationType",
      id: selectedRecord.Id,
      tag: APPLICATION_TYPE_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Application-type deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getApplicationTypeRecords({
      entity: "CMSReference/ApplicationTypes",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: APPLICATION_TYPE_TAG,
    });
    setAppTypeDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isApplicationTypeLoading &&
      !isApplicationTypeError &&
      applicationTypeList &&
      !isApplicationTypeFetching
    ) {
      dispatch(getAllApplicationTypes(applicationTypeList.value));
      let appTypeData = [...applicationTypeList.value];
      setAppTypeDetails({
        ...appTypeDetails,
        tableRowsData: applicationTypeList.value ? appTypeData : [],
        totalData:
          applicationTypeList && applicationTypeList["@odata.count"]
            ? applicationTypeList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isApplicationTypeError,
    applicationTypeList,
    isApplicationTypeLoading,
    isApplicationTypeFetching,
  ]);

  const deleteMessage = () => {
    return (
      <div>
        Are you sure you want to delete {selectedRecord.Name}?
        <div className="warning-div">
          <i>
            This will delete all the associated records <br />
            ( Like - Application, Application screen, Application Content, Application Menu)
          </i>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        {appTypeDetails && (
          <DataReactTable
            reactTableData={appTypeDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Application Type"
            isFetching={isApplicationTypeFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Application Type`}
          header
          btn="Success"
          message={
            <ApplicationTypeForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default ApplicationType;
