import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { USER_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const UserForm = ({ editData, setIsAddModalOpen }) => {
  const [addUser, { isLoading: isUserAddLoading }] = useAddMutation();
  const [updateUser, { isLoading: isUserUpdateLoading }] = useUpdateMutation();
  const userValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    EmailAddress: Yup.string().required("Please enter email address."),
    Entity: Yup.mixed().required("Please select entity."),
    UserType: Yup.mixed().required("Please select user-type."),
    SsoIdentifier: Yup.string().required("Please enter sso identifier."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const userDetail = {
      Name: values.Name,
      Description: values.Description,
      Email: values.EmailAddress,
      EntityId: values.Entity.Id,
      UserTypeId: values.UserType.Id,
      SsoIdentifier: values.SsoIdentifier,
    };
    if (values.SortOrder) userDetail.SortOrder = parseInt(values.SortOrder);

    if (editData && Object.entries(editData).length > 0) {
      userDetail.Id = editData.Id;
      updateUser({
        entity: "User",
        data: userDetail,
        tag: USER_TAG,
        isWogdApi: true,
      }).then((response) => {
        if (!response.error) {
          !isUserUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("User updated successfully.");
        }
      });
    } else {
      addUser({
        entity: "User",
        data: userDetail,
        tag: USER_TAG,
        isWogdApi: true,
      }).then((response) => {
        if (!response.error) {
          !isUserAddLoading && setIsAddModalOpen(false);
          successToast("User added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        Entity: editData && editData.Entity ? editData.Entity : "",
        UserType: editData && editData.UserType ? editData.UserType : "",
        EmailAddress: editData && editData.Email ? editData.Email : "",
        SsoIdentifier:
          editData && editData.SsoIdentifier ? editData.SsoIdentifier : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={userValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="EmailAddress"
              name="EmailAddress"
              fieldErrors={errors.EmailAddress}
              fieldTouched={touched.EmailAddress}
              fieldValue={values.EmailAddress}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
              readonly
            />
            <AsyncPaginates
              value={values.Entity}
              label="Entity"
              name="Entity"
              entity="Org/OrgEntities"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Entity}
              fieldTouched={touched.Entity}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.UserType}
              label="User Type"
              name="UserType"
              entity="UserType"
              setFieldValue={setFieldValue}
              fieldErrors={errors.UserType}
              fieldTouched={touched.UserType}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Sso Identifier"
              name="SsoIdentifier"
              fieldErrors={errors.SsoIdentifier}
              fieldTouched={touched.SsoIdentifier}
              fieldValue={values.SsoIdentifier}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
              readonly
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isUserAddLoading || isUserUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isUserAddLoading || isUserUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

UserForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default UserForm;
