import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactTableConstructor from "./components/ReactTableConstructor";
import ReactTableCell from "./components/ReactTableCell";

const ReactTableBase = ({
  tableConfig,
  columns,
  data,
  updateDraggableData,
  updateEditableData,
  onAddClick,
  onWizardClick,
  onArchive,
  isFetching,
  headerContent = true,
  totalRecords,
  setPageNumber,
  recordsPerPage,
  setRecordsPerPage,
  setSort,
  setGlobalSearchKeyword,
  setColumnFilter,
  onEditClick,
  onDeleteClick,
  onDisplayDetailClick,
  onCloneClick,
  onRestoreClick,
}) => {
  const { isSortable, withPagination, withSearchEngine } = tableConfig;
  const [filterValue, setFilterValue] = useState(null);
  const tableOptions = {
    columns,
    data,
    updateDraggableData,
    updateEditableData,
    setFilterValue,
    defaultColumn: {},
    dataLength: totalRecords ? totalRecords : 0,
    autoResetPage: false,
    disableSortBy: !isSortable,
    manualSortBy: isSortable,
    manualGlobalFilter: !withSearchEngine,
    manualPagination: withPagination,
    pageCount: Math.ceil((totalRecords ? totalRecords : 0) / recordsPerPage),
    initialState: {
      pageIndex: 0,
      pageSize: recordsPerPage,
      globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
    },
  };
  useEffect(() => {
    setGlobalSearchKeyword(filterValue);
    // eslint-disable-next-line
  }, [filterValue]);
  let tableOptionalHook = [];
  if (withSearchEngine) {
    tableOptions.defaultColumn = {
      Cell: ReactTableCell,
    };
  }

  return (
    <ReactTableConstructor
      key={"common"}
      tableConfig={tableConfig}
      tableOptions={tableOptions}
      tableOptionalHook={tableOptionalHook}
      onAddClick={onAddClick}
      onWizardClick={onWizardClick}
      onArchive={onArchive}
      isFetching={isFetching}
      headerContent={headerContent}
      setPageNumber={setPageNumber}
      setRecordsPerPage={setRecordsPerPage}
      setSort={setSort}
      setColumnFilter={setColumnFilter}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onDisplayDetailClick={onDisplayDetailClick}
      onCloneClick={onCloneClick}
      onRestoreClick={onRestoreClick}
    />
  );
};

ReactTableBase.propTypes = {
  tableConfig: PropTypes.shape({
    isSortable: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
  updateDraggableData: PropTypes.func,
  updateEditableData: PropTypes.func,
  isFetching: PropTypes.bool.isRequired,
  headerContent: PropTypes.bool,
  totalRecords: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setRecordsPerPage: PropTypes.func.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  setGlobalSearchKeyword: PropTypes.func.isRequired,
  setColumnFilter: PropTypes.func,
  onAddClick: PropTypes.func,
};

ReactTableBase.defaultProps = {
  totalRecords: 0,
  tableConfig: {
    withPagination: true,
    isSortable: false,
    withSearchEngine: false,
    manualPageSize: [],
  },
  columns: [
    { Header: "#", accessor: "id" },
    { Header: "Header Example Title one", accessor: "first" },
    { Header: "Header Example Title two", accessor: "last" },
  ],
  data: [
    { id: 1, first: "Cell Example Data one", last: "Cell Example Data two" },
    { id: 2, first: "Cell Example Data three", last: "Cell Example Data four" },
  ],
  updateDraggableData: () => { },
  updateEditableData: () => { },
  setGlobalSearchKeyword: () => { },
};

export default ReactTableBase;
