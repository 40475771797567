import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { successToast } from "@/shared/components/toast";
import { COMPONENT_TAG } from "@/shared/tagFile";

const ComponentForm = ({ editData, setIsAddModalOpen }) => {
  const [addComponent, { isLoading: isComponentAddLoading }] = useAddMutation();
  const [updateComponent, { isLoading: isComponentUpdateLoading }] =
    useUpdateMutation();

  const componentValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    SortOrder: Yup.number().min(0).nullable(true),
    File:
      editData && Object.entries(editData).length > 0
        ? Yup.mixed()
        : Yup.mixed().required("Please select component."),
  });

  const onSubmit = async (values) => {
    var formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Description", values.Description);
    if (values.SortOrder)
      formData.append("SortOrder", parseInt(values.SortOrder));
    if (editData && Object.entries(editData).length > 0) {
      let url = editData.FilePath;
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );
      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        formData.append("File", new File([u8arr], filename, { type: mime }));
      }
      values.File
        ? await formData.append("File", values.File)
        : await toDataURL(url).then((dataUrl) => {
            dataURLtoFile(dataUrl, `imageName.${url.split(".").pop()}`);
          });
      values.Image && formData.append("Image", values.Image);
      formData.append("Id", editData.Id);
      updateComponent({
        entity: "CMSReference/Component",
        data: formData,
        Id: editData.Id,
        tag: COMPONENT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isComponentUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Component updated successfully.");
        }
      });
    } else {
      formData.append("File", values.File);
      values.Image && formData.append("Image", values.Image);
      addComponent({
        entity: "CMSReference/Component",
        data: formData,
        tag: COMPONENT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isComponentAddLoading && setIsAddModalOpen(false);
          successToast("Component added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
        File: "",
        Image: "",
      }}
      validationSchema={componentValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;
        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {/* {editData && <img src={editData.FilePath} />} */}
            <CustomInput
              label="File"
              name="File"
              type="file"
              handleChange={(e) => {
                setFieldValue("File", e.target.files[0]);
              }}
              fieldErrors={errors.File}
              fieldTouched={touched.File}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Component Demo"
              name="Image"
              type="file"
              handleChange={(e) => {
                setFieldValue("Image", e.target.files[0]);
              }}
              fieldErrors={errors.Image}
              fieldTouched={touched.Image}
              handleBlur={handleBlur}
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isComponentAddLoading || isComponentUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isComponentAddLoading || isComponentUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ComponentForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ComponentForm;
