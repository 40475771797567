import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { MENU_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import MenuTypeForm from "./MenuTypeForm";
import { getAllMenuType } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const MenuType = () => {
  const [menuTypeDetails, setMenuTypeDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteMenuType, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getMenuTypeRecords,
    {
      data: menuTypeList,
      isError: isMenuTypeError,
      isLoading: isMenuTypeLoading,
      isFetching: isMenuTypeFetching,
    },
  ] = useLazyGetRecordsQuery();
  
  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "ContentObject",
      accessor: "ContentObject",
      filter: true,
      Cell: ({ value }) => {
        let contentKeys = "";
        if (value) {
          try {
            var contentObject = JSON.parse(value);
            for (const key in contentObject) {
              contentKeys += key + " | ";
            }
          } catch (error) {}
        }
        return value ? contentKeys.slice(0, -2) : "-";
      },
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (menuTypeData) => {
    setSelectedRecord(menuTypeData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteMenuType({
      entity: "CMSReference/MenuType",
      id: selectedRecord.Id,
      tag: MENU_TYPE_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Menu-type deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getMenuTypeRecords({
      entity: "CMSReference/MenuTypes",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: MENU_TYPE_TAG,
    });
    setMenuTypeDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isMenuTypeLoading &&
      !isMenuTypeError &&
      menuTypeList &&
      !isMenuTypeFetching
    ) {
      dispatch(getAllMenuType(menuTypeList.value));
      let menuTypeData = [...menuTypeList.value];
      setMenuTypeDetails({
        ...menuTypeDetails,
        tableRowsData: menuTypeList.value ? menuTypeData : [],
        totalData:
          menuTypeList && menuTypeList["@odata.count"]
            ? menuTypeList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isMenuTypeError, menuTypeList, isMenuTypeLoading, isMenuTypeFetching]);

  const deleteMessage = () => {
    return (
      <div>
        Are you sure you want to delete {selectedRecord.Name}?
        <div className="warning-div">
          <i>
            This will delete all the associated records <br />
            ( Like - Menu, Screen Menu, Application Menu)
          </i>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        {menuTypeDetails && (
          <DataReactTable
            reactTableData={menuTypeDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Menu Type"
            isFetching={isMenuTypeFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          xxl
          title={`${selectedRecord ? "Edit" : "Add"} Menu Type`}
          header
          btn="Success"
          message={
            <MenuTypeForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default MenuType;
