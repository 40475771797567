import React, { useState } from "react";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { FieldArray, getIn } from "formik";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import { Divider, Link } from "@material-ui/core";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { successToast } from "@/shared/components/toast";

const InsertMobileImages = ({
    formik,
    styles,
    getFieldValue,
    onFieldChange,
    addMedia,
    isMediaAddLoading,
  }) => {
    const { errors, touched, values, handleBlur, setFieldValue } = formik;

    const [currImageUploadButton, setCurrImageUploadButton] = useState("");
    const [currImageUploadButton2, setCurrImageUploadButton2] = useState("");
  
    const defaultImageField = [
      { value: "name", label: "Image Name" },
      { value: "url", label: "URL" },
    ];
  
    const uploadImage = (setFieldValue, index, data, values) => {
        data === "image1" ? setCurrImageUploadButton(`File${index}`) : setCurrImageUploadButton2(`File${index}`);
      var formData = new FormData();
      if (values[`File${index}`] !== undefined) {
        formData.append("MediaTypeId", 1);
        formData.append("PathFolderName", "content");
        formData.append("File", values[`File${index}`]);
      }
  
      addMedia({
        entity: `CMS/Media`,
        data: formData,
      }).then((response) => {
        if (!response.error) {
          const contentObjectData = values.ContentObject && values.ContentObject;
          contentObjectData.mobileImages[index][data].url = response.data.url;
          contentObjectData.mobileImages[index][data].name = response.data.name;
          setFieldValue(`File${index}`, "");
          setFieldValue("ContentObject", contentObjectData);
          successToast("Media uploaded successfully.");
        }
      });
    };

  return (
    <Col sm="12" md="12" lg="12" className="p-0">
      {values.ContentObject?.mobileImages && values.ContentObject?.mobileImages?.length && (
        <FieldArray name="ContentObject.mobileImages">
          {({ push, remove, replace }) => (
            <>
              {values.ContentObject.mobileImages.length &&
                values.ContentObject.mobileImages.map((image, index) => {
                  const imageName = values["ContentObject"]["mobileImages"][index];
                  const touchedName = getIn(touched, imageName);
                  return (
                    <Col key={`image_card_container${index}`} style={{border : "1px solid #d3d3d3",borderRadius : "3px", padding : "5px",marginBottom : "5px"}}>
                      <Label for="ContentObject" style={{ color: "#4ce1b6", paddingLeft : "5px" }}>
                        Mobile Image {index + 1}
                      </Label>
                      <Row
                        className="align-items-center"
                        key={`image_card${index}`}
                      >
                        <Col
                          xs={!index ? 12 : 11}
                          sm={!index ? 12 : 11}
                          md={!index ? 12 : 11}
                        >
                          <CreatableSelect
                            name={imageName}
                            isMulti
                            placeholder="Image fields"
                            value={getFieldValue("mobileImages", imageName, index)}
                            onChange={(event) =>
                              onFieldChange(event, replace, index, image)
                            }
                            options={defaultImageField}
                            styles={styles}
                            className="my-2"
                            isClearable={false}
                            backspaceRemovesValue={false}
                            fieldErrors={errors.InsertImage}
                            fieldTouched={touchedName}
                          />
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          className="p-0"
                          style={{ display: !index ? "none" : "block" }}
                        >
                          <MinusCircleIcon
                            onClick={() => {
                              if (
                                values.ContentObject?.mobileImages &&
                                values.ContentObject?.mobileImages?.length === 1
                              ) {
                                remove(index);
                                push({
                                  url: "",
                                  name: "",
                                });
                              } else {
                                remove(index);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                      {Object.keys(image).map((data, imgIndex) => {
                          if (data === "image1" || data === "image2") {
                            return (
                              <div key={`Image${index}${imgIndex}`}>
                                <Label for="ContentObject" style={{ color: "#4ce1b6", paddingLeft : "20px" }}>
                                    {data === "image1" ? "Image 1" : "Image 2"}
                                </Label>
                                <Col md={12} key={imgIndex}>
                                   <Row>
                                    <Col>
                                      <img
                                        src={
                                          values.ContentObject.mobileImages &&
                                          values.ContentObject.mobileImages[index]?.[data] &&
                                          values.ContentObject.mobileImages[index][data].url
                                        }
                                        alt=""
                                        style={{
                                          marginBottom: "10px",
                                          maxHeight: "200px",
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <AsyncPaginates
                                        label="Choose from Existing"
                                        value={
                                          values.ContentObject.mobileImages[index][data]
                                            .name.length
                                            ? values.ContentObject.mobileImages[index][data]
                                            : values.ContentObject.mobileImages[index][data]
                                                .name
                                        }
                                        name="File"
                                        type="mobileImage"
                                        isMobileImage1={data === "image1" ? true : false}
                                        entity="CMS/Medias"
                                        allValues={values}
                                        index={index}
                                        setFieldValue={setFieldValue}
                                        fieldErrors={errors.File}
                                        fieldTouched={touched.File}
                                        isAllowToAddContent={true}
                                        handleBlur={handleBlur}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="align-items-center">
                                    <Col xs={5} sm={5} md={5} lg={5}>
                                      <Divider
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                      />
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2}>
                                      <h4>OR</h4>
                                    </Col>
                                    <Col xs={5} sm={5} md={5} lg={5}>
                                      <Divider
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <CustomInput
                                        label="File"
                                        name="File"
                                        type="file"
                                        handleChange={(e) => {
                                          setFieldValue(
                                            `File${index}`,
                                            e.target.files[0]
                                          );
                                        }}
                                        fieldErrors={errors.File}
                                        fieldTouched={touched.File}
                                        handleBlur={handleBlur}
                                      />
                                    </Col>
                                    <Col
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        disabled={
                                          !(
                                            values[`File${index}`] !== undefined
                                          ) ||
                                          values[`File${index}`] === "" ||
                                          isMediaAddLoading
                                        }
                                        color="success"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          uploadImage(
                                            setFieldValue,
                                            index,
                                            data,
                                            values
                                          );
                                        }}
                                      >
                                        {!(
                                          values[`File${index}`] !== undefined
                                        ) ||
                                          values[`File${index}`] === "" ||
                                          (isMediaAddLoading &&
                                            (currImageUploadButton || currImageUploadButton2) ===
                                              `File${index}` && (
                                              <Spinner
                                                size="sm"
                                                variant="light"
                                              />
                                            ))}{" "}
                                        Upload {data === "image1" ? " 1" : " 2"}
                                      </Button>
                                    </Col>
                                    <Col md={12} key={imgIndex}>
                                        <CustomInput
                                        label="Name"
                                        name="Name"
                                        fieldValue={values.ContentObject.mobileImages[index][data].name}
                                        handleBlur={handleBlur}
                                        handleChange={(e) => {
                                            const contentObjectData =
                                            values.ContentObject &&
                                            values.ContentObject;
                                            contentObjectData.mobileImages[index][data].name =
                                            e.target.value;
                                            setFieldValue(
                                            "ContentObject",
                                            contentObjectData
                                            );
                                        }}
                                        />
                                    </Col>
                                  </Row>
                                </Col>
                              </div>
                            );
                          } else {
                            return (
                              <Col md={12} key={imgIndex}>
                                <CustomInput
                                  label={data}
                                  name={data}
                                  fieldValue={
                                    values.ContentObject.mobileImages[index][data]
                                  }
                                  handleBlur={handleBlur}
                                  handleChange={(e) => {
                                    const contentObjectData =
                                      values.ContentObject &&
                                      values.ContentObject;
                                    contentObjectData.mobileImages[index][data] =
                                      e.target.value;
                                    setFieldValue(
                                      "ContentObject",
                                      contentObjectData
                                    );
                                  }}
                                />
                             </Col>
                            );
                          }
                        })}
                      </Row>
                    </Col>
                  );
                })}
              {values.ContentObject.mobileImages.length && (
                <div className="d-flex justify-content-end">
                  <Link
                    onClick={() => push({image1 :{ name: "", url: ""}, image2 :{ name: "", url: "" }, description : ""})}
                    className="mt-3 cursor-pointer"
                    style={{ color: "#4ce1b6" }}
                    size="sm"
                  >
                    + Add Mobile Image
                  </Link>
                </div>
              )}
            </>
          )}
        </FieldArray>
      )}
    </Col>
  )
}

export default InsertMobileImages
