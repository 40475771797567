import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { ENTITY_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const EntityTypeForm = ({ editData, setIsAddModalOpen }) => {
  const [addEntityType, { isLoading: isEntityTypeAddLoading }] =
    useAddMutation();
  const [updateEntityType, { isLoading: isEntityTypeUpdateLoading }] =
    useUpdateMutation();

  const entityTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    if (values.SortOrder === "") {
      delete values.SortOrder;
    } else {
      values.SortOrder = parseInt(values.SortOrder);
    }
    if (editData && Object.entries(editData).length > 0) {
      values.Id = editData.Id;
      updateEntityType({
        entity: "OrgReference/OrgEntityType",
        data: values,
        tag: ENTITY_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isEntityTypeUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Entity-type updated successfully.");
        }
      });
    } else {
      addEntityType({
        entity: "OrgReference/OrgEntityType",
        data: values,
        tag: ENTITY_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isEntityTypeAddLoading && setIsAddModalOpen(false);
          successToast("Entity-type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={entityTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              onClick={handleSubmit}
              disabled={isEntityTypeAddLoading || isEntityTypeUpdateLoading}
              className="modal_ok float-right"
              color="success"
            >
              {(isEntityTypeAddLoading || isEntityTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

EntityTypeForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default EntityTypeForm;
