import React from "react";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAddMutation } from "@/services/gamecodeXApi";
import { successToast } from "@/shared/components/toast";
import { SCREEN_TAG, SCREEN_THEME_TAG } from "@/shared/tagFile";
import AsyncPaginates from "@/shared/components/LazySelect";

const CloneScreenThemeForm = ({ parentData, setIsAddModalOpen, setShowScreenThemeTab }) => {
  const [addScreenClone, { isLoading: isScreenCloneAddLoading }] = useAddMutation();

  const formValidationSchema = Yup.object().shape({
    Theme: Yup.mixed().nullable(),
  }).test('at-least-one', null, function (values) {
    const { Theme: Theme } = values;
    if (!Theme) {
      return this.createError({
        path: 'Theme',
        message: 'Please select theme',
      });
    }
    return true;
  });

  const onSubmit = (values) => {
    const screenCloneDetails = {
      ThemeId: values?.Theme?.Id || 0,
      ...parentData
    };
    addScreenClone({
      entity: "CMS/CloneScreen",
      data: screenCloneDetails,
      tag: [SCREEN_TAG, SCREEN_THEME_TAG],
    }).then(response => {
      if (!response.error) {
        successToast("Screen cloned successfully.");
        setIsAddModalOpen()
        setShowScreenThemeTab(false)
      }
    })
  };

  return (
    <>
      <Formik
        initialValues={{
          Theme: "",
          Screen: parentData ? parentData : "",
        }}
        validationSchema={formValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          } = formik;
          return (
            <Form className="mt-2">
              <div className="border border-#dee2e6 rounded p-2 bg-light">
                <AsyncPaginates
                  value={values.Theme}
                  label="Theme"
                  name="Theme"
                  entity="CMS/Themes"
                  handleChange={(e) => {
                    const allData = values
                    allData.Name = ""
                    allData.Description = ""
                    allData.ThemeType = ""
                    allData.Theme = e
                    setFieldValue("values", allData)
                  }}
                  fieldErrors={errors.Theme}
                  fieldTouched={touched.Theme}
                  handleBlur={handleBlur}
                  required
                />
              </div>
              <AsyncPaginates
                value={parentData?.ScreenModel || parentData}
                label="Screen"
                name="Screen"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Screen}
                fieldTouched={touched.Screen}
                handleBlur={handleBlur}
                readonly={parentData && parentData}
              />
              <div className="modal_ok d-flex justify-content-end">
                <Button
                  onClick={handleSubmit}
                  className="modal_ok float-right"
                  color="success"
                >
                  {isScreenCloneAddLoading && (
                    <Spinner size="sm" variant="light" />
                  )} Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default CloneScreenThemeForm