import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import {
  useAddMutation,
  useUpdateMutation,
} from "@/services/gamecodeXApi";
import { ADDRESS_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const AddressForm = ({ editData, setIsAddModalOpen }) => {
    const [addAddress, { isLoading: isAddressAddLoading }] =
    useAddMutation();
  const [updateAddress, { isLoading: isAddressUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const AddressValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter Name."),
    Address: Yup.string().required("Please enter Address."),
    City: Yup.string().required("Please enter City."),
    State: Yup.string().required("Please enter State."),
    Country: Yup.string().required("Please enter Country."),
    ZipCode: Yup.string().required("Please enter ZipCode."),
  });

  const onSubmit = (values) => {
    const addressDetail = {
      Name: values.Name,
      Line: values.Address,
      City: values.City,
      State: values.State,
      Country: values.Country,
      ZipCode: values.ZipCode,
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      addressDetail.Id = editRecordDetail.Id;
      addressDetail.PipelineId = editRecordDetail.PipelineId;
      updateAddress({
        entity: "Doc/Address",
        data: addressDetail,
        tag: ADDRESS_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Address updated successfully.");
        }
      });
    } else {
      addAddress({
        entity: "Doc/Address",
        data: addressDetail,
        tag: ADDRESS_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Address added successfully.");
          setIsAddModalOpen(false );
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editRecordDetail?.Name || "",
        Address: editRecordDetail?.Line || "",
        City: editRecordDetail?.City || "",
        State: editRecordDetail?.State || "",
        Country: editRecordDetail?.Country || "",
        ZipCode: editRecordDetail?.ZipCode || "",
      }}
    onSubmit={onSubmit}
    validationSchema={AddressValidationSchema}
  >
    {(formik) => {
      const {
        errors,
        touched,
        values,
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
      } = formik;

      return (
        <Form>
          {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
          <CustomInput
            label="Name"
            name="Name"
            fieldErrors={errors.Name}
            fieldTouched={touched.Name}
            fieldValue={values.Name}
            handleBlur={handleBlur}
            handleChange={handleChange}
            required
          />
          <CustomInput
            label="Address"
            name="Address"
            fieldErrors={errors.Address}
            fieldTouched={touched.Address}
            fieldValue={values.Address}
            handleBlur={handleBlur}
            handleChange={handleChange}
            required
          />
          <CustomInput
            label="City"
            name="City"
            fieldErrors={errors.City}
            fieldTouched={touched.City}
            fieldValue={values.City}
            handleBlur={handleBlur}
            handleChange={handleChange}
            required
          />
          <CustomInput
            label="State"
            name="State"
            fieldErrors={errors.State}
            fieldTouched={touched.State}
            fieldValue={values.State}
            handleBlur={handleBlur}
            handleChange={handleChange}
            required
          />
          <CustomInput
            label="Country"
            name="Country"
            fieldErrors={errors.Country}
            fieldTouched={touched.Country}
            fieldValue={values.Country}
            handleBlur={handleBlur}
            handleChange={handleChange}
            required
          />
          <CustomInput
            label="Zip Code"
            name="ZipCode"
            fieldErrors={errors.ZipCode}
            fieldTouched={touched.ZipCode}
            fieldValue={values.ZipCode}
            handleBlur={handleBlur}
            handleChange={handleChange}
            required
          />
          <Button
            disabled={isAddressAddLoading || isAddressUpdateLoading}
            onClick={handleSubmit}
            className="modal_ok float-right"
            color="success"
          >
            {(isAddressAddLoading ||
              isAddressUpdateLoading ) && (
              <Spinner size="sm" variant="light" />
            )}{" "}
            Save
          </Button>
        </Form>
      );
    }}
  </Formik>
  )
}

export default AddressForm
