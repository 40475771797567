import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { USER } from "@/shared/userRoles";
import Loading from "@/shared/components/Loading";
import Layout from "../../../Layout/index";
import DataTable from "../../../Tables/DataTable";
import Account from "./Account";
import OrgRoutes from "../OrgRoutes";
import CMSRoutes from "../CMSRoutes";
import Dashboard from "@/components/Dashboard";
import CommRoutes from "../CommRoutes";
import ItemRoutes from "../ItemRoutes";
import DocRoutes from "../DocRoutes";

const WrappedRoutes = () => {
  const role = useSelector((state) => state.user.role);
  if (role === USER) {
    return <Redirect to="/" />;
  }
  return (
    <div>
      <Layout />
      {role ? (
        <div className="container__wrap">
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/tables/api_table" component={DataTable} />
          <Route path="/account" component={Account} />
          <Route path="/cms" component={CMSRoutes} />
          <Route path="/comm" component={CommRoutes} />
          <Route path="/org" component={OrgRoutes} />
          <Route path="/item" component={ItemRoutes} />
          <Route path="/doc" component={DocRoutes} />
        </div>
       ) : (
        <Loading loading={true} />
      )} 
    </div>
  );
};

export default WrappedRoutes;
