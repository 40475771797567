import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal, Spinner } from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../../shared/prop-types/ReducerProps";

const ModalComponent = ({
  color,
  title,
  message,
  colored,
  header,
  rtl,
  handleOkClick,
  toggle,
  isSuggetion = false,
  isDeleting,
  handleCancelClick
}) => {
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });
  const onCancelClick = () => {
    handleCancelClick();
    toggle()
  }

  return (
    <div>
      <Modal
        isOpen={true}
        toggle={toggle}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          {header ? "" : Icon}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">{message}</div>
        {!isSuggetion && (
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel" onClick={handleCancelClick ? onCancelClick : toggle}>
              {handleCancelClick ? 'No' : 'Cancel'}
            </Button>{" "}
            <Button
              className="modal_ok"
              outline={colored}
              color={color}
              onClick={handleOkClick}
              disabled={isDeleting}
            >
              {isDeleting && <Spinner size="sm" variant="light" />} {handleCancelClick ? 'Yes' : 'Ok'}
            </Button>
          </ButtonToolbar>
        )}
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  rtl: RTLProps.isRequired,
  handleOkClick: PropTypes.func,
  handleCancelClick: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  isSuggetion: PropTypes.bool,
};

ModalComponent.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(ModalComponent);
