import { createSlice } from "@reduxjs/toolkit";
const ItemSlice = createSlice({
  name: "item",
  initialState: {
    shortcode: [],
    shortcodetype: [],
  },
  reducers: {
    getAllShortCode: (state, action) => {
      state.shortcode = action.payload;
    },
    getAllShortCodeType: (state, action) => {
      state.shortcodetype = action.payload;
    },
  },
});

export default ItemSlice.reducer;
export const { getAllShortCode, getAllShortCodeType } = ItemSlice.actions;
