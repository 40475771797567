import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col } from "reactstrap";
import ReactTableBase from "../../../../shared/components/table/ReactTableBase";

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DataReactTable = ({
  reactTableData,
  onAddClick,
  onWizardClick,
  onArchive,
  title,
  isFetching,
  headerContent = true,
  setPageNumber,
  recordsPerPage,
  setRecordsPerPage,
  setSort,
  setGlobalSearchKeyword,
  setColumnFilter,
}) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [totalRecords, setTotalRecords] = useState(reactTableData.totalData);
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  useEffect(() => {
    setData(reactTableData.tableRowsData);
    setTotalRecords(reactTableData.totalData);
  }, [reactTableData]);

  const updateDraggableData = (result) => {
    const items = reorder(rows, result.source.index, result.destination.index);
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((item, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return item;
      })
    );
  };

  const tableConfig = {
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search",
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div className="card__title">
              <h5 className="bold-text">{title}</h5>
            </div>
          </div>
          <ReactTableBase
            key={withSearchEngine ? "modified" : "common"}
            headerContent={headerContent}
            columns={reactTableData.tableHeaderData}
            data={rows}
            totalRecords={totalRecords}
            updateEditableData={updateEditableData}
            updateDraggableData={updateDraggableData}
            tableConfig={tableConfig}
            onAddClick={onAddClick}
            onWizardClick={onWizardClick}
            onArchive={onArchive}
            isFetching={isFetching}
            setPageNumber={setPageNumber}
            recordsPerPage={recordsPerPage}
            setRecordsPerPage={setRecordsPerPage}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
            onEditClick={reactTableData.onEditClick}
            onDeleteClick={reactTableData.onDeleteClick}
            onDisplayDetailClick={reactTableData.onDisplayDetailClick}
            onCloneClick={reactTableData.onCloneClick}
            onRestoreClick={reactTableData.onRestoreClick}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setRecordsPerPage: PropTypes.func.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  setGlobalSearchKeyword: PropTypes.func,
  setColumnFilter: PropTypes.func,
  onAddClick: PropTypes.func,
};

export default DataReactTable;
