import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Switch } from "@material-ui/core";
import * as Yup from "yup";
import _ from 'lodash';
import { Button, Col, Row, Spinner, Input } from "reactstrap";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { THEME_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ThemeForm = ({ editData, parentId, isAddModalOpen, setIsAddModalOpen, setCurrentContent, setActiveTab }) => {
    const [addContent, { isLoading: isContentAddLoading }] = useAddMutation();
    const [updateContent, { isLoading: isContentUpdateLoading }] = useUpdateMutation();
    const [editRecordDetail, setEditRecordDetail] = useState(editData ? editData : null);
    const contentValidationSchema = Yup.object().shape({
        Name: Yup.string().required("Please enter name."),
        ThemeType: Yup.mixed().required("Please select theme-type."),
    });

    const onSubmit = (values) => {
        const contentDetail = {
            Name: values.Name,
            Description: values.Description,
            ThemeTypeId: values.ThemeType.Id,
            ContentObject: JSON.stringify(values.ContentObject),
        };
        if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
            contentDetail.Id = editRecordDetail.Id;
            updateContent({
                entity: "CMS/Theme",
                data: contentDetail,
                tag: THEME_TAG,
            }).then((response) => {
                if (!response.error) {
                    if (setActiveTab) {
                        setCurrentContent(contentDetail);
                        !isContentUpdateLoading && setActiveTab("2");
                    } else {
                        !isContentAddLoading && setIsAddModalOpen(false);
                    }
                    successToast("Theme updated successfully.");
                }
            });
        } else {
            addContent({
                entity: "CMS/Theme",
                data: contentDetail,
                tag: THEME_TAG,
            }).then((response) => {
                if (!response.error) {
                    setEditRecordDetail(response.data);
                    !isContentAddLoading && setIsAddModalOpen(false);
                    successToast("Theme added successfully.");
                }
            });
        }
    };

    const getContentObject = () => {
        if (editData && Object.entries(editData).length > 0) {
            const themeTypeDTO = JSON.parse(editData?.ThemeTypeDTO?.ContentObject);
            Object.entries(themeTypeDTO).forEach(data => {
                themeTypeDTO[data[0]] = data[1]?.value || data[1];
            });
            const editDataContentObject = JSON.parse(editData?.ContentObject);
            return JSON.stringify({ ...themeTypeDTO, ...editDataContentObject });
        }
    }

    return (
        <Formik
            initialValues={{
                Name: editRecordDetail && editRecordDetail.Name
                    ? editRecordDetail.Name
                    : "",
                Description:
                    editRecordDetail && editRecordDetail.Description
                        ? editRecordDetail.Description
                        : "",
                ThemeType:
                    editRecordDetail && editRecordDetail.ThemeTypeDTO
                        ? editRecordDetail.ThemeTypeDTO
                        : "",
                ContentObject:
                    editRecordDetail && editRecordDetail.ContentObject
                        ? JSON.parse(getContentObject())
                        : "",
            }}
            validationSchema={contentValidationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => {
                const { errors, touched, values, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;
                const contentObjectData =
                    values.ContentObject && values.ContentObject;
                if (contentObjectData.hasOwnProperty("fontShadow")) {
                    contentObjectData["isActive"] = values.ContentObject["isActive"] === undefined ? true : values.ContentObject["isActive"]
                }
                return (
                    <Form>
                        <Row className="mt-3" style={{ maxHeight: Object.keys(editData).length || values.ThemeType ? "590px" : "", height: Object.keys(editData).length || values.ThemeType ? "100%" : "", overflow: Object.keys(editData).length || values.ThemeType ? "auto" : "" }}>
                            <Col>
                                <Row>
                                    <Col sm={12} md={values.ContentObject?.images?.length || values.ContentObject?.lists?.length ? 6 : 12} lg={values.ContentObject?.images?.length || values.ContentObject?.lists?.length ? 4 : 6}>
                                        {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
                                        <CustomInput
                                            label="Name"
                                            name="Name"
                                            fieldErrors={errors.Name}
                                            fieldTouched={touched.Name}
                                            fieldValue={values.Name}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            required
                                        />
                                        <CustomInput
                                            label="Description"
                                            name="Description"
                                            type="textarea"
                                            fieldErrors={errors.Description}
                                            fieldTouched={touched.Description}
                                            fieldValue={values.Description}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                        />
                                        <AsyncPaginates
                                            value={values.ThemeType}
                                            label="Theme Type"
                                            name="ThemeType"
                                            entity="CMSReference/ThemeTypes"
                                            setFieldValue={setFieldValue}
                                            fieldErrors={errors.ThemeType}
                                            fieldTouched={touched.ThemeType}
                                            isAllowToAddContent={true}
                                            handleBlur={handleBlur}
                                            required
                                            handleEditRecords={editRecordDetail}
                                        />
                                        <Row className="align-items-center">
                                            {values?.ContentObject &&
                                                Object.keys(values.ContentObject).map((data, fieldIndex) => {
                                                    return (
                                                        <React.Fragment key={fieldIndex}>
                                                            {data !== "isActive" &&
                                                                <>
                                                                    <Col md={10} >
                                                                        <CustomInput
                                                                            label={_.startCase(data)}
                                                                            name={data}
                                                                            placeholder={_.startCase(data)}
                                                                            type={JSON.parse(values.ThemeType.ContentObject)[data]?.type || "text"}
                                                                            isDisabled={data === "fontShadow" && !values.ContentObject["isActive"]}
                                                                            fieldValue={values.ContentObject[data]}
                                                                            handleBlur={handleBlur}
                                                                            handleChange={(e) => {
                                                                                const contentObjectData =
                                                                                    values.ContentObject && values.ContentObject;
                                                                                contentObjectData[data] = e.target.value;
                                                                                setFieldValue("ContentObject", contentObjectData);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col className="mt-2">
                                                                        {data === "fontShadow" ?
                                                                            <Switch color="primary" checked={values.ContentObject["isActive"]} onChange={(e) => {
                                                                                const contentObjectData =
                                                                                    values?.ContentObject && values?.ContentObject;
                                                                                contentObjectData[data] = e.target.checked ? values?.ContentObject[data] : "";
                                                                                contentObjectData["isActive"] = e.target.checked
                                                                                setFieldValue("ContentObject", contentObjectData);
                                                                            }} />
                                                                            :
                                                                            <Input
                                                                                id="exampleColor"
                                                                                value={values?.ContentObject[data]}
                                                                                type="color"
                                                                                onChange={(e) => {
                                                                                    const contentObjectData =
                                                                                        values?.ContentObject && values?.ContentObject;
                                                                                    contentObjectData[data] = e.target.value;
                                                                                    setFieldValue("ContentObject", contentObjectData);
                                                                                }}
                                                                            />}
                                                                    </Col>
                                                                    {data === "fontShadow" && values.ContentObject["isActive"] &&
                                                                        <>
                                                                            <Col md={10} style={{ padding: "0px 15px" }}>
                                                                                <div style={{ border: "1px solid #ced4da", textAlign: "center", padding: "10px 0px", borderRadius: "0.25rem", textShadow: values.ContentObject[data] ? `2px 2px 0px ${values.ContentObject[data]}` : "", fontSize: "25px", color: values.ContentObject["title"] ? `${values.ContentObject["title"]}` : "#000" }}>Text Shadow</div>
                                                                            </Col>
                                                                            <Col>
                                                                                <Input
                                                                                    id="exampleColor"
                                                                                    value={values?.ContentObject[data]}
                                                                                    type="color"
                                                                                    onChange={(e) => {
                                                                                        const contentObjectData =
                                                                                            values?.ContentObject && values?.ContentObject;
                                                                                        contentObjectData[data] = e.target.value;
                                                                                        setFieldValue("ContentObject", contentObjectData);
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                        </>}
                                                                </>}
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </Row>
                                    </Col>
                                    <Col
                                        sm={12}
                                        lg={values.ContentObject?.images?.length || values.ContentObject?.lists?.length ? 4 : 6}
                                    >
                                        <CustomInput
                                            label="ContentObject"
                                            name="ContentObject"
                                            type="codeblock"
                                            fieldErrors={errors.ContentObject}
                                            fieldTouched={touched.ContentObject}
                                            fieldValue={
                                                values.ContentObject
                                                    ? (function () {
                                                        const { isActive, ...rest } = values.ContentObject;
                                                        return JSON.stringify(rest, null, 4);
                                                    })()
                                                    : JSON.stringify({})
                                            }
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            readonly
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-end mt-3">
                            <Button
                                disabled={
                                    isContentAddLoading ||
                                    isContentUpdateLoading
                                }
                                onClick={handleSubmit}
                                className="modal_ok float-right"
                                color="success"
                            >
                                {(isContentAddLoading || isContentUpdateLoading) && (
                                    <Spinner size="sm" variant="light" />
                                )}{" "}
                                Save
                            </Button>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

ThemeForm.propTypes = {
    editData: PropTypes.object,
    setIsAddModalOpen: PropTypes.func.isRequired,
    parentData: PropTypes.object,
    parentId: PropTypes.object,
};

export default ThemeForm;