import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import DownIcon from "mdi-react/ChevronDownIcon";
import Loading from "@/shared/components/Loading";
import { SUPER_ADMIN, ADMIN, USER } from "@/shared/userRoles";
import TopbarNavLink from "./TopbarNavLink";
import TopbarNavCategory from "./TopbarNavCategory";

const TopbarNavAdmin = () => {
  const role = useSelector((state) => state.user.role);
  const [displayComponent, setDisplayComponent] = useState({
    component: <Loading loading={true} />,
  });
  useEffect(() => {
    setDisplayComponent({ component: <Redirect to="/" /> });
  }, []);
  if (role === USER) {
    return <Redirect to="/" />;
  }

  return role && role.length > 0 &&
    (role === ADMIN || role === SUPER_ADMIN) ?
    <UncontrolledDropdown className="topbar__nav-dropdown">
      <DropdownToggle className="topbar__nav-dropdown-toggle">
        Data Admin <DownIcon />
      </DropdownToggle>
      <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
        <DropdownItem>
          <TopbarNavCategory title="Comm" icon="user">
            <TopbarNavCategory title="Answer" icon="user">
              <TopbarNavLink title="Answer" route="/comm/answer" />
              <TopbarNavLink title="Answer Type" route="/comm/answer-type" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Choice" icon="user">
              <TopbarNavLink title="Choice" route="/comm/choice" />
              <TopbarNavLink title="Choice Type" route="/comm/choice-type" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Entity Response" icon="user">
              <TopbarNavLink
                title="Entity Response"
                route="/comm/entity-response"
              />
              <TopbarNavLink
                title="Entity Response Type"
                route="/comm/entity-response-type"
              />
            </TopbarNavCategory>
            <TopbarNavLink title="Form Response" route="/comm/form-response" />
            <TopbarNavCategory title="Option" icon="user">
              <TopbarNavLink title="Option" route="/comm/option" />
              <TopbarNavLink title="Option Type" route="/comm/option-type" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Question" icon="user">
              <TopbarNavLink title="Question" route="/comm/question" />
              <TopbarNavLink
                title="Question Option"
                route="/comm/question-option"
              />
              <TopbarNavLink title="Question Type" route="/comm/question-type" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Response" icon="user">
              <TopbarNavLink title="Response" route="/comm/response" />
              <TopbarNavLink title="Response Type" route="/comm/response-type" />
            </TopbarNavCategory>
            <DropdownItem>
              <TopbarNavCategory title="Comm" icon="user">
                <TopbarNavLink title="Survey" route="/comm/survey" />
                <TopbarNavLink
                  title="Survey Contact"
                  route="/comm/survey-contact"
                />
                <TopbarNavLink
                  title="Survey Question"
                  route="/comm/survey-question"
                />
                <TopbarNavLink title="Survey Type" route="/comm/survey-type" />
              </TopbarNavCategory>
            </DropdownItem>
          </TopbarNavCategory>
        </DropdownItem>
        <DropdownItem>
          <TopbarNavCategory title="Org" icon="user" isNew>
            <TopbarNavCategory title="Contact" icon="user" isNew>
              <TopbarNavLink title="Contact" route="/org/contact" />
              <TopbarNavLink title="Contact Type" route="/org/contact-type" />
            </TopbarNavCategory>
            {role === SUPER_ADMIN && (
              <>
              <TopbarNavCategory title="Entity" icon="user">
                <TopbarNavLink title="Entity" route="/org/entity" />
                <TopbarNavLink title="Entity Type" route="/org/entity-type" />
              </TopbarNavCategory>
              <TopbarNavLink title="User" icon="user" route="/org/user" />
              <TopbarNavCategory title="Role" icon="user">
                <TopbarNavLink title="Role" route="/org/role" />
                <TopbarNavLink title="Role Type" route="/org/role-type" />
              </TopbarNavCategory>
              </>
            )}
          </TopbarNavCategory>
        </DropdownItem>
        <DropdownItem>
          <TopbarNavCategory title="Item" icon="user" isNew>
            <TopbarNavLink title="Short Code Type" route="/item/short-code-type" />
            <TopbarNavLink title="Short Code" route="/item/short-code" />
          </TopbarNavCategory>
        </DropdownItem>
        <DropdownItem>
          <TopbarNavCategory title="CMS" icon="user">
            <TopbarNavCategory title="Applications" icon="user">
              <TopbarNavLink title="Application" route="/cms/application" />
              <TopbarNavLink
                title="Application Type"
                route="/cms/application-type"
              />
            </TopbarNavCategory>
            <TopbarNavCategory title="Screens" icon="user">
              <TopbarNavLink title="Screen" route="/cms/screen" />
              <TopbarNavLink title="Screen Type" route="/cms/screen-type" />
              <TopbarNavLink
                title="Application Screen"
                route="/cms/application-screen"
              />
              <TopbarNavLink
                title="Screen Mapping"
                route="/cms/screen-mapping"
              />
            </TopbarNavCategory>
            <TopbarNavLink
              title="Component"
              route="/cms/component"
              icon="user"
            />
            <TopbarNavCategory title="Media" icon="user">
              <TopbarNavLink title="Media" route="/cms/media" />
              <TopbarNavLink title="Media Type" route="/cms/media-type" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Contents" icon="user">
              <TopbarNavLink title="Content" route="/cms/content" />
              <TopbarNavLink
                title="Screen Content"
                route="/cms/screen-content"
              />
              <TopbarNavLink
                title="Application Content"
                route="/cms/application-content"
              />
              <TopbarNavLink title="Content Type" route="/cms/content-type" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Menu" icon="user">
              <TopbarNavLink title="Menu" route="/cms/menu" />
              <TopbarNavLink title="Menu Type" route="/cms/menu-type" />
              <TopbarNavLink title="Application Menu" route="/cms/application-menu" />
              <TopbarNavLink title="Screen Menu" route="/cms/screen-menu" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Tag" icon="user">
              <TopbarNavLink title="Tag" route="/cms/tag" />
              <TopbarNavLink title="Tag Type" route="/cms/tag-type" />
              <TopbarNavLink title="Screen Tag" route="/cms/screen-tag" />
              <TopbarNavLink title="Content Tag" route="/cms/content-tag" />
            </TopbarNavCategory>
            <TopbarNavLink
              title="App Version"
              route="/cms/app-version"
              icon="user"
            />
            <TopbarNavLink
              title="Application Referers"
              route="/cms/application-referers"
              icon="user"
            />
            <TopbarNavCategory title="Theme" icon="user">
              <TopbarNavLink title="Theme Type" route="/cms/theme-type" />
              <TopbarNavLink title="Theme" route="/cms/theme" />
              <TopbarNavLink title="Screen Theme" route="/cms/screen-theme" />
            </TopbarNavCategory>
          </TopbarNavCategory>
        </DropdownItem>
        <DropdownItem>
          <TopbarNavCategory title="Doc" icon="user">
            <TopbarNavLink title="Action" route="/doc/action" />
            <TopbarNavLink title="Address" route="/doc/address" />
            <TopbarNavCategory title="Clause" icon="user">
              <TopbarNavLink
                title="Clause Type"
                route="/doc/clause-type"
              />
              <TopbarNavLink title="Clause" route="/doc/clause" />
              <TopbarNavLink
                title="Clause Modify Request"
                route="/doc/clause-modify-request"
              />
            </TopbarNavCategory>
            <TopbarNavCategory title="Contract" icon="user">
              <TopbarNavLink title="Contract" route="/doc/contract" />
              <TopbarNavLink
                title="Contract Type"
                route="/doc/contract-type"
              />
              <TopbarNavLink
                title="Contract Clause"
                route="/doc/contract-clause"
              />
              <TopbarNavLink
                title="Contract Media"
                route="/doc/contract-media"
              />
            </TopbarNavCategory>
            <TopbarNavCategory title="Doc Media" icon="user">
              <TopbarNavLink title="Doc Media" route="/doc/doc-media" />
              <TopbarNavLink
                title="Doc Media Type"
                route="/doc/doc-media-type"
              />
            </TopbarNavCategory>
            <TopbarNavCategory title="Role" icon="user">
              <TopbarNavLink title="Role Type" route="/doc/role-type" />
            </TopbarNavCategory>
            <TopbarNavCategory title="Counter Party" icon="user">
              <TopbarNavLink
                title="Counter Party"
                route="/doc/counter-party"
              />
              <TopbarNavLink
                title="CounterParty Action"
                route="/doc/counter-party-action"
              />
            </TopbarNavCategory>
          </TopbarNavCategory>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    : displayComponent.component;
}

export default TopbarNavAdmin;
