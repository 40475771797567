import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Label, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import CustomInput from "@/shared/components/form/CustomInput";
import { AsyncPaginate } from "react-select-async-paginate";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import {
  SCREEN_TAG,
  APPLICATION_SCREEN_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const SEOForm = ({
  value,
  editData,
  setActiveTab,
  cloneScreen,
  setCurrentScreenId,
  setShowScreenThemeTab
}) => {

  const [addScreen, { isLoading: isScreenAddLoading }] = useAddMutation();
  const [updateScreen, { isLoading: isScreenUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(Object.values(editData).length ? editData : false);
  const ScreenValue = value ? value : editData

  const isDontedOptions = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ]

  const onSubmit = (values, { resetForm }) => {
    if (Object.values(ScreenValue).length === 0) {
      setActiveTab("1")
    }
    if (ScreenValue?.ScreenType.Id === 3) setShowScreenThemeTab(true)
    if (values) {
      const screenDetail = {
        Name: ScreenValue?.Name || "",
        Description: ScreenValue?.Description || "",
        ScreenTypeId: ScreenValue?.ScreenType.Id || "",
        Path: ScreenValue?.Path || "",
        MetaTitle: values.MetaTitle || ScreenValue?.Name || "",
        MetaDescription: values.MetaDescription || ScreenValue.Description || "",
        MetaViewport: values.MetaViewport,
        MetaCharset: values.MetaCharset,
        IsIndex: values.IsIndex.value,
        IsFollow: values.IsFollow.value,
      };
      if (!cloneScreen) {
        if (editRecordDetail) {
          screenDetail.Id = editRecordDetail.Id;
          updateScreen({
            entity: "CMS/Screen",
            data: screenDetail,
            tag: [SCREEN_TAG, APPLICATION_SCREEN_TAG],
          }).then((response) => {
            if (!response.error) {
              setCurrentScreenId(screenDetail)
              setActiveTab("3")
              successToast("Screen updated successfully.");
            }
          });
        } else {
          addScreen({
            entity: "CMS/Screen",
            data: screenDetail,
            tag: [SCREEN_TAG, APPLICATION_SCREEN_TAG],
          }).then((response) => {
            if (!response.error) {
              setCurrentScreenId(response.data)
              setActiveTab("3")
              successToast("Screen added successfully.");
            }
          });
        }
      } else {
        screenDetail.Id = editRecordDetail.Id;
        setCurrentScreenId(screenDetail)
        setActiveTab("3")
      }
    }
  };

  return (
    <Formik
      initialValues={{
        MetaTitle: editRecordDetail?.MetaTitle || ScreenValue?.Name || "",
        MetaDescription: editRecordDetail?.MetaDescription || ScreenValue.Description || "",
        MetaViewport:
          editRecordDetail && editRecordDetail.MetaViewport
            ? editRecordDetail.MetaViewport
            : "",
        MetaCharset:
          editRecordDetail && editRecordDetail.MetaCharset
            ? editRecordDetail.MetaCharset
            : "utf-8",
        IsIndex:
          editRecordDetail && Object.entries(editRecordDetail)?.length
            ? {
              label: editRecordDetail?.IsIndex ? "Yes" : "No",
              value: editRecordDetail?.IsIndex,
            }
            : "",
        IsFollow:
          editRecordDetail && Object.entries(editRecordDetail)?.length
            ? {
              label: editRecordDetail?.IsFollow ? "Yes" : "No",
              value: editRecordDetail?.IsFollow,
            }
            : "",
      }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Meta Title"
              name="MetaTitle"
              fieldErrors={errors.MetaTitle}
              fieldTouched={touched.MetaTitle}
              fieldValue={values?.MetaTitle}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="MetaDescription"
              name="MetaDescription"
              fieldErrors={errors.MetaDescription}
              fieldTouched={touched.MetaDescription}
              fieldValue={values?.MetaDescription}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="MetaViewport"
              name="MetaViewport"
              fieldErrors={errors.MetaViewport}
              fieldTouched={touched.MetaViewport}
              fieldValue={values.MetaViewport}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="MetaCharset"
              name="MetaCharset"
              fieldErrors={errors.MetaCharset}
              fieldTouched={touched.MetaCharset}
              fieldValue={values.MetaCharset}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="mb-3">
              <Label for="IsIndex">
                IsIndex
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="IsIndex"
                value={values.IsIndex || isDontedOptions[0]}
                options={isDontedOptions}
                placeholder={`Select IsIndex`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("IsIndex", e)}
              />
            </div>
            <div className="mb-3">
              <Label for="IsFollow">
                IsFollow
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="IsFollow"
                value={values.IsFollow || isDontedOptions[0]}
                options={isDontedOptions}
                placeholder={`Select IsFollow`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("IsFollow", e)}
              />
            </div>
            <Button
              disabled={
                isScreenAddLoading ||
                isScreenUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isScreenAddLoading ||
                isScreenUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              {!cloneScreen ? "Save" : "Next"}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

SEOForm.propTypes = {
  value: PropTypes.object,
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.bool,
  parentId: PropTypes.number,
};

export default SEOForm;
