import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { SCREEN_TAG_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ScreenTagForm from "./ScreenTagForm";
import { getAllScreenTags } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ScreenTag = () => {
    const [screenTagDetails, setScreenTagDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteScreenTag, { isLoading: isDeleting }] =
      useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [
      getScreenTagRecords,
      {
        data: screenTagList,
        isError: isScreenTagError,
        isLoading: isScreenTagLoading,
        isFetching: isScreenTagFetching,
      },
    ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Tag Name",
        accessor: "TagDTO.Name",
        filter: true,
      },
      {
        Header: "Screen Name",
        accessor: "ScreenDTO.Name",
        filter: true,
      },
    ];
  
    const handleEditData = (screenTagData) => {
      setSelectedRecord(screenTagData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteScreenTag({
        entity: "CMS/ScreenTag",
        id: selectedRecord.Id,
        tag: SCREEN_TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Screen-tag deleted successfully.");
        }
      });
    };
  
    useEffect(() => {
      if (location.state && location.state?.name) {
        setColumnFilter({
          [`${location.state.title.split(" ").join("")}.Name`]:
            location.state.name,
        });
      }
      history.push({ pathname: location.pathname, state: {} });
      // eslint-disable-next-line
    }, []);
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
  
      getScreenTagRecords({
        entity: "CMS/ScreenTags",
        entities: { Screen: {}, Tag: {} },
        top: pageSize,
        skip: (page - 1) * pageSize,
        sort: sort,
        filter: filterQuery,
        globalFilter: {
          columns: filterColumn,
          globalSearchKeyword: globalSearchKeyword,
        },
        tag: SCREEN_TAG_TAG,
      });
      setScreenTagDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);
  
    useEffect(() => {
      if (
        !isScreenTagLoading &&
        !isScreenTagError &&
        screenTagList &&
        !isScreenTagFetching
      ) {
        dispatch(getAllScreenTags(screenTagList.value));
        let screenTagData = [...screenTagList.value];
        setScreenTagDetails({
          ...screenTagDetails,
          tableRowsData: screenTagList.value ? screenTagData : [],
          totalData:
            screenTagList && screenTagList["@odata.count"]
              ? screenTagList["@odata.count"]
              : 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
          },
        });
      }
      // eslint-disable-next-line
    }, [
      isScreenTagError,
      screenTagList,
      isScreenTagLoading,
      isScreenTagFetching,
    ]);
  
    return (
      <Container>
        <Row>
          {screenTagDetails && (
            <DataReactTable
              reactTableData={screenTagDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              title="Tag Screen"
              isFetching={isScreenTagFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Screen Tag`}
            header
            btn="Success"
            message={
              <ScreenTagForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsAddModalOpen={setIsAddModalOpen}
              />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={`Are you sure you want to delete?`}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    );
}

export default ScreenTag
