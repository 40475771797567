import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import { useLazyGetRecordsQuery, useDeleteMutation } from "@/services/gamecodeXApi";
import { SCREEN_THEME_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ScreenThemeForm from "./ScreenThemeForm";
import { getAllScreenThemes } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ScreenTheme = () => {
    const [screenThemeDetails, setScreenThemeDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteScreenTheme, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [
        getScreenThemeRecords,
        {
            data: screenThemeList,
            isError: isScreenThemeError,
            isLoading: isScreenThemeLoading,
            isFetching: isScreenThemeFetching,
        },
    ] = useLazyGetRecordsQuery();

    const column = [
        {
            Header: "#",
            accessor: "Id",
            type: "Number",
        },
        {
            Header: "Screen Name",
            accessor: "ScreenDTO.Name",
            filter: true,
        },
        {
            Header: "Theme Name",
            accessor: "ThemeDTO.Name",
            filter: true,
        },
    ];

    const handleEditData = (screenThemeData) => {
        setSelectedRecord(screenThemeData);
        setIsAddModalOpen(true);
    };

    const handleDelete = () => {
        deleteScreenTheme({
            entity: "CMS/ScreenTheme",
            id: selectedRecord.Id,
            tag: SCREEN_THEME_TAG,
        }).then((response) => {
            if (!response.error) {
                !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
                successToast("Screen-theme deleted successfully.");
            }
        });
    };

    useEffect(() => {
        if (location.state && location.state?.name) {
            setColumnFilter({
                [`${location.state.title.split(" ").join("")}.Name`]:
                    location.state.name,
            });
        }
        history.push({ pathname: location.pathname, state: {} });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let { filterColumn, filterQuery } = formateColumnFilter(
            column,
            columnFilter
        );

        getScreenThemeRecords({
            entity: "CMS/ScreenThemes",
            entities: { Theme: {}, Screen: {} },
            top: pageSize,
            skip: (page - 1) * pageSize,
            // sort: sort,
            filter: filterQuery,
            globalFilter: {
                columns: filterColumn,
                globalSearchKeyword: globalSearchKeyword,
            },
            tag: SCREEN_THEME_TAG,
        });
        setScreenThemeDetails({ tableHeaderData: column, tableRowsData: [] });
        // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

    useEffect(() => {
        if (
            !isScreenThemeLoading &&
            !isScreenThemeError &&
            screenThemeList &&
            !isScreenThemeFetching
        ) {
            dispatch(getAllScreenThemes(screenThemeList.value));
            let screenThemeData = [...screenThemeList.value];
            setScreenThemeDetails({
                ...screenThemeDetails,
                tableRowsData: screenThemeList.value ? screenThemeData : [],
                totalData:
                    screenThemeList && screenThemeList["@odata.count"]
                        ? screenThemeList["@odata.count"]
                        : 0,
                onEditClick: (data) => handleEditData(data),
                onDeleteClick: (data) => {
                    setIsDeleteModalOpen(true);
                    setSelectedRecord(data);
                },
            });
        }
        // eslint-disable-next-line
    }, [
        isScreenThemeError,
        screenThemeList,
        isScreenThemeLoading,
        isScreenThemeFetching,
    ]);
    return (
        <>
            <Container>
                <Row>
                    {screenThemeDetails && (
                        <DataReactTable
                            reactTableData={screenThemeDetails}
                            onAddClick={() => {
                                setIsAddModalOpen(true);
                                setSelectedRecord();
                            }}
                            title="Screen Theme"
                            isFetching={isScreenThemeFetching}
                            setPageNumber={setPage}
                            recordsPerPage={pageSize}
                            setRecordsPerPage={setPageSize}
                            setSort={setSort}
                            setGlobalSearchKeyword={setGlobalSearchKeyword}
                            setColumnFilter={setColumnFilter}
                        />
                    )}
                </Row>
                {isAddModalOpen && (
                    <AddModal
                        toggle={() => setIsAddModalOpen(!isAddModalOpen)}
                        color="success"
                        title={`${selectedRecord ? "Edit" : "Add"} Screen Theme`}
                        header
                        btn="Success"
                        message={
                            <ScreenThemeForm
                                editData={selectedRecord ? selectedRecord : {}}
                                setIsAddModalOpen={setIsAddModalOpen}
                            />
                        }
                    />
                )}
                {isDeleteModalOpen && (
                    <Modal
                        color="danger"
                        title="Delete"
                        message={`Are you sure you want to delete?`}
                        handleOkClick={() => handleDelete()}
                        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                        isDeleting={isDeleting}
                    />
                )}
            </Container>
        </>
    )
}

export default ScreenTheme