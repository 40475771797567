import { createSlice } from "@reduxjs/toolkit";

const commSlice = createSlice({
    name: "comm",
    initialState: {
        answers: [],
        answerTypes: [],
        choices: [],
        choiceTypes: [],
        entityResponses: [],
        entityResponseTypes: [],
        options: [],
        optionTypes: [],
        questions: [],
        questionOptions: [],
        questionTypes: [],
        responses: [],
        responseTypes: [],
        surveys: [],
        surveyContacts: [],
        surveyQuestions: [],
        surveyTypes: [],
    },
    reducers: {
        getAllAnswers: (state, action) => {
            state.answers = action.payload;
        },
        getAllAnswerTypes: (state, action) => {
            state.answerTypes = action.payload;
        },
        getAllChoices: (state, action) => {
            state.choices = action.payload;
        },
        getAllChoiceTypes: (state, action) => {
            state.choiceTypes = action.payload;
        },
        getAllEntityResponses: (state, action) => {
            state.entityResponses = action.payload;
        },
        getAllEntityResponseTypes: (state, action) => {
            state.entityResponseTypes = action.payload;
        },
        getAllOptions: (state, action) => {
            state.options = action.payload;
        },
        getAllOptionTypes: (state, action) => {
            state.optionTypes = action.payload;
        },
        getAllQuestions: (state, action) => {
            state.questions = action.payload
        },
        getAllQuestionOptions: (state, action) => {
            state.questionOptions = action.payload
        },
        getAllQuestionTypes: (state, action) => {
            state.questionTypes = action.payload
        },
        getAllResponses: (state, action) =>{
            state.responses = action.payload
        },
        getAllResponseTypes: (state, action) =>{
            state.responseTypes = action.payload
        },
        getAllSurveys: (state, action) => {
            state.surveys = action.payload
        },
        getAllSurveyContacts: (state, action) => {
            state.surveyContacts = action.payload;
        },
        getAllSurveyQuestions: (state, action) => {
            state.surveyQuestions = action.payload;
        },
        getAllSurveyTypes: (state, action) => {
            state.surveyTypes = action.payload
        },
    },
});

export default commSlice.reducer;
export const {
    getAllAnswers,
    getAllAnswerTypes,
    getAllChoices,
    getAllChoiceTypes,
    getAllEntityResponses,
    getAllEntityResponseTypes,
    getAllOptions,
    getAllOptionTypes,
    getAllQuestions,
    getAllQuestionOptions,
    getAllQuestionTypes,
    getAllResponses,
    getAllResponseTypes,
    getAllSurveys,
    getAllSurveyContacts,
    getAllSurveyQuestions,
    getAllSurveyTypes,
} = commSlice.actions