import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import moment from "moment/moment";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { useLazyGetRecordsQuery } from "@/services/gamecodeXApi";
import { COUNTER_PARTY_ACTIONS_TAG } from "@/shared/tagFile";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllApplicationReferers } from "../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ApplicationReferer = () => {
    const [applicationRefererDetails, setApplicationRefererDetails] = useState();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const [
      getApplicationRefererRecords,
      {
        data: applicationRefererList,
        isError: isApplicationRefererError,
        isLoading: isApplicationRefererLoading,
        isFetching: isApplicationRefererFetching,
      },
  ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Email",
        accessor: "Email",
        filter: true,
      },
      {
        Header: "Referred From",
        accessor: "RefererURL",
        filter: true,
      },
      {
        Header: "Referred To",
        accessor: "TargetURL",
        filter: true,
      },
      {
        Header: "Referred Time",
        accessor: "RefererTime",
        filter: true,
        Cell: ({ value }) => {
          return value ? moment(value).format("YYYY-MM-DD") : "-";
        },
      },
    ];
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
      getApplicationRefererRecords({
        entity: "CMSReference/ApplicationReferers",
        top: pageSize,
        skip: (page - 1) * pageSize,
        sort: sort,
        filter: filterQuery,
        globalFilter: {
          columns: filterColumn,
          globalSearchKeyword: globalSearchKeyword,
        },
        tag: COUNTER_PARTY_ACTIONS_TAG,
      });
      setApplicationRefererDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);
  
    useEffect(() => {
      if (!isApplicationRefererLoading && !isApplicationRefererError && applicationRefererList && !isApplicationRefererFetching) {
        dispatch(getAllApplicationReferers(applicationRefererList.value));
        let applicationRefererData = [...applicationRefererList.value];
        setApplicationRefererDetails({
          ...applicationRefererDetails,
          tableRowsData: applicationRefererList.value ? applicationRefererData : [],
          totalData:
            applicationRefererList && applicationRefererList["@odata.count"] ? applicationRefererList["@odata.count"] : 0,
        });
      }
      // eslint-disable-next-line
    }, [isApplicationRefererError, applicationRefererList, isApplicationRefererLoading, isApplicationRefererFetching]);
  
  return (
    <Container>
      <Row>
        {applicationRefererDetails && (
          <DataReactTable
            reactTableData={applicationRefererDetails}
            title="Application Referers"
            isFetching={isApplicationRefererFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
    </Container>
  )
}

export default ApplicationReferer
