import React from "react";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAddMutation } from "@/services/gamecodeXApi";
import { successToast } from "@/shared/components/toast";
import { SCREEN_TAG, APPLICATION_SCREEN_TAG } from "@/shared/tagFile";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";

const CloneApplicationScreenForm = ({ parentData, setIsAddModalOpen, setActiveTab, setCurrentScreenId }) => {
  const [addScreenClone, { isLoading: isScreenCloneAddLoading }] = useAddMutation();
  const applicationValidationSchema = Yup.object().shape({
    Name: Yup.string().nullable(),
    ApplicationType: Yup.mixed().nullable(),
    Application: Yup.mixed().nullable(),
  }).test('at-least-one', null, function (values) {
    const { Name, Application, ApplicationType } = values;
    if (!Name && !ApplicationType && !Application) {
      return this.createError({
        path: 'Application',
        message: 'Please select Application',
      });
    }
    if (!Name && ApplicationType) {
      return this.createError({
        path: 'Name',
        message: 'Please enter application name.',
      });
    }
    if (Name && !ApplicationType) {
      return this.createError({
        path: 'ApplicationType',
        message: 'Please select application Type.',
      });
    }
    return true;
  });
  
  const onSubmit = (values) => {
    const screenCloneDetails = {
      ApplicationId: values?.Application?.Id || 0,
      ScreenId: parentData.id || parentData.Id,
      ScreenModel: {
        ScreenTypeId: parentData.ScreenTypeId,
        Name: parentData.Name,
        Description: parentData.Description,
        Path: parentData.Path,
        MetaTitle: parentData.MetaTitle,
        MetaDescription: parentData.MetaDescription,
        MetaViewport: parentData.MetaViewport,
        MetaCharset: parentData.MetaCharset,
      },
      ...(!(values?.Application?.Id) && {
        ApplicationModel: values?.Name ? {
          name: values?.Name,
          applicationTypeId: values?.ApplicationType?.Id,
          description: values?.Description
        } : ""
      })
    };
    if (parentData?.ScreenTypeId === 3) {
      setCurrentScreenId(screenCloneDetails)
      setActiveTab("4")
    } else {
      addScreenClone({
        entity: "CMS/CloneScreen",
        data: screenCloneDetails,
        tag: [SCREEN_TAG, APPLICATION_SCREEN_TAG],
      }).then(response => {
        if (!response.error) {
          successToast("Screen cloned successfully.");
          setIsAddModalOpen()
        }
      })
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          Name: "",
          Description: "",
          ApplicationType: "",
          Application: "",
          Screen: parentData ? parentData.ScreenModel : "",
        }}
        validationSchema={applicationValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          } = formik;
          return (
            <Form className="mt-2">
              <div className="border border-#dee2e6 rounded p-2 bg-light">
                <CustomInput
                  label="Application Name"
                  name="Name"
                  fieldErrors={errors.Name}
                  fieldTouched={touched.Name}
                  fieldValue={values.Name}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    const allData = values
                    allData.Name = e.target.value
                    allData.Application = ""
                    setFieldValue("values", allData)
                  }}
                  required
                />
                <CustomInput
                  label="Description"
                  name="Description"
                  type="textarea"
                  fieldErrors={errors.Description}
                  fieldTouched={touched.Description}
                  fieldValue={values.Description}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    const allData = values
                    allData.Description = e.target.value
                    allData.Application = ""
                    setFieldValue("values", allData)
                  }}
                />
                <AsyncPaginates
                  value={values.ApplicationType}
                  label="Application Type"
                  name="ApplicationType"
                  entity="CMSReference/ApplicationTypes"
                  handleChange={(e) => {
                    const allData = values
                    allData.ApplicationType = e
                    allData.Application = ""
                    setFieldValue("values", allData)
                  }}
                  fieldErrors={errors.ApplicationType}
                  fieldTouched={touched.ApplicationType}
                  handleBlur={handleBlur}
                  required
                />
                <hr />
                <div className="text-center">OR</div>
                <hr />
                <AsyncPaginates
                  value={values.Application}
                  label="Application"
                  name="Application"
                  entity="CMS/Applications"
                  handleChange={(e) => {
                    const allData = values
                    allData.Name = ""
                    allData.Description = ""
                    allData.ApplicationType = ""
                    allData.Application = e
                    setFieldValue("values", allData)
                  }}
                  fieldErrors={errors.Application}
                  fieldTouched={touched.Application}
                  handleBlur={handleBlur}
                  required
                />
              </div>
              <AsyncPaginates
                value={parentData?.ScreenModel || parentData}
                label="Screen"
                name="Screen"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Screen}
                fieldTouched={touched.Screen}
                handleBlur={handleBlur}
                readonly={parentData && parentData}
              />
              <div className="modal_ok d-flex justify-content-end">
                <Button
                  onClick={handleSubmit}
                  className="modal_ok float-right"
                  color="success"
                >
                  {parentData?.ScreenTypeId !== 3 && isScreenCloneAddLoading && (
                    <Spinner size="sm" variant="light" />
                  )}
                  {parentData?.ScreenTypeId === 3 ? `Next` : `Save`}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default CloneApplicationScreenForm
