import React from "react";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import { FieldArray } from "formik";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import ArrowUpCircleIcon from "mdi-react/ArrowUpCircleIcon";
import ArrowDownCircleIcon from "mdi-react/ArrowDownCircleIcon";
import { Divider, Link } from "@material-ui/core";
import CustomInput from "@/shared/components/form/CustomInput";

const svgContent = `<svg width="20" height="23" viewBox="0 0 20 23" fill="#8aebcf" xmlns="http://www.w3.org/2000/svg" color="green" class="sc-ywFzA gDEryD"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.02477 14.7513C8.65865 17.0594 11.6046 18.6059 17.5596 18.8856C18.6836 18.9384 19.5976 19.8435 19.5976 20.9688V20.9688C19.5976 22.0941 18.6841 23.0125 17.5599 22.9643C10.9409 22.6805 6.454 20.9387 3.75496 17.1258C0.937988 13.1464 0.486328 7.39309 0.486328 0.593262H4.50974C4.50974 7.54693 5.06394 11.9813 7.02477 14.7513Z"></path></svg>`
const encodedUri = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgContent)))}`;

const subListStyle = {
  height : "15px" , 
  width : "18px" , 
  zIndex : 2, 
  left : "-1.5rem", 
  top : "-0.55rem" , 
  position : "absolute"
}

const InsertMenuList = ({
  menuData,
  formik,
  styles,
  getFieldValue,
  onFieldChange,
  isSubMenu,
  parentIndex = null,
  ThirdLavel = false,
  mainParentIndex = null
}) => {
  const { values, handleBlur, setFieldValue } = formik;

  const listStype = {borderLeft : `4px solid${isSubMenu ? (ThirdLavel ? " #d2f8ed" : " #9ff1da")  : " #8aebcf"}`}

  return (
    <Col sm="12" md="12" lg="12">
      {values?.ContentObject?.images && values?.ContentObject?.lists && (
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        )}
      <Row>
        {menuData && menuData?.length && (
          <Col>
            <FieldArray name="ContentObject.lists">
              {({ push }) => (
                <>
                  {menuData?.length &&
                    menuData.map((list, index) => {
                      return (
                        <div key={`list_container${index}`}  style={{paddingLeft :  "15px" ,marginLeft  :"0.3rem"}}>
                          <Row
                            className="align-items-center"
                            key={`list${index}`}
                            style={{display : "flex" , justifyContent : "space-between"}}
                          >
                            {_.has(list, "name") && (
                              <div style={{position:"relative"}}>

                                {(isSubMenu === true && (parentIndex >= 0 || ThirdLavel === true)) && 
                                <div style={subListStyle}>
                                  <img src= {encodedUri} alt = "my SVG 1"  />
                                </div>}

                                <Col className="my-1">
                                  <h5
                                    style={{
                                      color: "#8aebcf",
                                    }}
                                  >
                                    {`${isSubMenu ? "Sub ":""} Menu ${parentIndex===null ? index + 1 : ThirdLavel ? (mainParentIndex+1).toString() + "." + ((parentIndex+1.1) + (index*0.1)).toLocaleString().toString() : ((parentIndex+1.1) + (index*0.1)).toLocaleString()}`}
                                  </h5>
                                </Col>
                              </div>
                            )}
                            <Col
                              xs={3}
                              sm={3}
                              md={3}
                              className="p-0 d-flex"
                              style={{ marginRight: "0.5rem" , justifyContent: "flex-end"}}
                            >
                              <ArrowUpCircleIcon 
                                onClick={() => {
                                  const contentObjectData = values.ContentObject
                                  if (isSubMenu) {
                                    if (ThirdLavel) {
                                      contentObjectData.lists[mainParentIndex].subMenu[parentIndex].subMenu.splice(index-1, 0, 
                                        ...contentObjectData.lists[mainParentIndex].subMenu[parentIndex].subMenu.splice(index,1)
                                      );
                                    } else {
                                      contentObjectData.lists[parentIndex]["subMenu"].splice(index-1, 0, 
                                        ...contentObjectData.lists[parentIndex]["subMenu"].splice(index,1)
                                      );
                                    }
                                  } else if(!isSubMenu && menuData.length) {
                                    contentObjectData.lists.splice(index-1, 0, 
                                      ...contentObjectData.lists.splice(index,1)
                                    );
                                  }
                                  setFieldValue("ContentObject", contentObjectData);
                                }}
                                style={{
                                  display: !index ? "none" : "block",
                                  cursor: "pointer",
                                }}
                              />
                              <ArrowDownCircleIcon 
                                onClick={() => {
                                  const contentObjectData = values.ContentObject
                                  if (isSubMenu) {
                                    if (ThirdLavel) {
                                      contentObjectData.lists[mainParentIndex].subMenu[parentIndex].subMenu.splice(index+1, 0, 
                                        ...contentObjectData.lists[mainParentIndex].subMenu[parentIndex].subMenu.splice(index,1)
                                      );
                                    } else {
                                      contentObjectData.lists[parentIndex]["subMenu"].splice(index+1, 0, 
                                        ...contentObjectData.lists[parentIndex]["subMenu"].splice(index,1)
                                      );
                                    }
                                  } else if(!isSubMenu && menuData.length) {
                                    contentObjectData.lists.splice(index+1, 0, 
                                      ...contentObjectData.lists.splice(index,1)
                                    );
                                  }
                                  setFieldValue("ContentObject", contentObjectData);
                                }}
                                style={{
                                  display: menuData?.length-1 === index ? "none" : "block",
                                  cursor: "pointer",
                                }}
                              />
                              <PlusCircleIcon 
                                onClick={() => {
                                  const contentObjectData = values.ContentObject
                                  if (isSubMenu) {
                                    if (ThirdLavel) {
                                      contentObjectData.lists[mainParentIndex].subMenu[parentIndex].subMenu.splice(index, 0, { name: "", path: "", href: "", subMenu: [] });
                                    } else {
                                      contentObjectData.lists[parentIndex]["subMenu"].splice(index, 0, { name: "", path: "", href: "", subMenu: [] });
                                    }
                                  } else if(!isSubMenu && menuData.length) {
                                    contentObjectData.lists.splice(index, 0, { name: "", path: "", href: "", subMenu: [] });
                                  }
                                  setFieldValue("ContentObject", contentObjectData);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                              <MinusCircleIcon
                                onClick={() => {
                                  const contentObjectData = values.ContentObject
                                  if (isSubMenu) {
                                    if (ThirdLavel) {
                                      contentObjectData.lists[mainParentIndex].subMenu[parentIndex].subMenu.splice(index, 1);
                                    } else {
                                      contentObjectData.lists[parentIndex]["subMenu"].splice(index, 1);
                                    }
                                  } else {
                                    contentObjectData.lists.splice(index, 1);
                                  }
                                  setFieldValue("ContentObject", contentObjectData);
                                }}
                                style={{
                                  display: !index && !isSubMenu && menuData.length === 1 ? "none" : "block",
                                  cursor: "pointer",
                                }}
                              />
                            </Col>
                          </Row>
                          <Row style={{border : "1px solid #d3d3d3" , borderRadius : "5px" , paddingBottom : "5px", ...listStype }}>
                            {Object.entries(list).map(
                              ([data, value], listIndex) => {
                                let newMenuData = values.ContentObject;
                                    const id = parentIndex===null ? index + 1 : ((parentIndex+1.1) + (index*0.1)).toLocaleString()
                                    const len = id.toString().split(".").length
                                    switch (len) {
                                      case 1:
                                          newMenuData = values.ContentObject.lists[index]["subMenu"]
                                        break;
                                      case 2:
                                          newMenuData = values.ContentObject.lists[parentIndex]?.["subMenu"]?.[index]?.["subMenu"]
                                        break;
                                      default:
                                        break;
                                    }

                                return (
                                  <React.Fragment
                                    key={`List${index}${listIndex}`}
                                  >
                                    {typeof value === "string" ? (
                                      <Col md={12} key={listIndex}>
                                        <CustomInput
                                          label={data}
                                          name={data}
                                          fieldValue={value}
                                          handleBlur={handleBlur}
                                          handleChange={(e) => {
                                            const contentObjectData = values.ContentObject;
                                            parentIndex === null 
                                              ? (contentObjectData.lists[index][data] = e.target.value) 
                                              : ThirdLavel !== true 
                                                ? (contentObjectData.lists[parentIndex].subMenu[index][data] = e.target.value)
                                                : (contentObjectData.lists[mainParentIndex].subMenu[parentIndex].subMenu[index][data] = e.target.value)
                                            setFieldValue(
                                              "ContentObject",
                                              contentObjectData
                                            );
                                          }}
                                          className="m-0 p-0"
                                          style={{height: `calc(1.5em + 0.3rem + 2px)`}}
                                          labelClassName="my-1"
                                        />
                                      </Col>
                                    ) : value.length ? (
                                      <InsertMenuList
                                        parentIndex={index}
                                        formik={formik}
                                        menuData={newMenuData}
                                        styles={styles}
                                        getFieldValue={getFieldValue}
                                        onFieldChange={onFieldChange}
                                        isSubMenu={true}
                                        mainParentIndex={parentIndex}
                                        ThirdLavel={isSubMenu && true}
                                      />
                                    ) : null}
                                  </React.Fragment>
                                );
                              }
                            )}
                            {ThirdLavel !== true && menuData.length && (
                              <div className="d-flex justify-content-end ml-2">
                                <Link
                                  onClick={() => {
                                    const contentObjectData = values.ContentObject;
                                    const id = parentIndex===null ? index + 1 : ((parentIndex+1.1) + (index*0.1)).toLocaleString()
                                    const len = id.toString().split(".").length
                                    switch (len) {
                                      case 1:
                                        contentObjectData.lists[index]["subMenu"].push({ name: "", path: "", href: "", subMenu: []});
                                        break;
                                      case 2:
                                          contentObjectData.lists[parentIndex]["subMenu"][index]["subMenu"].push({ name: "", path: "", href: "", subMenu: []});
                                        break;
                                      default:
                                        break;
                                    }
                                    setFieldValue(
                                      "ContentObject",
                                      contentObjectData
                                    );
                                  }}
                                  className="mt-2 pl-2 cursor-pointer"
                                  style={{ color: "#4ce1b6" }}
                                  size="sm"
                                >
                                  {`+ Sub Menu for ${parentIndex===null ? index + 1 : ((parentIndex+1.1) + (index*0.1)).toLocaleString()}`}
                                </Link>
                              </div>
                            )}
                          </Row>
                        </div>
                      );
                    })}
                  {!isSubMenu && menuData.length && (
                    <div className="d-flex justify-content-end">
                      <Link
                        onClick={() =>
                          push({ name: "", path: "", href: "", subMenu: [] })
                        }
                        className="mt-3 cursor-pointer"
                        style={{ color: "#4ce1b6" }}
                        size="sm"
                      >
                        + Add List
                      </Link>
                    </div>
                  )}
                </>
              )}
            </FieldArray>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default InsertMenuList;
