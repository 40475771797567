import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { OPTION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const OptionForm = ({ editData, setIsAddModalOpen, parentId, parentData }) => {
  const [addOption, { isLoading: isOptionAddLoading }] = useAddMutation();
  const [updateOption, { isLoading: isOptionUpdateLoading }] =
    useUpdateMutation();

  const optionValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    OptionType: Yup.mixed().required("Please select option type."),
  });

  const onSubmit = (values) => {
    let optionDetail = {
      Name: values.Name,
      Description: values.Description,
      PlaceholderText: values.PlaceholderText,
      OptionTypeId: values.OptionType.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      optionDetail.Id = editData.Id;
      updateOption({
        entity: "Option",
        isWogdApi: true,
        data: optionDetail,
        tag: OPTION_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Option updated successfully.");
        }
      });
    } else {
      addOption({
        entity: "Option",
        isWogdApi: true,
        data: optionDetail,
        tag: OPTION_TAG,
      }).then((response) => {
        if (!response.error) {
          !isOptionAddLoading && setIsAddModalOpen(false);
          successToast("Option added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        PlaceholderText:
          editData && editData.PlaceholderText ? editData.PlaceholderText : "",
        OptionType:
          editData && editData.OptionType
            ? editData.OptionType
            : parentData && parentData.OptionType
            ? parentData.OptionType
            : "",
      }}
      validationSchema={optionValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Placeholder Text"
              name="PlaceholderText"
              fieldErrors={errors.PlaceholderText}
              fieldTouched={touched.PlaceholderText}
              fieldValue={values.PlaceholderText}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.OptionType}
              label="Option Type"
              name="OptionType"
              entity="OptionType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.OptionType}
              fieldTouched={touched.OptionType}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isOptionAddLoading || isOptionUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isOptionAddLoading || isOptionUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

OptionForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
  parentData: PropTypes.object,
  parentId: PropTypes.object,
};

export default OptionForm;
