import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";

const ScreenForm = ({
  editData,
  activeTab,
  setActiveTab,
  setScreenValues,
}) => {
  const editRecordDetail = editData;
  const screenValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    ScreenType: Yup.mixed().required("Please select screen-type."),
    Path: Yup.string().notOneOf(['/mobile'], "Please enter different path").nullable(true),
  });
  const formRef = useRef()
  const onSubmit = (values) => {
    setActiveTab("2");
    setScreenValues(values)
  }
  useEffect(() => {
    if (activeTab === "2" && formRef.current) {
      formRef.current.handleSubmit()
    }
    return () => setScreenValues({})
  }, [activeTab]);

  return (
    <Formik
      initialValues={{
        Name:
          editRecordDetail && editRecordDetail.Name
            ? editRecordDetail.Name
            : "",
        Description:
          editRecordDetail && editRecordDetail.Description
            ? editRecordDetail.Description
            : "",
        ScreenType:
          editRecordDetail && editRecordDetail.ScreenTypeDTO
            ? editRecordDetail.ScreenTypeDTO
            : "",
        Path:
          editRecordDetail && editRecordDetail.Path
            ? editRecordDetail.Path
            : "",
      }}
      validationSchema={screenValidationSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Path"
              name="Path"
              fieldErrors={errors.Path}
              fieldTouched={touched.Path}
              fieldValue={values.Path}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.ScreenType}
              label="Screen Type"
              name="ScreenType"
              entity="CMSReference/ScreenTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.ScreenType}
              fieldTouched={touched.ScreenType}
              handleBlur={handleBlur}
              required
            />
            <Button
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              Next
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ScreenForm.propTypes = {
  editData: PropTypes.object,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  setScreenValues: PropTypes.func
};

export default ScreenForm;
