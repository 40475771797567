import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import { useLazyGetRecordsQuery, useDeleteMutation } from "@/services/gamecodeXApi";
import { THEME_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ThemeForm from "./ThemeForm";
import { getAllTheme } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const Themes = () => {
    const [themeDetails, setThemeDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteTheme, { isLoading: isDeleting }] = useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [
        getThemeRecords,
        {
            data: themeList,
            isError: isThemeError,
            isLoading: isThemeLoading,
            isFetching: isThemeFetching,
        },
    ] = useLazyGetRecordsQuery();
    
    const column = [
        {
            Header: "#",
            accessor: "Id",
            type: "Number",
        },
        {
            Header: "Name",
            accessor: "Name",
            filter: true,
        },
        {
            Header: "Description",
            accessor: "Description",
            filter: true,
            Cell: ({ value }) => {
                return value ? value : "-";
            },
        },
        {
            Header: "Theme Type",
            accessor: "ThemeTypeDTO.Name",
            filter: true,
            Cell: ({ value }) => {
                return value ? value : "-";
            },
        },
        {
            Header: "ContentObject",
            accessor: "ContentObject",
            filter: true,
            Cell: ({ value }) => {
                return value ? 
                <ul>
                    {Object.entries(JSON.parse(value))?.map(([keys, values], index) => {
                        return (<div key={keys}>{keys !== "isActive" ? <li> {keys}</li> : ""}</div>)
                    })}
                </ul>
                  : "-";
            },
        },
    ];

    const handleEditData = (themeData) => {
        setSelectedRecord(themeData);
        setIsAddModalOpen(true);
    };

    const handleDelete = () => {
        deleteTheme({
            entity: "CMS/Theme",
            id: selectedRecord.Id,
            tag: THEME_TAG,
        }).then((response) => {
            if (!response.error) {
                !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
                successToast("Theme deleted successfully.");
            }
        });
    };

    useEffect(() => {
        if (location.state && location.state?.name) {
            setColumnFilter({
                [`${location.state.title.split(" ").join("")}.Name`]:
                    location.state.name,
            });
        }
        history.push({ pathname: location.pathname, state: {} });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let { filterColumn, filterQuery } = formateColumnFilter(
            column,
            columnFilter
        );
        getThemeRecords({
            entity: "CMS/Themes",
            entities: { ThemeType: {} },
            top: pageSize,
            skip: (page - 1) * pageSize,
            // sort: sort,
            filter: filterQuery,
            globalFilter: {
                columns: filterColumn,
                globalSearchKeyword: globalSearchKeyword,
            },
            tag: THEME_TAG,
        });
        setThemeDetails({ tableHeaderData: column, tableRowsData: [] });
        // eslint-disable-next-line
    }, [pageSize, page, globalSearchKeyword, columnFilter]);

    useEffect(() => {
        if (!isThemeLoading && !isThemeError && themeList && !isThemeFetching) {
            dispatch(getAllTheme(themeList.value));
            let themeData = [...themeList.value];
            setThemeDetails({
                ...themeDetails,
                tableRowsData: themeList.value ? themeData : [],
                totalData:
                    themeList && themeList["@odata.count"] ? themeList["@odata.count"] : 0,
                onEditClick: (data) => handleEditData(data),
                onDeleteClick: (data) => {
                    setIsDeleteModalOpen(true);
                    setSelectedRecord(data);
                },
            });
        }
        // eslint-disable-next-line
    }, [isThemeError, themeList, isThemeLoading, isThemeFetching]);

    const deleteMessage = () => {
        return (
            <div>
                Are you sure you want to delete {selectedRecord.Name}?
                <div className="warning-div">
                    <i>
                        This will delete all the associated records <br />
                        ( Like - Screen Theme)
                    </i>
                </div>
            </div>
        );
    }

    return (
        <Container>
            <Row>
                {themeDetails && (
                    <DataReactTable
                        reactTableData={themeDetails}
                        onAddClick={() => {
                            setIsAddModalOpen(true);
                            setSelectedRecord();
                        }}
                        title="Theme"
                        isFetching={isThemeFetching}
                        setPageNumber={setPage}
                        recordsPerPage={pageSize}
                        setRecordsPerPage={setPageSize}
                        setSort={setSort}
                        setGlobalSearchKeyword={setGlobalSearchKeyword}
                        setColumnFilter={setColumnFilter}
                    />
                )}
            </Row>
            {isAddModalOpen && (
                <AddModal
                    toggle={() => setIsAddModalOpen(!isAddModalOpen)}
                    color="success"
                    xxxl
                    title={`${selectedRecord ? "Edit" : "Add"} Theme`}
                    header
                    btn="Success"
                    message={
                        <ThemeForm
                            editData={selectedRecord ? selectedRecord : {}}
                            setIsAddModalOpen={setIsAddModalOpen}
                        />
                    }
                />
            )}
            {isDeleteModalOpen && (
                <Modal
                    color="danger"
                    title="Delete"
                    message={deleteMessage()}
                    handleOkClick={() => handleDelete()}
                    toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                    isDeleting={isDeleting}
                />
            )}
        </Container>
    );
};

export default Themes;