import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import {
  useAddMutation,
  useUpdateMutation,
} from "@/services/gamecodeXApi";
import { CONTRACT_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const DocMediaTypeForm = ({ editData, setIsAddModalOpen }) => {
  const [addDocMediaType, { isLoading: isDocMediaTypeAddLoading }] =
    useAddMutation();
  const [updateDocMediaType, { isLoading: isDocMediaTypeUpdateLoading }] =
    useUpdateMutation();

  const clauseTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    if (editData && Object.entries(editData).length > 0) {
      values.Id = editData.Id;
      updateDocMediaType({
        entity: "DocReference/DocMediaType",
        data: values,
        tag: CONTRACT_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDocMediaTypeUpdateLoading && setIsAddModalOpen(false);
          successToast("DocMedia-type updated successfully.");
        }
      });
    } else {
      addDocMediaType({
        entity: "DocReference/DocMediaType",
        data: values,
        tag: CONTRACT_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDocMediaTypeAddLoading && setIsAddModalOpen(false);
          successToast("DocMedia-type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
      }}
      validationSchema={clauseTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={
                isDocMediaTypeAddLoading || isDocMediaTypeUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isDocMediaTypeAddLoading ||
                isDocMediaTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

DocMediaTypeForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default DocMediaTypeForm;
