//Catalog
export const PRODUCT_TAG = "product_tag";
export const PRODUCT_TYPE_TAG = "product_type_tag";
//Comm
export const ANSWER_TAG = "answer_tag";
export const ANSWER_TYPE_TAG = "answer_type_tag";
export const CHOICE_TAG = "choice_tag";
export const CHOICE_TYPE_TAG = "choice_type_tag";
export const OPTION_TAG = "option_tag";
export const OPTION_TYPE_TAG = "option_type_tag";
export const QUESTION_TAG = "question_tag";
export const QUESTION_OPTION_TAG = "question_option_tag";
export const QUESTION_TYPE_TAG = "question_type_tag";
export const RESPONSE_TAG = "response_tag";
export const RESPONSE_TYPE_TAG = "response_type_tag";
export const SURVEY_TAG = "survey_tag";
export const SURVEY_CONTACT_TAG = "survey_contact_tag";
export const SURVEY_QUESTION_TAG = "survey_question_tag";
export const SURVEY_TYPE_TAG = "survey_type_tag";
export const FORM_RESPONSE = "form-response";
//Dice 
export const COLOR_TAG = "color_tag";
export const COLOR_GROUP_TAG = "color_group_tag";
export const COLOR_PATTERN_TAG = "color_pattern_tag";
export const DIE_TAG = "die_tag";
export const DICE_ATTACHMENT_TAG = "dice_attachment_tag";
export const DIE_MEDIA_TAG = "die_media_tag";
export const DIE_TYPE_TAG = "die_type_tag";
export const FACE_LAYOUT_TAG = "face_layout_tag";
export const ENTITY_MEDIA_TAG = "entity_media_tag";
export const FACE_SHAPE_TAG = "face_shape_tag";
export const FACE_TYPE_TAG = "face_type_tag";
export const GEOMETRY_TYPE_TAG = "geometry_type_tag";
export const MATERIAL_TAG = "material_tag";
export const MATERIAL_TYPE_TAG = "material_type_tag";
export const PIP_TYPE_TAG = "pip_type_tag";
export const PRODUCT_LINE_TAG = "product_line_tag";
export const PRODUCT_MODEL_TAG = "product_model_tag";
export const SET_TAG = "set_tag";
export const SET_MEDIA_TAG = "set_media_tag";
export const SET_TYPE_TAG = "set_type_tag";
export const SET_TYPE_DIE_TYPE_TAG = "set_type_die_type_tag";
export const SHAPE_TAG = "shape_type_tag";
export const SHAPE_TYPE_TAG = "shape_type_tag";
export const STYLE_TAG = "style_tag"
export const STYLE_TYPE_TAG = "style_type_tag"
export const STYLE_STYLE_TYPE_TAG = "style_style_type_tag"
//Item
//ShortCode
export const SHORT_CODE_TAG = "short_code_tag"
export const SHORT_CODE_TYPE_TAG = "short_code_type_tag"

//Game
//Avatar
export const AVATAR_TAG = "avatar_tag";
export const AVATAR_DOCUMENT_TAG = "avatar_document_tag";
export const AVATAR_ITEM_TAG = "avatar_item_tag";
export const AVATAR_SKILL_TAG = "avatar_skill_tag";
export const AVATAR_STATE_TAG = "avatar_state_tag";
export const AVATAR_ROLE_TAG = "avatar_role_tag";
export const AVATAR_CLASS_TAG = "avatar_class_tag";
export const AVATAR_SPECIES_TAG = "avatar_species_tag";
export const AVATAR_LANGUAGE_TAG = "avatar_language_tag";
export const AVATAR_TRAITS_TAG = "avatar_traits_tag";
export const AVATAR_BOND_TAG = "avatar_bond_tag";
export const AVATAR_FLAW_TAG = "avatar_flaw_tag";
export const AVATAR_IDEAL_TAG = "avatar_ideal_tag";
export const AVATAR_SAVING_THROW_TAG = "avatar_saving_throw_tag";
export const AVATAR_SKILL_ATTRIBUTE_TAG = "avatar_skill_attribute_tag";
//Campaign
export const CAMPAIGN_TAG = "campaign_tag";
export const CAMPAIGN_PRODUCT_TAG = "campaign_product_tag";
export const CAMPAIGN_LOGO_STATUS_TAG = "campaign_logo_status_tag";
export const DEFAULT_CAMPAIGN_LOGO_TAG = "default_campaign_logo_tag";
export const CAMPAIGN_LOGO_TAG = "campaign_logo_tag";
export const CAMPAIGN_CONTENT_TAG = "campaign_content_tag";
//GameItem
export const GAME_ITEM_TAG = "game_item_tag";
//GameSkill
export const GAME_SKILL_TAG = "game_skill_tag";
//GameState
export const GAME_STATE_TAG = "game_state_tag";
//GameSystem
export const GAME_SYSTEM_TAG = "game_system_tag";
export const GAME_SYSTEM_MEDIA_TAG = "game_system_media_tag";
//Product Line
export const GAME_PRODUCT_LINE_TAG = "game_product_line_tag";
export const GAME_MEDIA_PRODUCT_LINE_TAG = "game_media_product_line_tag";
export const GAME_PRODUCT_LINE_TYPE_TAG = "game_product_line_type_tag";
//Player
export const PLAYER_TAG = "player_tag";
export const PLAYER_AVATAR_TAG = "player_avatar_tag";
export const PLAYER_ROLE_TAG = "player_role_tag";
export const PLAYER_MEDIA_TAG = "player_media_tag";
//Role
export const GAME_ROLE_TAG = "game_role_tag";
//Session
export const GAME_SESSION_TAG = "game_session_tag";
//Org
//Contact
export const CONTACT_TAG = "contact_tag";
export const CONTACT_TYPE_TAG = "contact_type_tag";
//Entity
export const ENTITY_TAG = "entity_tag";
export const ENTITY_TYPE_TAG = "entity_type_tag";
export const ORG_ENTITY_MEDIA_TAG = "org_entity_media_tag";
export const ENTITY_MEDIA_TYPE_TAG = "entity_media_type_tag";
//EntityRelation
export const ENTITY_RELATION_TAG = "entity_relation_tag";
export const ENTITY_RELATION_TYPE_TAG = "entity_relation_type_tag";
//EntitySkills
export const ENTITY_SKILLS_TAG = "entity_skills_tag";
//EntityResponse
export const ENTITY_RESPONSE_TAG = "entity_response_tag";
export const ENTITY_RESPONSE_TYPE_TAG = "entity_response_type_tag";
//EntityLocation
export const ENTITY_LOCATION_TAG = "entity_location_tag";
export const ENTITY_LOCATION_TYPE_TAG = "entity_location_type_tag";
export const ENTITY_PRODUCT_TAG = "entity_product_tag";
//Location
export const LOCATION_TAG = "location_tag";
export const LOCATION_TYPE_TAG = "location_type_tag";
//Skills
export const SKILLS_TAG = "skills_tag";
//Role
export const ROLE_TAG = "role_tag";
export const AUTH_ROLE_TAG = "auth_role_tag";
export const ROLE_TYPE_TAG = "role_type_tag";
export const AUTH_ROLE_TYPE_TAG = "auth_role_type_tag";
//User
export const USER_TAG = "user_tag";
export const USER_TYPE_TAG = "user_type_tag";
export const EVENT_USER_TAG = "event_user_tag";
export const TRIGGER_PIPELINE = 'trigger_pipeline';
//Site
//Application
export const APPLICATION_TYPE_TAG = "application_type_tag";
export const APPLICATION_TAG = "application_tag";
//Component
export const COMPONENT_TAG = "component_tag";
//Content
export const CONTENT_TYPE_TAG = "content_type_tag";
export const CONTENT_TAG = "content_tag";
export const ZONE_CONTENT_TAG = "zone_content_tag";
export const CONTENT_LOCATION_TAG = "content_location_tag"
export const CONTENT_MEDIA_TAG = "content_media_tag";
export const CONTENT_MENU_TAG = "content_menu_tag";
export const APPLICATION_CONTENT_TAG = "app_content_tag";
export const APPVERSION_TAG = "appversion_tag";
//Content Template
export const CONTENT_TEMPLATE_TAG = "content_template_tag";
export const CONTENT_TEMPLATE_TYPE_TAG = "content_template_type_tag";
//Media
export const SITE_MEDIA_TAG = "site_media_tag";
//Menu
export const MENU_ITEM_TAG = "menu_item_tag";
export const MENU_ITEM_TYPE_TAG = "menu_item_type_tag";
export const MENU_TYPE_TAG = "menu_type_tag";
export const MENU_TAG = "menu_tag";
export const MENU_MEDIA_TAG = "menu_media_tag";
export const APPLICATION_MENU_TAG = "application_menu_tag";
export const SCREEN_MENU_TAG = "screen_menu_tag";
//Role
export const SITE_ROLE_TAG = "site_role_tag";
//Screen
export const SCREEN_TYPE_TAG = "screen_type_tag";
export const SCREEN_SKIN_TAG = "screen_skin_tag";
export const SCREEN_TAG = "screen_tag";
export const SKIN_TAG = "skin_tag";
export const APPLICATION_SCREEN_TAG = "app_screen_tag";
export const SCREEN_CONTENT_TAG = "screen_content_tag";
//Tag
export const TAG_TYPE_TAG = "tag_type_tag";
export const TAG_TAG = "tag_tag";
export const SCREEN_TAG_TAG = "screen_tag_tag";
export const SCREEN_MAPPING_TAG = "screen_mapping_tag";
export const CONTENT_TAG_TAG = "content_tag_tag";
//Zone
export const ZONE_TYPE_TAG = "zone_type_tag";
export const ZONE_TAG = "zone_tag";
export const SCREEN_ZONE_TAG = "screen_zone_tag";
export const ZONE_MEDIA_TAG = "zone_media_tag";
export const ZONE_CONTENT_MEDIA_TAG = "zone_content_media_tag";
export const ZONE_MYCOLLECTION_TAG = "zone_mycollection_tag";
export const ZONE_MYDIE_TAG = "zone_mydie_tag";
export const ZONE_MYSET_TAG = "zone_myset_tag";
export const ZONE_MENU_TAG = "zone_menu_tag";
//System
//dnd5e
//Alignment
export const ALIGNMENT_TAG = "alignment_tag";
export const AVATAR_ALIGNMENT_TAG = "avatar_alignment_tag";
//Attribute
export const ATTRIBUTE_TAG = "attribute_tag";
export const AVATAR_ATTRIBUTE_TAG = "avatar_attribute_tag";
export const SKILL_ATTRIBUTE_TAG = "skill_attribute_tag";
//Background
export const BACKGROUND_TAG = "background_tag";
//Bond
export const BOND_TAG = "bond_tag";
//class
export const CLASS_TAG = "class_tag";
//character
export const CHARACTER_TAG = "character_tag";
//Feat
export const FEAT_TAG = "feat_tag";
//Flaw
export const FLAW_TAG = "flaw_tag";
//Ideal
export const IDEAL_TAG = "ideal_tag";
//Skill
export const SKILL_TAG = "skill_tag";
//species
export const SPECIES_TAG = "species_tag";
export const SPECIES_ATTRIBUTE_TAG = "species_attribute_tag";
//species language
export const SPECIES_LANGUAGE_TAG = "species_language_tag";
//Trait
export const TRAIT_TAG = "trait_tag";
//lookup
export const DND5E_LOOKUP_TAG = "dnd5e_lookup_tag"
export const LOOKUP_ABILITY_MODIFIER_TAG = "lookup_ability_modifier_tag";
export const LOOKUP_CLASS_WEALTH_TAG = "lookup_class_wealth_tag";
//Evolutionary
// Attribute
export const EVOLUTIONARY_ATTRIBUTE_TAG = "evolutionary_attribute_tag";
export const EVOLUTIONARY_AVATAR_ATTRIBUTE_TAG = "evolutionary_avatar_attribute_tag";
//Skill
export const EVOLUTIONARY_SKILL_TAG = "evolutionary_skill_tag";
export const EVOLUTIONARY_SKILL_ATTRIBUTE_TAG = "evolutionary_skill_attribute_tag";
//Item
export const EVOLUTIONARY_ITEM_TAG = "evolutionary_item_tag";
export const EVOLUTIONARY_ITEM_ATTRIBUTE_TAG = "evolutionary_item_attribute_tag";
//Lookup
export const EVOLUTIONARY_LOOKUP_TAG = "evolutionary_lookup_tag";
//Swade
//Attribute
export const SWADE_ATTRIBUTE_TAG = "swade_attribute_tag";
export const SWADE_AVATAR_ATTRIBUTE_TAG = "swade_avatar_attribute_tag";
export const SWADE_SKILL_TAG = "swade_skill_tag";
export const SWADE_SKILL_ATTRIBUTE_TAG = "swade_skill_attribute_tag";
export const SWADE_GEAR_TAG = "swade_gear_tag";
export const SWADE_AVATAR_GEAR_TAG = "swade_avatar_gear_tag";
export const SWADE_WOUND_TAG = "swade_wound_tag";
export const SWADE_BACKGROUND_TAG = "swade_background_tag"
export const SWADE_POWER_TAG = "swade_power_tag";
export const SWADE_AVATAR_POWER_TAG = "swade_avatar_power_tag";
export const SWADE_CHARACTER_TAG = "swade_character_tag";
export const SWADE_WEAPON_TAG = "swade_weapon_tag";
export const SWADE_AVATAR_WEAPON_TAG = "swade_avatar_weapon_tag";
export const SWADE_EDGE_TAG = "swade_edge_tag";
export const SWADE_LOOKUP_TAG = "swade_lookup_tag";
export const SWADE_FATIGUE_TAG = "swade_fatigue_tag";
export const SWADE_HINDRANCE_TAG = "seade_hindrance_tag";
//PathFinder
//Attribute
export const PATHFINDER_ATTRIBUTE_TAG = "pathfinder_attribute_tag";
export const PATHFINDER_AVATAR_ATTRIBUTE_TAG =
  "pathfinder_avatar_attribute_tag";
export const PATHFINDER_LOOKUP_TAG = "pathfinder_lookup_tag";
//Traveller
export const TRAVELLER_MAGAZINE_TAG = "traveller_magazine_tag";
export const TRAVELLER_AVATAR_MAGAZINE_TAG = "traveller_avatar_magazine_tag";
export const TRAVELLER_ARMOUR_TAG = "traveller_armour_tag";
export const TRAVELLER_AUGMENT_TAG = "traveller_augment_tag";
export const TRAVELLER_CHARACTER_TAG = "traveller_character_tag";
export const TRAVELLER_LOOKUP_TAG = "traveller_lookup_tag";
export const TRAVELLER_WEAPON_TAG = "traveller_weapon_tag";
export const TRAVELLER_AVATAR_WEAPON_TAG = "traveller_avatar_weapon_tag";
//Global
//Event
export const EVENT_TAG = "event_tag";
export const EVENT_TYPE_TAG = "event_type_tag";
export const UNIT_OF_MEASURE_TAG = "unit_of_measure_tag";
export const SCALE_TAG = "scale_tag";
//map
export const CELL_TAG = "cell_tag";
export const PROPERTY_TAG = "property_tag";
export const PROPERTY_VALUE_TAG = "property_value_tag";
export const TILE_TAG = "tile_tag";
export const TILE_TYPE_TAG = "tile_type_tag";
export const TILE_MEDIA_TAG = "tile_media_tag";
//Lookup
export const GLOBAL_LOOKUP_TAG = "global_lookup_tag";
//Language
export const LANGUAGE_TAG = "language_tag";
export const GLYPH_TAG = "glyph_tag";
export const GLYPH_RELATION_TAG = "glyph_relation_tag";
export const GLYPH_RELATION_TYPE_TAG = "glyph_relation_type_tag";
export const GLYPH_TYPE_TAG = "glyph_type_tag";
export const NOUN_TAG = "noun_tag";
export const NOUN_TYPE_TAG = "noun_type_tag";
export const WORD_TAG = "word_tag";
export const WORD_TYPE_TAG = "word_type_tag";
//SystemCoC
export const BELIEF_TAG = "belief_tag"
export const AVATAR_BELIEF_TAG = "avatar_belief_tag"
//SystemRifts 
export const ABILITY_TAG = "ability_tag";
export const SKILL_ABILITY_TAG = "skill_ability_tag";
export const SYSTEM_RIFTS_SKILL_TAG = "system_rifts_skill_tag";
export const SYSTEM_RIFTS_ALIGNMENT_TAG = "system_rifts_alignment_tag";
export const SYSTEM_RIFTS_AVATAR_ALIGNMENT_TAG = "system_rifts_avatar_alignment_tag";
export const SYSTEM_RIFTS_COMBAT_TAG = "combat_tag"
export const SYSTEM_RIFTS_CHARACTER_TAG = "system_rifts_character_tag"
export const SYSTEM_RIFTS_CONTACT_TAG = "system_rifts_contact_tag"
export const SYSTEM_RIFTS_EQUIPMENT_TAG = "equipment_tag"
export const SYSTEM_RIFTS_ARMOR_TAG = "system_rifts_armor_tag";
export const SYSTEM_RIFTS_LANGUAGE_TAG = "system_rifts_language_tag";
export const DASHBOARD_TAG = "dashboard_tag";
//SwordmasterPRo
export const DECK_TAG = "deck_tag";
export const CARD_TAG = "card_tag";
export const DECK_TYPE_TAG = "deck_type_tag";
export const CARD_TYPE_TAG = "card_type_tag";
export const DECK_MEDIA_TAG = "deck_media_tag";
export const CARD_MEDIA_TAG = "card_media_tag";
export const SWORDMASTERPRO_MEDIA_TAG = "swordmasterpro_media_tag";
//Proposed
export const DICE_SET_TAG = "dice_set_tag";
export const MANUFACTURER_TAG = "manufacturer_tag";
//Collect
export const USER_MY_DIE_MEDIA_TAG = "user_my_die_media_tag";
export const USER_MY_SET_MEDIA_TAG = "user_my_set_media_tag";
//Doc
export const CLAUSE_TYPE_TAG = "clause_type_tag";
export const CONTRACT_TYPE_TAG = "contract_type_tag";
export const ACTION_TAG = "action_tag";
export const CLAUSE_TAG = "clause_tag";
export const CONTRACT_TAG = "contract_tag";
export const DOC_MEDIA_TAG = "doc_media_tag";
export const CONTRACT_CLAUSE_TAG = "contract_clause_tag";
export const COUNTER_PARTY_TAG = "counter_party_tag";
export const COUNTER_PARTY_ACTIONS_TAG = "counter_party_actions_tag";
export const CONTRACT_MEDIA_TAG = "contract_media_tag";
export const ADDRESS_TAG = "address_tag";
export const COUNTRY_TAG = "country_tag";
export const STATE_TAG = "state_tag";
export const CITY_TAG = "city_tag";
export const THEME_TAG = "theme_type_tag";
export const THEME_TYPE_TAG = "theme_type_tag";
export const SCREEN_THEME_TAG = "screen_theme_tag";


export const TagType = [
  //Catalog
  PRODUCT_TAG,
  PRODUCT_TYPE_TAG,
  //comm
  ANSWER_TAG,
  ANSWER_TYPE_TAG,
  CHOICE_TAG,
  CHOICE_TYPE_TAG,
  OPTION_TAG,
  OPTION_TYPE_TAG,
  QUESTION_TAG,
  QUESTION_OPTION_TAG,
  QUESTION_TYPE_TAG,
  RESPONSE_TAG,
  RESPONSE_TYPE_TAG,
  SURVEY_TAG,
  SURVEY_CONTACT_TAG,
  SURVEY_TYPE_TAG,
  FORM_RESPONSE,
  //Game
  AVATAR_TAG,
  AVATAR_CLASS_TAG,
  AVATAR_SPECIES_TAG,
  AVATAR_DOCUMENT_TAG,
  AVATAR_ITEM_TAG,
  AVATAR_SKILL_TAG,
  AVATAR_LANGUAGE_TAG,
  AVATAR_TRAITS_TAG,
  AVATAR_BOND_TAG,
  AVATAR_FLAW_TAG,
  AVATAR_IDEAL_TAG,
  AVATAR_SAVING_THROW_TAG,
  AVATAR_SKILL_ATTRIBUTE_TAG,
  CAMPAIGN_TAG,
  CAMPAIGN_PRODUCT_TAG,
  CAMPAIGN_LOGO_TAG,
  CAMPAIGN_LOGO_STATUS_TAG,
  CAMPAIGN_CONTENT_TAG,
  DEFAULT_CAMPAIGN_LOGO_TAG,
  GAME_PRODUCT_LINE_TAG,
  GAME_MEDIA_PRODUCT_LINE_TAG,
  GAME_PRODUCT_LINE_TYPE_TAG,
  GAME_ITEM_TAG,
  GAME_ROLE_TAG,
  GAME_SKILL_TAG,
  GAME_STATE_TAG,
  GAME_SESSION_TAG,
  GAME_SYSTEM_TAG,
  GAME_SYSTEM_MEDIA_TAG,
  PLAYER_TAG,
  PLAYER_AVATAR_TAG,
  PLAYER_ROLE_TAG,
  PLAYER_MEDIA_TAG,
  DEFAULT_CAMPAIGN_LOGO_TAG,
  //Item
  SHORT_CODE_TAG,
  SHORT_CODE_TYPE_TAG,
  //Dice
  COLOR_TAG,
  COLOR_GROUP_TAG,
  COLOR_PATTERN_TAG,
  DIE_TAG,
  DICE_ATTACHMENT_TAG,
  DIE_MEDIA_TAG,
  DIE_TYPE_TAG,
  FACE_LAYOUT_TAG,
  ENTITY_MEDIA_TAG,
  FACE_SHAPE_TAG,
  FACE_TYPE_TAG,
  GEOMETRY_TYPE_TAG,
  MATERIAL_TAG,
  MATERIAL_TYPE_TAG,
  PIP_TYPE_TAG,
  PRODUCT_LINE_TAG,
  PRODUCT_MODEL_TAG,
  SET_TAG,
  SET_MEDIA_TAG,
  SET_TYPE_TAG,
  SET_TYPE_DIE_TYPE_TAG,
  SHAPE_TAG,
  SHAPE_TYPE_TAG,
  STYLE_TAG,
  STYLE_TYPE_TAG,
  STYLE_STYLE_TYPE_TAG,
  //Global
  EVENT_TAG,
  EVENT_TYPE_TAG,
  UNIT_OF_MEASURE_TAG,
  SCALE_TAG,
  GLOBAL_LOOKUP_TAG,
  //Org
  CONTACT_TAG,
  CONTACT_TYPE_TAG,
  ENTITY_TAG,
  ENTITY_TYPE_TAG,
  ORG_ENTITY_MEDIA_TAG,
  ENTITY_MEDIA_TYPE_TAG,
  ENTITY_RELATION_TAG,
  ENTITY_RELATION_TYPE_TAG,
  ENTITY_RESPONSE_TAG,
  ENTITY_RESPONSE_TYPE_TAG,
  ENTITY_LOCATION_TAG,
  ENTITY_LOCATION_TYPE_TAG,
  ENTITY_PRODUCT_TAG,
  ROLE_TAG,
  AUTH_ROLE_TAG,
  ROLE_TYPE_TAG,
  AUTH_ROLE_TYPE_TAG,
  LOCATION_TAG,
  LOCATION_TYPE_TAG,
  USER_TAG,
  USER_TYPE_TAG,
  EVENT_USER_TAG,
  //Sales 
  SKILLS_TAG,
  ENTITY_SKILLS_TAG,
  //Site
  APPLICATION_TYPE_TAG,
  APPLICATION_TAG,
  SCREEN_TYPE_TAG,
  SCREEN_TAG,
  SCREEN_MAPPING_TAG,
  SKIN_TAG,
  SCREEN_SKIN_TAG,
  SCREEN_CONTENT_TAG,
  APPLICATION_SCREEN_TAG,
  ZONE_TYPE_TAG,
  ZONE_TAG,
  SCREEN_ZONE_TAG,
  CONTENT_TYPE_TAG,
  CONTENT_TAG,
  APPVERSION_TAG,
  APPLICATION_CONTENT_TAG,
  CONTENT_LOCATION_TAG,
  CONTENT_MEDIA_TAG,
  CONTENT_MENU_TAG,
  SITE_MEDIA_TAG,
  MENU_TYPE_TAG,
  MENU_TAG,
  APPLICATION_MENU_TAG,
  SCREEN_MENU_TAG,
  MENU_ITEM_TAG,
  MENU_ITEM_TYPE_TAG,
  MENU_MEDIA_TAG,
  SITE_ROLE_TAG,
  ZONE_CONTENT_TAG,
  ZONE_MEDIA_TAG,
  ZONE_CONTENT_MEDIA_TAG,
  ZONE_MYDIE_TAG,
  ZONE_MYCOLLECTION_TAG,
  ZONE_MYSET_TAG,
  ZONE_MENU_TAG,
  CONTENT_TEMPLATE_TAG,
  CONTENT_TEMPLATE_TYPE_TAG,
  //Doc
  CLAUSE_TYPE_TAG,
  CONTRACT_TYPE_TAG,
  ACTION_TAG,
  CLAUSE_TAG,
  CONTRACT_TAG,
  DOC_MEDIA_TAG,
  CONTRACT_CLAUSE_TAG,
  COUNTER_PARTY_TAG,
  COUNTER_PARTY_ACTIONS_TAG,
  CONTRACT_MEDIA_TAG,
  ADDRESS_TAG,
  COUNTRY_TAG,
  STATE_TAG,
  CITY_TAG,
  //System_dnd5e
  ALIGNMENT_TAG,
  AVATAR_ALIGNMENT_TAG,
  ATTRIBUTE_TAG,
  AVATAR_ATTRIBUTE_TAG,
  SKILL_ATTRIBUTE_TAG,
  BACKGROUND_TAG,
  BOND_TAG,
  FEAT_TAG,
  FLAW_TAG,
  TRAIT_TAG,
  BOND_TAG,
  CLASS_TAG,
  IDEAL_TAG,
  CHARACTER_TAG,
  SKILL_TAG,
  SPECIES_TAG,
  SPECIES_ATTRIBUTE_TAG,
  SPECIES_LANGUAGE_TAG,
  DND5E_LOOKUP_TAG,
  LOOKUP_ABILITY_MODIFIER_TAG,
  LOOKUP_CLASS_WEALTH_TAG,
  // System_evolutionary
  EVOLUTIONARY_ATTRIBUTE_TAG,
  EVOLUTIONARY_AVATAR_ATTRIBUTE_TAG,
  EVOLUTIONARY_SKILL_TAG,
  EVOLUTIONARY_SKILL_ATTRIBUTE_TAG,
  EVOLUTIONARY_ITEM_TAG,
  EVOLUTIONARY_ITEM_ATTRIBUTE_TAG,
  EVOLUTIONARY_LOOKUP_TAG,
  // System_swade
  SWADE_ATTRIBUTE_TAG,
  SWADE_AVATAR_ATTRIBUTE_TAG,
  SWADE_SKILL_TAG,
  SWADE_SKILL_ATTRIBUTE_TAG,
  SWADE_GEAR_TAG,
  SWADE_AVATAR_GEAR_TAG,
  SWADE_WOUND_TAG,
  SWADE_POWER_TAG,
  SWADE_BACKGROUND_TAG,
  SWADE_AVATAR_POWER_TAG,
  SWADE_WEAPON_TAG,
  SWADE_AVATAR_WEAPON_TAG,
  SWADE_CHARACTER_TAG,
  SWADE_LOOKUP_TAG,
  SWADE_FATIGUE_TAG,
  SWADE_HINDRANCE_TAG,
  //System_ pathfinder
  PATHFINDER_ATTRIBUTE_TAG,
  PATHFINDER_AVATAR_ATTRIBUTE_TAG,
  PATHFINDER_LOOKUP_TAG,
  //System_traveller
  TRAVELLER_MAGAZINE_TAG,
  TRAVELLER_AVATAR_MAGAZINE_TAG,
  TRAVELLER_ARMOUR_TAG,
  TRAVELLER_AUGMENT_TAG,
  TRAVELLER_CHARACTER_TAG,
  TRAVELLER_LOOKUP_TAG,
  TRAVELLER_WEAPON_TAG,
  TRAVELLER_AVATAR_WEAPON_TAG,
  //map
  CELL_TAG,
  PROPERTY_TAG,
  PROPERTY_VALUE_TAG,
  TILE_TAG,
  TILE_TYPE_TAG,
  TILE_MEDIA_TAG,
  //Language
  LANGUAGE_TAG,
  GLYPH_TAG,
  GLYPH_RELATION_TAG,
  GLYPH_RELATION_TYPE_TAG,
  GLYPH_TYPE_TAG,
  NOUN_TAG,
  NOUN_TYPE_TAG,
  WORD_TAG,
  WORD_TYPE_TAG,
  //SystemCoc
  BELIEF_TAG,
  AVATAR_BELIEF_TAG,
  //SystemRifts
  ABILITY_TAG,
  SKILL_ABILITY_TAG,
  SYSTEM_RIFTS_SKILL_TAG,
  SYSTEM_RIFTS_ALIGNMENT_TAG,
  SYSTEM_RIFTS_AVATAR_ALIGNMENT_TAG,
  SYSTEM_RIFTS_COMBAT_TAG,
  SYSTEM_RIFTS_CHARACTER_TAG,
  SYSTEM_RIFTS_CONTACT_TAG,
  SYSTEM_RIFTS_EQUIPMENT_TAG,
  SYSTEM_RIFTS_ARMOR_TAG,
  SYSTEM_RIFTS_LANGUAGE_TAG,
  DASHBOARD_TAG,
  //SwordmasterPro
  DECK_TAG,
  CARD_TAG,
  DECK_MEDIA_TAG,
  CARD_MEDIA_TAG,
  SWORDMASTERPRO_MEDIA_TAG,
  //Proposed
  DICE_SET_TAG,
  MANUFACTURER_TAG,
  //Collect
  USER_MY_DIE_MEDIA_TAG,
  USER_MY_SET_MEDIA_TAG,
  THEME_TAG,
  THEME_TYPE_TAG,
  SCREEN_THEME_TAG,
];
