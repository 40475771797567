const formateColumnFilter = (column, columnFilter) => {
    const filterColumn = column.map(({ Header, accessor, type }) => ({
        Header,
        accessor,
        type,
    }));
    let filterQuery = "";
    let filterData = [];
    Object.entries(columnFilter).forEach(([key, value]) => {
        var columnDetail = column.find((val) => {
            return val.accessor === key;
        });
        if (columnDetail.type !== "Boolean") {
            if (value && typeof value === "number") {
                filterData.push(`${key} eq ${value}`);
            } else if (value) {
                value = encodeURIComponent(value)
                filterData.push(
                    `contains(tolower(${key.includes(".") ? key.replaceAll(".", "/") : key
                    }),'${value.replaceAll("'", "''").toLowerCase()}')`
                );
            }
        }
    });

    filterQuery = filterData.length > 0 ? filterData.join(" and ") : "";
    return { filterColumn, filterQuery };
}

export {
    formateColumnFilter
}
