import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { SHORT_CODE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useSelector } from "react-redux";

const ShortCodeForm = ({ editData, setIsAddModalOpen, isAddModalOpen }) => {
  const userId = useSelector((state) => state?.user?.UserId)
  const [addRecord, { isLoading: isAddRecordLoading }] = useAddMutation();
  const [updatRecord, { isLoading: isUpdateRecordLoading }] =
    useUpdateMutation();
  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    ShortCodeType: Yup.mixed().required("Please select short code type."),
    LongWebAddress: Yup.string().required("Please enter long web Address."),
    ShortCode: Yup.string().required("Please enter short code."),
  });

  const onSubmit = (values) => {
    const shortcodeDetail = {
      Name: values.Name,
      Description: values.Description,
      UserId: Number(userId),
      ShortCodeTypeId: values.ShortCodeType.Id,
      LongWebAddress: values.LongWebAddress,
      ShortWebAddress: `${values.ShortCodeType.Name}/${values.ShortCode}`,
    };
    if (editData && Object.entries(editData).length > 0) {
      shortcodeDetail.Id = editData.Id;
      updatRecord({
        entity: "ShortCode",
        isWogdApi: true,
        data: shortcodeDetail,
        tag: SHORT_CODE_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(!isAddModalOpen);
          successToast("ShortCode updated successfully.");
        }
      });
    } else {
      addRecord({
        isWogdApi: true,
        entity: "ShortCode",
        data: shortcodeDetail,
        tag: SHORT_CODE_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(!isAddModalOpen);
          successToast("ShortCode added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        ShortCodeType:
          editData && editData.ShortCodeType ? editData.ShortCodeType : "",
        LongWebAddress:
          editData && editData.LongWebAddress ? editData.LongWebAddress : "",
        ShortCode:
          editData && editData.ShortWebAddress ? editData.ShortWebAddress.split('/').slice(1).join("/") : "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;
        return (
          <Form>
            {Object.keys(editData).length > 0 && (
              <CustomInput label="Id" fieldValue={editData.Id} readonly />
            )}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly={Object.keys(editData).length > 0}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.ShortCodeType}
              label="ShortCode Type"
              name="ShortCodeType"
              entity="ShortCodeType"
              isParentBaseUrl
              setFieldValue={setFieldValue}
              fieldErrors={errors.ShortCodeType}
              fieldTouched={touched.ShortCodeType}
              handleBlur={handleBlur}
              isDisable={Object.keys(editData).length > 0}
              required
            />

            <CustomInput
              label="Long Web Address"
              name="LongWebAddress"
              fieldErrors={errors.LongWebAddress}
              fieldTouched={touched.LongWebAddress}
              fieldValue={values.LongWebAddress}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Short Code"
              name="ShortCode"
              fieldErrors={errors.ShortCode}
              fieldTouched={touched.ShortCode}
              fieldValue={values.ShortCode}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly={Object.keys(editData).length > 0}
              required
            />
            <Button
              disabled={isAddRecordLoading || isUpdateRecordLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isAddRecordLoading || isUpdateRecordLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ShortCodeForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.bool,
};

export default ShortCodeForm;
