import React from "react";
import { Route } from "react-router-dom";
import Answer from "@/components/Comm/Answer/Answer/Answer";
import AnswerType from "@/components/Comm/Answer/AnswerType/AnswerType";
import Choice from "@/components/Comm/Choice/Choice/Choice";
import ChoiceType from "@/components/Comm/Choice/ChoiceType/ChoiceType";
import EntityResponse from "@/components/Comm/EntityResponse/EntityResponse/EntityResponse";
import EntityResponseType from "@/components/Comm/EntityResponse/EntityResponseType/EntityResponseType";
import Option from "@/components/Comm/Option/Option/Option";
import OptionType from "@/components/Comm/Option/OptionType/OptionType";
import Survey from "@/components/Comm/Survey/Survey/Survey";
import SurveyContact from "@/components/Comm/Survey/SurveyContact/SurveyContact";
import SurveyQuestion from "@/components/Comm/Survey/SurveyQuestion/SurveyQuestion";
import SurveyType from "@/components/Comm/Survey/SurveyType/SurveyType";
import Question from "@/components/Comm/Question/Question/Question";
import QuestionType from "@/components/Comm/Question/QuestionType/Questiontype";
import QuestionOption from "@/components/Comm/Question/QuestionOption/QuestionOption";
import Response from "@/components/Comm/Response/Response/Response";
import ResponseType from "@/components/Comm/Response/ResponseType/ResponseType";
import FormResponse from "@/components/Comm/FormResponse/FormResponse";
//comm
const CommRoutes = () => (
  <div>
    <Route path="/comm/answer" component={Answer} />
    <Route path="/comm/answer-type" component={AnswerType} />
    <Route path="/comm/choice" component={Choice} />
    <Route path="/comm/choice-type" component={ChoiceType} />
    <Route path="/comm/entity-response" component={EntityResponse} />
    <Route path="/comm/entity-response-type" component={EntityResponseType} />
    <Route path="/comm/form-response" component={FormResponse} />
    <Route path="/comm/option" component={Option} />
    <Route path="/comm/option-type" component={OptionType} />
    <Route path="/comm/question" component={Question} />
    <Route path="/comm/question-option" component={QuestionOption} />
    <Route path="/comm/question-type" component={QuestionType} />
    <Route path="/comm/response" component={Response} />
    <Route path="/comm/response-type" component={ResponseType} />
    <Route path="/comm/survey" component={Survey} />
    <Route path="/comm/survey-contact" component={SurveyContact} />
    <Route path="/comm/survey-question" component={SurveyQuestion} />
    <Route path="/comm/survey-type" component={SurveyType} />
  </div>
);

export default CommRoutes;
