import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import Tabs from "./Tabs";

const Activity = ({ tree, displayData }) => {
  const { t } = useTranslation("common");

  return (
    <Col xl={6}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">
              {t("gamecodex.org.entity.activity.section_title")}
            </h5>
            <h5 className="subhead">
              Use default tabs with class
              <span className="red-text"> tabs--bordered-bottom</span>
            </h5>
          </div>
          <div className="tabs tabs--bordered-bottom">
            <Tabs tree={tree} displayData={displayData} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Activity;
