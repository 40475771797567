import React, {useEffect} from "react";
import { Button, Col, Label, Row } from "reactstrap";
import { FieldArray } from "formik";
import DeleteIcon from "mdi-react/DeleteIcon";
import AddCircleIcon from "mdi-react/AddCircleIcon";
import ArrowBottomBoldCircleIcon from "mdi-react/ArrowBottomBoldCircleIcon";
import ArrowUpBoldCircleIcon from "mdi-react/ArrowUpBoldCircleIcon";
import { Divider, Link } from "@material-ui/core";
import _ from "lodash";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import QuestionFields from "./QuestionFields";

const QuestionForm = ({ formik, editData }) => {
  const { errors, touched, values, handleBlur, setFieldValue } = formik;

  useEffect(() => {
    if (values.questions.length) {
      values.questions.forEach((question) => {
        if (
          (question.QuestionType.Name === "multiple-toggle" ||
            question.QuestionType.Name === "checkbox") &&
          question.Options.length === 0
        ) {
          question.Options.push({
            Name: "",
            Description: "",
            PlaceholderText: "",
            OptionType: "",
          });
        }
      });
    }
  }, [values.questions]);
  
  return (
    <FieldArray name="questions">
      {({ push, remove, replace, insert, swap }) => (
        <>
          <CustomInput
            label="Add Question"
            name="AddQuestion"
            type="checkbox"
            fieldErrors={errors.AddQuestion}
            fieldTouched={touched.AddQuestion}
            fieldValue={values.AddQuestion}
            handleBlur={handleBlur}
            isChecked={values.AddQuestion}
            isDisabled={
              _.isEmpty(editData) ? false : _.isEmpty(editData.SurveyQuestions)
            }
            handleChange={(e) => {
              if (!values.AddQuestion) {
                push({
                  Name: "",
                  Description: "",
                  PlaceholderText: "",
                  QuestionType: "",
                  IsRequired: false,
                  Options: [
                    {
                      Name: "",
                      Description: "",
                      PlaceholderText: "",
                      OptionType: "",
                    },
                  ],
                });
              } else {
                values.questions = [];
              }
              setFieldValue("AddQuestion", e.target.checked);
            }}
          />
          {values.AddQuestion &&
            values.questions.map((data, index) => {
              return (
                <div key={`question_card_container${index}`}>
                  {values.questions.length >= 2 && index >= 1 && (
                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  )}
                  <Row className="mb-2">
                    <Col xs={10} sm={10} md={10}>
                      <Label
                        for="ContentObject"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        Question {index + 1}
                      </Label>
                    </Col>
                    <Col xs={2} sm={2} md={2} className="p-0 text-right pr-2">
                      <AddCircleIcon
                        className="cursor-pointer mr-1"
                        onClick={() => {
                          insert(index + 1, {
                            Name: "",
                            Description: "",
                            PlaceholderText: "",
                            QuestionType: "",
                            IsRequired: false,
                            Options: [
                              {
                                Name: "",
                                Description: "",
                                PlaceholderText: "",
                                OptionType: "",
                              },
                            ],
                          });
                        }}
                      />
                      <DeleteIcon
                        onClick={() => {
                          if (values.questions.length === 1) {
                            values.AddQuestion = false;
                            remove(index);
                          } else {
                            remove(index);
                          }
                        }}
                        className="cursor-pointer mr-1"
                      />
                      {index > 0 && (
                        <ArrowUpBoldCircleIcon
                          className="cursor-pointer mr-1"
                          onClick={() => {
                            swap(index, index - 1);
                          }}
                        />
                      )}
                      {values.questions.length - 1 !== index && (
                        <ArrowBottomBoldCircleIcon
                          className="cursor-pointer mr-1"
                          onClick={() => {
                            swap(index, index + 1);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row
                    className="align-items-start"
                    key={`question_card${index}`}
                  >
                    <Col xs={12} sm={12} md={12}>
                      <Row>
                        <Col lg={12} md={12}>
                          <CustomInput
                            label="Is this question required?" 
                            name={`questions[${index}].IsRequired`}
                            type="checkbox"
                            fieldValue={data.IsRequired}
                            isChecked={data.IsRequired}
                            handleChange={(e) => {
                              setFieldValue(`questions[${index}].IsRequired`, e.target.checked);
                            }}
                            className="mb-0"
                          />
                        </Col>
                        <Col lg={6} md={6}>
                          <AsyncPaginates
                            value={data.QuestionType}
                            label="Question Type"
                            name={`questions[${index}].QuestionType`}
                            entity="QuestionType"
                            isParentBaseUrl={true}
                            setFieldValue={setFieldValue}
                            fieldErrors={
                              errors.questions &&
                              errors.questions[index] &&
                              errors.questions[index].QuestionType
                            }
                            fieldTouched={
                              touched.questions &&
                              touched.questions[index] &&
                              touched.questions[index].QuestionType
                            }
                            handleBlur={handleBlur}
                            required
                          />
                        </Col>
                        <QuestionFields
                          data={data}
                          replace={replace}
                          index={index}
                          field="Question"
                          colSize={6}
                          handleBlur={handleBlur}
                          errorObj={errors.questions && errors.questions[index]}
                          touchedObj={
                            touched.questions && touched.questions[index]
                          }
                        />
                      </Row>
                      {values.questions[index].QuestionType &&
                        (values.questions[index].QuestionType.Name ===
                          "multiple-toggle" ||
                          values.questions[index].QuestionType.Name ===
                            "checkbox") && (
                          <FieldArray name={`questions[${index}].Options`}>
                            {({ push, remove, replace, swap, insert }) => (
                              <>
                                {values.questions[index].Options.map(
                                  (data, i) => {
                                    return (
                                      <div key={`options_card_container${i}`}>
                                        <Row className="justify-content-end">
                                          <Col lg={9} md={9}>
                                            <Label
                                              for="ContentObject"
                                              style={{ fontWeight: 600 }}
                                            >
                                              Option {i + 1}
                                            </Label>
                                          </Col>
                                          <Col
                                            lg={2}
                                            md={2}
                                            className="text-right"
                                          >
                                            <AddCircleIcon
                                              className="cursor-pointer mr-1"
                                              onClick={() => {
                                                insert(i + 1, {
                                                  Name: "",
                                                  Description: "",
                                                  PlaceholderText: "",
                                                  OptionType: "",
                                                });
                                              }}
                                            />
                                            <DeleteIcon
                                              onClick={() => {
                                                if (
                                                  values.questions[index]
                                                    .Options.length === 1
                                                ) {
                                                  remove(i);
                                                  push({
                                                    Name: "",
                                                    Description: "",
                                                    PlaceholderText: "",
                                                    OptionType: "",
                                                  });
                                                } else {
                                                  remove(i);
                                                }
                                              }}
                                              className="cursor-pointer"
                                            />
                                            {i > 0 && (
                                              <ArrowUpBoldCircleIcon
                                                className="cursor-pointer mr-1"
                                                onClick={() => {
                                                  swap(i, i - 1);
                                                }}
                                              />
                                            )}
                                            {values.questions[index].Options
                                              .length -
                                              1 !==
                                              i && (
                                              <ArrowBottomBoldCircleIcon
                                                className="cursor-pointer mr-1"
                                                onClick={() => {
                                                  swap(i, i + 1);
                                                }}
                                              />
                                            )}
                                          </Col>
                                        </Row>
                                        <Row
                                          className="align-items-center justify-content-end"
                                          key={`options_card${i}`}
                                        >
                                          <Col xs={11} sm={11} md={11}>
                                            <Row>
                                              <Col lg={3} md={3}>
                                                <AsyncPaginates
                                                  value={data.OptionType}
                                                  label="Option Type"
                                                  name={`questions[${index}].Options[${i}].OptionType`}
                                                  entity="OptionType"
                                                  isParentBaseUrl={true}
                                                  setFieldValue={setFieldValue}
                                                  required
                                                />
                                              </Col>
                                              <QuestionFields
                                                data={data}
                                                replace={replace}
                                                index={i}
                                                field="Option"
                                                colSize={3}
                                              />
                                            </Row>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                )}
                                <div className="d-flex justify-content-end">
                                  <Link
                                    component="button"
                                    variant="body2"
                                    style={{
                                      marginRight: "52px",
                                      color: "#4ce1b6",
                                    }}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      push({
                                        Name: "",
                                        Description: "",
                                        PlaceholderText: "",
                                        OptionType: "",
                                      });
                                    }}
                                  >
                                    + add one more option
                                  </Link>
                                </div>
                              </>
                            )}
                          </FieldArray>
                        )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          {values.AddQuestion && (
            <>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div className="d-flex">
                <Button
                  onClick={() => {
                    push({
                      Name: "",
                      Description: "",
                      PlaceholderText: "",
                      QuestionType: "",
                      IsRequired: false,
                      Options: [
                        {
                          Name: "",
                          Description: "",
                          PlaceholderText: "",
                          OptionType: "",
                        },
                      ],
                    });
                  }}
                  className="mt-3"
                  color="success"
                  size="sm"
                >
                  Add Question
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </FieldArray>
  );
};

export default QuestionForm;
