import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { SCREEN_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ScreenTypeForm from "./ScreenTypeForm";
import { getAllScreenTypes } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ScreenType = () => {
  const [screenTypeDetails, setScreenTypeDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteScreenType, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getScreenTypeRecords,
    {
      data: screenTypeList,
      isError: isScreenTypeError,
      isLoading: isScreenTypeLoading,
      isFetching: isScreenTypeFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (screenTypeData) => {
    setSelectedRecord(screenTypeData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteScreenType({
      entity: "CMSReference/ScreenType",
      id: selectedRecord.Id,
      tag: SCREEN_TYPE_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Screen-type deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getScreenTypeRecords({
      entity: "CMSReference/ScreenTypes",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SCREEN_TYPE_TAG,
    });
    setScreenTypeDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isScreenTypeLoading &&
      !isScreenTypeError &&
      screenTypeList &&
      !isScreenTypeFetching
    ) {
      dispatch(getAllScreenTypes(screenTypeList.value));
      let screenTypeData = [...screenTypeList.value];
      setScreenTypeDetails({
        ...screenTypeDetails,
        tableRowsData: screenTypeList.value ? screenTypeData : [],
        totalData:
          screenTypeList && screenTypeList["@odata.count"]
            ? screenTypeList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isScreenTypeError,
    screenTypeList,
    isScreenTypeLoading,
    isScreenTypeFetching,
  ]);

  const deleteMessage = () => {
    return (
      <div>
        Are you sure you want to delete {selectedRecord.Name}?
        <div className="warning-div">
          <i>
            This will delete all the associated records <br />
            ( Like - Screen, Application screen, Screen Content, Screen Menu)
          </i>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        {screenTypeDetails && (
          <DataReactTable
            reactTableData={screenTypeDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Screen Type"
            isFetching={isScreenTypeFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Screen Type`}
          header
          btn="Success"
          message={
            <ScreenTypeForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default ScreenType;
