import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Spinner } from "reactstrap";
import ReactApexChart from 'react-apexcharts'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useLazyGetRecordsQuery,
} from "@/services/gamecodeXApi";
import { DASHBOARD_TAG } from "@/shared/tagFile"
import CustomInput from "@/shared/components/form/CustomInput";
import { successToast, errorToast } from "@/shared/components/toast";
import { getAllDashboardDetails } from "../Site/SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const [startYearData, setStartYearData] = useState();
  const [endYearData, setEndYearData] = useState();
  const [options, setOptions] = useState();
  const [series, setSeries] = useState();
  const [value, setValue] = useState();
  const dispatch = useDispatch();

  const [
    getDashboardRecords,
    {
      data: dashboardList,
      isError: isDashboardError,
      isLoading: isDashboardLoading,
      isFetching: isDashboardFetching,
    },
  ] = useLazyGetRecordsQuery();

  const applicationValidationSchema = Yup.object().shape({
    StartYear: Yup.string().required("Please enter start year."),
    EndYear: Yup.string().required("Please enter end year."),
  });

  const onSubmit = (values) => {
    if (values) {
      setValue(values)
      getDashboardRecords({
        entity: `GetDashboardForSuccessfullyFundedProject`,
        isWogdApi: true,
        query: `startYear=${values.StartYear}&endYear=${values.EndYear}`,
        tag: DASHBOARD_TAG,
      }).then((response) => {
        if (response.data.value.length) {
          successToast("Successfully.");
        } else {
          errorToast("Please enter valid year")
        }
      });
    }
  }

  useEffect(() => {
    if (
      !isDashboardLoading &&
      !isDashboardError &&
      dashboardList &&
      !isDashboardFetching
    ) {
      dispatch(getAllDashboardDetails(dashboardList.value));
      var startYear = []
      var endYear = []
      dashboardList.value.map((item) => {
        if (parseInt(item.Year) === parseInt(value.StartYear)) {
          startYear.push(item)
        } else {
          endYear.push(item)
        }
      })
      setStartYearData(startYear)
      setEndYearData(endYear)
    }
    // eslint-disable-next-line
  }, [
    isDashboardError,
    dashboardList,
    isDashboardLoading,
    isDashboardFetching,
  ]);

  useEffect(() => {
    if (startYearData) {
      var category = []
      startYearData.map((item) => {
        category.push(item.CategoryName)
      })
      setOptions({
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: category,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " projects";
            },
          },
        },
      })
    }
  }, [startYearData])

  useEffect(() => {
    if (startYearData && endYearData) {
      var sYear = []
      var eYear = []
      startYearData.map((item) => {
        sYear.push(item.SuccessfullyFundedProjects)
      })
      endYearData.map((item) => {
        eYear.push(item.SuccessfullyFundedProjects)
      })
      setSeries([
        {
          name: value.StartYear,
          data: sYear,
        },
        {
          name: value.EndYear,
          data: eYear,
        },
      ])
    }
  }, [startYearData, endYearData])

  return (<>
    <Formik
      initialValues={{
        StartYear: "",
        EndYear: "",
      }}
      onSubmit={onSubmit}
      validationSchema={applicationValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form className="d-flex justify-content-around">
            <CustomInput
              label="Start Year"
              name="StartYear"
              fieldErrors={errors.StartYear}
              fieldTouched={touched.StartYear}
              fieldValue={values.StartYear}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="End Year"
              name="EndYear"
              fieldErrors={errors.EndYear}
              fieldTouched={touched.EndYear}
              fieldValue={values.EndYear}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />

            <Button
              disabled={isDashboardLoading}
              onClick={handleSubmit}
              className="modal_ok mt-4"
              color="success"
            >
              {(isDashboardLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
    <div className="App">
      <div className="echarts">
        {options && series && <ReactApexChart options={options} series={series} type="bar" height={350} />}
      </div>
    </div>
  </>
  );
};

export default Dashboard;
