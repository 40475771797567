import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { RESPONSE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ResponseForm = ({ editData, setIsAddModalOpen }) => {
  const user = useSelector((state) => state.user);
  const [addResponse, { isLoading: isResponseAddLoading }] = useAddMutation();
  const [updateResponse, { isLoading: isResponseUpdateLoading }] =
    useUpdateMutation();

  const validationSchema = Yup.object().shape({
    Survey: Yup.mixed().required("Please select survey."),
    ResponseType: Yup.mixed().required("Please select response type."),
  });

  const onSubmit = (values) => {
    let responseDetail = {
      Name: values.Name,
      Description: values.Description,
      UserId: user.UserId,
      SurveyId: values.Survey.Id,
      ResponseTypeId: values.ResponseType.Id,
      PlaceholderText: values.PlaceholderText,
    };
    if (editData && Object.entries(editData).length > 0) {
      responseDetail.Id = editData.Id;
      updateResponse({
        entity: "Response",
        isWogdApi: true,
        data: responseDetail,
        tag: RESPONSE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isResponseUpdateLoading && setIsAddModalOpen(false);
          successToast("Response updated successfully.");
        }
      });
    } else {
      addResponse({
        entity: "Response",
        isWogdApi: true,
        data: responseDetail,
        tag: RESPONSE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isResponseAddLoading && setIsAddModalOpen(false);
          successToast("Response added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        Survey: editData && editData.Survey ? editData.Survey : "",
        ResponseType:
          editData && editData.ResponseType ? editData.ResponseType : "",
        PlaceholderText:
          editData && editData.PlaceholderText ? editData.PlaceholderText : "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.Survey}
              label="Survey"
              name="Survey"
              entity="Survey"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Survey}
              fieldTouched={touched.Survey}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.ResponseType}
              label="Response Type"
              name="ResponseType"
              entity="ResponseType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.ResponseType}
              fieldTouched={touched.ResponseType}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Placeholder Text"
              name="PlaceholderText"
              fieldErrors={errors.PlaceholderText}
              fieldTouched={touched.PlaceholderText}
              fieldValue={values.PlaceholderText}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isResponseAddLoading || isResponseUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isResponseAddLoading || isResponseUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ResponseForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ResponseForm;
