import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import {
  useAddMutation,
  useUpdateMutation,
} from "@/services/gamecodeXApi";
import { CONTRACT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ContractForm = ({ editData, setActiveTab, setIsAddModalOpen, cloneContract, setCurrentContractId }) => {
  const user = useSelector((state) => state?.user)
  const [addContract, { isLoading: isContractAddLoading }] =
    useAddMutation();
  const [updateContract, { isLoading: isContractUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const contractValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    ContractType: Yup.mixed().required("Please select contract-type."),
    Entity: Yup.mixed().required("Please select entity."),
  });

  const onSubmit = (values) => {
    const contractDetail = {
      Name: values.Name,
      Description: values.Description,
      Version: values.Version,
      ContractTypeId: values.ContractType.Id,
      EntityId: values.Entity.Id,
    };
    if (!cloneContract) {
      if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
        contractDetail.Id = editRecordDetail.Id;
        contractDetail.State = editRecordDetail?.State;
        contractDetail.PipelineId = editRecordDetail.PipelineId;
        updateContract({
          entity: "Doc/Contract",
          data: contractDetail,
          tag: CONTRACT_TAG,
        }).then((response) => {
          if (!response.error) {
            if (setActiveTab) {
              setActiveTab("2")
              successToast("Contract updated successfully.");
            } else {
              setIsAddModalOpen(false);
              successToast("Contract updated successfully.");
            }
          }
        });
      } else {
        addContract({
          entity: "Doc/Contract",
          data: contractDetail,
          tag: CONTRACT_TAG,
        }).then((response) => {
          if (!response.error) {
            setEditRecordDetail(response?.data);
            if (setActiveTab) {
              setCurrentContractId({Id: response?.data?.id, Name: response?.data?.name });
              setActiveTab("2");
              successToast("Contract added successfully.");
            } else {
              successToast("Contract added successfully.");
              setIsAddModalOpen(false );
            }
          }
        });
      }
    }else{
      addContract({
        entity: `Doc/CloneContract?contractId=${editRecordDetail.Id}`,
        data: contractDetail,
        tag: CONTRACT_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Contract cloned successfully.");
          setIsAddModalOpen(false );
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editRecordDetail?.Name || "",
        Description: editRecordDetail?.Description || "",
        Version: editRecordDetail?.Version || "",
        ContractType: editRecordDetail?.ContractTypeDTO || "",
        Entity: cloneContract ? {Name: user.name , Id: user.EntityId} : editRecordDetail?.EntityDTO || "",
      }}
      onSubmit={onSubmit}
      validationSchema={contractValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Version"
              name="Version"
              fieldErrors={errors.Version}
              fieldTouched={touched.Version}
              fieldValue={values.Version}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.ContractType}
              label="Contract Type"
              name="ContractType"
              entity="DocReference/ContractTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.ContractType}
              fieldTouched={touched.ContractType}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Entity}
              label="Entity"
              name="Entity"
              entity="Org/OrgEntities"
              entities= "$select=Id,Name&entities=Users&$expand=UserDTO($select=Id,email)"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Entity}
              fieldTouched={touched.Entity}
              handleBlur={handleBlur}
              required
              readonly={cloneContract}
            />
            <Button
              disabled={isContractAddLoading || isContractUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isContractAddLoading ||
                isContractUpdateLoading ) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ContractForm.propTypes = {
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
};

export default ContractForm;
