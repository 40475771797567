import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "../../../../containers/Tables/DataTable/components/DataReactTable";
import AddModal from "../../../../shared/components/AddModal";
import Modal from "../../../../shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "../../../../services/gamecodeXApi";
import { SITE_MEDIA_TAG } from "../../../../shared/tagFile";
import { successToast } from "../../../../shared/components/toast";
import { formateColumnFilter } from "../../../../utils/columnFilter";
import MediaTypeForm from "./MediaTypeForm";
import { getAllMediaTypes } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const MediaType = () => {
  const [mediaTypeDetails, setMediaTypeDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteMediaType, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getMediaTypeRecords,
    {
      data: mediaTypeList,
      isError: isMediaTypeError,
      isLoading: isMediaTypeLoading,
      isFetching: isMediaTypeFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (mediaTypeData) => {
    setSelectedRecord(mediaTypeData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteMediaType({
      entity: "CMSReference/MediaType",
      id: selectedRecord.Id,
      tag: SITE_MEDIA_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Media-type deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getMediaTypeRecords({
      entity: "CMSReference/MediaTypes",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SITE_MEDIA_TAG,
    });
    setMediaTypeDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isMediaTypeLoading &&
      !isMediaTypeError &&
      mediaTypeList &&
      !isMediaTypeFetching
    ) {
      dispatch(getAllMediaTypes(mediaTypeList.value));
      let mediaTypeData = [...mediaTypeList.value];
      setMediaTypeDetails({
        ...mediaTypeDetails,
        tableRowsData: mediaTypeList.value ? mediaTypeData : [],
        totalData:
          mediaTypeList && mediaTypeList["@odata.count"]
            ? mediaTypeList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isMediaTypeError,
    mediaTypeList,
    isMediaTypeLoading,
    isMediaTypeFetching,
  ]);

  return (
    <Container>
      <Row>
        {mediaTypeDetails && (
          <DataReactTable
            reactTableData={mediaTypeDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Media Type"
            isFetching={isMediaTypeFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Media Type`}
          header
          btn="Success"
          message={
            <MediaTypeForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default MediaType;
