import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { SCREEN_CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ScreenContentForm from "./ScreenContentForm";
import ContentForm from "../../Content/Content/ContentForm";
import { getAllScreenContents } from "../../SiteSlice";
import "react-toastify/dist/ReactToastify.css";

const ScreenContent = () => {
  const [screenContentDetails, setScreenContentDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteScreenContent, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const [contentDetails, setContentDetails] = useState(false)
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [
    getScreenContentRecords,
    {
      data: screenContentList,
      isError: isScreenContentError,
      isLoading: isScreenContentLoading,
      isFetching: isScreenContentFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Screen Name",
      accessor: "ScreenDTO.Name",
      filter: true,
    },
    {
      Header: "Content Name",
      accessor: "ContentDTO",
      filter: true,
      Cell: ({ value }) => {
        return value ?
        <ul
          style={{cursor: "pointer", color: "#70bbfd"}}
          onClick={() => {setContentDetails(value)}}
        > 
          {value?.Name} 
        </ul>
        : "-";
      }
    },
    {
      Header: "Component Name",
      accessor: "ComponentDTO.Name",
      filter: true,
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (screenContentData) => {
    setSelectedRecord(screenContentData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteScreenContent({
      entity: "CMS/ScreenContent",
      id: selectedRecord.Id,
      tag: SCREEN_CONTENT_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Screen-content deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state?.name) {
      setColumnFilter({
        [`${location.state.title.split(" ").join("")}.Name`]:
          location.state.name,
      });
    }
    history.push({ pathname: location.pathname, state: {} });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getScreenContentRecords({
      entity: "CMS/ScreenContents",
      entities: { "Content.ContentType": {}, Screen: {}, Component: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SCREEN_CONTENT_TAG,
    });
    setScreenContentDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isScreenContentLoading &&
      !isScreenContentError &&
      screenContentList &&
      !isScreenContentFetching
    ) {
      dispatch(getAllScreenContents(screenContentList.value));
      let screenContentData = [...screenContentList.value];
      setScreenContentDetails({
        ...screenContentDetails,
        tableRowsData: screenContentList.value ? screenContentData : [],
        totalData:
          screenContentList && screenContentList["@odata.count"]
            ? screenContentList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isScreenContentError,
    screenContentList,
    isScreenContentLoading,
    isScreenContentFetching,
  ]);

  return (
    <Container>
      <Row>
        {screenContentDetails && (
          <DataReactTable
            reactTableData={screenContentDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Screen Content"
            isFetching={isScreenContentFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {contentDetails && (
        <AddModal
          xxxl
          toggle={() => setContentDetails(false)}
          color="success"
          title="Edit Content"
          header
          btn="Success"
          message={
            <ContentForm 
              editData={contentDetails}
              setIsAddModalOpen={() => setContentDetails(false)}
            />
          }
        />
      )}
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Screen Content`}
          header
          btn="Success"
          message={
            <ScreenContentForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default ScreenContent;
