import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import {
  useAddMutation,
  useUpdateMutation,
} from "@/services/gamecodeXApi";
import { COUNTER_PARTY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const CounterPartyForm = ({ editData, setIsAddModalOpen }) => {
  const [addCounterParty, { isLoading: isCounterPartyAddLoading }] =
    useAddMutation();
  const [updateCounterParty, { isLoading: isCounterPartyUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const counterPartyValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    Role: Yup.mixed().required("Please select role."),
    Entity: Yup.mixed().nullable(),
    Email: Yup.string().nullable(),
    Contract: Yup.mixed().required("Please select contract."),
  }).test('at-least-one', null, function (values) {
    const {Email, Entity} = values;
    if(!Entity && !Email) {
      return this.createError({
        path: "Entity",
        message: "Please select entity.",
      })
    }
  });

  const onSubmit = (values) => {
    const counterPartyDetail = {
      Name: values.Name,
      Description: values.Description,
      RoleTypeId: values.Role.Id,
      EntityId: values.Entity.Id,
      ...(values.Email && {Email: values.Email}),
      ContractId: values.Contract.Id,
      ...(values?.Address?.Id && {AddressId: values?.Address?.Id}),
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      counterPartyDetail.Id = editRecordDetail.Id;
      counterPartyDetail.PipelineId = editRecordDetail.PipelineId;
      updateCounterParty({
        entity: "Doc/CounterParty",
        data: counterPartyDetail,
        tag: COUNTER_PARTY_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Counter-Party updated successfully.");
        }
      });
    } else {
      addCounterParty({
        entity: "Doc/CounterParty",
        data: counterPartyDetail,
        tag: COUNTER_PARTY_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Counter-Party added successfully.");
          setIsAddModalOpen(false );
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editRecordDetail?.Name || "",
        Description: editRecordDetail?.Description || "",
        Role: editRecordDetail?.RoleTypeDTO || "",
        Entity: editRecordDetail?.EntityDTO || "",
        Email: editRecordDetail?.Email || "",
        Contract: editRecordDetail?.ContractDTO || "",
        Address: editRecordDetail?.AddressDTO || "",
      }}
      onSubmit={onSubmit}
      validationSchema={counterPartyValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.Role}
              label="Role"
              name="Role"
              entity="DocReference/DocRoleTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Role}
              fieldTouched={touched.Role}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Contract}
              label="Contract"
              name="Contract"
              entity="Doc/Contracts"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Contract}
              fieldTouched={touched.Contract}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Address}
              label="Address"
              name="Address"
              entity="Doc/Addresses"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Address}
              fieldTouched={touched.Address}
              handleBlur={handleBlur}
            />
            <div  className="border border-#dee2e6 rounded p-2 bg-light">
              <AsyncPaginates
                value={values.Entity}
                label="Entity"
                name="Entity"
                entity="Org/OrgEntities"
                entities= "$select=Id,Name&entities=Users&$expand=UserDTO($select=Id,email)"
                handleChange={(e) => {
                  const allData = values
                  allData.Entity = e
                  allData.Email = ""
                  setFieldValue("values", allData)
                }}
                fieldErrors={errors.Entity}
                fieldTouched={touched.Entity}
                handleBlur={handleBlur}
                required
              />
              <hr />
                <div className="text-center">OR</div>
              <hr />
              <CustomInput
                label="Email"
                name="Email"
                fieldErrors={errors.Email}
                fieldTouched={touched.Email}
                fieldValue={values.Email}
                handleBlur={handleBlur}
                handleChange={(e) => {
                  const allData = values
                  allData.Email = e.target.value
                  allData.Entity = ""
                  setFieldValue("values", allData)
                }}
                required
              />
            </div>
            <Button
              disabled={isCounterPartyAddLoading || isCounterPartyUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isCounterPartyAddLoading ||
                isCounterPartyUpdateLoading ) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

CounterPartyForm.propTypes = {
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
};

export default CounterPartyForm;
