import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { CONTENT_TAG_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ContentTagForm = ({ editData, setIsAddModalOpen }) => {
  const [addContentTag, { isLoading: isContentTagAddLoading }] =
    useAddMutation();
  const [
    updateContentTag,
    { isLoading: isContentTagUpdateLoading },
  ] = useUpdateMutation();

  const contentValidationSchema = Yup.object().shape({
    Tag: Yup.mixed().required("Please select tag."),
    Content: Yup.mixed().required("Please select content."),
  });

  const onSubmit = (values) => {
    const contentTagDetail = {
      TagId: values.Tag.Id,
      ContentId: values.Content.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      contentTagDetail.Id = editData.Id;
      updateContentTag({
        entity: "CMS/ContentTag",
        data: contentTagDetail,
        tag: CONTENT_TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContentTagUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Content-tag updated successfully.");
        }
      });
    } else {
      addContentTag({
        entity: "CMS/ContentTag",
        data: contentTagDetail,
        tag: CONTENT_TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContentTagAddLoading && setIsAddModalOpen(false);
          successToast("Content-tag added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Tag:
          editData && editData.TagDTO ? editData.TagDTO : "",
        Content: editData && editData.ContentDTO ? editData.ContentDTO : "",
      }}
      validationSchema={contentValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Tag}
              label="Tag"
              name="Tag"
              entity="CMS/Tags"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Tag}
              fieldTouched={touched.Tag}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Content}
              label="Content"
              name="Content"
              entity="CMS/Contents"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Content}
              fieldTouched={touched.Content}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={
                isContentTagAddLoading ||
                isContentTagUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isContentTagAddLoading ||
                isContentTagUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ContentTagForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ContentTagForm;
