import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { CHOICE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ChoiceForm = ({ editData, setIsAddModalOpen, parentId, parentData }) => {
  const [addChoice, { isLoading: isChoiceAddLoading }] = useAddMutation();
  const [updateChoice, { isLoading: isChoiceUpdateLoading }] =
    useUpdateMutation();

  const choiceValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    Option: Yup.mixed().required("Please select option."),
    Answer: Yup.mixed().required("Please select answer."),
    ChoiceType: Yup.mixed().required("Please select choice type."),
  });

  const onSubmit = (values) => {
    let choiceDetail = {
      Name: values.Name,
      Description: values.Description,
      PlaceholderText: values.PlaceholderText,
      OptionId: values.Option.Id,
      AnswerId: values.Answer.Id,
      ChoiceTypeId: values.ChoiceType.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      choiceDetail.Id = editData.Id;
      updateChoice({
        entity: "Choice",
        isWogdApi: true,
        data: choiceDetail,
        tag: CHOICE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isChoiceUpdateLoading && setIsAddModalOpen(false)
            successToast("Choice updated successfully.");
        }
      });
    } else {
      addChoice({
        entity: "Choice",
        isWogdApi: true,
        data: choiceDetail,
        tag: CHOICE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isChoiceAddLoading && setIsAddModalOpen(false);
          successToast("Choice added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        PlaceholderText:
          editData && editData.PlaceholderText ? editData.PlaceholderText : "",
        Option:
          editData && editData.Option
            ? editData.Option
            : parentData && parentData.Option
            ? parentData.Option
            : "",
        Answer:
          editData && editData.Answer
            ? editData.Answer
            : parentData && parentData.Answer
            ? parentData.Answer
            : "",
        ChoiceType:
          editData && editData.ChoiceType
            ? editData.ChoiceType
            : parentData && parentData.ChoiceType
            ? parentData.ChoiceType
            : "",
      }}
      validationSchema={choiceValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Placeholder Text"
              name="PlaceholderText"
              fieldErrors={errors.PlaceholderText}
              fieldTouched={touched.PlaceholderText}
              fieldValue={values.PlaceholderText}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.Option}
              label="Option"
              name="Option"
              entity="Option"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Option}
              fieldTouched={touched.Option}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Answer}
              label="Answer"
              name="Answer"
              entity="Answer"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.Answer}
              fieldTouched={touched.Answer}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.ChoiceType}
              label="ChoiceType"
              name="ChoiceType"
              entity="ChoiceType"
              isParentBaseUrl={true}
              setFieldValue={setFieldValue}
              fieldErrors={errors.ChoiceType}
              fieldTouched={touched.ChoiceType}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isChoiceAddLoading || isChoiceUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isChoiceAddLoading || isChoiceUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ChoiceForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
  parentData: PropTypes.object,
  parentId: PropTypes.object,
};

export default ChoiceForm;
