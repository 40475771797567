import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap';
import _ from "lodash";
import { Link } from "@material-ui/core";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import ArrowUpCircleIcon from "mdi-react/ArrowUpCircleIcon";
import ArrowDownCircleIcon from "mdi-react/ArrowDownCircleIcon";
import CreatableSelect from "react-select/creatable";
import CustomInput from "@/shared/components/form/CustomInput";
import DynamicFieldFile from './DynamicFieldFile';
import TextEditor from './TextEditor';


const customInput = [
  { value: "title", label: "Title" },
  { value: "subTitle", label: "SubTitle" },
  { value: "description", label: "Description" },
  { value: "email", label: "Email" },
  { value: "phoneNumber", label: "PhoneNumber" },
  { value: "buttonText", label: "ButtonText" },
  { value: "image", label: "Image" },
  { value: "video", label: "Video" }
];

const customInputType = [
  { value: "string", label: "String" },
  { value: "textarea", label: "Full Text" },
  { value: "textEditor", label: "Text Editor" },
  { value: "number", label: "Number" },
  { value: "email", label: "Email" },
  { value: "date", label: "Date" },
  { value: "image", label: "Image" },
  { value: "audio", label: "Audio" },
  { value: "video", label: "Video" },
]


const InsertDynamicFields = ({
  formik,
  styles,
  getFieldValue,
  onFieldChange,
  addMedia,
  isMediaAddLoading,
  storeContentId,
  editData
}) => {
  const { values, handleBlur, setFieldValue, errors, touched } = formik;
  const [isSwapToggle, setIsSwapToggle] = useState(0)
  const ContentObjectValue = JSON.parse(values?.ContentType?.ContentObject)?.dynamicFieldList || [];
  const ContentObjectType = ContentObjectValue.length > 0 ? ContentObjectValue.map(value => {
    return Object.keys(value).reduce((acc, data) => {
      acc[data] = value[data].type;
      return acc;
    }, {});
  }) : [];

  const dynamicFieldType = values?.ContentObject?.dynamicFieldType || [];
  const remainingObjects = dynamicFieldType.filter(obj => obj.isNewType)

  useEffect(() => {
    const contentObjectData = { ...values.ContentObject };
    const updatedFieldType = isSwapToggle || contentObjectData?.dynamicFieldType?.length ? [...contentObjectData.dynamicFieldType] : [...ContentObjectType, ...remainingObjects];
    if (!_.isEqual(updatedFieldType, values?.ContentObject?.dynamicFieldType) && Object.keys(ContentObjectType?.[0] || {}).length && storeContentId) {
      contentObjectData.dynamicFieldType = updatedFieldType;
      setFieldValue("ContentObject", contentObjectData);
    }
  }, [isSwapToggle, remainingObjects, dynamicFieldType, storeContentId]);

  const handleDropdownChange2 = (index, event, values, setFieldValue) => {
    const contentObjectData = values.ContentObject || {};
    if (!contentObjectData.dynamicFieldType) {
      contentObjectData.dynamicFieldType = Object.keys(ContentObjectType?.[0] || {}).length ? [{ isNewType: true }] : [{}];
    }
    const resp = {};
    const type = {};
    event.forEach((val) => {
      const itemValue = val?.value.split(" ").join("");
      const itemLabelValue = values?.ContentObject?.dynamicFieldList[index]?.[itemValue] || "";
      const itemLabelType = values?.ContentObject?.dynamicFieldType[index]?.[itemValue] || "";
      resp[itemValue] = itemLabelValue;
      type[itemValue] = itemLabelType;
    });
    contentObjectData.dynamicFieldList = contentObjectData.dynamicFieldList || [];
    contentObjectData.dynamicFieldType = contentObjectData.dynamicFieldType || [];
    const mediaType = resp.hasOwnProperty("image") ? "image" : resp.hasOwnProperty("video") ? "video" : null;
    if (mediaType) {
      resp.parentName = "";
      resp[mediaType] = { url: "", name: "", href: "" };
      type[mediaType] = mediaType;
      type.parentName = "string";
    }

    contentObjectData.dynamicFieldList[index] = { ...resp };
    if (Object.keys(ContentObjectType?.[0] || {}).length) {
      type.isNewType = true;
    }
    contentObjectData.dynamicFieldType[index] = {
      ...type
    };
    setFieldValue("ContentObject", contentObjectData);
  };
  return (
    <Col sm="12" md="12" lg="12" >
      {values.ContentObject?.dynamicFieldList && (
        <Row style={{ border: "1px solid #d3d3d3", padding: "5px", marginBottom: "8px", borderRadius: "3px" }}>
          <div style={{ width: "100%", padding: "0px 10px" }}>
            <div style={{ color: "#4ce1b6", textAlign: "center" }}>Dynamic Inputs List</div>
            {values?.ContentObject?.dynamicFieldList?.map((val, index) => {
              return <div key={`dynamicFieldList${index} `} style={{ border: "1px solid #d3d3d3", borderRadius: "3px", padding: "10px", marginBottom: "5px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Label for="ContentObject" style={{ color: "#4ce1b6" }}>
                    List {index + 1}
                  </Label>
                  <div>
                    <MinusCircleIcon
                      onClick={() => {
                        const contentObjectData = values.ContentObject
                        contentObjectData?.dynamicFieldList?.splice(index, 1);
                        contentObjectData?.dynamicFieldType?.splice(index, 1);
                        setFieldValue("ContentObject", contentObjectData);
                      }}
                      style={{
                        display: index === 0 ? "none" : "inline-block",
                        cursor: "pointer",
                      }}
                    />
                    <ArrowUpCircleIcon
                      onClick={() => {
                        const contentObjectData = values.ContentObject
                        contentObjectData?.dynamicFieldList?.splice(index - 1, 0,
                          ...contentObjectData?.dynamicFieldList?.splice(index, 1)
                        );
                        contentObjectData?.dynamicFieldType?.splice(index - 1, 0,
                          ...contentObjectData?.dynamicFieldType?.splice(index, 1)
                        );
                        setFieldValue("ContentObject", contentObjectData);
                        setIsSwapToggle(prev => prev + 1)
                      }}
                      style={{
                        display: !index ? "none" : "inline-block",
                        cursor: "pointer",
                      }}
                    />
                    <ArrowDownCircleIcon
                      onClick={() => {
                        const contentObjectData = values.ContentObject
                        contentObjectData?.dynamicFieldList?.splice(index + 1, 0,
                          ...contentObjectData?.dynamicFieldList?.splice(index, 1)
                        );
                        contentObjectData?.dynamicFieldType?.splice(index + 1, 0,
                          ...contentObjectData?.dynamicFieldType?.splice(index, 1)
                        );
                        setFieldValue("ContentObject", contentObjectData);
                        setIsSwapToggle(prev => prev + 1)
                      }}
                      style={{
                        display: values?.ContentObject?.dynamicFieldList?.length - 1 === index ? "none" : "inline-block",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <CreatableSelect
                  name='ContentObject'
                  id="ContentObject"
                  isMulti
                  placeholder="Select Inputs"
                  isClearable={false}
                  backspaceRemovesValue={false}
                  value={Object.keys(values.ContentObject?.dynamicFieldList[index])?.map((val, idx) => {
                    if (val !== 'images' && val !== 'lists' && val !== 'insertBlog' && val !== 'videos' && val !== 'mobileImages' && val !== 'dynamicFieldList') {
                      return {
                        'label': val,
                        'value': val,
                        isFixed: editData && editData.ContentObject ? Object.keys(JSON.parse(editData?.ContentObject)?.dynamicFieldList?.[index] || "")?.includes(val) : false
                      }
                    }
                  })}
                  onChange={(event) => handleDropdownChange2(index, event, values, setFieldValue)}
                  options={customInput}
                  styles={styles}
                  className="mb-2"
                  fieldErrors={errors.ContentObject}
                  fieldTouched={touched.ContentObject}
                  autoFocus={true}
                  required={true}
                />
                {values?.ContentObject?.dynamicFieldType && Object.entries(values.ContentObject?.dynamicFieldType[index])?.map((val, subIndex) => {
                  return val[0] === "images" || val[0] === "lists" || val[0] === "insertBlog" || val[0] === "videos" || val[0] === "mobileImages" || val[0] === "dynamicFieldList" || val[0] === "isNewType" ? <div key={subIndex}></div> :
                    <div key={subIndex}>
                      <Label for="InputType"> {`${val[0]}Type`}</Label>
                      <CreatableSelect
                        name='InputType'
                        id="InputType"
                        placeholder={`Select ${val[0]}'s Type`}
                        value={val[1] ? { 'label': val[1], 'value': val[1] } : null}
                        onChange={(event) => {
                          if (event.value === "image" || event.value === "audio" || event.value === "video") {
                            setFieldValue(`ContentObject.dynamicFieldType[${index}].${val[0]}`, event.value)
                            setFieldValue(`ContentObject.dynamicFieldList[${index}].${val[0]}`, { url: "", name: "", href: "" })
                          } else {
                            setFieldValue(`ContentObject.dynamicFieldType[${index}].${val[0]}`, event.value)
                            setFieldValue(`ContentObject.dynamicFieldList[${index}].${val[0]}`, "")
                          }
                        }}
                        options={customInputType}
                        styles={{
                          control: base => ({
                            ...base,
                            borderColor: val[1] === "" ? "red" : base.borderColor,
                            outline: val[1] === "" ? "red" : base.outline,
                            boxShadow: val[1] === "" ? " red" : base.boxShadow,
                            "&:hover": {
                              borderColor: val[1] === "" ? 'red' : base.borderColor,
                            }
                          }
                          )
                        }}
                        className="mb-2"
                        fieldErrors={errors.ContentObject}
                        fieldTouched={touched.ContentObject}
                        autoFocus={true}
                        required={true}
                        isSearchable={false}
                      />
                    </div>
                })}
                {values?.ContentObject?.dynamicFieldList?.length &&
                  Object.keys(values.ContentObject.dynamicFieldList[index]).map((data, fieldIndex) => {
                    if (
                      data !== "images" &&
                      data !== "lists" &&
                      data !== "videos" &&
                      data !== "mobileImages" &&
                      data !== "dynamicFieldList"
                    ) {
                      const fieldType = values?.ContentObject?.dynamicFieldType?.[index]?.[data];
                      if (values?.ContentObject?.dynamicFieldType?.[index]?.[data]) {
                        if (
                          fieldType !== "image" &&
                          fieldType !== "video" &&
                          fieldType !== "audio" &&
                          fieldType !== "textEditor"
                        ) {
                          return (
                            <CustomInput
                              label={_.startCase(data)}
                              name={data}
                              placeholder={_.startCase(data)}
                              type={
                                fieldType === "image" ||
                                  fieldType === "video" ||
                                  fieldType === "audio"
                                  ? "file"
                                  : fieldType
                              }
                              fieldValue={
                                typeof values.ContentObject.dynamicFieldList[index][data] ===
                                  "string"
                                  ? values.ContentObject.dynamicFieldList[index][data]
                                  : values.ContentObject.dynamicFieldList[index][data].value
                              }
                              handleBlur={handleBlur}
                              handleChange={(e) => {
                                const contentObjectData =
                                  values.ContentObject.dynamicFieldList[index] &&
                                  values.ContentObject.dynamicFieldList[index];
                                if (
                                  fieldType === "image" ||
                                  fieldType === "video" ||
                                  fieldType === "audio"
                                ) {
                                  contentObjectData[data].value.url =
                                    e.target.files[0];
                                } else {
                                  contentObjectData[data] =
                                    e.target.value;
                                }
                                setFieldValue(
                                  `ContentObject.dynamicFieldList[${index}]`,
                                  contentObjectData
                                );
                              }}
                            />
                          );
                        } else if (fieldType === "image" || fieldType === "video" || fieldType === "audio") {
                          return (
                            <DynamicFieldFile
                              key={fieldIndex}
                              label={data}
                              fieldType={fieldType}
                              formik={formik}
                              styles={styles}
                              getFieldValue={getFieldValue}
                              onFieldChange={onFieldChange}
                              addMedia={addMedia}
                              isDunamicField={true}
                              isMediaAddLoading={isMediaAddLoading}
                              index={index}
                            />
                          );
                        } else if (fieldType === "textEditor") {
                          return (
                            <TextEditor
                              label={data}
                              index={index}
                              formik={formik}
                            />
                          )
                        } else {
                          // return <div key={fieldIndex}></div>;
                        }
                      }
                    }
                  })
                }
              </div>
            })}
          </div>
          <div className="d-flex justify-content-end w-100">
            <Link
              onClick={() => {
                const contentObjectData = {
                  ...values.ContentObject,
                  dynamicFieldList: values.ContentObject?.dynamicFieldList || [],
                  dynamicFieldType: values.ContentObject?.dynamicFieldType || [{}]
                };
                contentObjectData.dynamicFieldList.push({});
                contentObjectData.dynamicFieldType.push(Object.keys(ContentObjectType?.[0] || {}).length > 0 ? { isNewType: true } : {})
                setFieldValue("ContentObject", contentObjectData);
              }}
              className="mt-3 cursor-pointer"
              style={{ color: "#4ce1b6" }}
              size="sm"
            >
              + Add List
            </Link>
          </div>
        </Row>
      )}
    </Col>
  )
}

export default InsertDynamicFields
