import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import {
  useAddMutation,
} from "@/services/gamecodeXApi";
import {
  APPLICATION_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ApplicationCloneForm = ({
  data,
  setIsAddCloneModalOpen,
}) => {
  const [addApplicationClone, { isLoading: isApplicationCloneAddLoading }] =
    useAddMutation();
  const applicationDetail = data;
  const applicationCloneValidationSchema = Yup.object().shape({
    Prefix: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    addApplicationClone({
      entity: `Clone?prefix=${values.Prefix}&id=${applicationDetail.Id}&tableName=application`,
      tag: APPLICATION_TAG,
    }).then((response) => {
      if (!response.error) {
        successToast("ApplicationClone added successfully.");
        setIsAddCloneModalOpen(false);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        Prefix: "",
        Id: "",
      }}
      onSubmit={onSubmit}
      validationSchema={applicationCloneValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Prefix"
              name="Prefix"
              fieldErrors={errors.Prefix}
              fieldTouched={touched.Prefix}
              fieldValue={values.Prefix}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Id"
              fieldValue={applicationDetail.Id}
              readonly
            />
            <CustomInput
              label="Table Name"
              fieldValue="Application"
              readonly
            />
            <Button
              disabled={isApplicationCloneAddLoading || isApplicationCloneAddLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isApplicationCloneAddLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ApplicationCloneForm.propTypes = {
  data: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddCloneModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
  setCurrentApplicationCloneId: PropTypes.func,
};

export default ApplicationCloneForm;
