import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { CONTACT_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ContactTypeForm = ({ editData, setIsAddModalOpen }) => {
  const [addContactType, { isLoading: isContactTypeAddLoading }] =
    useAddMutation();
  const [updateContactType, { isLoading: isContactTypeUpdateLoading }] =
    useUpdateMutation();

  const contactTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const contactTypeDetail = {
      Name: values.Name,
      Description: values.Description,
    };

    if (values.SortOrder)
      contactTypeDetail.SortOrder = parseInt(values.SortOrder);
    if (editData && Object.entries(editData).length > 0) {
      contactTypeDetail.Id = editData.Id;
      updateContactType({
        entity: "ContactType",
        isWogdApi: true,
        data: contactTypeDetail,
        tag: CONTACT_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContactTypeUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Contact-type updated successfully.");
        }
      });
    } else {
      addContactType({
        entity: "ContactType",
        isWogdApi: true,
        data: contactTypeDetail,
        tag: CONTACT_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContactTypeAddLoading && setIsAddModalOpen(false);
          successToast("Contact-type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={contactTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isContactTypeAddLoading || isContactTypeUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isContactTypeAddLoading || isContactTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ContactTypeForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ContactTypeForm;
