import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/gamecodeXApi";
import { SCREEN_TAG_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenTagForm = ({ editData, setIsAddModalOpen }) => {
  const [addScreenTag, { isLoading: isScreenTagAddLoading }] =
    useAddMutation();
  const [
    updateScreenTag,
    { isLoading: isScreenTagUpdateLoading },
  ] = useUpdateMutation();

  const screenValidationSchema = Yup.object().shape({
    Tag: Yup.mixed().required("Please select tag."),
    Screen: Yup.mixed().required("Please select screen."),
  });

  const onSubmit = (values) => {
    const screenTagDetail = {
      TagId: values.Tag.Id,
      ScreenId: values.Screen.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      screenTagDetail.Id = editData.Id;
      updateScreenTag({
        entity: "CMS/ScreenTag",
        data: screenTagDetail,
        tag: SCREEN_TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenTagUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Screen-tag updated successfully.");
        }
      });
    } else {
      addScreenTag({
        entity: "CMS/ScreenTag",
        data: screenTagDetail,
        tag: SCREEN_TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenTagAddLoading && setIsAddModalOpen(false);
          successToast("Screen-tag added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Tag:
          editData && editData.TagDTO ? editData.TagDTO : "",
        Screen: editData && editData.ScreenDTO ? editData.ScreenDTO : "",
      }}
      validationSchema={screenValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Tag}
              label="Tag"
              name="Tag"
              entity="CMS/Tags"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Tag}
              fieldTouched={touched.Tag}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Screen}
              label="Screen"
              name="Screen"
              entity="CMS/Screens"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Screen}
              fieldTouched={touched.Screen}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={
                isScreenTagAddLoading ||
                isScreenTagUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isScreenTagAddLoading ||
                isScreenTagUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ScreenTagForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ScreenTagForm;
