import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/gamecodeXApi";
import { SCREEN_MAPPING_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllScreenMappings } from "../SiteSlice";
import "react-toastify/dist/ReactToastify.css";
import ScreenMappingForm from "./ScreenMappingForm";

const ScreenMapping = () => {
    const [screenMappingDetails, setScreenMappingDetails] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [deleteScreenMapping, { isLoading: isDeleting }] =
      useDeleteMutation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState([]);
    const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
    const [columnFilter, setColumnFilter] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [
      getScreenMappingRecords,
      {
        data: screenMappingList,
        isError: isScreenMappingError,
        isLoading: isScreenMappingLoading,
        isFetching: isScreenMappingFetching,
      }
    ] = useLazyGetRecordsQuery();
  
    const column = [
      {
        Header: "#",
        accessor: "Id",
        type: "Number",
      },
      {
        Header: "Screen Name",
        accessor: "ScreenDTO.Name",
        filter: true,
      },
      {
        Header: "Web Address",
        accessor: "LongWebAddress",
        filter: true,
      },
    ];
  
    const handleEditData = (screenMappingData) => {
      setSelectedRecord(screenMappingData);
      setIsAddModalOpen(true);
    };
  
    const handleDelete = () => {
      deleteScreenMapping({
        entity: "CMS/ScreenMapping",
        id: selectedRecord.Id,
        tag: SCREEN_MAPPING_TAG,
      }).then((response) => {
        if (!response.error) {
          !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
          successToast("Screen mapping deleted successfully.");
        }
      });
    };
  
    useEffect(() => {
      if (location.state && location.state?.name) {
        setColumnFilter({
          [`${location.state.title.split(" ").join("")}.Name`]:
            location.state.name,
        });
      }
      history.push({ pathname: location.pathname, state: {} });
      // eslint-disable-next-line
    }, []);
  
    useEffect(() => {
      let { filterColumn, filterQuery } = formateColumnFilter(
        column,
        columnFilter
      );
  
      getScreenMappingRecords({
        entity: "CMS/ScreenMappings",
        entities: { Screen: {} },
        top: pageSize,
        skip: (page - 1) * pageSize,
        sort: sort,
        filter: filterQuery,
        globalFilter: {
          columns: filterColumn,
          globalSearchKeyword: globalSearchKeyword,
        },
        tag: SCREEN_MAPPING_TAG,
      });
      setScreenMappingDetails({ tableHeaderData: column, tableRowsData: [] });
      // eslint-disable-next-line
    }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);
  
    useEffect(() => {
      if (
        !isScreenMappingLoading &&
        !isScreenMappingError &&
        screenMappingList &&
        !isScreenMappingFetching
      ) {
        dispatch(getAllScreenMappings(screenMappingList.value));
        let screenMappingData = [...screenMappingList.value];
        setScreenMappingDetails({
          ...screenMappingDetails,
          tableRowsData: screenMappingList.value ? screenMappingData : [],
          totalData:
            screenMappingList && screenMappingList["@odata.count"]
              ? screenMappingList["@odata.count"]
              : 0,
          onEditClick: (data) => handleEditData(data),
          onDeleteClick: (data) => {
            setIsDeleteModalOpen(true);
            setSelectedRecord(data);
          },
        });
      }
      // eslint-disable-next-line
    }, [
      isScreenMappingError,
      screenMappingList,
      isScreenMappingLoading,
      isScreenMappingFetching,
    ]);
  
  return (
    <Container>
        <Row>
          {screenMappingDetails && (
            <DataReactTable
              reactTableData={screenMappingDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              title="Screen Mapping"
              isFetching={isScreenMappingFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Screen Mapping`}
            header
            btn="Success"
            message={
              <ScreenMappingForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsAddModalOpen={setIsAddModalOpen}
              />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={`Are you sure you want to delete?`}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
  )
}

export default ScreenMapping
