import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { errorToast } from '../shared/components/toast';
import { TagType } from '../shared/tagFile';

const errorModal = (error, arg, errMessage) => {
  let errorMessage = "";
  if (errMessage) {
    errorMessage = `${errMessage?.value || errMessage}`;
  } else {
    switch (error.status) {
      case 404:
        errorMessage = "Error 404 not found";
        break;
      case 500:
        errorMessage = "Error 500 internal server error";
        break;
      case 401:
        errorMessage = "Error 401 unauthorized";
        break;
      case 400:
        errorMessage = "Error 400 bad request";
        break;
      case 417:
        errorMessage = error.data.value;
        break;
      default:
        errorMessage = `Something went wrong calling ${arg.entity}`;
        break;
    }
  }
  errorToast(errorMessage);
}

const expandChildQuery = (expand) => {
  return "$expand=" + Object.entries(expand).map(
    (o) => `${o[0]}
          ${o[1].fields
        ? `($filter=IsDeleted ne true;${o[1].top ? `$top=${o[1].top};` : ""}$select=${o[1].fields};${o[1].expand
          ? expandChildQuery(o[1].expand)
          : o[1].filter ? `($filter=${o[1].filter})` : ""})`
        : `${o[1].expand
          ? `($filter=IsDeleted ne true;${o[1].top ? `$top=${o[1].top};` : ""}${expandChildQuery(o[1].expand)})`
          : o[1].filter ? `($filter=${o[1].filter})` : ""}`}`
  ).join(",").trim();
};
const entitiesChildQuery = (entities) => {
  return Object.entries(entities).map(
    (o) => {
      return `entities=${o[0]}`
    }
  ).join("&").trim();
};

const baseAPIQuery = ({
  entity,
  subEntity = "",
  filter = "",
  sort = [],
  skip = 0,
  top = 10,
  expand = {},
  entities = {},
  query = "",
  globalFilter = {},
  isArchive = false,
}, allRecords) => {
  let finalUrl = `${entity}`;
  let filterQuery = isArchive ? "" : "$filter=IsDeleted ne true";
  if (subEntity !== "") {
    finalUrl += `/${subEntity}`;
  }
  if (Object.entries(globalFilter).length > 0) {
    if (
      globalFilter.globalSearchKeyword &&
      globalFilter.globalSearchKeyword !== ""
    ) {
      let filterData = [];
      globalFilter.columns.forEach((value) => {
        if (
          value.Header !== "Action" &&
          value.Header !== "Media" &&
          typeof value.accessor !== "function" &&
          value.type !== "Boolean"
        ) {
          if (value.type !== "Number") {
            filterData.push(
              `contains(tolower(${value.accessor.replaceAll(".", "/")}),'${
                encodeURIComponent(globalFilter.globalSearchKeyword).toLowerCase()
              }')`
            );
          } else if (!isNaN(globalFilter.globalSearchKeyword)) {
            filterData.push(
              `${value.accessor} eq ${globalFilter.globalSearchKeyword}`
            );
          }
        }
      });
      filterQuery += ` and (${filterData.join(" or ")})`;
    }
  }
  if (filter !== "") {
    filterQuery += isArchive ? `$filter=(${filter})` : ` and (${filter})`;
  }
  finalUrl += allRecords ? '' : `?$skip=${skip}&$top=${top}&$count=true`;
  if (query !== "") {
    finalUrl = `${finalUrl}&${query}`;
  }
  if (sort.length > 0) {
    let sortQuery = allRecords ? "?orderby=" : "&$orderby=";
    sortQuery += sort.map((value) => {
      return `${value.columnName} ${value.direction}`;
    });
    finalUrl += sortQuery;
  } else {
    finalUrl += allRecords ? "?$orderby=Id desc" : "&$orderby=Id desc";
  }
  if (Object.entries(expand).length > 0) {
    let expandQueryStr = "";
    expandQueryStr = "&" + expandChildQuery(expand);
    finalUrl += expandQueryStr;
    Object.entries(expand).forEach((o) => {
      if (!o[1].expand && !o[1].filter) {
        filterQuery += ` and ${o[0]}/IsDeleted ne true`;
      }
    });
  }
  if (Object.entries(entities).length > 0) {
    let entitiesQueryStr = "";
    entitiesQueryStr = "&" + entitiesChildQuery(entities);
    finalUrl += entitiesQueryStr;
  }
  if (isArchive){
    finalUrl += "&isArchive=true"
  }
  finalUrl += `&${filterQuery}`;
  return finalUrl
};

const getBaseQuery = (args, WebApi, extraOptions) => {
  const func = fetchBaseQuery({
    baseUrl:
     args.isWogdApi 
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL_ZWEIHANDER,
  });

  return func(args, WebApi, extraOptions);
};

export const gamecodeXApi = createApi({
  reducerPath: 'gamecodeXApi',
  tagTypes: TagType,
  baseQuery: getBaseQuery,
  // baseQuery: fetchBaseQuery({
  //   baseUrl: process.env.REACT_APP_API_URL,
  // }),
  endpoints: (builder) => ({
    getRecords: builder.query({
      query: (params) => {
        const finalUrl = baseAPIQuery(params, false);
        const isWogdApi = params?.isWogdApi || false
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${finalUrl}`,
          method: 'GET',
          isWogdApi: isWogdApi,
          headers: {
            'content-type': `application/json;odata=verbose`,
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      },
      providesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg);
        }
        return (arg.tag ? [arg.tag] : [])
      },
    }),
    getAllRecords: builder.query({
      query: (params) => {
        const finalUrl = baseAPIQuery(params, true);
        const isWogdApi = params?.isWogdApi || false
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${finalUrl}`,
          method: 'GET',
          isWogdApi: isWogdApi,
          headers: {
            'content-type': `application/json;odata=verbose`,
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      },
      providesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg);
        }
        return (arg.tag ? [arg.tag] : [])
      },
    }),
    getRecordsById: builder.query({
      query: ({ entity, id, expand, isWogdApi = false }) => {
        let finalUrl = `${entity}/${id}`;
        if (expand && Object.entries(expand).length > 0) {
          let expandQueryStr = "";
          expandQueryStr = "?" + expandChildQuery(expand);
          finalUrl += expandQueryStr;
        }
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${finalUrl}`,
          method: 'GET',
          isWogdApi: isWogdApi,
          headers: {
            'content-type': `application/json;odata=verbose`,
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      },
      providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    }),
    getSimpleRecords: builder.query({
      query: ({ entity }) => {
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${entity}`,
          method: "GET",
          headers: {
            'content-type': `application/json;odata=verbose`,
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      },
      providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    }),
    add: builder.mutation({
      query: ({
        entity,
        query="",
        data,
        isWogdApi = false,
        responseType = ""
      }) => {
        let accessToken = localStorage.getItem("accessToken");
        return ({
          url: entity,
          ...(responseType === 'text' && { responseHandler: (response) => response.text() }),
          method: 'POST',
          body: data,
          isWogdApi: isWogdApi,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
          params:query
        })
      },
      invalidatesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg, error?.data);
        }
        return (arg.tag ? typeof arg.tag === "object" ? [...arg.tag] : [arg.tag] : [])
      },
    }),
    update: builder.mutation({
      query: ({
        entity,
        data,
        query = "",
        Id = 0,
        isWogdApi = false,
        responseType = ""
      }) => {
        let accessToken = localStorage.getItem("accessToken");
        return ({
          url: `${entity}${(Id || data.Id) ? "/" + +(Id ? Id : data.Id) : ""}${query ? `?${query}` : ""}`,
          ...(responseType === 'text' && { responseHandler: (response) => response.text() }),
          isWogdApi: isWogdApi,
          method: 'PUT',
          body: data,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        })
      },
      invalidatesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg, error?.data);
        }
        return (arg.tag ? typeof arg.tag === "object" ? [...arg.tag] : [arg.tag] : [])
      },
    }),
    delete: builder.mutation({
      query: ({ entity, id, isWogdApi = false }) => {
        let accessToken = localStorage.getItem("accessToken");
        return ({
          url: `${entity}/${id}`,
          method: 'DELETE',
          isWogdApi: isWogdApi,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        })
      },
      invalidatesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg, error?.data);
        }
        return (arg.tag ? [arg.tag] : [])
      },
    }),
  }),
})

export const {
  useGetRecordsQuery,
  useLazyGetRecordsQuery,
  useGetRecordsByIdQuery,
  useLazyGetRecordsByIdQuery,
  useGetAllRecordsQuery,
  useLazyGetAllRecordsQuery,
  useAddMutation,
  useUpdateMutation,
  useDeleteMutation,
  useLazyGetSimpleRecordsQuery
} = gamecodeXApi
