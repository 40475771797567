import React, { useState } from "react";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { getIn } from "formik";
import { Divider } from "@material-ui/core";
import { CONTENT_TAG, SITE_MEDIA_TAG } from "@/shared/tagFile";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { successToast } from "@/shared/components/toast";

const DynamicFieldFile = ({
    label,
    fieldType,
    formik,
    addMedia,
    isMediaAddLoading,
    index= 0,
  }) => {
    const { errors, touched, values, handleBlur, setFieldValue } = formik;

    const [currFileUploadButton, setCurrFileUploadButton] = useState("");

    const uploadImage = (setFieldValues, data, values) => {
      setCurrFileUploadButton(`File`);
      var formData = new FormData();
      const mediaTypeId =
        fieldType === "image" ? 1 : fieldType === "video" ? 3 : 2;
      if (values[`File`] !== undefined) {
        formData.append("MediaTypeId", mediaTypeId);
        formData.append("PathFolderName", "content");
        formData.append("File", values[`File`]);
      }
  
      addMedia({
        entity: `CMS/Media`,
        data: formData,
        tag: [CONTENT_TAG, SITE_MEDIA_TAG],
      }).then((response) => {
        if (!response.error) {
          const contentObjectData = values.ContentObject;
          contentObjectData.dynamicFieldList[index][label].url = response.data.url;
          contentObjectData.dynamicFieldList[index][label].name = response.data.name;
          setFieldValues(`File`, "");
          setFieldValues("ContentObject", contentObjectData);
          successToast("Media uploaded successfully.");
        }
      });
    };
  
    const fileName = values.ContentObject.dynamicFieldList[index][label];
    const touchedName = getIn(touched, fileName);
  return (
    <div>
      {values.ContentObject.dynamicFieldList[index][label] &&
        Object.keys(values.ContentObject?.dynamicFieldList[index][label])?.length && (
          <>
            {
              <div style={{border : "1px solid #d3d3d3" , padding : "10px", marginBottom : "8px" , borderRadius : "3px"}}>
                <Label for="ContentObject" style={{ color: "#4ce1b6" }}>
                  {label}
                </Label>
                <Row>
                  {Object.keys(Object.hasOwn(values.ContentObject.dynamicFieldList[index]?.[label],"value") ? values.ContentObject.dynamicFieldList[index]?.[label]["value"] : values.ContentObject.dynamicFieldList[index]?.[label]).map(
                    (data, imgIndex) => {
                      const content = values.ContentObject.dynamicFieldList[index][label][data];
                      const fileExtension = content?.split('.').pop();
                      const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension?.toLowerCase());
                      const isAudio = ['mp3', "wav", "ogg", "m4a"].includes(fileExtension?.toLowerCase());
                      const dataType = isImage ? 'image' : isAudio ? 'audio' : 'video';
                      if (data === "url") {
                        return (
                          <React.Fragment key={`Image${imgIndex}`}>
                            <Col md={12} key={imgIndex}>
                              <Row>
                                <Col>
                                  {values.ContentObject.dynamicFieldList[index][label][data] && dataType === 'video' && (
                                    <>
                                      <iframe
                                        width="100%"
                                        height="100%"
                                        src={values.ContentObject.dynamicFieldList[index][label].url}
                                        title="YouTube video player"
                                        frameborder="0"
                                        style={{ marginBottom: "10px" }}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        ></iframe>
                                    </>
                                  )}
                                  {values.ContentObject.dynamicFieldList[index][label][data] && dataType === 'image' && (
                                    <>
                                      <img
                                        src={values.ContentObject.dynamicFieldList[index][label].url}
                                        alt="Uploaded Image"
                                        style={{ width: "100%", height: "auto", marginBottom: "10px" }}
                                      />
                                    </>
                                  )}
                                  {values.ContentObject.dynamicFieldList[index][label][data] && dataType === 'audio' && (
                                    <audio controls style={{width : "100%"}}>
                                      <source 
                                        src={values.ContentObject.dynamicFieldList[index][label].url}
                                        alt="Uploaded Audio"
                                      />
                                    </audio>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <AsyncPaginates
                                    label="Choose from Existing"
                                    value={
                                      values.ContentObject.dynamicFieldList[index]?.[label]?.name?.length
                                        ? values.ContentObject.dynamicFieldList[index]?.[label]
                                        : values.ContentObject.dynamicFieldList[index]?.[label].name
                                    }
                                    fieldName={label}
                                    fieldType={fieldType}
                                    name="File"
                                    type="dynamicFieldList"
                                    entity="CMS/Medias"
                                    allValues={values}
                                    setFieldValue={setFieldValue}
                                    fieldErrors={errors.File}
                                    fieldTouched={touched.File}
                                    isAllowToAddContent={true}
                                    handleBlur={handleBlur}
                                    index={index}
                                  />
                                </Col>
                              </Row>
                              <Row className="align-items-center">
                                <Col xs={5} sm={5} md={5} lg={5}>
                                  <Divider
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                  <h4>OR</h4>
                                </Col>
                                <Col xs={5} sm={5} md={5} lg={5}>
                                  <Divider
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </Col>
                              </Row>
                                <Row>
                                  <Col>
                                    <CustomInput
                                      label={`${fieldType} URL`}
                                      name={label}
                                      placeholder={`Enter ${fieldType} url`}
                                      fieldValue={
                                        values.ContentObject.dynamicFieldList[index]?.[label]?.url?.length
                                            ? values.ContentObject.dynamicFieldList[index][label].url
                                            : ""
                                      }
                                      handleBlur={handleBlur}
                                      handleChange={(e) => {
                                        const contentObjectData = values.ContentObject;
                                        
                                          contentObjectData.dynamicFieldList[index][label].url = 
                                          e.target.value?.includes("https://www.youtube.com") ? 
                                          e.target.value?.replace("watch?v=","embed/").split("&")[0] : 
                                          e.target.value?.includes("https://giphy.com") ?
                                          `https://media4.giphy.com/media/${e.target.value.split("-").pop()}/giphy.gif` :
                                          e.target.value;
                                        setFieldValue(
                                          "ContentObject",
                                          contentObjectData
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>

                                <Row className="align-items-center">
                                  <Col xs={5} sm={5} md={5} lg={5}>
                                    <Divider
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    />
                                  </Col>
                                  <Col xs={2} sm={2} md={2} lg={2}>
                                    <h4>OR</h4>
                                  </Col>
                                  <Col xs={5} sm={5} md={5} lg={5}>
                                    <Divider
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    />
                                  </Col>
                                </Row>
                              
                              <Row>
                                <Col>
                                  <CustomInput
                                    label="File"
                                    name="File"
                                    type="file"
                                    accept={
                                      fieldType === "image"
                                        ? "image/*"
                                        : fieldType === "video"
                                        ? "video/*"
                                        : "audio/*"
                                    }
                                    handleChange={(e) => {
                                      setFieldValue(`File`, e.target.files[0]);
                                    }}
                                    fieldErrors={errors.File}
                                    fieldTouched={touched.File}
                                    handleBlur={handleBlur}
                                  />
                                </Col>
                                <Col
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    disabled={
                                      !(values[`File`] !== undefined) ||
                                      values[`File`] === "" ||
                                      isMediaAddLoading
                                    }
                                    color="success"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      uploadImage(setFieldValue, data, values);
                                    }}
                                  >
                                    {((values[`File${index}`] === "") ||
                                      (isMediaAddLoading &&
                                        currFileUploadButton === `File`)) && (
                                          <Spinner size="sm" variant="light" />
                                        )}{" "}
                                    Upload
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <Col md={12} key={imgIndex}>
                            <CustomInput
                              label={data}
                              name={data}
                              fieldValue={
                                values.ContentObject.dynamicFieldList[index][label][data]
                              }
                              handleBlur={handleBlur}
                              handleChange={(e) => {
                                const contentObjectData =
                                values.ContentObject && values.ContentObject;
                                contentObjectData.dynamicFieldList[index][label][data] =
                                e.target.value;
                                setFieldValue(
                                  "ContentObject",
                                  contentObjectData
                                );
                              }}
                            />
                          </Col>
                        );
                      }
                    }
                  )}
                </Row>
              </div>
            }
          </>
        )}
    </div>
  )
}

export default DynamicFieldFile
