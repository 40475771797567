import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import {
  useAddMutation,
  useUpdateMutation,
} from "@/services/gamecodeXApi";
import { TAG_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const TagForm = ({ editData, setIsAddModalOpen }) => {
    const [addTag, { isLoading: isTagAddLoading }] =
    useAddMutation();
  const [updateTag, { isLoading: isTagUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const tagValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    TagType: Yup.mixed().required("Please select tag-type."),
  });

  const onSubmit = (values) => {
    const tagDetail = {
      Name: values.Name,
      Description: values.Description,
      TagTypeId: values.TagType.Id,
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      tagDetail.Id = editRecordDetail.Id;
      updateTag({
        entity: "CMS/Tag",
        data: tagDetail,
        tag: TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Tag updated successfully.");
        }
      });
    } else {
      addTag({
        entity: "CMS/Tag",
        data: tagDetail,
        tag: TAG_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Tag added successfully.");
          setIsAddModalOpen(false );
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editRecordDetail?.Name || "",
        Description: editRecordDetail?.Description || "",
        TagType: editRecordDetail?.TagTypeDTO || "",
      }}
      onSubmit={onSubmit}
      validationSchema={tagValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.TagType}
              label="Tag Type"
              name="TagType"
              entity="CMSReference/TagTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.TagType}
              fieldTouched={touched.TagType}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isTagAddLoading || isTagUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isTagAddLoading ||
                isTagUpdateLoading ) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

TagForm.propTypes = {
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
};

export default TagForm
